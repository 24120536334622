import type { FC } from "react";

export const ItemsBlock: FC<Pick<StagingBuilder, "items">> = ({ items }) => {
  return (
    <div className="relative col-start-2 row-start-3 m-2 mr-4 mt-0 block w-full lg:col-start-4 lg:row-span-2 lg:row-start-2">
      <div className="relative flex h-36 w-full rounded-3xl p-6 pt-0 pl-0 sm:p-0 sm:pt-3 md:h-auto">
        <div className="relative w-full pt-[100%] ">
          <div className="absolute inset-0">
            <div className="relative h-full w-full pt-[100%] lg:h-4/5 lg:pt-[80%]">
              <div className="absolute top-0 z-10 h-full w-full">
                <div className="pt-[25%] pl-[10%] lg:pt-[15%]">
                  <span className="mb-1 block text-center text-xl font-normal leading-none tracking-tighter text-gray-600 lg:text-lg">
                    Items
                  </span>
                  <span className="block text-center text-xl font-bold leading-none lg:text-2xl lg:font-normal">
                    {items}
                  </span>
                </div>
              </div>
              <img
                src="/media/static-images/cart2.png"
                alt="cart"
                className="absolute inset-0 object-contain"
                style={{
                  filter: "drop-shadow(3px 3px 1px var(--logo-blue))",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <span className="sm:h-full md:h-full lg:h-full"></span>
    </div>
  );
};
