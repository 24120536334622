import axios from "axios";
import { toast } from "react-toastify";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { fetchingData, Istate } from "../..";
import {
  fetchingDataFailure,
  fetchingDataSuccess,
} from "../../users/userActions";
import { updateFileNumber } from "../actions/updateFileNumber";

export const handleFileNumberChange = ({
  fileNumber,
  notify,
}: {
  fileNumber: number;
  notify: (content: string) => React.ReactText;
}): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: Function
  ): Promise<void> => {
    dispatch(fetchingData()); // active loading sign

    const { data }: { data: Istate["data"] } = getState();
    const stagingBuilderID = data.user.activeStagingBuilder;
    axios({
      method: "patch",
      url: "/api/form/staging-builder/file-number",
      data: {
        stagingBuilderID: data.user.activeStagingBuilder,
        fileNumber,
      },
    })
      .then((res) => {
        if (res.status === 201) {
          dispatch(
            updateFileNumber({
              stagingBuilderID,
              newFileNumber: res.data.newFileNumber,
              editedOn: res.data.editedOn,
            })
          );
          notify("File number changed successfully.");
        } else throw new Error("Something went wrong");
      })
      .then(() => {
        dispatch(fetchingDataSuccess());
      })
      .catch((err) => {
        if (err.response !== undefined) {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
            dispatch(fetchingDataFailure());
          } else dispatch(fetchingDataFailure(err.response.status));
        } else {
          console.error(err);
          dispatch(fetchingDataFailure());
        }
      });
  };
};
