import { Link } from "react-router-dom";

import { shallowEqual, useSelector } from "react-redux";
import { Istate } from "../../../redux";

import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import FormikControl from "../../FormikComponents/FormikControl";
import * as Yup from "yup";

import axios from "axios";
import { toast } from "react-toastify";
import { CustomButton } from "../../HighlyReusableComponents/CustomButton";

export const RecoverForm = () => {
  const loginFailed = useSelector(
    (state: Istate) => state.data.user.loginFailed,
    shallowEqual
  );
  const notify = (content: string) => toast(content);
  const initialValues = {
    email: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string().required("Required").email("Invalid email format"),
  });
  const onSubmit = (
    values: typeof initialValues,
    actions: FormikHelpers<typeof initialValues>
  ) => {
    axios({
      method: "post",
      url: "/api/form/users/recover-account",
      data: { email: values.email },
    })
      .then((res) => {
        if (res.data.success === true) {
          notify(
            "You will receive an email shortly with directions on how to reset your password."
          );
          actions.setSubmitting(false);
        }
      })
      .catch((err) => {});
  };

  return (
    <div className="mt-3 mx-8">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(props: FormikProps<any>) => (
          <Form>
            {loginFailed && (
              <div className="input-container">Incorrect email or password</div>
            )}
            <FormikControl
              control="input"
              type="email"
              label="Email"
              name="email"
              inLineLabelError={true}
              styledClass="focus:outline-none focus:ring"
            />
            <CustomButton disabled={props.isSubmitting} type="submit">
              {props.submitCount ? "Check Your Email" : "Recover Account"}
            </CustomButton>
          </Form>
        )}
      </Formik>
      <div className="flex justify-between">
        <Link to="/user/login">Login</Link>
        <Link className="right" to="/user/create-account">
          Create Account
        </Link>
      </div>
    </div>
  );
};
