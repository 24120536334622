import { push } from "connected-react-router";
import React from "react";
import { useDispatch } from "react-redux";
import { AppThunk } from "../../../../redux";
import { LocaleNumber } from "../../../constants";

interface Props {
  item: itemVerbose;
  setGroupModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ItemPreview = ({ item, setGroupModalOpen }: Props) => {
  const dispatch: AppThunk = useDispatch();
  const goToItem = () => {
    dispatch(push(`/inventory/edit/${item.itemNo}`));
    setGroupModalOpen(false);
  };
  return (
    <div
      className="grid p-2 m-1 simple-border"
      style={{
        gridTemplateColumns: "auto 1fr",
      }}
    >
      <img
        className="pointer"
        onClick={() => goToItem()}
        style={{
          maxHeight: "100px",
          maxWidth: "100px",
        }}
        src={item.pictureA}
        alt={item.itemName}
      />
      <div
        className="bg-white p-1 ml-2 grid gap-1"
        style={{
          gridTemplateColumns: "auto 1fr",
        }}
      >
        <span>ItemNo:</span>
        <span>{item.itemNo}</span>
        <span>Item Name:</span>
        <span>{item.itemName}</span>
        <span>Rental Rate:</span>
        <span>${LocaleNumber(item.rentalRate)}</span>
        <span>Retail Value:</span>
        <span>${LocaleNumber(item.rentalPrice)}</span>
      </div>
    </div>
  );
};
