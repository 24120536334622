import { useState, FunctionComponent, memo, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { AppDispatch, setRoutePayload } from "../../redux";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { ContextMenu } from "../HighlyReusableComponents/ContextMenu";
import { RowContextMenu } from "./RowContextMenu";
import { ItemHoverView } from "../Stage/draggableInventory/DraggableItem.tsx/ItemHoverView";

interface IpropsInventoryListRow {
  itemID: number;
  index: number;
}
const InventoryListRowFC: FunctionComponent<IpropsInventoryListRow> = ({
  index,
  itemID,
}) => {
  const location = useLocation();
  // use index prop if it is in range of filtered ivnentory
  // findIndex if it is not
  // if the itemID is incorrect default to find index
  // const item = useSelector(
  //   (state: Istate) =>
  //     state.data.filteredInventory.length > index
  //       ? state.data.filteredInventory[index].itemID === itemID
  //         ? state.data.filteredInventory[index]
  //         : state.data.filteredInventory[
  //             state.data.filteredInventory.findIndex(
  //               (item) => item.itemID === itemID
  //             )
  //           ]
  //       : state.data.filteredInventory[
  //           state.data.filteredInventory.findIndex(
  //             (item) => item.itemID === itemID
  //           )
  //         ],
  //   shallowEqual
  // );
  const item = useSelector(
    (state: Istate) =>
      state.data.filteredInventory.find(
        ({ itemID: itemID2 }) => itemID2 === itemID
      ),
    shallowEqual
  );
  const dispatch: AppDispatch = useDispatch();
  const inventoryItemSelected = (item: itemVerbose | undefined) => {
    if (item && location.pathname !== `/inventory/edit/${item.itemNo}`) {
      dispatch(push(`/inventory/edit`));
      dispatch(setRoutePayload({ key: "Inventory", payload: item }));
      dispatch(push(`/inventory/edit/${item.itemNo}`));
    }
  };
  const [thumbnailFailed, setThumbnailFailed] = useState(true);
  const [expandThumbnail, setExpandThumbnail] = useState(false);
  return !item ? null : (
    <ContextMenu menu={<RowContextMenu {...item} />}>
      {(contextEvent) => (
        <tr
          className="inventory-row"
          onClick={() => inventoryItemSelected(item)}
          onContextMenu={contextEvent}
        >
          <td
            className="relative"
            onMouseLeave={() => setExpandThumbnail(false)}
            onMouseEnter={() => setExpandThumbnail(true)}
          >
            {expandThumbnail && (
              <ItemHoverView
                rowItem={item}
                setExpandThumbnail={setExpandThumbnail}
                target="right-side-container"
              />
            )}
            <picture>
              {thumbnailFailed && (
                <source
                  srcSet={`https://storage.googleapis.com/modelhomefurnitureoutlet.com/thumbnails/item-${item?.itemNo}.jpg`}
                />
              )}
              <img
                onError={() => setThumbnailFailed(false)}
                loading="lazy"
                className="inventory-image"
                src={item.pictureA}
                alt={"item-" + item.itemNo}
              />
            </picture>
          </td>
          <td>{item.itemNo}</td>
          <td>{item.quantity}</td>
          <td>{item.itemGroups[0] !== "" ? item.itemGroups.length : null}</td>
          <td>{item.rentalPrice ? item.rentalPrice : "null"}</td>
          <td>{item.rentalRate ? item.rentalRate : "null"}</td>
          <td>{item.itemName}</td>
        </tr>
      )}
    </ContextMenu>
  );
};
export const InventoryListRow = memo(InventoryListRowFC);
