import { push } from "connected-react-router";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import useSWR from "swr";
import { fetcher } from "../../../../../fetcher";
import { ToastyRed } from "../../../../constants";
import { Data } from "../controls/Main";
import { Report } from "./Report";

interface Props {}

export const Main = (props: Props) => {
  // redirect if the current user does not have a section
  const { data } = useSWR<Data>("/api/effects/get/user-section", fetcher);
  const { section } = { ...data };
  const dispatch = useDispatch();
  useEffect(() => {
    if (section === "0") {
      ToastyRed(
        "You do not currently have a section. Select one on this page."
      );
      dispatch(push("/inventory/delta/controls"));
    }
  }, [section, dispatch]);
  // create api endpoint to get items in the current users section
  // fetch the items that are in the current users section

  if (!data) return null;
  return <Report />;
};
