import { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { pushStagingBuilderNote } from "../../../redux/stagingBuilder/stagingBuilderActions";

export const StagingBuilderNotesSocket = () => {
  const dispatch = useDispatch();
  const socket = useSelector(
    (state: Istate) => state.data.socket,
    shallowEqual
  );
  useEffect(() => {
    const handleNewStagingBuilderNote = (stringyData: string) => {
      const data: { message: StagingBuilderNote; success: boolean } =
        JSON.parse(stringyData);
      const { message } = data;
      const { stagingBuilderID } = message;
      dispatch(pushStagingBuilderNote({ stagingBuilderID, message }));
    };
    socket?.off("NEW_STAGING_BUILDER_NOTE", handleNewStagingBuilderNote);
    socket?.on("NEW_STAGING_BUILDER_NOTE", handleNewStagingBuilderNote);
    return () => {
      socket?.off("NEW_STAGING_BUILDER_NOTE", handleNewStagingBuilderNote);
    };
  }, [socket, dispatch]);
  return <></>;
};
