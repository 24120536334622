import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Istate } from "../../../../redux";
import { ItemPreview } from "./ItemPreview";
interface Props {
  itemGroups: string[];
  groupsArray: {
    [index: string]: number[];
  };

  setGroupModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const arrayReducer = (a: number[], c: number[]) => (c ? [...a, ...c] : [...a]);
export const ItemsInOtherGroups = ({
  itemGroups,
  groupsArray,
  setGroupModalOpen,
}: Props) => {
  const groupedItems =
    itemGroups &&
    itemGroups
      .filter((groupID) => groupID !== "")
      .map((groupID) => groupsArray[groupID]);
  const matchedItems = groupedItems
    ? Array.from(new Set(groupedItems.reduce(arrayReducer, [])))
    : [];
  const items = useSelector((state: Istate) => {
    let temp: { [index: string]: typeof state.data.inventory[0] } = {};
    state.data.inventory
      .filter(
        ({ itemNo, pictureA, itemID }) =>
          Array.from(new Set([...matchedItems, itemID])).length ===
          matchedItems.length
      )
      .forEach((item) => (temp[item.itemID.toString()] = { ...item }));
    return temp;
  }, shallowEqual);
  return (
    <div>
      {groupedItems &&
        groupedItems.map((group, index1) => (
          <React.Fragment key={index1}>
            <h2 className="p-1 pl-2 bg-logo-purple text-white text-lg">
              Group: {itemGroups[index1]}
            </h2>
            <div className="grid col-2">
              {group &&
                group.map(
                  (itemID, index2) =>
                    items[itemID.toString()] && (
                      <ItemPreview
                        key={index2}
                        setGroupModalOpen={setGroupModalOpen}
                        item={items[itemID.toString()]}
                      />
                    )
                )}
            </div>
          </React.Fragment>
        ))}
    </div>
  );
};
