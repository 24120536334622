import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { fetchingData } from "../../..";
import { fetchingDataSuccess } from "../../../users/userActions";
import { ContainerItem } from "../../stagingBuilderTypes";

interface AddNewContainerItemProps {
  stagingBuilderID: number;
  itemContainerID: number;
  itemID: number;
}

export const newContainerItem = (
  containerItem: AddNewContainerItemProps
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: Function
  ): Promise<void> => {
    dispatch(fetchingData()); // active loading sign
    const {
      data: { socket },
      router: {
        location: { pathname },
      },
    } = getState() as Istate;

    const payload = JSON.stringify({
      type: "CONTAINER_ITEM",
      action: "NEW",
      payload: {
        stagingBuilderID: containerItem.stagingBuilderID,
        containerItem,
        pathname,
      },
    });
    socket?.emit("STAGING_BUILDER_UPDATE", payload);
  };
};

export const addNewContainerItem = (
  containerItem: ContainerItem
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(addNewContainerItemRedux(containerItem));
    dispatch(fetchingDataSuccess()); // active loading sign
  };
};

const addNewContainerItemRedux = (
  containerItem: ContainerItem
): StagingBuilderReducer => {
  return {
    type: "ADD_NEW_CONTAINER_ITEM",
    payload: { containerItem },
  };
};
