export const RESET_ACTIVE_ITEM: string = "RESET_ACTIVE_ITEM";
export const SET_ACTIVE_ITEM: string = "SET_ACTIVE_ITEM";
export const UPDATE_ACTIVE_ITEM: string = "UPDATE_ACTIVE_ITEM";
export const FILL_INVENTORY_VERBOSE: string = "FILL_INVENTORY_VERBOSE";
export const FILL_INVENTORY_MINIMAL: string = "FILL_INVENTORY_MINIMAL";
export const UPDATE_BUILDER_INVENTORY_ORDER: string =
  "UPDATE_BUILDER_INVENTORY_ORDER";
export const CLEAR_INVENTORY: string = "CLEAR_INVENTORY";

export const FAVORITE_ITEM_CLICKED: string = "FAVORITE_ITEM_CLICKED";
export const FAVORITE_ITEM_FAILURE: string = "FAVORITE_ITEM_FAILURE";
export const FAVORITE_ITEM_SUCCESS: string = "FAVORITE_ITEM_SUCCESS";

export const INVENTORY_SEARCH: string = "INVENTORY_SEARCH";
export const FILL_VENDORS: string = "FILL_VENDORS";
export const FILL_COLLECTIONS: string = "FILL_COLLECTIONS";
export const FILL_ROOMS: string = "FILL_ROOMS";

// for stagingBuilder
interface itemMinKey {
  [index: number]: {};
}
export interface itemMinimal extends itemMinKey {
  itemID: number;
  itemNo: string;
  itemName: string;
  itemGroups: string[];
  itemReferences: string[];
  pictureA: string;
  pictureB: string;
  rentalRate: number;
  rentalPrice: number;
  dimensions: string;
  favorite: number;
  active: number;
  itemStatus: number;
  quanAvail: number;
  quanHold: number;
  quanStaged: number;
  quanDraggable: number;
  piece: number;
}

export interface itemInContainer extends itemMinKey {
  itemID: number;
  itemNo: string;
  itemName: string;
  pictureA: string;
  rentalRate: number;
  rentalPrice: number;
  favorite: number;
}
