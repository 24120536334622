import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { moveContainerItem } from "../../../../../redux/stagingBuilder/stagingBuilderActions";

interface Props {
  stagingBuilderID: number;
  itemContainerID: number;
  containerItemID: number;
}

export const ItemContainerSelect = ({
  itemContainerID,
  stagingBuilderID,
  containerItemID,
}: Props) => {
  const dispatch = useDispatch();
  const { itemContainers } = useSelector(
    (state: Istate) => ({
      itemContainers: state.data.stagingBuilders[
        state.data.user.activeStagingBuilderIndex
      ].itemContainers.map(({ itemContainerID, containerName }) => ({
        value: itemContainerID,
        label: containerName,
      })),
    }),
    shallowEqual
  );
  const [value, setValue] = useState(
    itemContainers.find(({ value }) => itemContainerID === value)
  );
  useEffect(() => {
    setValue(itemContainers.find(({ value }) => itemContainerID === value));
  }, [itemContainerID]);

  const handleClick = () => {
    const index = itemContainers.findIndex(
      ({ value }) => itemContainerID === value
    );
    console.log(index);
    if (index !== -1) {
      let newIndex = index + 1;
      if (newIndex === itemContainers.length) newIndex = 1;
      setValue(itemContainers[newIndex]);
      const newItemContainerID = itemContainers[newIndex].value;
      dispatch(
        moveContainerItem({
          patchData: {
            stagingBuilderID,
            itemContainerID: itemContainerID,
            containerItemID,
            newItemContainerID: newItemContainerID,
          },
        })
      );
    }
  };
  return (
    <div>
      <Select
        name=""
        id=""
        isClearable={false}
        options={itemContainers}
        value={value}
        onMenuOpen={handleClick}
      />
    </div>
  );
};
