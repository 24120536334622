import React from "react";
import { HeaderGroup } from "react-table";
import { ProposalTHWrapper } from "../ProposalSearch/ContextMenus";

interface Props {
  headerGroups: HeaderGroup<StagingBuilder>[];
}

export const ProposalTableHeader: React.FC<Props> = ({ headerGroups }) => {
  return (
    <thead className="hidden sm:table-header-group">
      {
        // Loop over the header rows
        headerGroups.map(
          (headerGroup: HeaderGroup<StagingBuilder & object>) => (
            // Apply the header row props
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="sticky top-0 bg-white"
            >
              {headerGroup.headers.map((column) => (
                <ProposalTHWrapper
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  column={column}
                />
              ))}
            </tr>
          )
        )
      }
    </thead>
  );
};
