import React from "react";
import { ErrorMessage, Field, FieldProps, useField } from "formik";
import TextError from "./TextError";

interface Iprops {
  label: React.ReactNode;
  labelClass?: string;
  styledClass?: string;
  name: string;
  disabled?: boolean;
  replaceSecondField?: JSX.Element | null;
  controlContainerclass?: string | undefined;
}
const fancyCheck = ({ field, form }: FieldProps<string>) => {
  return (
    <>
      <input
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            form.setFieldValue(field.name, !field.value);
          }
        }}
        className="opacity-0 absolute h-8 w-8 cursor-pointer"
        type="checkbox"
        {...field}
      />
      <div className="bg-white border-2 rounded-md border-blue-400 w-8 h-8 flex flex-shrink-0 justify-center items-center mr-2 ">
        <svg
          className={`fill-current w-3 h-3 text-logo-blue pointer-events-none
            ${field.value ? " block" : " hidden"}
          `}
          version="1.1"
          viewBox="0 0 17 12"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(-9 -11)" fill="#1F73F1" fillRule="nonzero">
              <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
            </g>
          </g>
        </svg>
      </div>
    </>
  );
};
export const LeftCheckbox = (props: Iprops) => {
  const disabled = props.disabled === undefined ? false : props.disabled;
  return (
    <div className={props.controlContainerclass}>
      <div className="flex items-center mr-4 mb-2">
        <Field
          className="opacity-0 absolute h-8 w-8"
          type="checkbox"
          name={props.name}
          disabled={disabled}
          component={fancyCheck}
        />
        <label htmlFor={props.name} className="select-none">
          {props.label}
        </label>
      </div>
    </div>

    // <>
    //   <label className={props.labelClass} htmlFor={props.name}>
    // <Field
    //   className={props.styledClass ? props.styledClass : "left-checkbox"}
    //   style={{ justifySelf: "left" }}
    //   type="checkbox"
    //   name={props.name}
    //   disabled={disabled}
    // />
    //     {props.label}
    //   </label>
    // </>
  );
};
export const RightCheckbox = (props: Iprops) => {
  const disabled = props.disabled === undefined ? false : props.disabled;
  return (
    <div
      className={
        props.controlContainerclass
          ? props.controlContainerclass
          : "flex space-between"
      }
    >
      <div className="grid">
        <label className={props.labelClass} htmlFor={props.name}>
          {props.label}
        </label>
        <ErrorMessage name={props.name} component={TextError} />
      </div>
      <div style={{ display: "inline-block" }}>
        <Field
          className={props.styledClass ? props.styledClass : "left-checkbox"}
          style={{ justifySelf: "left" }}
          type="checkbox"
          name={props.name}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
export const RightDoubleCheckbox = (props: Iprops) => {
  const disabled = props.disabled === undefined ? false : props.disabled;
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <label className={props.labelClass} htmlFor={props.name}>
        {props.label}
      </label>
      <div style={{ display: "inline-block" }}>
        <Field
          className={props.styledClass ? props.styledClass : "left-checkbox"}
          style={{ justifySelf: "left" }}
          type="checkbox"
          name={props.name + "0"}
          disabled={disabled}
        />
        <Field
          className={props.styledClass ? props.styledClass : "left-checkbox"}
          style={{ justifySelf: "left" }}
          type="checkbox"
          name={props.name + "1"}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
export const RightDoubleCheckboxRow = (props: Iprops) => {
  const disabled = props.disabled === undefined ? false : props.disabled;
  const { replaceSecondField } = props;
  return (
    <tr>
      <td>
        <label className={props.labelClass} htmlFor={props.name}>
          {props.label}
        </label>
      </td>
      <td>
        <Field
          className={props.styledClass ? props.styledClass : "left-checkbox"}
          style={{ justifySelf: "left" }}
          type="checkbox"
          name={props.name + "0"}
          disabled={disabled}
        />
      </td>
      {replaceSecondField === undefined ? (
        <td>
          <Field
            className={props.styledClass ? props.styledClass : "left-checkbox"}
            style={{ justifySelf: "left" }}
            type="checkbox"
            name={props.name + "1"}
            disabled={disabled}
          />
        </td>
      ) : (
        replaceSecondField
      )}
    </tr>
  );
};
