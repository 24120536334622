import { Action, ActionCreator, AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AppDispatch, Istate } from "../";

import axios from "axios";
import {
  UPDATE_CONTAINER_ITEM,
  FILL_STAGING_BUILDERS,
  NEW_STAGINGBUILDER,
  SET_ACTIVE_STAGINGBUILDER,
  LOAD_ACTIVE_STAGINGBUILDER,
  ADD_NEW_ITEM_CONTAINER,
  UPDATE_ITEM_CONTAINER_NAME,
  REMOVE_ITEM_FROM_ITEM_CONTAINER,
  SET_TIMESTAMP_STAGINGBUILDER,
  UPDATE_CONTAINER_ITEM_STATUS,
  UPDATE_ITEM_CONTAINER_STATUS,
  GET_ALL_STAGINGBUILDER_NOTES,
  ADD_CONTAINER_ITEM_ID_TO_CONTAINER_ITEM,
  MOVE_CONTAINER_ITEM,
  CHANGE_CONTAINER_ITEM_QUANTITY,
  ContainerItem,
  StagingBuilderContainer,
} from "./stagingBuilderTypes";
import {
  fetchingData,
  fetchingDataSuccess,
  fetchingDataFailure,
} from "../users/userActions";
import { FormikContextType, FormikHelpers } from "formik";
import { deleteContainerItemRedux } from ".";
import { updateBuilderInventoryColors } from "../inventory/inventoryActions";
import { updateBuilderInventoryItem } from "../inventory/actions/updateBuilderInventoryItem";
import { ToastyRed, ToastyWhite } from "../../components/constants";
import { push, RouterState } from "connected-react-router";
import { toast } from "react-toastify";

export type ThunkResult<R> = ThunkAction<R, AppDispatch, null, AnyAction>;

export const newStagingBuilder = (stagingBuilder: StagingBuilder) => {
  return {
    type: NEW_STAGINGBUILDER,
    payload: { stagingBuilder },
  };
};
const setItemContainerStatus = (payload: {
  active: number;
  itemContainerID: number;
}) => {
  return {
    type: UPDATE_ITEM_CONTAINER_STATUS,
    payload: payload,
  };
};
export const setActiveStagingBuilder = (payload: number) => {
  return {
    type: SET_ACTIVE_STAGINGBUILDER,
    payload: payload,
  };
};
export const loadActiveStagingBuilder = (payload: any) => {
  return {
    type: LOAD_ACTIVE_STAGINGBUILDER,
    payload: payload,
  };
};
export const updateTimeStamp = (payload: number) => {
  return {
    type: SET_TIMESTAMP_STAGINGBUILDER,
    payload: payload,
  };
};

export const fetchFullStagingBuilder = (
  stagingBuilderID: number,
  refresh = false,
  doubleClick = false
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: Function
  ): Promise<void> => {
    dispatch(fetchingData());
    // make this staging builder the active token
    if (typeof stagingBuilderID !== "number") return;

    dispatch(
      setStagingBuilderToken({
        stagingBuilderID,
        doubleClick,
      })
    );
    // get data.stagingBuilders out of the state
    const {
      data: { stagingBuilders },
      router,
    } = getState() as Istate;
    // find the stagingBuilder

    const thisStagingBuilder = stagingBuilders.find(
      (stagingBuilder: StagingBuilder) =>
        stagingBuilder.stagingBuilderID === stagingBuilderID
    );
    // check if it has been loaded with axios already, if so dont fetch again
    if (thisStagingBuilder?.loaded && !refresh) {
      dispatch(setActiveStagingBuilder(thisStagingBuilder.stagingBuilderID));
      dispatch(fetchingDataSuccess());
    } else if (stagingBuilderID) {
      axiosFetchStagingBuilder(dispatch, router, { stagingBuilderID });
    }
  };
};

export const fetch_full_StagingBuilder = (
  {
    stagingBuilderID,
    fileNumber,
  }: RequireOnlyOne<{ stagingBuilderID: number; fileNumber: number }>,
  refresh = false,
  redirect: boolean | string = false
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: Function
  ): Promise<void> => {
    dispatch(fetchingData());
    let doubleClick: boolean;
    if (typeof redirect === "string") doubleClick = false;
    else doubleClick = redirect;
    const { router } = getState() as Istate;

    if (fileNumber !== undefined) {
      dispatch(
        setStagingBuilderToken({
          fileNumber,
          doubleClick,
        })
      );
      await axiosFetchStagingBuilder(dispatch, router, { fileNumber });
      const queryParams = router.location.query["tag"]
        ? `&tag=${router.location.query["tag"]}`
        : "";
      if (typeof redirect === "string") dispatch(push(redirect));
      else
        dispatch(
          push(
            `/${router.location.pathname.toString()}?left-side=scan${queryParams}`
          )
        );
    } else if (stagingBuilderID !== undefined) {
      // always redirect customers
      dispatch(fetchFullStagingBuilder(stagingBuilderID, refresh, true));
    }
  };
};

interface FetchFullAxiosProps {
  stagingBuilderID: number;
  fileNumber: number;
}
const axiosFetchStagingBuilder = (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  router: RouterState<unknown>,
  { stagingBuilderID, fileNumber }: RequireOnlyOne<FetchFullAxiosProps>
) => {
  let url: string;
  if (stagingBuilderID)
    url = `/api/effects/get/staging-builder?stagingBuilderID=${stagingBuilderID}`;
  else if (fileNumber)
    url = `/api/effects/get/staging-builder?file-number=${fileNumber}`;
  else
    return console.error(
      "Something went wrong while trying to do request a StagingBuilder"
    );

  return axios({
    method: "get",
    url,
  })
    .then((response) => {
      const responseData: StagingBuilder = JSON.parse(response.data);
      responseData.loaded = true;
      dispatch(loadActiveStagingBuilder(responseData));
      return;
    })
    .then(() => {
      dispatch(fetchingDataSuccess());
      if (router.location.pathname === "/calendar") dispatch(push("/"));
      return;
    })
    .catch((err) => {
      if (err.response?.status) {
        dispatch(fetchingDataFailure(err.response.status));
        console.error(err.response.status);
      }
    });
};

export const fillStagingBuilders = (payload: StagingBuilder[]) => {
  return {
    type: FILL_STAGING_BUILDERS,
    payload,
  };
};
export const addContainerItemIDToContainerItem = (payload: {
  itemID: number;
  itemContainerID: number;
  containerItemID: number;
}) => {
  return {
    type: ADD_CONTAINER_ITEM_ID_TO_CONTAINER_ITEM,
    payload: payload,
  };
};

export const updateItemContainerNames = (
  itemContainers: {
    stagingBuilderID: number;
    itemContainerID: number;
    containerName: string;
  }[]
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: Function
  ): Promise<void> => {
    const { data }: { data: Istate["data"] } = getState();
    const oldItemContainers =
      data.stagingBuilders[data.user.activeStagingBuilderIndex].itemContainers;
    const containersToPatch = itemContainers.filter(
      (Container, index) =>
        Container.containerName !== oldItemContainers[index].containerName
    );
    if (containersToPatch.length) {
      dispatch(fetchingData()); // active loading sign
      axios({
        method: "patch",
        url: "/api/form/staging-builder/item-container/container-name",
        data: {
          stagingBuilderID: data.user.activeStagingBuilder,
          containersToPatch,
        },
      })
        .then((response) => {
          dispatch(updateItemContainerNamesRedux(containersToPatch));
          console.log({ containersToPatch });
          return JSON.parse(response.data);
        })
        .then((response) => {
          if (response.editedOn) dispatch(updateTimeStamp(response.editedOn));
        })
        .then(() => dispatch(fetchingDataSuccess()))
        .catch((err) => {
          if (err.response) {
            dispatch(fetchingDataFailure(err.response.status));
            console.error(err.response.status);
          }
        });
    }
  };
};
export const removeItemContainer = (
  itemContainerID: number
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: Function
  ): Promise<void> => {
    dispatch(fetchingData()); // active loading sign
    const { data }: { data: Istate["data"] } = getState();
    dispatch(setItemContainerStatus({ itemContainerID, active: 0 }));
    axios({
      method: "patch",
      url: "/api/cursor/staging-builder/remove-container",
      data: {
        stagingBuilderID: data.user.activeStagingBuilder,
        itemContainerID,
      },
    })
      .then((response) => {
        return JSON.parse(response.data);
      })
      .then((response) => {
        if (response.success)
          dispatch(
            fetchFullStagingBuilder(data.user.activeStagingBuilder, true)
          );
      })
      .then(() => dispatch(updateBuilderInventoryColors()))
      .catch((err) => {
        if (err.response) {
          dispatch(fetchingDataFailure(err.response.status));
          console.error(err.response.status);
        }
      });
  };
};
export const updateItemContainerNamesRedux = (
  payload: {
    containerName: string;
    itemContainerID: number;
    stagingBuilderID: number;
  }[]
) => {
  return {
    type: UPDATE_ITEM_CONTAINER_NAME,
    payload: payload,
  };
};
// someone clicks on the trashcan icon on the item in the itemContainer
export const removeItemFromItemContainer = ({
  itemID,
  itemContainerID,
  stagingBuilderID,
  stagingStatus,
  itemStatus,
  containerItemID,
}: {
  itemID: number;
  itemContainerID: number;
  stagingBuilderID: number;
  stagingStatus: number;
  itemStatus: number;
  containerItemID: number;
}): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: Function
  ): Promise<void> => {
    dispatch(fetchingData());
    axios({
      method: "patch",
      url: "/api/cursor/staging-builder/container-item/remove",
      data: {
        itemID,
        itemContainerID,
        stagingBuilderID,
        itemStatus,
        stagingStatus,
        active: false,
        containerItemID,
      },
    })
      .then((response) => {
        return JSON.parse(response.data);
      })
      .then((data) => {
        if (data.success) {
          dispatch(fetchingDataSuccess());
          dispatch(updateContainerItem(data.containerItem));
          dispatch(updateBuilderInventoryItem(data.builderItem));
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch(fetchingDataFailure(err.response.status));
          console.error(err.response.status);
        }
      });
  };
};
export const removeItemFromItemContainerRedux = (payload: {
  itemID: number;
  itemContainerID: number;
}) => {
  return {
    type: REMOVE_ITEM_FROM_ITEM_CONTAINER,
    payload: payload,
  };
};
export const updateContainerItemStatus = (payload: {
  itemID: number;
  itemContainerID: number;
  itemStatus: number;
}) => {
  return {
    type: UPDATE_CONTAINER_ITEM_STATUS,
    payload: payload,
  };
};
export const updateContainerItem = (
  payload: ContainerItem
): StagingBuilderReducer => {
  return {
    type: UPDATE_CONTAINER_ITEM,
    payload,
  };
};

export const fetchStagingBuilders = (): ThunkAction<
  Promise<void>,
  {},
  {},
  AnyAction
> => {
  return async (
    dispatch: ActionCreator<ThunkAction<Action, AppDispatch, void, AnyAction>>
  ) => {
    dispatch(fetchingData());
    axios({
      method: "get",
      url: "/api/effects/get/staging-builders",
    })
      .then((response) => {
        return JSON.parse(response.data);
      })
      .then((data) => {
        if (data.success === true && data.stagingBuilders !== null)
          dispatch(fillStagingBuilders(data.stagingBuilders));

        dispatch(fetchingDataSuccess());
      })
      .catch((err) => {
        if (err.response) dispatch(fetchingDataFailure(err.response.status));
        else console.error(err);
      });
  };
};

export const addNewItemContainer = (): ThunkAction<
  Promise<void>,
  {},
  {},
  AnyAction
> => {
  return async (
    dispatch: ActionCreator<ThunkAction<Action, AppDispatch, void, AnyAction>>,
    getState: Function
  ): Promise<void> => {
    dispatch(fetchingData());
    const { data }: { data: Istate["data"] } = getState();
    const index = data.stagingBuilders.findIndex(
      (stagingBuilder) =>
        stagingBuilder.stagingBuilderID === data.user.activeStagingBuilder
    );
    const containerLength = data.stagingBuilders[index].itemContainers.length;
    axios({
      method: "post",
      url: "/api/cursor/staging-builder/new-container",
      data: {
        stagingBuilderID: data.user.activeStagingBuilder,
        containerName: `Room-${containerLength}`,
      },
    })
      .then((response) => {
        return JSON.parse(response.data);
      })
      .then((response) => {
        dispatch(addNewItemContainerRedux(response));
      })
      .then(() => dispatch(fetchingDataSuccess()))
      .then(() => {
        const objDiv = document.getElementById("staging-builder-scroller");
        if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
      })
      .catch((err) => {
        dispatch(fetchingDataFailure());
        console.error(err.response.status);
      });
  };
};
export const addNewItemContainerRedux = (payload: StagingBuilderContainer) => {
  return {
    type: ADD_NEW_ITEM_CONTAINER,
    payload: { ...payload, items: [] },
  };
};

export const setStagingBuilderToken = ({
  stagingBuilderID,
  fileNumber,
  doubleClick = false,
}: {
  stagingBuilderID?: number;
  fileNumber?: number;
  doubleClick?: boolean;
}): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: Function
  ): Promise<void> => {
    // DO NOT USE user.activeStagingBuilder here, it may have not been updated yet
    const {
      data: { socket },
    }: { data: Istate["data"] } = getState();
    await axios({
      method: "post",
      url: "/api/cursor/staging-builder/token",
      data: { stagingBuilderID, fileNumber },
    })
      .then((res) => {
        if (res.status === 200 && socket !== undefined) {
          socket.emit("JOIN_ROOM", JSON.stringify({ token: res.data.token }));
        }
      })
      .then(() => {
        if (doubleClick) dispatch(push("/stage"));
      })
      .catch((err) => {
        if (err.response) {
          dispatch(fetchingDataFailure(err.response.status));
          console.error(err.response.status);
        } else console.error(err);
      });
  };
};

export const updateStagingBuilderNotes = (payload: {
  stagingBuilderID: number;
  messages: StagingBuilderNote[];
}) => {
  return {
    type: GET_ALL_STAGINGBUILDER_NOTES,
    payload: payload,
  };
};
export const newStagingBuilderNote = ({
  message,
  actions,
}: {
  message: { comment: string };
  actions: FormikHelpers<typeof message>;
}): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: Function
  ): Promise<void> => {
    const { data }: { data: Istate["data"] } = getState();
    const stagingBuilderID = data.user.activeStagingBuilder;

    axios({
      method: "post",
      url: "/api/form/staging-builder/note",
      data: {
        stagingBuilderID,
        message: message.comment,
      },
    })
      .then((res) => {
        if (res.status === 201) {
          actions.setFieldValue("comment", "");
          actions.resetForm();
          dispatch(
            pushStagingBuilderNote({
              stagingBuilderID: res.data.message.stagingBuilderID,
              message: res.data.message,
            })
          );
          return true;
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch(fetchingDataFailure(err.response.status));
          console.error(err.response.status);
        }
        return false;
      });
  };
};

export const getStagingBuilderNotes = (): ThunkAction<
  Promise<void>,
  {},
  {},
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: Function
  ): Promise<void> => {
    const {
      data: {
        user: { activeStagingBuilder: stagingBuilderID },
      },
    }: { data: Istate["data"] } = getState();
    axios({
      method: "get",
      url: `/api/effects/get/staging-builder-notes?stagingBuilderID=${stagingBuilderID}`,
    })
      .then((res) => {
        dispatch(
          updateStagingBuilderNotes({
            stagingBuilderID,
            messages: res.data.messages,
          })
        );
      })
      .catch((err) => {
        if (err.response.status) {
          dispatch(fetchingDataFailure(err.response.status));
          console.error(err.response.status);
        }
      });
  };
};

export const pushStagingBuilderNote = (payload: {
  stagingBuilderID: number;
  message: StagingBuilderNote;
}): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: Function
  ): Promise<void> => {
    const {
      data: { user },
    }: { data: Istate["data"] } = getState();
    if (user.id !== payload.message.userID)
      ToastyWhite(`${payload.message.message} \n-${payload.message.first}`);
    dispatch(pushStagingBuilderNoteRedux(payload));
  };
};
const pushStagingBuilderNoteRedux = (payload: {
  stagingBuilderID: number;
  message: StagingBuilderNote;
}) => {
  return {
    type: "PUSH_STAGING_BUILDER_NOTE",
    payload: payload,
  };
};

export const updateStagingInfo = ({
  formData,
  actions,
}: {
  formData: any;
  actions: FormikHelpers<typeof formData>;
}): ThunkAction<
  Promise<void>,
  { data: Istate["data"]; router: RouterState<unknown> },
  {},
  AnyAction
> => {
  return async (dispatch, getState): Promise<void> => {
    const {
      data: {
        user: { activeStagingBuilder: stagingBuilderID },
      },
    } = getState();

    axios({
      method: "put",
      url: "/api/form/staging-builder/staging-info",
      data: { stagingBuilderID, ...formData },
    })
      .catch((err) => {
        if (err.response) {
          dispatch(fetchingDataFailure(err.response.status));
          console.error(err.response.status);
        } else dispatch(fetchingDataFailure());
      })
      .then(() => {
        const {
          router: {
            location: {
              query: { "open-modal": openModal },
            },
          },
        } = getState();

        toast.success("Information Successfully Saved");
        dispatch(fetchFullStagingBuilder(stagingBuilderID, true));
        if (openModal === "staging-information") {
          dispatch(getStagingBuilderNotes());
        }
      });
  };
};
export const moveContainerItem = ({
  patchData,
}: {
  patchData: {
    stagingBuilderID: number;
    itemContainerID: number;
    containerItemID: number;
    newItemContainerID: number;
    itemStatus?: number;
    active?: number;
  };
}): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: Function
  ): Promise<void> => {
    const { data }: { data: Istate["data"] } = getState();
    // newItemContainerID = -1 means move to trash
    if (patchData.newItemContainerID === -1) {
      patchData.itemStatus = 6; // this is only true if it is a proposal
      const newItemContainerID = data.stagingBuilders[
        data.user.activeStagingBuilderIndex
      ].itemContainers.find(
        (container) => container.containerType === 0
      )?.itemContainerID;
      if (newItemContainerID !== undefined)
        patchData.newItemContainerID = newItemContainerID;
      else {
        dispatch(fetchingDataFailure());
        return;
      }
    }

    const success = await axios({
      method: "patch",
      url: "/api/form/staging-builder/container-item/move",
      data: { ...patchData },
    })
      .then((res) => {
        if (res.status === 201) {
          if (res.data.editedOn) dispatch(updateTimeStamp(res.data.editedOn));
          if (res.data.method === "MOVE") {
            dispatch(moveContainerItemRedux(patchData));
            dispatch(updateBuilderInventoryItem(res.data.builderItem));
          } else if (res.data.method === "DELETE") {
            dispatch(
              deleteContainerItemRedux({
                containerItemID: patchData.containerItemID,
                itemContainerID: patchData.itemContainerID,
              })
            );
            dispatch(updateBuilderInventoryItem(res.data.builderItem));
          } else if ("FAILURE")
            ToastyRed("You cannot remove items from locked rooms");

          return res.data.success;
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch(fetchingDataFailure(err.response.status));
          console.error(err.response.status);
        } else console.error(err);
        return false;
      });
    if (success) {
      dispatch(fetchingDataSuccess());
    }
  };
};
export const moveContainerItemRedux = (payload: {
  stagingBuilderID: number;
  itemContainerID: number;
  containerItemID: number;
  newItemContainerID: number;
  active?: number;
}) => {
  return {
    type: MOVE_CONTAINER_ITEM,
    payload: payload,
  };
};

export const changeContainerItemQuantity = ({
  patchData,
}: {
  patchData: {
    stagingBuilderID: number;
    itemContainerID: number;
    containerItemID: number;
    newQuantity: number;
  };
}): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>
    // getState: Function
  ): Promise<void> => {
    axios({
      method: "patch",
      url: "/api/form/staging-builder/container-item/change-quantity",
      data: { ...patchData },
    })
      .then((res) => {
        if (res.status === 201) {
          if (res.data.editedOn) dispatch(updateTimeStamp(res.data.editedOn));
          dispatch(changeContainerItemQuantityRedux(patchData));
          return res.data.builderItem;
        }
      })
      .then((builderItem) => {
        dispatch(updateBuilderInventoryItem(builderItem));
        dispatch(fetchingDataSuccess());
      })
      .catch((err) => {
        if (err.response) {
          dispatch(fetchingDataFailure(err.response.status));
          console.error(err.response.status);
        } else console.error(err);
        return false;
      });
  };
};
const changeContainerItemQuantityRedux = (payload: {
  stagingBuilderID: number;
  itemContainerID: number;
  containerItemID: number;
  newQuantity: number;
}) => {
  return {
    type: CHANGE_CONTAINER_ITEM_QUANTITY,
    payload: payload,
  };
};
