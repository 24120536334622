import { memo } from "react";
import { AddressBlock } from "./blocks/Address";
import { StagerBlock } from "./blocks/Stager";
import { StatusBlock } from "./blocks/Status";
import { ItemsBlock } from "./blocks/Items";
import { PricingBlock } from "./blocks/Pricing";
import { TruckDates } from "./blocks/TruckDates";
import { MetaDates } from "./blocks/MetaDates";
import { ItemHold } from "./blocks/Hold";
import { useSelector } from "react-redux";

interface Props {
  transfer: boolean | undefined;
}

export const StagingBuilderHeader_FC = ({ transfer }: Props) => {
  const {
    address,
    address1,
    address2,
    city,
    zip,
    stateInitials,
    stagingStatus,
    items,
    clientName,
    stagingBuilderID,
    monthlyRate,
    retailValue,
    fileNumber,
    stagerName,
  } = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex]
  );

  return (
    <div className="max-w-inherit from-logo-blue w-full overflow-x-hidden bg-gradient-to-br to-purple-600 p-2 brightness-125 lg:bg-white lg:from-white lg:to-white">
      <div className="grid grid-cols-2 gap-4 p-2 lg:grid-cols-4 lg:grid-rows-[78px_78px_78px_78px] lg:gap-y-2">
        <AddressBlock
          address={address}
          address1={address1}
          address2={address2}
          city={city}
          zip={zip}
          stateInitials={stateInitials}
          stagingBuilderID={stagingBuilderID}
          clientName={clientName}
        />
        <StatusBlock
          stagingStatus={stagingStatus}
          fileNumber={fileNumber}
          businessID={1}
        />
        <ItemHold stagingStatus={stagingStatus} />
        <StagerBlock stagerName={stagerName} />
        <PricingBlock monthlyRate={monthlyRate} retailValue={retailValue} />
        <TruckDates />
        <MetaDates />
        <ItemsBlock items={items} />
      </div>
    </div>
  );
};

export const StagingBuilderHeaderV2 = memo(StagingBuilderHeader_FC);
