import React from "react";
import { StagingBuilderItemContainerHeader } from "../../Stage/StagingBuilder/ItemContainers/StagingBuilderItemContainerHeader";
import { StagingBuilderItem } from "../../Stage/StagingBuilder/StagingItem/StagingBuilderItem";
import { ContainerItemWrapper } from "../../Stage/StagingBuilder/StagingBuilderRoom";
import { useSelector, shallowEqual } from "react-redux";

interface Props {
  itemContainerIndex: number;
  headerHeight: number;
}
export const StagingBuilderItemContainer = ({
  itemContainerIndex,
  headerHeight,
}: Props) => {
  const {
    locked,
    containerType,
    items: itemContainerItems,
  } = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex]
        .itemContainers[itemContainerIndex],
    shallowEqual
  );

  return (
    <>
      <StagingBuilderItemContainerHeader
        index={itemContainerIndex}
        trash={containerType === 0}
        edit={false}
        headerHeight={headerHeight}
      />
      <ContainerItemWrapper>
        {itemContainerItems.map(({ containerItemID }, containerItemIndex) => (
          <StagingBuilderItem
            key={containerItemID}
            itemContainerIndex={itemContainerIndex}
            containerItemIndex={containerItemIndex}
            locked={locked}
            edit={false}
          />
        ))}
      </ContainerItemWrapper>
    </>
  );
};
