import { Prompt } from "react-router-dom";
import { useFormikContext } from "formik";
export const PromptIfDirty = () => {
  const formik = useFormikContext();
  return (
    <Prompt
      when={formik.dirty && formik.submitCount === 0}
      message="Are you sure you want to leave? You have unsaved changes on this page."
    />
  );
};
