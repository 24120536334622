import React, { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { updateStagingBuilderMeta } from "../../../redux/stagingBuilder/actions/updateStagingBuilderMeta";

export const StagingBuilderMetaInfoSocket = () => {
  const dispatch = useDispatch();
  const socket = useSelector(
    (state: Istate) => state.data.socket,
    shallowEqual
  );
  useEffect(() => {
    const handleUpdateStagingBuilderMeta = (stringyData: string) => {
      const data: {
        stagingBuilder: StagingBuilder;
        success: boolean;
      } = JSON.parse(stringyData);
      console.log("data from StagingBuilderMetaInfoSocket: ", data);
      dispatch(updateStagingBuilderMeta(data.stagingBuilder));
    };
    socket?.off("UPDATE_STAGING_BUILDER", handleUpdateStagingBuilderMeta);
    socket?.on("UPDATE_STAGING_BUILDER", handleUpdateStagingBuilderMeta);
    return () => {
      socket?.off("UPDATE_STAGING_BUILDER", handleUpdateStagingBuilderMeta);
    };
  }, [socket, dispatch]);
  return <></>;
};
