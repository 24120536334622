import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";
import { AppThunk, createAccount } from "../../../redux";

import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import FormikControl from "../../FormikComponents/FormikControl";
import * as Yup from "yup";
import { professionOptions } from "../../DropDownLists";
import { CustomButton } from "../../HighlyReusableComponents/CustomButton";

export const CreateForm = () => {
  const dispatch: AppThunk = useDispatch();

  const initialValues = {
    first: "",
    last: "",
    profession: 0,
    phone: "",
    phoneAgreement1: true,
    phoneAgreement2: true,
    email: "",
    emailAgreement1: true,
    emailAgreement2: true,
    password: "",
    confirmPassword: "",
  };
  // If ever updating this, Need to update the parser on api also
  const validationSchema = Yup.object({
    first: Yup.string()
      .min(2, "Too Short")
      .max(126, "Too Long")
      .required("Required"),
    last: Yup.string()
      .min(2, "Too Short")
      .max(126, "Too Long")
      .required("Required"),
    profession: Yup.number().required("Required").moreThan(0, "Required"),
    phone: Yup.string()
      .required("Required")
      .min(10, "Too Short")
      .matches(
        /^(\+1)?( |-)?\(?\d{3}\)?( |-)?\d{3}( |-)?\d{4}$/,
        "Incorrect Phone Number Format"
      ),
    email: Yup.string().required("Required").email("Invalid email format"),
    password: Yup.string()
      .required("Required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&:])[A-Za-z\d@$!%*#?&:]{8,}$/,
        "Password must contain a number, letter, special character, and be 8 characters long."
      ),
    confirmPassword: Yup.string()
      .required()
      .label("Confirm password")
      .test("passwords-match", "Passwords do not match", function (value) {
        return this.parent.password === value;
      }),
  });
  const onSubmit = (
    values: typeof initialValues,
    formikHelpers: FormikHelpers<typeof initialValues>
  ) => {
    dispatch(createAccount(values, formikHelpers));
  };

  return (
    <div className="mt-3 mx-8 max-w-md">
      <h1 style={{ textAlign: "center" }}>Create Account</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(props: FormikProps<any>) => (
          <Form>
            <div className="mx-auto grid grid-cols-2 gap-2">
              <FormikControl
                control="input"
                label="First Name"
                name="first"
                inLineLabelError={true}
                styledClass="focus:outline-none focus:ring"
              />
              <FormikControl
                control="input"
                label="Last Name"
                name="last"
                inLineLabelError={true}
                styledClass="focus:outline-none focus:ring"
              />
            </div>
            <FormikControl
              control="basic-select"
              styledClass="form-select mt-1 block w-full"
              options={professionOptions}
              label="What Best Describes You"
              name="profession"
            />
            <FormikControl
              control="phone-number"
              label="Phone Number"
              name="phone"
              inLineLabelError={true}
              styledClass="focus:outline-none focus:ring"
            />
            {/* <div className="checkbox-container">
              <FormikControl
                control="left-checkbox"
                type="checkbox"
                label={
                  <p className="p-label__checkbox">
                    I agree to receive automated status updates about my
                    stagings via text. Msg&Data rates may apply.
                  </p>
                }
                labelClass="grid auto-1fr"
                name="phoneAgreement1"
                notsearchable={1}
              />
            </div>
            <div className="checkbox-container">
              <FormikControl
                control="left-checkbox"
                type="checkbox"
                label={
                  <p className="p-label__checkbox">
                    I agree to recieve occasional StagingRents promotions &
                    notices via text. Msg&Data rates may apply.
                  </p>
                }
                labelClass="grid auto-1fr"
                name="phoneAgreement2"
                notsearchable={1}
              />
            </div> */}
            <FormikControl
              control="input"
              type="email"
              label="Email"
              name="email"
              inLineLabelError={true}
              styledClass="focus:outline-none focus:ring"
            />
            {/* <div className="checkbox-container">
              <FormikControl
                control="left-checkbox"
                type="checkbox"
                label={
                  <p className="p-label__checkbox">
                    I agree to receive automated status updates about my
                    stagings via email.
                  </p>
                }
                labelClass="grid auto-1fr"
                name="emailAgreement1"
                notsearchable={1}
              />
            </div>
            <div className="checkbox-container">
              <FormikControl
                control="left-checkbox"
                type="checkbox"
                label={
                  <p className="p-label__checkbox">
                    I agree to recieve occasional StagingRents promotions &
                    notices via email.
                  </p>
                }
                labelClass="grid auto-1fr"
                name="emailAgreement2"
                notsearchable={1}
              />
            </div> */}
            <FormikControl
              control="input"
              type="password"
              label="Password"
              name="password"
              inLineLabelError={true}
              styledClass="focus:outline-none focus:ring"
            />
            <FormikControl
              control="input"
              type="password"
              label="Confirm Password"
              name="confirmPassword"
              inLineLabelError={true}
              styledClass="focus:outline-none focus:ring"
            />
            <CustomButton type="submit">Create Account</CustomButton>
          </Form>
        )}
      </Formik>
      <div className="flex justify-between">
        <Link to="/user/recover-account">Forgot Password?</Link>
        <Link className="right " to="/user/login">
          Login
        </Link>
      </div>
      <p style={{ marginTop: "8px" }}>
        We respect your privacy and will never sell, rent, lease or give away
        your information (name, address, email, phone number, etc.) to any third
        party.
      </p>
    </div>
  );
};
