import React from "react";
import { FormikProps } from "formik";
import Select from "react-select";
import { CustomButton } from "../../HighlyReusableComponents/CustomButton";

interface Iprops {
  FormikProps: FormikProps<any>;
}
export const EditorUpdateQuantities = ({ FormikProps }: Iprops) => {
  const { values } = FormikProps;

  const [myCurrentLocation, setCurrentLocation] = React.useState("");
  const handleInventoryChange = (valueIncrement: number) => {
    FormikProps.setFieldTouched("quantity", true);
    FormikProps.setFieldTouched("quanAvail", true);
    switch (myCurrentLocation) {
      case "LOFT": {
        FormikProps.setFieldTouched("quanLoft", true);
        const quantity = parseInt(values.quanLoft);
        if (quantity > 0 || valueIncrement > 0)
          FormikProps.setFieldValue("quanLoft", quantity + valueIncrement);
        break;
      }
      case "HUDDLE": {
        FormikProps.setFieldTouched("quanHuddle", true);
        const quantity = parseInt(values.quanHuddle);
        if (quantity > 0 || valueIncrement > 0)
          FormikProps.setFieldValue("quanHuddle", quantity + valueIncrement);
        break;
      }
      case "MHFO": {
        FormikProps.setFieldTouched("quanMHFO", true);
        const quantity = parseInt(values.quanMHFO);
        if (quantity > 0 || valueIncrement > 0)
          FormikProps.setFieldValue("quanMHFO", quantity + valueIncrement);
        break;
      }
      case "CASTLE": {
        FormikProps.setFieldTouched("quanCastle", true);
        const quantity = parseInt(values.quanCastle);
        if (quantity > 0 || valueIncrement > 0)
          FormikProps.setFieldValue("quanCastle", quantity + valueIncrement);
        break;
      }
      case "REPAIR": {
        FormikProps.setFieldTouched("quanRepair", true);
        const quantity = parseInt(values.quanRepair);
        if (quantity > 0 || valueIncrement > 0)
          FormikProps.setFieldValue("quanRepair", quantity + valueIncrement);
        break;
      }
      default: {
        break;
      }
    }
  };
  return (
    <div className="mx-2">
      <p>Edit Inventory:</p>
      <Select
        onChange={(event) => setCurrentLocation(event?.value || "")}
        value={locOptions.find((option) => option.value === myCurrentLocation)}
        options={locOptions}
      />
      <div className="grid grid-cols-2 gap-1">
        <CustomButton
          type="button"
          disabled={myCurrentLocation ? false : true}
          onClick={() => handleInventoryChange(-1)}
        >
          -
        </CustomButton>
        <CustomButton
          style={{ marginBottom: "0" }}
          type="button"
          disabled={myCurrentLocation ? false : true}
          onClick={() => handleInventoryChange(1)}
        >
          +
        </CustomButton>
      </div>
    </div>
  );
};
const locOptions = [
  { label: "Where Am I? ", value: "" },
  { label: "Loft ", value: "LOFT" },
  { label: "Huddle ", value: "HUDDLE" },
  { label: "MHFO ", value: "MHFO" },
  { label: "Castle ", value: "CASTLE" },
  { label: "Repair ", value: "REPAIR" },
];
