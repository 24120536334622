import { FormikProps, useField } from "formik";
import { shallowEqual, useSelector } from "react-redux";
import { useCalendar } from "../../../../constants/contexts/calendar/CalendarContext";
import FormikControl from "../../../../FormikComponents/FormikControl";
import { CustomButton } from "../../../../HighlyReusableComponents/CustomButton";
import { TextAreaCounterMemo } from "../../../../HighlyReusableComponents/FormComponents/TextArea_counter";
import { AddressFormikForm } from "../../../../StartStagingBuilder/StartStagingBuilderForm";
import styled from "styled-components";
import { MHFOSubmissionValues } from "../../../../constants/hooks/fetching/useCalendarMHFO";

const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

interface Props {
  formikProps: FormikProps<MHFOSubmissionValues>;
}

export const MHFODeliveryFields = ({ formikProps: { submitForm } }: Props) => {
  const {
    mhfo: { deleteMHFODelivery },
  } = useCalendar();

  const [commentsField, ,] = useField("task-description");

  const deliveryID = useSelector(
    (state: Istate) => state.router.location.query["mhfo"],
    shallowEqual
  );
  return (
    <div className="mt-6 grid-cols-7 grid gap-x-2">
      <FormikControl
        styledClass="h-4ch"
        controlContainerclass="basic-input w-3/4 col-span-3"
        control="input"
        label="Customer Name"
        name="customer-name"
        inLineLabelError
      />
      <div></div>
      <div></div>
      <DatePickerWrapper className="w-full col-span-2">
        <FormikControl
          labelClass="block"
          controlContainerclass="basic-input block"
          label="Scheduled Task Date"
          name="task-time"
          control="date-picker"
        />
      </DatePickerWrapper>
      <div className="col-span-5 grid grid-cols-2 gap-x-2 mr-20">
        <AddressFormikForm address1PlaceHolder="" />
      </div>
      <div className="col-span-2">
        <FormikControl
          styledClass="h-4ch"
          controlContainerclass="basic-input mt-3"
          control="input"
          label="Invoice #"
          name="invoice-number"
        />
        <FormikControl
          styledClass="h-4ch"
          controlContainerclass="basic-input w-40 mt-3"
          control="input"
          label="Est. Hours Req'd"
          name="task-hours"
        />
      </div>
      <div className="col-span-3">
        <div className="mt-3 w-full">
          <label htmlFor="task-description">Delivery Notes</label>
          <TextAreaCounterMemo
            id="task-description"
            className="block border border-solid rounded p-1 border-gray-400 focus:outline-none focus:ring"
            style={{ width: "100%", height: "12rem" }}
            {...commentsField}
            maxLength={512}
            wrapperStyles={{ maxWidth: "100%" }}
          />
        </div>
      </div>

      <div className="flex justify-between col-span-4 items-end">
        <CustomButton
          style={{ height: "min-content" }}
          appendClassName="mb-1 h-min ml-1 mr-2"
          onClick={submitForm}
        >
          Submit Calendar Event
        </CustomButton>
        {deleteMHFODelivery && (
          <CustomButton
            appendClassName="mb-1 bg-black text-white ml-2"
            style={{ height: "min-content" }}
            onClick={() =>
              deleteMHFODelivery({ MHFODeliveryID: Number(deliveryID) })
            }
          >
            Delete Calendar Event
          </CustomButton>
        )}
      </div>
    </div>
  );
};
