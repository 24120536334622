import { StagingBuilderStatuses } from "../enums/StagingBuilderStatuses";

type ReturnType = "On Hold" | "Staged" | "Not On Hold";
export const ParseStatusAsItem = (
  stagingStatus: StagingBuilderStatuses
): ReturnType => {
  switch (stagingStatus) {
    // On Hold
    case StagingBuilderStatuses.PROPOSAL_HOLD_CUSTOMER:
      return "On Hold";
    case StagingBuilderStatuses.PROPOSAL_HOLD_EMPLOYEE:
      return "On Hold";
    case StagingBuilderStatuses.PROPOSAL_APPROVAL_REQUEST_HOLD:
      return "On Hold";
    case StagingBuilderStatuses.APPROVAL_PROCESS_STARTED:
      return "On Hold";
    case StagingBuilderStatuses.SCHEDULED_AND_TAGGED:
      return "On Hold";
    case StagingBuilderStatuses.PAID:
      return "On Hold";
    case StagingBuilderStatuses.LIMBO_HOLD:
      return "On Hold";
    case StagingBuilderStatuses.LIMBO_REQUEST_HOLD:
      return "On Hold";
    // Staged
    case StagingBuilderStatuses.STAGED:
      return "Staged";
    case StagingBuilderStatuses.PICKUP_REQUEST:
      return "Staged";
    case StagingBuilderStatuses.PICKUP_SCHEDULED:
      return "Staged";
    default:
      return "Not On Hold";
  }
};
