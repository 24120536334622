import { useEffect } from "react";
import styled from "styled-components";
import { FormikProps } from "formik";
import { LeftEditorPanel } from "./LeftEditorPanel";
import { RightEditorPanel } from "./RightEditorPanel";

const InventoryEditorWrapper = styled.div`
  margin: 8px 4px 0 4px;
  border-top: 1px solid lightgrey;
  border-radius: 0;
  min-height: 100px;
  max-height: 100%;
  height: 83%;
  display: grid;
  grid-template-columns: 3fr 2fr;
  max-width: 50vw;
`;

interface IEditorBodyContainer {
  FormikProps: FormikProps<any>;
}
export const EditorBodyContainer = ({ FormikProps }: IEditorBodyContainer) => {
  const { initialValues } = FormikProps;
  useEffect(() => {
    FormikProps.resetForm(initialValues);
  }, [initialValues]);
  return (
    <InventoryEditorWrapper>
      <LeftEditorPanel FormikProps={FormikProps} />
      <RightEditorPanel activeItem={FormikProps.initialValues} />
    </InventoryEditorWrapper>
  );
};
