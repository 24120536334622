import React from "react";
import { FieldArrayRenderProps } from "formik";
import { EditContainerName } from "./EditContainerName";
import { DeleteItemContainer } from "./DeleteItemContainer";
import styled from "styled-components";
import { ImLock, ImUnlocked } from "react-icons/im";
import { useSelector, shallowEqual } from "react-redux";
import { rateReducer, retailReducer } from "../../../constants/";

const itemContainerHeaderColor = (containerType: number) => {
  switch (containerType) {
    case 0:
      return "teal" as const;
    case 1:
      return "var(--logo-purple)" as const;
    case 2:
      return "darkblue" as const;
    default:
      return "black" as const;
  }
};

const StagingRoomHeaderContainer = styled.div<{
  containerType: number;
  headerOffSetHeight: number | undefined;
}>`
  display: grid;
  grid-template-columns: 2fr repeat(4, 1fr) 15ch;
  background-color: ${(props) => itemContainerHeaderColor(props.containerType)};
  border-radius: 2px;
  padding: 10px 20px;
  position: sticky;
  z-index: 6;
  top: 0;
  @media (min-width: 768px) {
    top: ${({ headerOffSetHeight }) => headerOffSetHeight}px;
  }
`;
interface IcontainerHeaderProps {
  index: number;
  locked?: boolean;
  arrayHelpers?: FieldArrayRenderProps;
  trash: boolean;
  edit: boolean;
  headerHeight: number;
}
function StagingBuilderRoomHeaderFC({
  index,
  arrayHelpers,
  trash,
  edit,
  headerHeight,
}: IcontainerHeaderProps) {
  const { itemContainerID, locked, containerName, containerType } = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex]
        .itemContainers[index],
    shallowEqual
  );
  const containerRate = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[
        state.data.user.activeStagingBuilderIndex
      ].itemContainers[index].items.reduce(rateReducer, 0),
    shallowEqual
  );

  return (
    <StagingRoomHeaderContainer
      headerOffSetHeight={headerHeight}
      containerType={containerType}
    >
      {trash ? (
        <p
          className="flex column space-around text-white font-bold bg-transparent"
          style={{
            border: "1px solid #0000",
            boxShadow: "none",
          }}
        >
          Trash
        </p>
      ) : (
        <div className="grid" style={{ gridTemplateColumns: "1fr 20px" }}>
          {edit && !locked ? (
            <EditContainerName index={index} />
          ) : (
            <p
              className="flex column space-around text-white font-bold bg-transparent"
              style={{
                border: "1px solid #0000",
                boxShadow: "none",
              }}
            >
              {containerName}
            </p>
          )}
        </div>
      )}
      {trash ? null : (
        <>
          <div></div>
          <div></div>
          <div
            style={{ gridTemplateColumns: "auto auto 1fr" }}
            className="item-container__header__price grid center"
          >
            Rate:
            <span className="sup-dollar ml-5">{containerRate || 0}</span>
          </div>
          <div className="grid center">
            {edit &&
              (locked ? (
                <ImLock color="white" />
              ) : (
                <ImUnlocked color="white" />
              ))}
          </div>
          {edit && arrayHelpers && !locked && (
            <DeleteItemContainer
              arrayHelpers={arrayHelpers}
              index={index}
              itemContainerID={itemContainerID}
            />
          )}
        </>
      )}
    </StagingRoomHeaderContainer>
  );
}

export const StagingBuilderItemContainerHeader = React.memo(
  StagingBuilderRoomHeaderFC
);
