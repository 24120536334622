import styled from "styled-components";

export const FloatingCell = styled.div.attrs((attrs) => {
  return {
    ...attrs,
    className: attrs.className + " h-full overflow-ellipsis",
  };
})`
  overflow: hidden;
  &:hover {
    text-overflow: unset;
    overflow: unset;
    animation: floatText 6.5s infinite linear;
  }
`;
