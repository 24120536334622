import { push } from "connected-react-router";
import { useMemo, useState, CSSProperties } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  handleFileNumberClick,
  hrefPrefix,
  parseTagColor,
} from "../../../../constants";
import { CustomButton } from "../../../../HighlyReusableComponents/CustomButton";
import { DateComponent } from "../../../../HighlyReusableComponents/FormComponents/DateComponent";
import { BasicModal } from "../../../../Modals/BasicModal";

const modalStyle: CSSProperties = {
  top: "30vh",
  left: "35vw",
  right: "35vw",
  bottom: "auto",
  overflow: "visible",
  height: "auto",
  padding: "3rem",
};

export const GenericHoldTagsGenerator = () => {
  const dispatch = useDispatch();
  const { pathname, query } = useSelector(
    (state: Istate) => state.router.location,
    shallowEqual
  );
  const { deliveryActual, fileNumber, stagingStatus, stagingBuilderID } =
    useSelector(
      (state: Istate) =>
        state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex],
      shallowEqual
    );

  const options1 = useMemo(() => {
    return new Array(50).fill(0).map((_, i) => ({
      label: `${(i + 1) * 6} tags (${i + 1} sheets)`,
      value: `${(i + 1) * 6}`,
    }));
  }, []);
  const options2 = useMemo(() => {
    return new Array(301)
      .fill(0)
      .map((_, i) => ({
        label: `${i + 1} (sheet: ${i / 6 + 1})`,
        value: `${i}`,
      }))
      .filter((_, i) => i % 6 === 0);
  }, []);

  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    new Date(deliveryActual || 0)
  );
  const [value1, setValue1] = useState(options1[7]);
  const [value2, setValue2] = useState(options2[0]);

  return (
    <BasicModal
      modalStyle={modalStyle}
      open={query["open-modal"] === "generic-hold-tags"}
      setOpen={() => dispatch(push(pathname))}
      employeeOnly
    >
      <div className="flex flex-col">
        <div>
          <h1 className="text-2xl pb-2">Generic Hold Tags Generator</h1>
        </div>
        <div>
          <h2 className="text-xl pb-2">
            Please Use Paper Bin: {parseTagColor(fileNumber)}
          </h2>
        </div>
        <div className="grid grid-cols-2">
          <label className="grid items-center" htmlFor="fileNumber">
            File Number:
          </label>
          <div className="basic-input w-full">
            <input
              id="fileNumber"
              value={
                fileNumber ? fileNumber : "A file number is required to print"
              }
              readOnly
              onClick={() =>
                handleFileNumberClick(dispatch, {
                  businessID: 1,
                  fileNumber,
                  stagingStatus,
                })
              }
            />
          </div>

          <label className="grid items-center">Delivery Date:</label>
          <div className="basic-input min-w-full">
            <DateComponent
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              shouldUpdateDataBase={{
                stagingBuilder: true,
                key: "deliveryActual",
              }}
            />
          </div>

          <label className="grid items-center" htmlFor="value1">
            Tags to Print
          </label>
          <div>
            <Select
              id="value1"
              onChange={(e) => e && setValue1(e)}
              value={value1}
              options={options1}
            />
          </div>

          <label className="grid items-center" htmlFor="value2">
            Start with Tag:
          </label>
          <div>
            <Select
              id="value2"
              onChange={(e) => e && setValue2(e)}
              value={value2}
              options={options2}
            />
          </div>
        </div>

        <CustomButton
          disabled={!deliveryActual || !fileNumber}
          linkProps={{
            target: "_blank",
            to: {
              // pathname: `${hrefPrefix}/api/print/staging-builder/generic-hold-tags`,
              // search: `delivery=${deliveryActual}&file=${fileNumber}&tags=${value1.value}&start=${value2.value}`,
              pathname: `https://service.stagingrents.com/api/page-to-pdf`,
              search: `pathname=print/tags/generic-hold-tags?start=${
                Number(value2.value) + 1
              }&tags=${
                value1.value
              }&file-number=${fileNumber}&delivery=${deliveryActual}&stagingBuilderID=${stagingBuilderID}`.replaceAll(
                "&",
                "%26"
              ),
            },
          }}
        >
          Generate Sheet!
        </CustomButton>
      </div>
    </BasicModal>
  );
};
