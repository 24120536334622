import React from "react";
import { FormikProps } from "formik";
import { InitialValues } from "./InitialValues";
import FormikControl from "../../../../../FormikComponents/FormikControl";
import { useSelector } from "react-redux";
import { compare } from "../../../../../DropDownLists";

interface Props {
  formikProps: FormikProps<InitialValues>;
  stagingBuilder: StagingBuilder;
  options: USER_MIN[];
}

export const FormFields = ({ formikProps, stagingBuilder, options }: Props) => {
  const { id: userID, first, last } = useSelector(
    (state: Istate) => state.data.user
  );

  return stagingBuilder.employeeCreated && userID ? (
    <div className="grid grid-cols-2 gap-1">
      <FormikControl
        control="basic-select"
        label="Change Ownership To: "
        name="userID"
        menuPlacement="top"
        options={[
          ...options?.map(({ userID, first, last }) => {
            return { label: `${first} ${last}`, value: userID.toString() };
          }),
          { label: `${first} ${last}`, value: userID.toString() },
        ].sort(compare)}
      />
      <div>
        <h2>New User Information</h2>
        <p className="grid auto-1fr gap-3">
          <span>Email: </span>
          <span>
            {
              options?.find(
                (user) =>
                  Number(user.userID) === Number(formikProps.values.userID)
              )?.email
            }
          </span>
        </p>
      </div>
    </div>
  ) : (
    <p>
      This StagingBuilder&trade; was not created by an Employee therefore
      ownership cannot be transfered
    </p>
  );
};
