import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import {
  Istate,
  AppDispatch,
  fetchingData,
  fetchingDataSuccess,
  fetchingDataFailure,
  scannerResStatus,
} from "../";
import {
  RESET_ACTIVE_ITEM,
  SET_ACTIVE_ITEM,
  UPDATE_ACTIVE_ITEM,
  FILL_INVENTORY_VERBOSE,
  FILL_INVENTORY_MINIMAL,
  INVENTORY_SEARCH,
  FILL_VENDORS,
  FILL_COLLECTIONS,
  FILL_ROOMS,
  CLEAR_INVENTORY,
  itemMinimal,
  UPDATE_BUILDER_INVENTORY_ORDER,
} from "./inventoryTypes";
import { SystemState } from "../initialReduxState";

import axios from "axios";
import { push } from "connected-react-router";
import { updateContainerItemStatus } from "../stagingBuilder/stagingBuilderActions";
import { updateStagingBuilderMeta } from "../stagingBuilder/actions/updateStagingBuilderMeta";
import { toast } from "react-toastify";
export type ThunkResult<R> = ThunkAction<R, AppDispatch, unknown, AnyAction>;

export const resetActiveItem = () => {
  return {
    type: RESET_ACTIVE_ITEM,
  };
};

export const setActiveItem = (payload: itemVerbose) => {
  return {
    type: SET_ACTIVE_ITEM,
    payload,
  };
};

export const updateActiveItem = (payload: itemVerbose) => {
  return {
    type: UPDATE_ACTIVE_ITEM,
    payload,
  };
};
export const clearInventory = () => {
  return {
    type: CLEAR_INVENTORY,
  };
};

interface IsearchPayload {
  filterType: string;
  filterKey: string;
  keyValue: string;
}
export const inventorySearchByItemNo = (payload: IsearchPayload) => {
  return {
    type: INVENTORY_SEARCH,
    payload,
  };
};
export const inventorySearch = (payload: SystemState["inventorySearch"]) => {
  return {
    type: INVENTORY_SEARCH,
    payload,
  };
};
interface IsearchParametersA {
  itemNo: string;
  model: string;
  ain: string;
  vendorID: string;
  collectionID: string;
  room: string;
  piece: string;
  itemName: string;
}
interface IsearchParametersB {}

export const handleInventorySearch = ({
  searchParametersA,
  searchParametersB,
}: {
  searchParametersA?: IsearchParametersA;
  searchParametersB?: IsearchParametersB;
}): ThunkAction<Promise<void>, Istate, {}, AnyAction> => {
  return async (dispatch, getState): Promise<void> => {
    const { data } = getState();
    // loading indicator set in earlier function
    // dispatch(fetchingData());
    const regex1 = /-| |\./g;
    let searchPayload = { ...data.inventorySearch };
    searchParametersA &&
      Object.entries(searchParametersA).forEach(
        (obj: [string, string | number | boolean]) => {
          if (typeof obj[1] === "string")
            searchPayload[obj[0]].keyValue = obj[1]
              .toLowerCase()
              .replace(regex1, "( ?|-?|.?|)");
          else searchPayload[obj[0]].keyValue = obj[1];
        }
      );
    searchParametersB &&
      Object.entries(searchParametersB).forEach(
        (obj: [string, string | string[] | number | boolean]) => {
          if (typeof obj[1] === "string")
            searchPayload[obj[0]].keyValue = obj[1]
              .toLowerCase()
              .replace(regex1, "( ?|-?|.?|)");
          else searchPayload[obj[0]].keyValue = obj[1];
        }
      );
    dispatch(inventorySearch(searchPayload));
  };
};
// export const handleInventorySearch = (
//   searchParameters: IsearchParameters
// ): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
//   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
//     const promise = Promise.resolve();
//     // loading indicator set in earlier function
//     // dispatch(fetchingData());
//     let searchPayload: IsearchPayload;
//     promise
//       .then(() => {
//         searchPayload = {
//           filterType: "start",
//           filterKey: "",
//           keyValue: "",
//         };
//         dispatch(inventorySearchByItemNo({ ...searchPayload }));
//       })
//       .then(() => {
//         searchPayload = {
//           filterType: "includes",
//           filterKey: "itemNo",
//           keyValue: searchParameters.itemNo,
//         };
//         if (searchParameters.itemNo) {
//           dispatch(inventorySearchByItemNo({ ...searchPayload }));
//           return 1;
//         }
//         return 0;
//       })
//       .then((searches) => {
//         // model searches ourSku also
//         searchPayload = {
//           filterType: "includes-model",
//           filterKey: "model",
//           keyValue: searchParameters.model,
//         };
//         if (searchParameters.model) {
//           dispatch(inventorySearchByItemNo({ ...searchPayload }));
//           searches++;
//         }
//         return searches;
//       })
//       .then((searches) => {
//         searchPayload = {
//           filterType: "equality",
//           filterKey: "ain",
//           keyValue: searchParameters.ain,
//         };
//         if (~~searchParameters.ain) {
//           dispatch(inventorySearchByItemNo({ ...searchPayload }));
//           searches++;
//         }
//         return searches;
//       })
//       .then((searches) => {
//         searchPayload = {
//           filterType: "equality",
//           filterKey: "vendorID",
//           keyValue: searchParameters.vendorID,
//         };
//         if (~~searchParameters.vendorID) {
//           dispatch(inventorySearchByItemNo({ ...searchPayload }));
//           searches++;
//         }
//         return searches;
//       })
//       .then((searches) => {
//         searchPayload = {
//           filterType: "equality",
//           filterKey: "collectionID",
//           keyValue: searchParameters.collectionID,
//         };
//         if (~~searchParameters.collectionID) {
//           dispatch(inventorySearchByItemNo({ ...searchPayload }));
//           searches++;
//         }
//         return searches;
//       })
//       .then((searches) => {
//         searchPayload = {
//           filterType: "equality",
//           filterKey: "room",
//           keyValue: searchParameters.room,
//         };
//         if (~~searchParameters.room) {
//           dispatch(inventorySearchByItemNo({ ...searchPayload }));
//           searches++;
//         }
//         return searches;
//       })
//       .then((searches) => {
//         searchPayload = {
//           filterType: "equality",
//           filterKey: "piece",
//           keyValue: searchParameters.piece,
//         };
//         if (~~searchParameters.piece) {
//           dispatch(inventorySearchByItemNo({ ...searchPayload }));
//           searches++;
//         }
//         return searches;
//       })
//       .then((searches) => {
//         searchPayload = {
//           filterType: "includes",
//           filterKey: "itemName",
//           keyValue: searchParameters.itemName,
//         };
//         if (searchParameters.itemName) {
//           dispatch(inventorySearchByItemNo({ ...searchPayload }));
//           searches++;
//         }
//         return searches;
//       })
//       .then((searches) => {
//         if (searches === 0) {
//           searchPayload = {
//             filterType: "none",
//             filterKey: "",
//             keyValue: "",
//           };
//           dispatch(inventorySearchByItemNo({ ...searchPayload }));
//         }
//         return;
//       })
//       .then(() => {
//         searchPayload = {
//           filterType: "finish",
//           filterKey: "",
//           keyValue: "",
//         };
//         dispatch(inventorySearchByItemNo({ ...searchPayload }));
//       });
//   };
// };

export const fillInventoryVerbose = (payload: any) => {
  return {
    type: FILL_INVENTORY_VERBOSE,
    payload,
  };
};
export const fillInventoryMin = (payload: any) => {
  return {
    type: FILL_INVENTORY_MINIMAL,
    payload,
  };
};
export const updateBuilderInventoryOrder = (payload: { itemID: number }[]) => {
  return {
    type: UPDATE_BUILDER_INVENTORY_ORDER,
    payload,
  };
};
export const setVendors = (payload: any) => {
  return {
    type: FILL_VENDORS,
    payload,
  };
};
export const setCollections = (payload: any) => {
  return {
    type: FILL_COLLECTIONS,
    payload,
  };
};
const setRooms = (payload: any) => {
  return {
    type: FILL_ROOMS,
    payload,
  };
};
export const fetchInventoryVerbose = ({
  force = true,
}): ThunkAction<Promise<void>, Istate, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState
  ): Promise<void> => {
    const { data } = getState();
    if (!(data.inventory.length === 0 && !force)) return;

    dispatch(fetchingData());
    axios({
      method: "get",
      url: "/api/inventory/full",
    })
      .then((response) => {
        return JSON.parse(response.data);
      })
      .then((response) => {
        dispatch(fillInventoryVerbose(response.allItems));
        dispatch(setVendors(response.vendors));
        dispatch(setCollections(response.collections));
        dispatch(setRooms(response.rooms));
      })
      .then(() => dispatch(fetchingDataSuccess()))
      .catch((err) => {
        if (err.response) {
          dispatch(fetchingDataFailure(err.response.status));
        } else dispatch(fetchingDataFailure());
      });
  };
};
export const fetchInventoryMin = (): ThunkAction<
  Promise<void>,
  Istate,
  {},
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: Function
  ): Promise<void> => {
    dispatch(fetchingData());
    const { data } = getState();
    axios({
      method: "get",
      url: `/api/inventory/min?stagingBuilderID=${data.user.activeStagingBuilder}`,
    })
      .then((response) => {
        return JSON.parse(response.data);
      })
      .then(
        async ({
          itemsAsObject,
          orderArray,
          clashes,
          groups,
        }: {
          itemsAsObject: { [itemID: number]: itemMinimal };
          orderArray: {
            itemID: number;
            index: number;
          }[];
          clashes: {
            itemContainerID: number;
            itemID: number;
            itemStatus: number;
          }[];
          groups: { [index: string]: number[] };
        }) => {
          dispatch(
            fillInventoryMin({
              itemObject: itemsAsObject,
              orderArray: orderArray,
              groups: groups,
            })
          );
          if (clashes[0] !== undefined) {
            clashes.forEach((item) => {
              dispatch(updateContainerItemStatus(item));
            });
            let stagingBuilder =
              data.stagingBuilders[data.user.activeStagingBuilderIndex];
            stagingBuilder.clashes = clashes;
            dispatch(updateStagingBuilderMeta(stagingBuilder));
          }
        }
      )
      .then(() => dispatch(fetchingDataSuccess()))
      .catch((err) => {
        console.error(err);
        if (err.response) {
          dispatch(fetchingDataFailure(err.response.status));
        } else dispatch(fetchingDataFailure());
      });
  };
};
export const startNewItem = (): ThunkAction<
  Promise<void>,
  {},
  {},
  AnyAction
> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(fetchingData());
    axios({
      method: "get",
      url: "/api/form/inventory/new",
    })
      .then((response) => {
        return JSON.parse(response.data);
      })
      .then((data: itemVerbose) => {
        const newItem: itemVerbose = {
          ...data,
          ain: "",
          collectionID: 0,
          comparePrice: "",
          depth: "",
          dimensions: "",
          excludeSale: false,
          ezrent: 0,
          landedCost: "",
          sellPrice: "",
          rentalPrice: "",
          specialPrice: "",
          rentalRate: "",
          piece: 0,
          room: 0,
          webCardPlacement: "",
          height: "",
          mhfo: false,
          hotbuy: false,
          quanLoft: 0,
          quanHuddle: 0,
          quanMHFO: 0,
          quanCastle: 0,
          quanRepair: 0,
          quanStaged: 0,
          quanAvail: 0,
          quanHold: 0,
          quantity: 0,
          vendorID: 0,
          pictureAdded: false,
          itemName: "",
          model: "",
          length: "",
          webDescription: "",
          tagDescription: "",
          vendorURL: "",
        };
        dispatch(setActiveItem(newItem));
        dispatch(push(`/inventory/new/${data.itemNo}`));
      })
      .then(() => dispatch(fetchingDataSuccess()))
      .catch((err) => {
        if (err.response) {
          dispatch(fetchingDataFailure(err.response.status));
          console.error(err.response.status);
        } else dispatch(fetchingDataFailure());
      });
  };
};
export const handleInventorySubmit = (
  itemInformation: any
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(fetchingData());
    await axios({
      method: "POST",
      url: "/api/form/inventory/submit",
      data: itemInformation,
    })
      .then((response) => JSON.parse(response.data))
      .catch((err) => {
        if (err.response) {
          dispatch(fetchingDataFailure(err.response.status));
          console.error(err.response);
        } else dispatch(fetchingDataFailure());
      });
  };
};
export const handleInventoryUpdate = (
  itemInformation: any,
  postedImages: boolean
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(fetchingData());
    await axios({
      method: "patch",
      url: "/api/form/inventory/update",
      data: itemInformation,
    })
      .then((response) => JSON.parse(response.data))
      .then((data) => {
        if (!postedImages) {
          dispatch(resetActiveItem());
          dispatch(updateActiveItem(data.itemInformation));
        }
      })
      .then(() => {
        if (!postedImages) dispatch(fetchingDataSuccess());
      })
      .catch((err) => {
        if (err.response) {
          dispatch(fetchingDataFailure(err.response.status));
        } else dispatch(fetchingDataFailure());
      });
  };
};

export const postNewPictures = (
  newItem: boolean,
  itemNo: string,
  blobA: Blob | null,
  blobB: Blob | null,
  state: {
    pictureA: React.Dispatch<React.SetStateAction<boolean>>;
    pictureB: React.Dispatch<React.SetStateAction<boolean>>;
    submittingPictures: React.Dispatch<React.SetStateAction<boolean>>;
  }
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(fetchingData());
    const data = new FormData();

    data.append("itemNo", itemNo);
    data.append("newItem", Number(newItem ? true : false).toString());
    blobA && data.append("picture[]", blobA, `${itemNo}-1.jpg`); // goes with pictureA
    blobB && data.append("picture[]", blobB, `${itemNo}-2.jpg`); // goes with pictureB
    axios({
      method: "post",
      url: "/api/form/inventory/images",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    })
      .then((response) => {
        return JSON.parse(response.data);
      })
      .then((data) => {
        dispatch(resetActiveItem());
        dispatch(updateActiveItem(data.itemInformation));
        dispatch(push(`/inventory/edit/${data.itemInformation.itemNo}`));
        return;
      })
      .then(() => dispatch(fetchingDataSuccess()))
      .catch((err) => {
        if (err.response) {
          dispatch(fetchingDataFailure(err.response.status));
          console.error(err.response.status);
        } else {
          dispatch(fetchingDataFailure());
        }
      })
      .then(() => {
        state.pictureA(false);
        state.pictureB(false);
        state.submittingPictures(false);
      });
  };
};

export const handleInventoryEmployeeScan = ({
  barcode,
  type,
  location,
}: {
  barcode: string;
  type: string;
  location: string;
}): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(fetchingData());
    axios({
      method: "post",
      url: `/api/scan/employee/inventory/${type}`,
      data: { barcode, location },
    })
      .then((response) => {
        dispatch(
          scannerResStatus({
            key: "EmployeeScanner",
            status: response.status,
          })
        );
        return response.data;
      })
      .then(() => dispatch(fetchingDataSuccess()))
      .catch((err) => {
        dispatch(fetchingDataFailure(err.response.status));
        dispatch(
          scannerResStatus({
            key: "EmployeeScanner",
            status: err.response.status,
          })
        );
        console.error(err.response.status);
      });
    dispatch(fetchingDataSuccess());
  };
};

export const favoriteItem = (
  itemID: number,
  setFavorite: React.Dispatch<React.SetStateAction<boolean>>
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(fetchingData());
    const data = new FormData();
    data.append("itemID", itemID.toString());
    axios({
      method: "post",
      url: "/api/cursor/draggable-inventory/favorite-item",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    })
      .then((response) => {
        return JSON.parse(response.data);
      })
      .then((data) => {
        if (process.env.NODE_ENV === "development") console.log(data); // should probably replace the item information with new picture urls
        return;
      })
      .then(() => dispatch(favoriteItemSuccess(itemID)))
      .then(() => dispatch(fetchingDataSuccess()))
      .catch((err) => {
        setFavorite(false);
        if (err.response) {
          dispatch(favoriteItemFailure(itemID));
          dispatch(fetchingDataFailure(err.response.status));
        } else dispatch(fetchingDataFailure());
      });
  };
};
export const favoriteItemFailure = (payload: number): InventoryReducer => {
  return {
    type: "FAVORITE_ITEM_FAILURE",
    payload,
  };
};
export const favoriteItemSuccess = (payload: number): InventoryReducer => {
  return {
    type: "FAVORITE_ITEM_SUCCESS",
    payload: { itemID: payload },
  };
};

let artToastWarning = true;
let rugToastWarning = true;
export const builderInventorySearch = ({
  catNos,
}: {
  catNos: string;
}): InventoryReducer => {
  // art
  if (Number(catNos) > 800 && Number(catNos) < 900 && artToastWarning) {
    artToastWarning = false;
    toast(
      "Sorry, we don't have all the art in the website yet. We are working hard to get it finished.",
      {
        autoClose: false,
        position: "top-center",
      }
    );
  }
  if (Number(catNos) > 900 && Number(catNos) < 1000 && rugToastWarning) {
    rugToastWarning = false;
    toast(
      "Sorry, we don't have all the rugs in the website yet. We are working hard to get it finished.",
      {
        autoClose: false,
        position: "top-center",
      }
    );
  }
  return {
    type: "BUILDER_INVENTORY_SEARCH",
    payload: { catNos },
  };
};
export const updateBuilderInventoryColors = (): InventoryReducer => {
  return {
    type: "BUILDER_INVENTORY_COLORS",
    payload: null,
  };
};
export const resetBuilderInventory = (): InventoryReducer => {
  return {
    type: "BUILDER_INVENTORY_RESET",
    payload: null,
  };
};
