import { push } from "connected-react-router";
import {
  FC,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useReducer,
  useContext,
} from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fetchingData, fetchingDataSuccess } from "../../../../redux";
import {
  CreateMHFODelivery,
  DeleteMHFODelivery,
  MHFOEventProps,
  UpdateMHFODelivery,
  useCalendarMHFO,
} from "../../hooks/fetching/useCalendarMHFO";
import {
  CreateTask,
  DeleteTask,
  TaskProps,
  UpdateTask,
  useCalendarTasks,
} from "../../hooks/fetching/useCalendarTasks";

interface EventState {
  mhfo: {
    events: MHFOEventProps[];
    createMHFODelivery?: CreateMHFODelivery;
    updateMHFODelivery?: UpdateMHFODelivery;
    deleteMHFODelivery?: DeleteMHFODelivery;
  };
  tasks: {
    events: TaskProps[];
    createTask?: CreateTask;
    deleteTask?: DeleteTask;
    updateTask?: UpdateTask;
  };
}
const initialEventState: EventState = {
  mhfo: {
    events: [] as MHFOEventProps[],
  },
  tasks: {
    events: [] as TaskProps[],
  },
};

export type EventReducer =
  | {
      type: "UPDATE_MHFO_EVENTS";
      payload: MHFOEventProps[];
    }
  | {
      type: "UPDATE_TASK_EVENTS";
      payload: TaskProps[];
    };

export const eventReducer = (state: EventState, action: EventReducer) => {
  switch (action.type) {
    case "UPDATE_MHFO_EVENTS":
      return { ...state, mhfo: { events: [...action.payload] } };
    case "UPDATE_TASK_EVENTS":
      return { ...state, tasks: { events: [...action.payload] } };
    default:
      return { ...state };
  }
};

const CalendarContext = createContext<EventState>({
  ...initialEventState,
});

const { Consumer: CalendarConsumer, Provider: CalendarProvider } =
  CalendarContext;

export const useCalendar = () => useContext(CalendarContext);
/**
 * Adds calendar context for use on /calendar path
 *
 * But this could technically be used anywhere that it is needed
 * @param children
 * @returns children wrapped in a context
 * @todo
 * 1) Figure out why socket event is firing twice in useEffect...
 */
const CALENDAR: FC<{
  children: JSX.Element | JSX.Element[];
}> = ({ children }) => {
  const dispatch = useDispatch();
  const {
    socket,
    user: { loading: reduxLoading },
  } = useSelector((state: Istate) => state.data, shallowEqual);

  const [state, dispatchEvents] = useReducer(eventReducer, initialEventState);

  const [redirect, setRedirect] = useState("");
  // setIsLoading
  // [tasks, mhfo]
  const [loading, setIsLoading] = useState([false, false]);

  useEffect(() => {
    if (loading.includes(true) && !reduxLoading) dispatch(fetchingData());
    else if (reduxLoading) dispatch(fetchingDataSuccess());
    return () => {
      dispatch(fetchingDataSuccess());
    };
    // Do not want to change on reduxLoading or this runs WAY TOO OFTEN
    // eslint-disable-next-line
  }, [loading, dispatch]);

  const saveCalendarEvent = useCallback((stringyData: string) => {
    const data = JSON.parse(stringyData) as EventReducer;
    try {
      data.payload.forEach((e) => {
        e.taskStart = Date.parse(e.taskStart as string);
        e.taskEnd = Date.parse(e.taskEnd as string);
        return e;
      });
      dispatchEvents(data);
    } catch (error) {
      toast(
        "Something went wrong while loading events... Try refreshing your browser.",
        { type: "error" }
      );
    }
    switch (data.type) {
      case "UPDATE_TASK_EVENTS":
        setIsLoading((current) => {
          current[0] = false;
          return [...current];
        });
        break;
      case "UPDATE_MHFO_EVENTS":
        setIsLoading((current) => {
          current[1] = false;
          return [...current];
        });
        break;
    }
  }, []);

  // calendar hooks
  const { createTask, deleteTask, updateTask } = useCalendarTasks({
    setRedirect,
    setIsLoading,
  });
  const { createMHFODelivery, deleteMHFODelivery, updateMHFODelivery } =
    useCalendarMHFO({ setRedirect, setIsLoading });

  // memoization of the entire state
  // dont add create/delete/update or load is called everytime
  const memoizedProviderValue = useMemo(
    () => ({
      mhfo: {
        events: state.mhfo.events,
        createMHFODelivery,
        updateMHFODelivery,
        deleteMHFODelivery,
      },
      tasks: { events: state.tasks.events, createTask, deleteTask, updateTask },
    }),
    [
      state,
      createTask,
      deleteTask,
      updateTask,
      createMHFODelivery,
      updateMHFODelivery,
      deleteMHFODelivery,
    ]
  );
  // conditional
  useEffect(() => {
    if (redirect && !loading.includes(true)) {
      dispatch(push(redirect));
      setRedirect("");
    }
  }, [dispatch, redirect, loading]);

  useEffect(() => {
    if (socket) {
      // tasks
      if (socket.listeners("LOAD_CALENDAR_TASKS").length)
        socket.off("LOAD_CALENDAR_TASKS", saveCalendarEvent);
      socket.on("LOAD_CALENDAR_TASKS", saveCalendarEvent);
      // mhfo
      if (socket.listeners("LOAD_CALENDAR_MHFO").length)
        socket.off("LOAD_CALENDAR_MHFO", saveCalendarEvent);
      socket.on("LOAD_CALENDAR_MHFO", saveCalendarEvent);
      //

      socket.emit(
        "CALENDAR",
        JSON.stringify({
          type: "MHFO",
          action: "LOAD",
          payload: null,
        })
      );
      socket.emit(
        "CALENDAR",
        JSON.stringify({
          type: "TASKS",
          action: "LOAD",
          payload: null,
        })
      );
      setIsLoading([true, true]);
    }
    return () => {
      socket?.off("LOAD_CALENDAR_MHFO", saveCalendarEvent);
      socket?.off("LOAD_CALENDAR_TASKS", saveCalendarEvent);
    };
  }, [socket, saveCalendarEvent]);
  return (
    <CalendarProvider value={memoizedProviderValue}>
      {children}
    </CalendarProvider>
  );
};

export { CALENDAR, CalendarConsumer };
