import { useRef, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Istate } from "../../../../../redux";
import { getStagingBuilderNotes } from "../../../../../redux/stagingBuilder/stagingBuilderActions";
import { CommentForm } from "./CommentForm";
import { dateOptions } from "../../../../constants/";
interface Props {}
export const StagingBuilderNotes = (props: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStagingBuilderNotes());
  }, [dispatch]);
  const messages = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex]
        .messages,
    shallowEqual
  );
  const businessID = useSelector(
    (state: Istate) => state.data.user.businessID,
    shallowEqual
  );
  const commentContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (commentContainerRef?.current)
      commentContainerRef.current.scrollTop =
        commentContainerRef.current.offsetHeight;
  }, [messages, commentContainerRef]);
  return (
    <>
      <h2>Staging Builder Notes</h2>
      <div
        ref={commentContainerRef}
        style={{ padding: "4px 4px 0 4px" }}
        className="simple-border simple-border__no-margin staging-builder-comments__container overflow-y-scroll"
      >
        {messages?.map((comment) =>
          !comment.message.match(/^!employee/i) ? (
            <div
              className="staging-builder-comments"
              key={comment.stagingBuilderNoteID}
            >
              <p>
                {comment.message} -{" "}
                <span className="text-bold">{comment.first}</span>{" "}
                <span className="comment-timestamp">
                  {new Date(comment.created).toLocaleDateString(
                    "us-AZ",
                    dateOptions
                  )}
                </span>
              </p>
            </div>
          ) : businessID === 1 ? (
            <div
              className="staging-builder-comments"
              key={comment.stagingBuilderNoteID}
            >
              <p>
                {comment.message.split(/^!employee/i)} -{" "}
                <span className="text-bold">{comment.first}</span>{" "}
                <span className="comment-timestamp">
                  {new Date(comment.created).toLocaleDateString(
                    "us-AZ",
                    dateOptions
                  )}
                </span>
              </p>
            </div>
          ) : null
        )}
      </div>
      <CommentForm />
    </>
  );
};
