import axios from "axios";
import { push } from "connected-react-router";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { fetchingData } from "../..";
import { fillInventoryMin } from "../../inventory/inventoryActions";
import { itemMinimal } from "../../inventory/inventoryTypes";
import {
  fetchingDataFailure,
  fetchingDataSuccess,
  logOutUser,
} from "../../users/userActions";
import {
  loadActiveStagingBuilder,
  setStagingBuilderToken,
  updateContainerItemStatus,
} from "../stagingBuilderActions";
import { updateStagingBuilderMeta } from "./updateStagingBuilderMeta";

export const fillStagingBuilderFromRefresh = (): ThunkAction<
  Promise<void>,
  {},
  {},
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: Function
  ): Promise<void> => {
    dispatch(fetchingData());
    axios({
      method: "get",
      url: `/api/effects/user-refreshed/staging-builder`,
    })
      .then((response) => {
        return { ...response.data };
      })
      .then(
        async ({
          success,
          stagingBuilder,
          itemsAsObject,
          orderArray,
          clashes,
          groups,
        }: {
          success: boolean;
          stagingBuilder: StagingBuilder;
          itemsAsObject: { [itemID: number]: itemMinimal };
          orderArray: {
            itemID: number;
            index: number;
          }[];
          clashes: {
            itemContainerID: number;
            itemID: number;
            itemStatus: number;
          }[];
          groups: { [index: string]: number[] };
        }) => {
          if (stagingBuilder !== undefined && success === true) {
            dispatch(
              setStagingBuilderToken({
                stagingBuilderID: stagingBuilder.stagingBuilderID,
              })
            );
            dispatch(loadActiveStagingBuilder(stagingBuilder));
            dispatch(
              fillInventoryMin({
                itemObject: itemsAsObject,
                orderArray,
                groups,
              })
            );
            if (clashes[0] !== undefined) {
              clashes.forEach((item) => {
                dispatch(updateContainerItemStatus(item));
              });
              const { data } = getState() as Istate;
              stagingBuilder =
                data.stagingBuilders[
                  data.stagingBuilders.findIndex(
                    (sb) =>
                      sb.stagingBuilderID === stagingBuilder.stagingBuilderID
                  )
                ];

              stagingBuilder.clashes = clashes;
              dispatch(updateStagingBuilderMeta(stagingBuilder));
            }
          }
        }
      )
      .then(() => dispatch(fetchingDataSuccess()))
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(logOutUser());
            dispatch(push("/user/login"));
          }
          dispatch(fetchingDataFailure(err.response.status));
        } else dispatch(fetchingDataFailure());
      });
  };
};
