import React, { createRef } from "react";
import { FiPrinter } from "react-icons/fi";
import { GiExtractionOrb } from "react-icons/gi";
import { HiRefresh } from "react-icons/hi";
import { BiCommentDetail } from "react-icons/bi";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { AppThunk, Istate, resetActiveItem } from "../../../../../redux";
import { updateContainerItemLockedFields } from "../../../../../redux/stagingBuilder/actions/updateContainerItemLockedFields";
import { ContainerItem } from "../../../../../redux/stagingBuilder/stagingBuilderTypes";
import { HandleItemActivityClick } from "../../../../../SocketControl/Inventory";
import { hrefPrefix, ToastyRed } from "../../../../constants";
import { ContainerItemStatuses } from "../../../../constants/enums/ContainerItemStatuses";
import { EmployeeEditItemForm } from "./EmployeeEditItemForm";

interface Props {
  containerItem: ContainerItem;
  setMarkupMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EmployeeEdit = (props: Props) => {
  const dispatch: AppThunk = useDispatch();
  const divref = createRef<HTMLDivElement>();
  const { itemID, containerItemID, itemStatus } = { ...props.containerItem };
  const itemNo = useSelector(
    (state: Istate) => state.data.builderInventory[itemID].itemNo,
    shallowEqual
  );
  React.useEffect(() => {
    divref.current?.classList.add("active");
  }, [divref]);

  const handleRefreshClick = () => {
    const Confirm = window.confirm(
      "You are about to refresh the locked rate and locked retail of this item."
    );

    if (Confirm) {
      if (itemStatus === ContainerItemStatuses.STAGED) {
        ToastyRed(
          "You cannot change the rental rate or sales price of staged items"
        );
        return;
      }
      containerItemID &&
        dispatch(
          updateContainerItemLockedFields({
            containerItemID,
          })
        );
    }
  };
  return (
    <div
      ref={divref}
      className="absolute top right left bottom markup-menu z-4 p-1"
    >
      <Link
        onClick={() => dispatch(resetActiveItem())}
        to={`/inventory/edit/${itemNo}`}
      >
        <h3 style={{ color: "var(--link-blue)" }}>Item Info</h3>
      </Link>
      <Link
        to={{
          pathname: `${hrefPrefix}/api/print/single-tag?itemNo=${itemNo}&printQuantity=4&tagStyle=4&tagSize=1`,
        }}
        target="_blank"
      >
        <FiPrinter
          className="absolute z-5"
          size="1rem"
          style={{ right: "64px", top: "0.25rem" }}
          cursor="pointer"
        />
      </Link>
      <HandleItemActivityClick item={{ ...props.containerItem, itemNo }}>
        {(handleClick) => (
          <GiExtractionOrb
            className="absolute z-5"
            cursor="pointer"
            type="button"
            onClick={handleClick}
            style={{ right: "94px", top: "0.25rem" }}
          >
            See SB Activity
          </GiExtractionOrb>
        )}
      </HandleItemActivityClick>
      <HiRefresh
        className="absolute z-5"
        size="1rem"
        style={{ right: "34px", top: "0.25rem" }}
        cursor="pointer"
        onClick={handleRefreshClick}
      />
      <Link
        to={{
          search: `open-modal=container-item-comment-form&containerItemID=${containerItemID}`,
        }}
      >
        <BiCommentDetail
          className="absolute z-5"
          size="1rem"
          style={{ right: "4px", top: "2.0rem" }}
          cursor="pointer"
        />
      </Link>
      <EmployeeEditItemForm {...props} />
    </div>
  );
};
