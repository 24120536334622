import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { MegaMenuProps } from ".";
import { AppThunk, setRoutePayload } from "../../../../redux";
import { builderInventorySearch } from "../../../../redux/inventory/inventoryActions";
import { menus } from "../../../constants";
import { Menu } from "../../../constants/values/MegaMenuSearch";
interface Props {
  setWhatIsOpen: React.Dispatch<React.SetStateAction<MegaMenuProps | null>>;
  menu: MegaMenuProps;
}
// I need a drop down list for each room with all the subcats and all the items in them
export const MegaMenu = ({ menu, setWhatIsOpen }: Props) => {
  const dispatch: AppThunk = useDispatch();
  const [currentMenu, setCurrentMenu] = useState<Menu | null>(null);
  const [showAllCats, setShowAllCats] = useState<Function | undefined>(
    undefined
  );
  const [menuOffSet, setMenuOffSet] = useState<"left" | "right">("left");

  const [menuClasses, setMenuClasses] = useState<string[]>(
    new Array(menus[menu].length)
  );
  const [hoverTimer, setHoverTimer] = useState<number | NodeJS.Timeout | null>(
    null
  );

  const openSubMenu = (currentMenu: Menu, index: number) => {
    setCurrentMenu(currentMenu);
    setShowAllCats(undefined);
    setShowAllCats(() => {
      let x = currentMenu.showAll ? currentMenu.showAll() : undefined;
      return x
        ? () =>
            setCurrentMenu((current) => {
              if (current !== null && x !== undefined)
                return { ...current, cats: x };
              else return null;
            })
        : undefined;
    });
    let menuClassesCopy = menuClasses.map(() => "");
    menuClassesCopy[index] = "sm:bg-white";
    setMenuClasses(menuClassesCopy);
  };

  const hoverPause = (currentMenu: Menu, index: number) => {
    const timer = setTimeout(() => {
      openSubMenu(currentMenu, index);
      setMenuOffSet("right");
    }, 200);
    setHoverTimer(timer);
  };
  const clearHoverPause = () => {
    hoverTimer && clearTimeout(hoverTimer);
    setHoverTimer(null);
  };

  useEffect(() => {
    openSubMenu(menus[menu][0], 0);
    setMenuOffSet("left");
    // including openSubMenu creates an infinite loop
    // could potentially memoize it, but it works fine as is
    // eslint-disable-next-line
  }, [menu]);

  useEffect(() => {
    return () => {
      hoverTimer && clearTimeout(hoverTimer);
    };
    // don't need to include hoverTimer as we ONLY want this to run this to prevent memory leak
    // eslint-disable-next-line
  }, []);

  const dispatchSearch = (catNos: string) => {
    dispatch(setRoutePayload({ key: "Stage", payload: "BUILDER_SEARCH" }));
    dispatch(builderInventorySearch({ catNos }));
    setWhatIsOpen(null);
  };
  return menu ? (
    <div
      onMouseLeave={() => setWhatIsOpen(null)}
      className={`absolute left right bg-white grid mega-menu-wrapper mobile-grid-${menuOffSet} overflow-x-hidden`}
    >
      <ul className="flex column bg-back-drop-light h-full overflow-x-hidden">
        {menus[menu].map((menu, index) => (
          <li
            onMouseLeave={() => clearHoverPause()}
            onMouseOver={() => hoverPause(menu, index)}
            onClick={() => hoverPause(menu, index)}
            className={"w-full " + (menuClasses[index] || "bg-back-drop-light")}
            key={index}
          >
            <div
              onMouseOver={() => openSubMenu(menu, index)}
              className="hover-gaurd w-full flex flex-row justify-between"
            >
              <span className="inline-block p-2">{menu.title}</span>
              <span
                onClick={() => {
                  setMenuOffSet("right");
                }}
                className="inline-block p-2 sm:hidden text-xl"
              >
                &gt;&gt;
              </span>
            </div>
          </li>
        ))}
      </ul>
      {currentMenu && (
        <div className="m-4 relative">
          <h1 className="text-xl flex justify-between flex-row">
            <span>{currentMenu.title}</span>
            <span
              className="inline-block sm:hidden"
              onClick={() => {
                setMenuOffSet("left");
              }}
            >
              &lt;&lt;
            </span>
          </h1>
          <ul className="link-list">
            {currentMenu.cats.map((cat, index) => (
              <li
                style={{ color: cat.urlParam === "" ? "red" : "black" }}
                key={index}
              >
                <span onClick={() => dispatchSearch(cat.urlParam)}>
                  {cat.title}
                </span>
              </li>
            ))}
          </ul>
          {showAllCats !== undefined && (
            <p className="link absolute bottom" onClick={() => showAllCats()}>
              Show All {currentMenu.title}
            </p>
          )}
        </div>
      )}
    </div>
  ) : null;
};
