import { EventProps } from "../index";
import { CalendarEventErrors, CalendarEventTypes } from ".";
import { MHFOEventProps } from "../../constants/hooks/fetching/useCalendarMHFO";
import { GetDate } from "../../constants";

interface Props {
  task: MHFOEventProps;
  length: number;
  index: number;
}

export const MHFODelivery = ({ task }: Props): EventProps | undefined => {
  let className: string | undefined;
  let errorType = CalendarEventErrors.NO_ERROR;

  const toolTipInformation: Partial<EventProps> & {
    toolTip: (e: EventProps) => string;
  } = {
    toolTip: (e) => {
      return `Customer: ${task.customer}\n\n${
        task.taskDetails
      }\n\nCreated: ${GetDate(task.createdOn)}`;
    },
  };
  const title = (
    <p className="flex justify-between">
      <span>MHFO-{task.customer}</span>
      <span>{task.taskHours}</span>
      <span></span>
    </p>
  );
  return {
    ...toolTipInformation,
    id: `MHFO-${task.MHFODeliveryID}`,
    title,
    type: CalendarEventTypes.IMPORTANT_TASK,
    allDay: true,
    start: new Date(task.taskStart),
    end: new Date(task.taskStart),
    domAttributes: { className },
    styles: {
      backgroundColor: className === "" ? "transparent" : "rgb(178,117,9)",
      color: className === "" ? "black" : "white",
      fontWeight: className === "" ? "bold" : "normal",
    },
    errorType,
  };
};
