import React, { FormEvent, useEffect } from "react";
import { useSelector } from "react-redux";
import { useBooleanState } from "../../HighlyReusableComponents/hooks/useBooleanState";
import { handleAddItemToStickerQueue } from "../../HighlyReusableComponents/SocketEmitters/handleAddItemToStickerQueue";
import { BarcodeInputField } from "../StagingBuilder/BarcodeInputField";
import { TagOptions } from "./TagOptions";

interface Props {
  barcodeRef: React.RefObject<HTMLInputElement>;
}

export const ScannerForm = ({ barcodeRef }: Props) => {
  const socket = useSelector((state: Istate) => state.data.socket);
  const pricingControl = () => {};
  useEffect(() => {
    socket?.off("INVENTORY", pricingControl);
    socket?.on("INVENTORY", pricingControl);
    return () => {
      socket?.off("INVENTORY", pricingControl);
    };
  }, [socket]);

  const initialValues = {
    auto: true,
    stickerTag: false,
    normalTag: false,
    queueNormalTag: false,
    newWindowNormalTag: false,
    newTabNormalTag: false,
    zebraTag: false,
  };
  const checkState = useBooleanState(initialValues);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      barcodeRef.current?.value === undefined ||
      barcodeRef.current?.value === ""
    )
      return;
    // need to check if it exists
    // if it does push new info, maybe use modal?
    handleAddItemToStickerQueue(socket, barcodeRef.current.value, {
      ...checkState.values,
    });
    // reset the input field
    barcodeRef.current.value = "";
  };
  return (
    <form onSubmit={onSubmit}>
      <TagOptions checkState={checkState} />
      <BarcodeInputField barcodeRef={barcodeRef} />
      <button style={{ display: "none" }} type="submit"></button>
    </form>
  );
};
