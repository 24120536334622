import React from "react";
import { FormLogic } from "./form/FormLogic";

export const ConfirmationModal = () => {
  return (
    <div className="confirmation-modal">
      <FormLogic />
    </div>
  );
};
