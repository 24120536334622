export const options = [
  { label: "Choose:", value: "0" },
  { label: "1-A", value: "1" },
  { label: "1-B", value: "2" },
  { label: "1-C", value: "3" },
  { label: "2-A", value: "4" },
  { label: "2-B", value: "5" },
  { label: "3-A", value: "6" },
  { label: "3-B", value: "7" },
  { label: "3-C", value: "8" },
  { label: "4-A", value: "9" },
  { label: "4-B", value: "10" },
  { label: "5-A", value: "11" },
  { label: "5-B", value: "12" },
  { label: "5-C", value: "13" },
  { label: "6-A", value: "14" },
  { label: "6-B", value: "15" },
  { label: "6-C", value: "16" },
  { label: "7-A", value: "17" },
  { label: "7-B", value: "18" },
  { label: "7-C", value: "19" },
  { label: "8-A", value: "20" },
  { label: "9-A", value: "21" },
];
