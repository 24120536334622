import { Switch, Route } from "react-router-dom";
import { EmployeeScanner } from "./UpdateInventory";
import { CustomButton } from "../HighlyReusableComponents/CustomButton";
import { ChooseScanner } from "./";
import { Scanner as PricingScanner } from "./UpdatePricing";
import { Scanner as TagScanner } from "./TagControl";
import { Scanner as BinScanner } from "./BinControl";
import { Main as DeltaInventoryScanner } from "../page-specific/scanner/delta-inventory/Main";
import { BinningContext } from "./BinControl/useBinNumber";
interface Props {
  barcodeRef: React.RefObject<HTMLInputElement>;
  setBackgroundColor: React.Dispatch<React.SetStateAction<string>>;
}

export const ScannerRouter = ({ barcodeRef, setBackgroundColor }: Props) => {
  return (
    <Switch>
      <Route exact path="/scanner/inventory-control">
        <ul>
          <li className="w-32">
            <CustomButton linkProps={{ to: "/scanner/inventory-control/loft" }}>
              <h1>Loft</h1>
            </CustomButton>
          </li>
          <li className="w-32">
            {/* <Link to="/scanner/inventory-control/mhfo" className="active"> */}
            <CustomButton disabled>
              <h1 style={{ color: "#777" }}>MHFO</h1>
            </CustomButton>
            {/* </Link> */}
          </li>
          <li className="w-32">
            {/* <Link to="/scanner/inventory-control/huddle" className="active"> */}
            <CustomButton disabled>
              <h1 style={{ color: "#777" }}>Huddle</h1>
            </CustomButton>
            {/* </Link> */}
          </li>
        </ul>
      </Route>
      <Route exact path="/scanner/inventory-control/loft">
        <EmployeeScanner
          setColorFeedback={setBackgroundColor}
          barcodeRef={barcodeRef}
          location="LOFT"
        />
      </Route>
      <Route exact path="/scanner/pricing-control">
        <PricingScanner barcodeRef={barcodeRef} />
      </Route>
      <Route exact path="/scanner/tag-control">
        <TagScanner barcodeRef={barcodeRef} />
      </Route>
      <Route exact path="/scanner/bin-control">
        <BinScanner barcodeRef={barcodeRef} />
      </Route>
      <Route exact path="/scanner/delta-inventory">
        <BinningContext>
          <DeltaInventoryScanner barcodeRef={barcodeRef} />
        </BinningContext>
      </Route>
      <Route>
        <ChooseScanner />
      </Route>
    </Switch>
  );
};
