import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { logOutUser } from "../../redux/users/userActions";
import { useNavigation } from "../mobile-nav/context/NavigationContext";

export const LogOut = () => {
  const dispatch = useDispatch();
  const { updateMobileViewSide } = useNavigation();
  const [, setstate] = useState(false);
  useEffect(() => {
    updateMobileViewSide(0);
    dispatch(logOutUser());
    setstate(true);
  }, [dispatch, updateMobileViewSide]);
  return null;
};
