import { FormikProps } from "formik";
import { useSelector, shallowEqual } from "react-redux";
import { Istate } from "../../../../../../redux";
import { dateOptionsShortVerbose, GetDate } from "../../../../../constants";
import FormikControl from "../../../../../FormikComponents/FormikControl";
import { CustomButton } from "../../../../../HighlyReusableComponents/CustomButton";
import { InitialValues } from "./initialValues";
// need Form control

interface Props {
  initialValues: InitialValues;
  FormikProps: FormikProps<InitialValues>;
  stagingBuilder: StagingBuilder;
}
export const FormFields = ({
  stagingBuilder: { pickupDesired, pickupActual },
  FormikProps: { submitForm, dirty },
}: Props) => {
  const { stagingSchedulingPermissions, ownsThisStagingBuilder } = useSelector(
    (state: Istate) => {
      const muUserID = state.data.user.id;
      const myBusinessID = state.data.user.businessID;
      const stagingBuilderBusinessID =
        state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex]
          .businessID;
      const stagingBuilderUserID =
        state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex]
          .userID;
      const stagingBuilderShared =
        state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex]
          .shared;
      return {
        stagingSchedulingPermissions: state.data.user.businessID === 1,
        ownsThisStagingBuilder:
          muUserID === stagingBuilderUserID ||
          (stagingBuilderShared && myBusinessID === stagingBuilderBusinessID),
        businessID: myBusinessID,
      };
    },
    shallowEqual
  );
  return (
    <>
      <h2 className="my-4 text-2xl">Choose A Date You Want</h2>
      {ownsThisStagingBuilder ? (
        <FormikControl
          control="date-picker"
          label={<span className="text-bold">Desired Pickup Date</span>}
          name="pickupDesired"
        />
      ) : (
        <div className="flex justify-between">
          <span className="text-bold">Desired Pickup Date</span>
          {GetDate(
            pickupDesired,
            "The owner of this StagingBuilder has not chosen a desired date.",
            dateOptionsShortVerbose
          )}
        </div>
      )}
      <br />
      <h2 className="mb-4 text-2xl">Actual Scheduled Date</h2>
      {stagingSchedulingPermissions ? (
        <FormikControl
          disabled={!stagingSchedulingPermissions}
          controlContainerclass="basic-input flex justify-between"
          control="date-picker"
          label={<span className="text-bold">Scheduled Pickup Date</span>}
          name="pickupActual"
        />
      ) : (
        GetDate(
          pickupActual,
          "This StagingBuilder has not yet been scheduled.",
          dateOptionsShortVerbose
        )
      )}
      <CustomButton
        type="button"
        onClick={submitForm}
        style={{
          backgroundColor: "var(--logo-purple)",
          color: "white",
        }}
        disabled={!dirty}
      >
        Submit
      </CustomButton>
    </>
  );
};
