import { Link } from "react-router-dom";
import { Sections } from "./Sections";

export const Instructions = () => {
  return (
    <div className="md:h-full md:overflow-y-auto">
      <h2 className="text-xl">Instructions</h2>
      <ul>
        <li
          className="grid place-items-start gap-x-2 mt-3"
          style={{ gridTemplateColumns: "1.5ch 1fr" }}
        >
          1)
          <p className="max-w-prose">
            Compare the{" "}
            <Link to="/calendar" className="text-defaults-link">
              Calender
            </Link>{" "}
            with the schedule for the past 8 weeks. Fix any discrepancies.
          </p>
        </li>
        <li
          className="grid place-items-start gap-x-2 mt-3"
          style={{ gridTemplateColumns: "1.5ch 1fr" }}
        >
          2)
          <p className="max-w-prose">
            Fix any blinking StagingBuilders in the{" "}
            <Link to="/calendar" className="text-defaults-link">
              Calender
            </Link>
            .
          </p>
        </li>
        <li
          className="grid place-items-start gap-x-2 mt-3"
          style={{ gridTemplateColumns: "1.5ch 1fr" }}
        >
          3)
          <p className="max-w-prose">
            On the right side of this page choose the Section of the Loft you
            want to work on. Below is a detailed list of what each section is:
          </p>
          <Sections />
        </li>
        <li
          className="grid place-items-start gap-x-2 mt-3"
          style={{ gridTemplateColumns: "1.5ch 1fr" }}
        >
          4)
          <p className="max-w-prose">
            Scan all of the items on the floor for the section you are working
            on. (This includes items with hold tags on them.)
          </p>
        </li>
        <li
          className="grid place-items-start gap-x-2"
          style={{ gridTemplateColumns: "1.5ch 1fr" }}
        >
          5)
          <p className="max-w-prose">
            Generate &Delta;Report and make sure that you didn't miss any items.
            Common items that are missed are ones without tags on them.
          </p>
        </li>
        <li
          className="grid place-items-start gap-x-2"
          style={{ gridTemplateColumns: "1.5ch 1fr" }}
        >
          6)
          <p className="max-w-prose">
            Make tags for inventory items that need them. Make sure that you did
            not skip scanning items that did not have tags. (Scan the tags you
            just made if they were missing in step 4.)
          </p>
        </li>
        <li
          className="grid place-items-start gap-x-2"
          style={{ gridTemplateColumns: "1.5ch 1fr" }}
        >
          7)
          <p className="max-w-prose">
            Move misplaced inventory to their appropriate sections.
          </p>
        </li>
        <li
          className="grid place-items-start gap-x-2"
          style={{ gridTemplateColumns: "1.5ch 1fr" }}
        >
          8)
          <p className="max-w-prose">
            Overwrite the inventory by clicking the button on the right hand
            side.
          </p>
        </li>
      </ul>
      <div></div>
    </div>
  );
};
