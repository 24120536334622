import { push } from "connected-react-router";
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { AppThunk, updateAccountInfo } from "../../../../redux";
import { PromptIfDirty } from "../../../FormikComponents/PromptIfDirty";
import { FormFields } from "./FormFields";
import { formInit, InitialValues } from "./initialValues";
interface Props {
  user: User;
}

export const FormLogic = ({ user }: Props) => {
  const dispatch: AppThunk = useDispatch();
  const onSubmit = (
    values: InitialValues,
    actions: FormikHelpers<InitialValues>
  ) => {
    let submissionValues = {
      ...values,
      phone: values.phone ? values.phone : "",
    };
    dispatch(updateAccountInfo(submissionValues));
  };
  return (
    <Formik
      initialValues={formInit(user).initialValues}
      validationSchema={formInit(user).validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {(FormikProps: FormikProps<InitialValues>) => (
        <Form>
          <FormFields />
          <PromptIfDirty />
          <div className="grid col-2 gap-1">
            <button
              className="bg-logo-blue w-full py-1.5 mt-2 rounded-lg border border-gray-400 focus:outline-none focus:ring hover:underline hover:shadow-lg"
              onClick={() => dispatch(push("/accounts/my-account"))}
              type="button"
            >
              Cancel
            </button>
            <button
              className="bg-logo-blue w-full py-1.5 mt-2 rounded-lg border border-gray-400 focus:outline-none focus:ring hover:underline hover:shadow-lg"
              disabled={!FormikProps.dirty}
              onClick={FormikProps.submitForm}
              type="button"
            >
              Save
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
