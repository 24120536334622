import { StagingItemStatuses } from "../constants/enums/StagingItemStatuses";

export const StagingItemStatusesDD = [
  { id: StagingItemStatuses.TBD, text: "TBD" },
  { id: StagingItemStatuses.RETURNED, text: "Avail. to Rent" },
  { id: StagingItemStatuses.SOLD, text: "Sold" },
  { id: StagingItemStatuses.TOUCH_UP, text: "Touch Up" },
  { id: StagingItemStatuses.WORN_OUT, text: "Worn-Out To Sell" },
  { id: StagingItemStatuses.SHRINKAGE, text: "Mystery Shrinkage" },
  { id: StagingItemStatuses.STOLEN_MISSING, text: "Stolen or Missing" },
  { id: StagingItemStatuses.DAMAGED_BILL, text: "Damaged to Bill" },
  { id: StagingItemStatuses.DESTROYED_BILL, text: "DESTROYED to Bill" },
  { id: StagingItemStatuses.DAMAGED_PAID, text: "Damaged PAID" },
  { id: StagingItemStatuses.DESTROYED_PAID, text: "DESTROYED PAID" },
  {
    id: StagingItemStatuses.REJECTED_FROM_DELIVERY,
    text: "Rejected From Delivery",
  },
];
