import { useMemo } from "react";
import type { FC } from "react";
import { BlockContainer } from "./BlockContainer";
import { BlockBackDrop } from "./BlockBackDrop";
import { ParseStatus } from "../../../../constants";

export const StatusBlock: FC<
  Pick<StagingBuilder, "stagingStatus" | "businessID" | "fileNumber">
> = ({ stagingStatus, businessID, fileNumber }) => {
  const status = useMemo(() => {
    return ParseStatus(stagingStatus, businessID, fileNumber);
  }, [stagingStatus]);
  return (
    <div className="col-start-2 mt-auto block h-min w-[calc(100%-1rem)] lg:col-start-3 lg:row-start-2 lg:my-0 lg:h-[70px] lg:w-full">
      <BlockContainer>
        <BlockBackDrop />
        <div className="flex flex-col">
          <span className="text-sm font-medium leading-tight">
            Staging Status
          </span>
          <span className="leading-none text-gray-700">{status}</span>
        </div>
      </BlockContainer>
    </div>
  );
};
