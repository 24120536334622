import { useMemo } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { PromptIfDirty } from "../FormikComponents/PromptIfDirty";
import {
  initialValues,
  InitialValues,
  StartStagingSchema,
} from "./initialValues";
import { StartStagingBuilderForm } from "./StartStagingBuilderForm";
import { useDispatch, useSelector } from "react-redux";
import { startStagingBuilder } from "../../redux/stagingBuilder";

export const FormLogic = () => {
  const dispatch = useDispatch();
  const businessID = useSelector((state: Istate) => state.data.user.businessID);
  const handleNewStagingBuilder = (
    data: InitialValues,
    actions: FormikHelpers<InitialValues>
  ) => {
    dispatch(startStagingBuilder({ ...data }));
    actions.setSubmitting(false);
  };
  const query = useSelector((state: Istate) => state.router.location.query);
  const memoizedIntialValues = useMemo(
    () => initialValues(businessID, Number(query["date"])),
    [query]
  );

  return (
    <Formik
      validationSchema={StartStagingSchema}
      initialValues={memoizedIntialValues}
      onSubmit={handleNewStagingBuilder}
      validateOnBlur
    >
      <Form>
        <PromptIfDirty />
        <StartStagingBuilderForm />
      </Form>
    </Formik>
  );
};
