import { useState, useEffect, useMemo } from "react";
import { Droppable, DroppableProvided } from "react-beautiful-dnd";
import { DraggableInventoryItemContainer } from "./DraggableItem.tsx/DraggableInventoryItemContainer";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import type { Istate } from "../../../redux";
import { setRoutePayload } from "../../../redux";

export const DraggableInventoryMap = () => {
  const dispatch = useDispatch();
  const orderIdArray = useSelector(
    (state: Istate) => state.data.builderInventoryOrder
  );
  let itemsAsObject = useSelector(
    (state: Istate) => state.data.builderInventory,
    shallowEqual
  );

  const stageEvent = useSelector(
    (state: Istate) => state.data.route.Stage.payload,
    shallowEqual
  );

  // start it off empty
  const [itemsToRender, setItemsToRender] = useState<typeof orderIdArray>([]);
  // if there ARE items, more items is true
  const [moreItems, setMoreItems] = useState(orderIdArray.length > 0);
  const startup = useMemo(
    () => () => {
      const { length: orderIdArrayLength } = orderIdArray;
      const sliceNumber = orderIdArrayLength > 15 ? 15 : orderIdArrayLength;
      setItemsToRender(orderIdArray.slice(0, sliceNumber));
      setMoreItems(orderIdArrayLength > sliceNumber);
    },
    [orderIdArray]
  );

  const { length: itemsToRenderLength } = itemsToRender;
  useEffect(() => {
    switch (stageEvent) {
      case "INVENTORY_ORDER_UPDATE": {
        setItemsToRender(orderIdArray.slice(0, itemsToRenderLength));
        dispatch(setRoutePayload({ key: "Stage", payload: null }));
        break;
      }
      case "BUILDER_SEARCH": {
        startup();
        dispatch(setRoutePayload({ key: "Stage", payload: null }));
        break;
      }
      default:
        break;
    }
  }, [dispatch, stageEvent, orderIdArray, itemsToRenderLength, startup]);

  useEffect(() => {
    // if itemsToRender is empty add 15 items or as many as posible
    if (itemsToRenderLength === 0 && orderIdArray.length > 0) {
      startup();
    }
  }, [orderIdArray, itemsToRender, itemsToRenderLength, startup]);

  const loadMoreItems = () => {
    const numberOfItemsToEndOn =
      itemsToRender.length + 15 < orderIdArray.length
        ? itemsToRender.length + 15
        : orderIdArray.length;
    setMoreItems(!(numberOfItemsToEndOn === orderIdArray.length));
    setItemsToRender((current) =>
      current.concat(orderIdArray.slice(current.length, numberOfItemsToEndOn))
    );
  };
  return (
    <Droppable droppableId="items">
      {(provided: DroppableProvided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {/* @ts-ignore */}
          <InfiniteScroll
            dataLength={itemsToRender.length}
            next={loadMoreItems}
            hasMore={moreItems}
            scrollableTarget="item-scroll-target"
            loader={
              <p className="text-center h-16">
                <b>Loading More...</b>
              </p>
            }
            endMessage={
              <p className="text-center h-16">
                <b>
                  No More Items of This Type.
                  <br /> Try Searching for Something Else
                </b>
              </p>
            }
            scrollThreshold={0.6}
            style={{ overflow: "visible" }}
          >
            {itemsToRender.map(
              (nextItem: { itemID: number }, index: number) => {
                const rowItem = itemsAsObject[nextItem.itemID.toString()];
                return (
                  <DraggableInventoryItemContainer
                    key={nextItem.itemID}
                    rowItem={rowItem}
                    index={index}
                  />
                );
              }
            )}
          </InfiniteScroll>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
