import { BsDashSquare, BsPlusSquare } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { fetchingData, fetchingDataSuccess } from "../../../../../redux";
import {
  changeContainerItemQuantity,
  moveContainerItem,
} from "../../../../../redux/stagingBuilder/stagingBuilderActions";

interface Props {
  containerItemID: number;
  stagingBuilderID: number;
  itemContainerID: number;
  quantity: number;
}

export const InventoryAddRemove = ({
  containerItemID,
  stagingBuilderID,
  itemContainerID,
  quantity,
}: Props) => {
  const dispatch = useDispatch();
  const changeQuantity = async (addOrRemove: -1 | 1) => {
    dispatch(fetchingData());
    const newQuantity = quantity + addOrRemove;
    if (newQuantity === 0) {
      if (window.confirm("Would you like to move this item to the trash?"))
        dispatch(
          moveContainerItem({
            patchData: {
              stagingBuilderID,
              itemContainerID,
              containerItemID,
              newItemContainerID: -1,
            },
          })
        );
      else dispatch(fetchingDataSuccess());
    } else
      dispatch(
        changeContainerItemQuantity({
          patchData: {
            stagingBuilderID,
            itemContainerID,
            containerItemID,
            newQuantity,
          },
        })
      );
  };

  return (
    <div>
      <span>
        <BsDashSquare
          cursor="pointer"
          size={28}
          className="inline-block p-1 m-1 ml-0"
          onClick={() => {
            changeQuantity(-1);
          }}
        />
      </span>
      <span>
        <BsPlusSquare
          cursor="pointer"
          size={28}
          className="inline-block p-1 m-1"
          onClick={() => {
            changeQuantity(1);
          }}
        />
      </span>
    </div>
  );
};
