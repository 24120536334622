import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { itemMinimal } from "../../../../redux/inventory/inventoryTypes";

import { DraggableInventoryItem } from "./DraggableInventoryItem";

interface Props {
  rowItem: itemMinimal;
  index: number;
}
export const DraggableInventoryItemContainerFC = ({
  rowItem,
  index,
}: Props) => {
  return rowItem ? (
    <Draggable
      isDragDisabled={rowItem.quanDraggable < 1}
      draggableId={rowItem.itemID.toString()}
      index={index}
    >
      {(provided, snapshot) => (
        <DraggableInventoryItem
          rowItem={rowItem}
          provided={provided}
          snapshot={snapshot}
        />
      )}
    </Draggable>
  ) : null;
};

export const DraggableInventoryItemContainer = React.memo(
  DraggableInventoryItemContainerFC
);
