import React from "react";
import { IconType } from "react-icons";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { AppThunk } from "../../redux";
import { ToastyRed } from "../constants";

interface Props {
  label: string;
  Icon?: IconType;
  href?: string;
  target?: string;
  reduxAction?: {
    action: (payload: any) => ThunkAction<Promise<void>, {}, {}, AnyAction>;
    payload: any;
  };
  confirmationText?: string;
  disabled?: boolean;
  disabledText?: string;
  query?: string;
}
/**
 * Nav components for fancy dropdown menu
 *
 * use href="" when only wanting to change query string (search)
 *
 */
export const NavLi = ({
  label,
  href,
  target = "_blank",
  reduxAction,
  Icon,
  confirmationText,
  disabled = false,
  disabledText,
  query,
}: Props) => {
  const dispatch: AppThunk = useDispatch();
  if (process.env.NODE_ENV === "development") {
    if (href === undefined && query === undefined && reduxAction === undefined)
      throw new Error("href AND reduxAction cant be undefined").stack;
    else if (href !== undefined && reduxAction !== undefined)
      throw new Error("href AND reduxAction cant both be defined").stack;
  }
  const handleButtonClick = () => {
    if (reduxAction) {
      let Confirm = true;
      if (confirmationText !== undefined)
        Confirm = window.confirm(confirmationText);
      if (Confirm) dispatch(reduxAction.action(reduxAction.payload));
    }
  };
  const [altertedAt, setAlertedAt] = React.useState(0);
  const handleHover = () => {
    if (disabled && altertedAt + 5000 < Date.now()) {
      setAlertedAt(Date.now());
      ToastyRed(
        "Please fix the out of stock errors in your StagingBuilder before continuing"
      );
    }
  };
  return href || query ? (
    <li className="sb-dropdown-item">
      <Link
        className="text-left w-100p"
        rel="noreferrer"
        target={target}
        to={({ pathname }) => ({
          pathname: href || pathname,
          search: query,
        })}
      >
        <button disabled={disabled} type="button">
          <div className="flex space-between">
            {Icon && <Icon className="sb-dropdown-item-icon" />}
            {label}
          </div>
        </button>
      </Link>
    </li>
  ) : (
    <li className="sb-dropdown-item" onMouseEnter={handleHover}>
      <button disabled={disabled} type="button" onClick={handleButtonClick}>
        <div className="flex space-between">
          {Icon && <Icon className="sb-dropdown-item-icon" />}
          {label}
        </div>
      </button>
    </li>
  );
};
