import { DateSelection } from "./DateSelection";
import { EditImportantTask } from "./EditImportantTask";
import { EditMHFODelivery } from "./EditMHFODelivery";

export const CalendarModals = () => {
  return (
    <>
      <DateSelection />
      <EditImportantTask />
      <EditMHFODelivery />
    </>
  );
};
