import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { fetchingData } from "../../..";
import { ToastyRed } from "../../../../components/constants";

interface AddNewContainerItemProps {
  stagingBuilderID: number;
  containerItemID: number;
  comments: string;
}
/**
 * @todo
 * 1) need to have the server reply with a response
 * 2) need this function to listen for the response
 * 3) need to find (or make) a reducer that can handle adding it
 */
export const containerItemComment = (
  containerItem: AddNewContainerItemProps
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: Function
  ): Promise<void> => {
    dispatch(fetchingData()); // active loading sign
    const {
      data: { socket },
      router: {
        location: { pathname, query },
      },
    } = getState() as Istate;
    if (
      Number(query.containerItemID) !== Number(containerItem.containerItemID)
    ) {
      ToastyRed("Something went wrong... Please try again.");
      return;
    }
    const payload = JSON.stringify({
      type: "CONTAINER_ITEM",
      action: "COMMENT",
      payload: {
        stagingBuilderID: containerItem.stagingBuilderID,
        containerItem,
        pathname,
      },
    });
    socket?.emit("STAGING_BUILDER_UPDATE", payload);
  };
};

// export const addNewContainerItem = (
//   containerItem: ContainerItem
// ): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
//   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
//     dispatch(addNewContainerItemRedux(containerItem));
//     dispatch(fetchingDataSuccess()); // active loading sign
//   };
// };

// const addNewContainerItemRedux = (
//   containerItem: ContainerItem
// ): StagingBuilderReducer => {
//   return {
//     type: "ADD_NEW_CONTAINER_ITEM",
//     payload: { containerItem },
//   };
// };
