import "./StagingProposalList.scss";

import ProposalContainerHeader from "./ProposalContainerHeader";
import ProposalContainerColumnContainer from "./ProposalContainerColumnContainer";

function ProposalContainer() {
  return (
    <>
      <ProposalContainerHeader />
      <ProposalContainerColumnContainer />
    </>
  );
}

export default ProposalContainer;
