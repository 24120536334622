import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { fetchingData } from "../../..";

interface AddNewContainerItemProps {
  stagingBuilderID?: number;
  fileNumber?: number;
}

export const selectContainerItems = ({
  stagingBuilderID,
  fileNumber,
}: RequireOnlyOne<
  AddNewContainerItemProps,
  "fileNumber" | "stagingBuilderID"
>): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: Function
  ): Promise<void> => {
    dispatch(fetchingData()); // active loading sign
    const {
      data: { socket },
      router: {
        location: { pathname },
      },
    } = getState() as Istate;

    const payload = JSON.stringify({
      type: "CONTAINER_ITEM",
      action: "SELECT",
      payload: {
        stagingBuilderID,
        fileNumber,
        pathname,
      },
    });
    socket?.emit("STAGING_BUILDER_UPDATE", payload);
  };
};

// export const addNewContainerItem = (
//   containerItem: ContainerItem
// ): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
//   return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
//     dispatch(addNewContainerItemRedux(containerItem));
//     dispatch(fetchingDataSuccess()); // active loading sign
//   };
// };

// const addNewContainerItemRedux = (
//   containerItem: ContainerItem
// ): StagingBuilderReducer => {
//   return {
//     type: "ADD_NEW_CONTAINER_ITEM",
//     payload: { containerItem },
//   };
// };
