import { useEffect, useState } from "react";

import styled from "styled-components";
import { useNavigation } from "../../../mobile-nav/context/NavigationContext";
import { StagingBuilderScanner } from "../../../Scanner/StagingBuilder";
import { ScannerHeader } from "./ScannerHeader";
import { ScannerItems } from "./ScannerItems";

const ScannerWrapper = styled.div`
  grid-rows: 2rem 5rem auto;
  padding: 1vh 3vw 1vh 3vw;
`;
export enum SortType {
  RECENT,
  ROOM,
}
export const Scanner = () => {
  const [sortType, setSortType] = useState<SortType>(SortType.RECENT);
  const { updateMobileViewSide } = useNavigation();
  useEffect(() => {
    updateMobileViewSide(0);
  }, []);
  return (
    <ScannerWrapper className="w-full h-full">
      <StagingBuilderScanner />
      <ScannerHeader {...{ setSortType, sortType }} />
      <ScannerItems sortType={sortType} />
    </ScannerWrapper>
  );
};
