import { FieldArrayRenderProps, useFormikContext } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { AppThunk, removeItemContainer } from "../../../../redux";

interface Props {
  index: number;
  itemContainerID: number;
  arrayHelpers: FieldArrayRenderProps;
}

export const DeleteItemContainer = ({
  index,
  itemContainerID,
  arrayHelpers,
}: Props) => {
  const dispatch: AppThunk = useDispatch();
  const ctx = useFormikContext();
  const handleDeleteRoom = (index: number) => {
    const Continue = window.confirm(
      'Are you sure you want to delete this room?\n\
      The items will also be removed and added to your removed items\n\
      You can access this by clicking "Show Removed Items"'
    );
    if (Continue) {
      arrayHelpers.remove(index);
      dispatch(removeItemContainer(itemContainerID));
    }
    ctx.resetForm();
  };
  return (
    <div className="hidden md:block">
      <div className="btn btn-delete" onClick={() => handleDeleteRoom(index)}>
        Delete Room
      </div>
    </div>
  );
};
