import React from "react";
import { useField, useFormikContext } from "formik";

interface Iprops {
  name: string;
  className: string;
}
export const QuantityComponentAvailable = (props: Iprops) => {
  const {
    values: { quanLoft, quanHuddle, quanMHFO, quanCastle, quanRepair },
    touched,
    setFieldValue,
  } = useFormikContext<itemVerbose>();
  const [field, meta] = useField(props);
  React.useEffect(() => {
    setFieldValue(
      props.name,
      `${
        Number(quanLoft) +
        Number(quanHuddle) +
        Number(quanMHFO) +
        Number(quanCastle) +
        Number(quanRepair)
      }`
    );
  }, [
    quanLoft,
    quanHuddle,
    quanMHFO,
    quanCastle,
    quanRepair,
    touched.quanLoft,
    touched.quanHuddle,
    touched.quanMHFO,
    touched.quanCastle,
    touched.quanRepair,
    setFieldValue,
    props.name,
  ]);
  return (
    <>
      <input {...props} {...field} disabled={true} />
      {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
    </>
  );
};
export const QuantityComponentTotal = (props: Iprops) => {
  const {
    values: {
      quanLoft,
      quanHuddle,
      quanMHFO,
      quanCastle,
      quanRepair,
      quanHold,
      quanStaged,
    },
    touched,
    setFieldValue,
  } = useFormikContext<itemVerbose>();
  const [field, meta] = useField(props);
  React.useEffect(() => {
    setFieldValue(
      props.name,
      `${
        Number(quanLoft) +
        Number(quanHuddle) +
        Number(quanMHFO) +
        Number(quanCastle) +
        Number(quanRepair) +
        Number(quanHold) +
        Number(quanStaged)
      }`
    );
  }, [
    quanLoft,
    quanHuddle,
    quanMHFO,
    quanCastle,
    quanRepair,
    touched.quanLoft,
    touched.quanHuddle,
    touched.quanMHFO,
    touched.quanCastle,
    touched.quanRepair,
    setFieldValue,
    props.name,
  ]);
  return (
    <>
      <input {...props} {...field} disabled={true} />
      {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
    </>
  );
};
