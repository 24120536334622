import { push } from "connected-react-router";
import { format } from "date-fns";
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CustomButton } from "../../HighlyReusableComponents/CustomButton";
import { BasicModal } from "../../Modals/BasicModal";
import Select from "react-select";
import styled from "styled-components";
import { socketDateChange } from "../../../redux/stagingBuilder/actions/handleSocketDateChange";
import { ImCross } from "react-icons/im";
import { StartStagingWrapper } from "../../StartStagingBuilder";
import { DeliveryFormFields, DeliveryLogic } from "./Forms/Castle";
import { ImportantTaskLogic } from "./Forms/ImportantTask/ImportantTaskLogic";
import { ImportantTaskFields } from "./Forms/ImportantTask/ImportantTaskFields";
import { MHFODeliveryFields } from "./Forms/MHFO/MHFODeliveryFields";
import { MHFODeliveryLogic } from "./Forms/MHFO/MHFODeliveryLogic";
import { StagingBuilderStatuses } from "../../constants";
enum OpenLogic {
  NULL,
  NEW_STAGING,
  STAGING_PICKUP,
  STAGING_ADJUSTMENT,
  CASTLE_DELIVERY,
  CASTLE_PICKUP,
  CASTLE_ADJUSTMENT,
  MHFO_DELIVERY,
  IMPORTANT_TASK,
}
const switchOpenLogic = (openLogic: OpenLogic) => {
  switch (openLogic) {
    case OpenLogic.NULL:
      return "";
    case OpenLogic.NEW_STAGING:
      return "max-height: 600px;";
    case OpenLogic.STAGING_PICKUP:
      return "max-height: 400px;";
    case OpenLogic.CASTLE_DELIVERY:
      return "max-height: 900px;";
    case OpenLogic.MHFO_DELIVERY:
      return "max-height: 700px;";
    case OpenLogic.IMPORTANT_TASK:
      return "max-height: 700px;";
    default:
      return "";
  }
};

const CalendarModalWrapper = styled.div.attrs((attrs) => {
  return { ...attrs };
})<{ openLogic: OpenLogic }>`
  display: flex;
  // transition: max-height 0.1s ease-out;
  max-height: 110px;
  ${({ openLogic }) => switchOpenLogic(openLogic)}
`;
const CalendarModalTable1 = styled.table`
  border-collapse: separate;
  border-spacing: 6px 0;

  td,
  th {
    -webkit-column-gap: 6px;
    -moz-column-gap: 6px;
    column-gap: 6px;
  }
  th {
    text-align: left;
  }
`;

const modalStyle = (openLogic: OpenLogic): React.CSSProperties => ({
  top: [
    OpenLogic.NEW_STAGING,
    OpenLogic.CASTLE_DELIVERY,
    OpenLogic.MHFO_DELIVERY,
  ].includes(openLogic)
    ? OpenLogic.CASTLE_DELIVERY === openLogic
      ? "5vh"
      : "18vh"
    : "30vh",
  left: "22vw",
  right: "22vw",
  bottom: "auto",
  overflow: "visible",
  height: "auto",
});

export const DateSelection = () => {
  const dispatch = useDispatch();
  const { pathname, query } = useSelector(
    (state: Istate) => state.router.location
  );
  const queryStringDate = useMemo(() => Number(query["date"]), [query]);
  const fileNumbers = useSelector((state: Istate) =>
    state.data.stagingBuilders
      .filter(({ fileNumber }) => fileNumber)
      .map(({ fileNumber, ...rest }) => ({
        label: fileNumber?.toString(),
        value: fileNumber?.toString(),
        content: rest,
      }))
  );
  const [openLogic, setOpenLogic] = useState<OpenLogic>(OpenLogic.NULL);
  const [file, setFile] = useState<null | {
    value?: string;
    label?: string;
    content: Partial<StagingBuilder>;
  }>(null);
  useEffect(() => {
    if (!queryStringDate && !isNaN(queryStringDate)) {
      toast("Something went wrong while trying to process that date.", {
        type: "error",
      });
    } else if (isNaN(queryStringDate)) {
      setFile(null);
      setOpenLogic(OpenLogic.NULL);
    }
  }, [queryStringDate]);

  const FormLogic = useMemo(() => {
    switch (openLogic) {
      case OpenLogic.NULL:
        return "";
      case OpenLogic.NEW_STAGING:
        return <StartStagingWrapper />;
      case OpenLogic.STAGING_PICKUP:
        return (
          <div className="mt-2 grid grid-cols-2">
            <div>
              <h2 className="text-xl mb-2">Schedule A Staging Pickup</h2>
              <p className="mb-2">
                To schedule a staging pickup there must be a corresponding
                StagingBuilder. If one does not exist (the returning staging
                predates this system) you can create a blank StagingBuilder and
                mark it as Staged.
              </p>
              <label className="basic-input block w-96" htmlFor="">
                File Number to Schedule Pickup for
                <Select
                  autoFocus
                  onChange={(e) => setFile(e ? e : null)}
                  isClearable
                  options={fileNumbers}
                />
              </label>
            </div>
            {file && (
              <div>
                <h3 className="text-lg">
                  Staging Information: File # {file.value}
                </h3>
                <CalendarModalTable1>
                  <thead>
                    <tr>
                      <th>Field</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>File Number:</td>
                      <td>{file.value}</td>
                    </tr>
                    <tr>
                      <td>Stager Name:</td>
                      <td>{file.content.stagerName}</td>
                    </tr>
                    <tr>
                      <td>Address:</td>
                      <td>{file.content.address}</td>
                    </tr>
                    <tr>
                      <td>Customer Desired Pickup:</td>
                      <td>
                        {file.content.pickupDesired
                          ? format(file.content.pickupDesired, "MMMM dd, yyyy")
                          : "TBD"}
                      </td>
                    </tr>
                    <tr>
                      <td>Current Scheduled Pickup:</td>
                      <td>
                        {file.content.pickupActual
                          ? format(file.content.pickupActual, "MMMM dd, yyyy")
                          : "TBD"}
                      </td>
                    </tr>
                  </tbody>
                </CalendarModalTable1>
                <CustomButton
                  onClick={() => {
                    if (
                      file.content.deliveryActual &&
                      Number(file.content.deliveryActual) > 0 &&
                      queryStringDate < file.content.deliveryActual
                    )
                      return toast(
                        "You cannot schedule a pickup before the delivery date.",
                        { type: "error" }
                      );
                    let Confirm = true;
                    if (queryStringDate > Date.now() + 28 * 86400000)
                      Confirm = window.confirm(
                        "The date you are trying to schedule is more than 28 days in the future. Is this correct?"
                      );
                    else if (queryStringDate < Date.now() - 86400000)
                      Confirm = window.confirm(
                        "The date you are trying to schedule is in the PAST. Is this correct?"
                      );
                    if (
                      Confirm &&
                      file.content.stagingBuilderID &&
                      queryStringDate
                    ) {
                      dispatch(
                        socketDateChange({
                          action: "STATUS",
                          payload: {
                            status: StagingBuilderStatuses.PICKUP_SCHEDULED,
                            stagingBuilder: {
                              stagingBuilderID: file.content.stagingBuilderID,
                              pickupActual: queryStringDate,
                            },
                          },
                        })
                      );
                      dispatch(push(pathname));
                    }
                  }}
                >
                  Schedule Pickup
                </CustomButton>
              </div>
            )}
          </div>
        );
      case OpenLogic.CASTLE_DELIVERY:
        return (
          <DeliveryLogic>
            {(formikProps) => <DeliveryFormFields formikProps={formikProps} />}
          </DeliveryLogic>
        );
      case OpenLogic.MHFO_DELIVERY:
        return (
          <MHFODeliveryLogic>
            {(formikProps) => <MHFODeliveryFields formikProps={formikProps} />}
          </MHFODeliveryLogic>
        );
      case OpenLogic.IMPORTANT_TASK:
        return (
          <ImportantTaskLogic>
            {(formikProps) => <ImportantTaskFields formikProps={formikProps} />}
          </ImportantTaskLogic>
        );
      default:
        return "";
    }
  }, [openLogic, file, queryStringDate, dispatch]);
  const ModalStyle = useMemo(() => modalStyle(openLogic), [openLogic]);
  return (
    <BasicModal
      modalStyle={ModalStyle}
      open={query["open-modal"] === "date-selection"}
      setOpen={() => dispatch(push(pathname))}
      employeeOnly
    >
      <div
        onClick={() => dispatch(push(pathname))}
        className="absolute z-5"
        style={{ right: "15px", top: "15px" }}
      >
        <ImCross cursor="pointer" size="2em" />
      </div>
      <CalendarModalWrapper openLogic={openLogic}>
        <div>
          <div className="bg-white">
            <h1 className="text-2xl pb-2">
              Add a Calendar Event for:{" "}
              {queryStringDate && format(queryStringDate, "MMMM dd, yyyy")}
            </h1>
            <div className="grid grid-cols-8 gap-1">
              <CustomButton
                appendClassName="bg-logo-purple text-white"
                onClick={() => setOpenLogic(OpenLogic.NEW_STAGING)}
              >
                New StagingBuilder
              </CustomButton>
              <CustomButton
                onClick={() => setOpenLogic(OpenLogic.STAGING_PICKUP)}
              >
                Pickup StagingBuilder
              </CustomButton>
              <CustomButton
                style={{ backgroundColor: "rgb(202,188,158)" }}
                disabled
              >
                Staging Adjustment
              </CustomButton>
              <CustomButton
                onClick={() => setOpenLogic(OpenLogic.CASTLE_DELIVERY)}
                style={{ backgroundColor: "rgb(18,149,86)" }}
                disabled
              >
                Castle Delivery
              </CustomButton>
              <CustomButton
                style={{ backgroundColor: "rgb(254,112,106)" }}
                disabled
              >
                Castle Pickup
              </CustomButton>
              <CustomButton
                style={{ backgroundColor: "rgb(233,231,164)" }}
                disabled
              >
                Castle Adjustment
              </CustomButton>
              <CustomButton
                appendClassName="text-white"
                onClick={() => setOpenLogic(OpenLogic.MHFO_DELIVERY)}
                style={{ backgroundColor: "rgb(178,117,9)" }}
              >
                MHFO Delivery
              </CustomButton>
              <CustomButton
                onClick={() => setOpenLogic(OpenLogic.IMPORTANT_TASK)}
                appendClassName="text-white"
                style={{ backgroundColor: "navy" }}
              >
                Important Task
              </CustomButton>
            </div>
            {FormLogic}
          </div>
        </div>
      </CalendarModalWrapper>
    </BasicModal>
  );
};
