import React from "react";
import { BsTrash } from "react-icons/bs";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  AppThunk,
  Istate,
  removeItemFromItemContainer,
} from "../../../../redux";
import { moveContainerItem } from "../../../../redux/stagingBuilder/stagingBuilderActions";
import { ContainerItem } from "../../../../redux/stagingBuilder/stagingBuilderTypes";
import { ToastyRed } from "../../../constants";

interface Props {
  containerItem: Partial<ContainerItem> & {
    containerItemID: number;
    stagingBuilderID: number;
    itemContainerID: number;
    itemStatus: number;
    itemID: number;
  };
}

export const TrashItem = ({
  containerItem: {
    containerItemID,
    stagingBuilderID,
    itemContainerID,
    itemStatus,
    itemID,
  },
}: Props) => {
  const dispatch: AppThunk = useDispatch();
  const {
    stagingBuilderID: stagingBuilderID2,
    stagingStatus,
    employeeCreated,
  } = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex],
    shallowEqual
  );
  const { businessID } = useSelector(
    (state: Istate) => state.data.user,
    shallowEqual
  );

  const removeItemFromContainer = (id: number) => {
    const Continue = window.confirm(
      "Do you really want to remove this item from this room?\n" +
        "You will be able to move it back by clicking on:\n" +
        '"Show Removed Items" and selecting "move."'
    );
    // We are only doing this on the front end incase delete items gets called from delete room
    if (Continue) {
      if (stagingBuilderID2 === stagingBuilderID)
        if (businessID !== 1 || Boolean(employeeCreated))
          dispatch(
            moveContainerItem({
              patchData: {
                stagingBuilderID: stagingBuilderID,
                itemContainerID: itemContainerID,
                containerItemID: id,
                newItemContainerID: -1,
              },
            })
          );
        else if (containerItemID !== undefined)
          dispatch(
            removeItemFromItemContainer({
              itemID: itemID,
              itemContainerID: itemContainerID,
              stagingBuilderID,
              stagingStatus,
              itemStatus: itemStatus,
              containerItemID: containerItemID,
            })
          );
        else ToastyRed("Something went wrong when trying to remove that item.");
    }
  };
  return (
    <div
      style={{
        margin: "auto",
        display: "grid",
        alignItems: "center",
      }}
    >
      <BsTrash
        color="darkred"
        onClick={() =>
          containerItemID && removeItemFromContainer(containerItemID)
        }
        cursor="pointer"
      />
    </div>
  );
};
