import { Socket } from "socket.io-client";

interface StickerQueueOptions {
  auto?: boolean;
  stickerTag?: boolean;
  normalTag?: boolean;
  queueNormalTag?: boolean;
  newWindowNormalTag?: boolean;
  newTabNormalTag?: boolean;
  queueQuantity?: number;
}
export const handleAddItemToStickerQueue = (
  socket: Socket | undefined,
  barcode: string,
  options?: StickerQueueOptions
) => {
  if (options && options.auto === undefined) options.auto = true;
  socket?.emit(
    "INVENTORY",
    JSON.stringify({
      type: "SCANNER_CONTROL",
      action: "PRINT",
      payload: { barcode, ...options },
    })
  );
};
