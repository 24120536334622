import { Formik, FormikHelpers, FormikProps } from "formik";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useCalendar } from "../../../../constants/contexts/calendar/CalendarContext";
import { TaskSubmissionValues } from "../../../../constants/hooks/fetching/useCalendarTasks";

interface Props {
  children: (props: FormikProps<TaskSubmissionValues>) => React.ReactNode;
}

export const ImportantTaskLogic: React.FC<Props> = ({ children }) => {
  const date = useSelector((state: Istate) => state.router.location.query.date);
  const initialValues: TaskSubmissionValues = useMemo(
    () => ({
      "task-time": Number(date) || null,
      hours: "",
      "task-description": "",
      title: "",
    }),
    [date]
  );
  const {
    tasks: { createTask },
  } = useCalendar();
  const onSubmit = (
    values: typeof initialValues,
    a: FormikHelpers<typeof initialValues>
  ) => {
    if (values.title === "")
      values.title =
        values["task-description"].match(/(\w| ){0,25}(\n|$)/gi)?.[0] || "";
    if (createTask) createTask(values, "/calendar");
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {(props: FormikProps<typeof initialValues>) => children(props)}
    </Formik>
  );
};
