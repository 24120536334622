import { memo } from "react";
import { Link } from "react-router-dom";

function ProposalContainerHeader() {
  return (
    <div
      style={{ height: "50px", gridAutoRows: "48px" }}
      className="relative grid grid-1fr-auto"
    >
      <div
        className="absolute top-0 left-0 w-full sm:w-auto"
        style={{
          padding: "40px calc(100% - 25ch) 8px 8px",
          backgroundColor: "var(--logo-purple)",
          borderRadius: "0 0 20px 0 ",
          minWidth: "60%",
        }}
      ></div>
      <h1
        className="row-1 z-2 2xl:text-3xl lg:text-2xl sm:text-lg text-2xl text-white"
        style={{ margin: "8px 0 0 13px" }}
      >
        StagingBuilder&trade; Archive
      </h1>
      <Link className="hidden md:contents" to="/start-staging">
        <h2
          className="row-1 m-0 2xl:text-2xl text-xl text-right underline"
          style={{
            color: "var(--link-blue)",
            margin: "8px 6% 0 0",
          }}
        >
          Start New Staging
        </h2>
      </Link>
      <div className="col-span-full row-start-2 hidden">Show filters</div>
    </div>
  );
}

export default memo(ProposalContainerHeader);
