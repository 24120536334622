import { FormEvent, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { BarcodeInputField } from "../../../Scanner/StagingBuilder/BarcodeInputField";
import { useBinningContext } from "../../../Scanner/BinControl/useBinNumber";

interface Props {
  barcodeRef: React.RefObject<HTMLInputElement>;
}

export const ScannerForm = ({ barcodeRef }: Props) => {
  const socket = useSelector((state: Istate) => state.data.socket);
  const pricingControl = () => {};
  const { newBarcodeScan } = useBinningContext();

  useEffect(() => {
    socket?.off("INVENTORY", pricingControl);
    socket?.on("INVENTORY", pricingControl);
    return () => {
      socket?.off("INVENTORY", pricingControl);
    };
  }, [socket]);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      barcodeRef.current?.value === undefined ||
      barcodeRef.current?.value === ""
    )
      return;
    if (barcodeRef.current?.value.split("-")[1] === "20") {
      newBarcodeScan?.(barcodeRef.current.value);
      barcodeRef.current.value = "";
      return;
    }
    newBarcodeScan?.(barcodeRef.current.value);
    // need to check if it exists
    // if it does push new info, maybe use modal?
    await fetch("/api/scan/inventory/delta-inventory", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ barcode: barcodeRef.current?.value || null }),
    })
      .then(async (res) => await res.json())
      .then(({ success, message }) => {
        if (!success)
          toast(message, {
            toastId: barcodeRef.current?.value,
            autoClose: false,
            type: "error",
          });
      })
      .catch((err) => console.error(err));
    // reset the input field
    barcodeRef.current.value = "";
  };
  return (
    <form onSubmit={onSubmit}>
      <BarcodeInputField barcodeRef={barcodeRef} />
      <button className="hidden" type="submit" />
    </form>
  );
};
