import React from "react";
import styled from "styled-components";
import { FormikProps } from "formik";

import FormikControl from "../../FormikComponents/FormikControl";
import {
  AppThunk,
  fetchingData,
  handleInventorySearch,
  Istate,
} from "../../../redux";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import {
  styleOptions,
  colorOptions,
  rooms,
  RetentionOptions,
} from "../../DropDownLists/";
import { GroupModal } from "./Grouping/GroupModal";
import { CustomButton } from "../../HighlyReusableComponents/CustomButton";

const InventoryEditorWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 7vw 7vw;
  grid-auto-rows: minmax(2.5ch, auto);
  margin-right: 8px;
  // border 1px solid lightgrey;
  // border-radius: 2px;
  min-height: 100px;
  max-height: 100%;
`;

interface ILeftEditorPanel {
  activeItem?: itemVerbose;
  FormikProps: FormikProps<any>;
}
export const LeftEditorPanel = (props: ILeftEditorPanel) => {
  const dispatch: AppThunk = useDispatch();
  const vendorsRaw = useSelector(
    (state: Istate) =>
      state.data.inventoryMeta && state.data.inventoryMeta.vendors,
    shallowEqual
  );

  const vendors =
    vendorsRaw &&
    vendorsRaw.map((vendor) => {
      const returnValue = {
        id: vendor.vendorID,
        text: vendor.vendorName,
      };
      return returnValue;
    });

  const collectionsAll = useSelector(
    (state: Istate) =>
      state.data.inventoryMeta && state.data.inventoryMeta.collections,
    shallowEqual
  );
  const piecesAll = useSelector(
    (state: Istate) =>
      state.data.inventoryMeta && state.data.inventoryMeta.rooms,
    shallowEqual
  );

  const [collections, setCollections] = React.useState([
    { id: 0, text: "Choose: " },
  ]);
  const [searchCollections, setSearchCollections] = React.useState([
    { id: 0, text: "Search: " },
  ]);
  const vendorSelected = () => {
    const filteredCollections =
      collectionsAll &&
      collectionsAll.filter(
        (collection) =>
          props.FormikProps.values.vendorID == collection.vendorID ||
          collection.collectionID == 0
      );

    const mappedCollections =
      filteredCollections &&
      filteredCollections.map((collection) => {
        const returnValue = {
          id: collection.collectionID,
          text: collection.collectionName,
        };
        return returnValue;
      });

    mappedCollections && setCollections(mappedCollections);
  };
  const searchVendorChanged = () => {
    const filteredCollections =
      collectionsAll &&
      collectionsAll.filter(
        (collection) =>
          props.FormikProps.values["vendorID-search"] == collection.vendorID
      );

    const mappedCollections =
      filteredCollections &&
      filteredCollections.map((collection) => {
        const returnValue = {
          id: collection.collectionID,
          text: collection.collectionName,
        };
        return returnValue;
      });

    mappedCollections && setSearchCollections(mappedCollections);
  };

  React.useEffect(() => {
    vendorSelected();
  }, [collectionsAll, props.FormikProps.values.vendorID]);
  React.useEffect(() => {
    searchVendorChanged();
  }, [collectionsAll, props.FormikProps.values["vendorID-search"]]);

  const [pieces, setPieces] = React.useState([{ id: 0, text: "Choose: " }]);
  const [searchPieces, setSearchPieces] = React.useState([
    { id: 0, text: "Search: " },
  ]);
  const roomSelected = () => {
    if (props.FormikProps.values.room == 0) {
      props.FormikProps.setFieldValue("piece", "0");
    }
    const mappedPieces =
      piecesAll &&
      props.FormikProps.values.room &&
      piecesAll[Number(props.FormikProps.values.room)] &&
      piecesAll[Number(props.FormikProps.values.room)].map((piece) => {
        const returnValue = {
          id: piece.catNo,
          text: piece.roomName,
        };
        return returnValue;
      });

    const newPieces = mappedPieces
      ? [{ id: 0, text: "Choose: " }, ...mappedPieces]
      : [{ id: 0, text: "Choose: " }];
    setPieces(newPieces);
  };
  const searchRoomChanged = () => {
    if (props.FormikProps.values["room-search"] == 0) {
      props.FormikProps.setFieldValue("piece-search", "0");
      setSearchPieces([]);
    }
    const filteredPieces =
      piecesAll && piecesAll[~~props.FormikProps.values["room-search"]];
    const mappedPieces =
      filteredPieces &&
      filteredPieces.map((piece) => {
        const returnValue = {
          id: piece.catNo,
          text: piece.roomName,
        };
        return returnValue;
      });
    mappedPieces && setSearchPieces(mappedPieces);
  };
  React.useEffect(() => {
    roomSelected();
  }, [piecesAll, props.FormikProps.values.room]);
  React.useEffect(() => {
    searchRoomChanged();
  }, [piecesAll, props.FormikProps.values["room-search"]]);

  const handleFormUpdate = () => {
    console.log("trying to submit");
    props.FormikProps.handleSubmit();
  };
  const handleSearch = () => {
    dispatch(fetchingData());
    const searchParametersA = {
      itemNo: props.FormikProps.values["itemNo-search"],
      model: props.FormikProps.values["model-search"],
      ain: props.FormikProps.values["ain-search"],
      vendorID: props.FormikProps.values["vendorID-search"],
      collectionID: props.FormikProps.values["collectionID-search"],
      room: props.FormikProps.values["room-search"],
      piece: props.FormikProps.values["piece-search"],
      itemName: props.FormikProps.values["itemName-search"],
    };
    // needed to allow the loading indicator to start before this starts
    setTimeout(() => {
      dispatch(handleInventorySearch({ searchParametersA }));
    }, 0);
  };
  React.useEffect(() => {
    handleSearch();
  }, [
    props.FormikProps.values["itemNo-search"],
    props.FormikProps.values["model-search"],
    props.FormikProps.values["ain-search"],
    props.FormikProps.values["vendorID-search"],
    props.FormikProps.values["collectionID-search"],
    props.FormikProps.values["room-search"],
    props.FormikProps.values["piece-search"],
    props.FormikProps.values["itemName-search"],
  ]);

  const [groupModalOpen, setGroupModalOpen] = React.useState(false);

  return (
    <>
      <GroupModal
        setGroupModalOpen={setGroupModalOpen}
        groupModalOpen={groupModalOpen}
      />
      <InventoryEditorWrapper>
        <div></div>
        <div>
          <h2 style={{ textAlign: "center", marginBottom: "3px" }}>Input</h2>
        </div>
        <div>
          <h2 style={{ textAlign: "center", marginBottom: "3px" }}>Search</h2>
        </div>
        <FormikControl
          control="inventory-input"
          label="AutoGen ID"
          name="itemNo"
          disablefield={1}
          fastInput={true}
          fastSearch={true}
        />
        <FormikControl
          control="inventory-input"
          label="Model #(SKU)"
          name="model"
          fastInput={true}
          fastSearch={true}
        />
        <FormikControl
          control="inventory-input"
          label="Our SKU"
          name="ourSku"
          notsearchable={1}
          fastInput={true}
        />
        <FormikControl
          control="inventory-input"
          label="MUID/AIN"
          name="ain"
          fastInput={true}
          fastSearch={true}
        />
        <FormikControl
          control="inventory-input"
          label="Vendor URL"
          name="vendorURL"
          notsearchable={1}
          fastInput={true}
        />
        <FormikControl
          control="inventory-select"
          options={vendors}
          label="Vendor"
          name="vendorID"
          default={
            props.activeItem?.vendorID ? undefined : { id: 0, text: "Choose: " }
          }
        />
        <FormikControl
          control="inventory-select"
          options={collections}
          searchOptions={searchCollections}
          label="Collection"
          name="collectionID"
        />
        <FormikControl
          control="inventory-select"
          options={rooms}
          label="Room"
          name="room"
          default={
            props.activeItem?.room ? undefined : { id: 0, text: "Choose: " }
          }
        />
        <FormikControl
          control="inventory-select"
          options={pieces}
          searchOptions={searchPieces}
          label="Piece"
          name="piece"
        />
        <FormikControl
          control="inventory-input"
          label="Descriptive Name"
          name="itemName"
          fastInput={true}
          fastSearch={true}
        />
        <div style={{ gridColumn: "1/4" }}></div>
        <FormikControl
          control="inventory-input"
          label="MHFO Tag Text"
          name="tagDescription"
          notsearchable={1}
          fastInput={true}
        />
        <FormikControl
          control="inventory-input"
          label="SR Comments"
          name="webDescription"
          notsearchable={1}
          fastInput={true}
        />
        <div style={{ gridColumn: "1/4" }}></div>
        <FormikControl
          control="inventory-input"
          label="Dimensions"
          name="dimensions"
          notsearchable={1}
          disablefield={1}
        />
        <FormikControl
          control="inventory-input"
          label="Length (inches)"
          name="length"
          notsearchable={1}
          fastInput={true}
        />
        <FormikControl
          control="inventory-input"
          label="Height (inches)"
          name="height"
          notsearchable={1}
          fastInput={true}
        />
        <FormikControl
          control="inventory-input"
          label="Depth (inches)"
          name="depth"
          notsearchable={1}
          fastInput={true}
        />
        <div style={{ gridColumn: "1/4" }}></div>
        <FormikControl
          control="multi-select"
          label="Color"
          name="itemColor"
          menuPlacement="top"
          options={colorOptions.filter(Boolean)}
        />
        <FormikControl
          control="multi-select"
          label="Style"
          name="itemStyle"
          menuPlacement="top"
          options={styleOptions}
        />
        <FormikControl
          control="inventory-select"
          label="Retention Rating"
          name="itemRating"
          options={RetentionOptions}
          fastInput={true}
          fastSearch={true}
        />
        <div className="mx-1">
          <CustomButton
            type="button"
            children="Grouping Options"
            onClick={() => setGroupModalOpen(true)}
          />
        </div>
        <div className="mx-1">
          <CustomButton
            type="button"
            children="Update"
            disabled={
              props.FormikProps.isSubmitting || !props.FormikProps.dirty
            }
            // disabledStyle={{ backgroundColor: "var(--logo-blue-comp)" }}
            onClick={() => {
              props.FormikProps.setSubmitting(true);
              handleFormUpdate();
            }}
          />
        </div>
        <div className="mx-1">
          <CustomButton
            type="button"
            children="Search"
            onClick={() => handleSearch()}
          />
        </div>
      </InventoryEditorWrapper>
    </>
  );
};
