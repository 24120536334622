export const calculateDistance = ({
  lat,
  lng,
}: {
  lat: number | null;
  lng: number | null;
}): number | null => {
  const businessCenter = {
    lat: 33.41167993235127,
    lng: -111.87379874825369,
  };
  return lat && lng
    ? Math.acos(
        Math.cos((Math.PI / 180) * businessCenter.lat) *
          Math.cos((Math.PI / 180) * lat) +
          Math.sin((Math.PI / 180) * businessCenter.lat) *
            Math.sin((Math.PI / 180) * lat) *
            Math.cos((Math.PI / 180) * (businessCenter.lng - lng))
      ) *
        6371 *
        0.6213712
    : null;
};
