import { useEffect } from "react";
import { ScannerForm } from "./ScannerForm";

interface Iprops {
  barcodeRef: React.RefObject<HTMLInputElement>;
  setColorFeedback: React.Dispatch<React.SetStateAction<string>>;
  location: INVENTORY_LOCATIONS;
}
export const EmployeeScanner = ({
  barcodeRef,
  setColorFeedback,
  location,
}: Iprops) => {
  useEffect(() => {
    barcodeRef.current?.focus();
  }, []);

  return (
    <div onClick={() => barcodeRef.current?.focus()}>
      <ScannerForm
        setColorFeedback={setColorFeedback}
        barcodeRef={barcodeRef}
        location={location}
      />
    </div>
  );
};
