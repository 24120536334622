import { ImCross } from "react-icons/im";
import Modal from "react-modal";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { AppThunk, Istate } from "../../../../../redux";
import { ModalContainer } from "./ModalContainer";
import "../ModalStyles.scss";
import { push } from "connected-react-router";

export const CustomerModal = () => {
  const dispatch: AppThunk = useDispatch();
  const { query } = useSelector(
    (state: Istate) => state.router.location,
    shallowEqual
  );
  const closeModal = (newQS: string = "") => {
    dispatch(push(`/stage${newQS}`));
  };
  return (
    <Modal
      isOpen={query["open-modal"] === "staging-information"}
      onRequestClose={() => closeModal()}
    >
      <div className="staging-information-wrapper hundred-hundred hundred-hundred__min relative">
        <div
          onClick={() => closeModal()}
          className="absolute z-5"
          style={{ right: "15px", top: "0" }}
        >
          <ImCross cursor="pointer" size="2em" />
        </div>
        <ModalContainer />
      </div>
    </Modal>
  );
};
