import {
  UPDATE_CONTAINER_ITEM,
  FILL_STAGING_BUILDERS,
  SET_ACTIVE_STAGINGBUILDER,
  LOAD_ACTIVE_STAGINGBUILDER,
  ADD_NEW_ITEM_CONTAINER,
  UPDATE_ITEM_CONTAINER_NAME,
  REMOVE_ITEM_FROM_ITEM_CONTAINER,
  SET_TIMESTAMP_STAGINGBUILDER,
  UPDATE_CONTAINER_ITEM_STATUS,
  UPDATE_ITEM_CONTAINER_STATUS,
  GET_ALL_STAGINGBUILDER_NOTES,
  ADD_CONTAINER_ITEM_ID_TO_CONTAINER_ITEM,
  MOVE_CONTAINER_ITEM,
  CHANGE_CONTAINER_ITEM_QUANTITY,
  StagingBuilderContainer,
  ContainerItem,
  NEW_STAGINGBUILDER,
} from "./stagingBuilderTypes";

import { initialReduxState, SystemState } from "../initialReduxState";
import { GetUniqueObjects } from "../../components/constants";
export interface IstagingBuilderReducer {
  type: string;
  payload?: any;
}
const rateReducer = (total: number, _this: ContainerItem) =>
  total + Number(_this.rentalRate) * Number(_this.quantity) * _this.active;
const retailReducer = (total: number, _this: ContainerItem) =>
  total + Number(_this.rentalPrice) * Number(_this.quantity) * _this.active;
const arraySum = (total: number, _this: number) => total + _this;

const calcPrices = (csb: StagingBuilder[], index: number) => {
  const monthlyRate = csb[index].itemContainers
    .map((container) =>
      container.containerType !== 0 ? container.items.reduce(rateReducer, 0) : 0
    )
    .reduce(arraySum, 0);
  const retailValue = csb[index].itemContainers
    .map((container) =>
      container.containerType !== 0
        ? container.items.reduce(retailReducer, 0)
        : 0
    )
    .reduce(arraySum, 0);

  csb[index] = { ...csb[index], monthlyRate, retailValue };
  return csb;
};

const stagingBuilderReducer = (
  state: SystemState = initialReduxState,
  action: IstagingBuilderReducer
): SystemState => {
  const activeIndex = state.stagingBuilders.findIndex(
    (sb) => sb.stagingBuilderID === state.user.activeStagingBuilder
  );
  switch (action.type as StagingBuilderReducer["type"]) {
    case UPDATE_ITEM_CONTAINER_STATUS: {
      const {
        itemContainerID,
        active,
      }: {
        itemContainerID: number;
        active: number;
      } = action.payload;

      const itemContainerIndexToUpdate = state.stagingBuilders[
        activeIndex
      ].itemContainers.findIndex(
        (itemContainer) => itemContainer.itemContainerID === itemContainerID
      );

      let copyOfStagingBuilders = [...state.stagingBuilders];

      copyOfStagingBuilders[activeIndex].itemContainers[
        itemContainerIndexToUpdate
      ].active = Boolean(active);

      return { ...state, stagingBuilders: [...state.stagingBuilders] };
    }
    case UPDATE_CONTAINER_ITEM: {
      const containerItem: ContainerItem = action.payload;

      if (containerItem.stagingBuilderID !== state.user.activeStagingBuilder)
        return { ...state };

      const itemContainerIndex = state.stagingBuilders[
        activeIndex
      ].itemContainers.findIndex(
        (itemContainer) =>
          itemContainer.itemContainerID === containerItem.itemContainerID
      );
      const containerItemIndexToUpdate = state.stagingBuilders[
        activeIndex
      ].itemContainers[itemContainerIndex].items.findIndex(
        (item) => item.containerItemID === containerItem.containerItemID
      );

      let copyOfStagingBuilders = [...state.stagingBuilders];
      if (containerItemIndexToUpdate === -1)
        copyOfStagingBuilders[activeIndex].itemContainers[
          itemContainerIndex
        ].items.unshift(containerItem);
      else
        copyOfStagingBuilders[activeIndex].itemContainers[
          itemContainerIndex
        ].items[containerItemIndexToUpdate] = containerItem;

      copyOfStagingBuilders = calcPrices(copyOfStagingBuilders, activeIndex);

      return { ...state, stagingBuilders: copyOfStagingBuilders };
    }
    case ADD_CONTAINER_ITEM_ID_TO_CONTAINER_ITEM: {
      const {
        itemContainerID,
        itemID,
        containerItemID,
      }: {
        itemContainerID: number;
        itemID: number;
        containerItemID: number;
      } = action.payload;

      // used to change itemStatus (for color in draggable inventory)
      const item = state.builderInventory[itemID];
      if (item === undefined) return { ...state };
      item.itemStatus = 1;

      const itemContainerIndex = state.stagingBuilders[
        activeIndex
      ].itemContainers.findIndex(
        (itemContainer) => itemContainerID === itemContainer.itemContainerID
      );

      const containerItemIndex = state.stagingBuilders[
        activeIndex
      ].itemContainers[itemContainerIndex].items.findIndex(
        (item) => itemID === item.itemID
      );
      if (containerItemIndex !== -1) {
        let copyOfStagingBuilders = [...state.stagingBuilders];
        copyOfStagingBuilders[activeIndex].itemContainers[
          itemContainerIndex
        ].items[containerItemIndex].containerItemID = containerItemID;

        const copyOfBuilderInventory = { ...state.builderInventory };
        copyOfBuilderInventory[itemID.toString()] = item;
        return {
          ...state,
          builderInventory: { ...copyOfBuilderInventory },
          stagingBuilders: [...copyOfStagingBuilders],
        };
      } else return { ...state };
    }
    case MOVE_CONTAINER_ITEM: {
      const {
        stagingBuilderID,
        itemContainerID,
        containerItemID,
        newItemContainerID,
        active,
      }: {
        stagingBuilderID: number;
        itemContainerID: number;
        itemID: number;
        containerItemID: number;
        newItemContainerID: number;
        active?: number;
      } = action.payload;

      if (stagingBuilderID !== state.user.activeStagingBuilder)
        return { ...state };
      if (newItemContainerID === 0) return { ...state };

      const itemContainerIndex = state.stagingBuilders[
        activeIndex
      ].itemContainers.findIndex(
        (itemContainer) => itemContainerID === itemContainer.itemContainerID
      );
      const newItemContainerIndex = state.stagingBuilders[
        activeIndex
      ].itemContainers.findIndex(
        (itemContainer) => newItemContainerID === itemContainer.itemContainerID
      );
      if (
        itemContainerIndex === -1 ||
        newItemContainerIndex === -1 ||
        newItemContainerIndex === itemContainerIndex
      )
        return { ...state };

      const containerItemIndex = state.stagingBuilders[
        activeIndex
      ].itemContainers[itemContainerIndex].items.findIndex(
        (item) => item.containerItemID === containerItemID
      );

      if (containerItemIndex === -1) return { ...state };

      let copyOfStagingBuilders = [...state.stagingBuilders];
      const containerItem = copyOfStagingBuilders[activeIndex].itemContainers[
        itemContainerIndex
      ].items.splice(containerItemIndex, 1)[0];
      if (containerItem === undefined) return { ...state };
      containerItem.itemContainerID = newItemContainerID;
      if (active !== undefined) containerItem.active = active;

      copyOfStagingBuilders[activeIndex].itemContainers[
        newItemContainerIndex
      ].items.push(containerItem);
      copyOfStagingBuilders = calcPrices(copyOfStagingBuilders, activeIndex);

      copyOfStagingBuilders[activeIndex].itemContainers = [
        ...copyOfStagingBuilders[activeIndex].itemContainers,
      ];
      return {
        ...state,
        stagingBuilders: [...copyOfStagingBuilders],
      };
    }
    case CHANGE_CONTAINER_ITEM_QUANTITY: {
      const {
        itemContainerID,
        containerItemID,
        newQuantity,
      }: {
        stagingBuilderID: number;
        itemContainerID: number;
        containerItemID: number;
        newQuantity: number;
      } = action.payload;

      const itemContainerIndex = state.stagingBuilders[
        activeIndex
      ].itemContainers.findIndex(
        (itemContainer) => itemContainerID === itemContainer.itemContainerID
      );

      const containerItemIndex = state.stagingBuilders[
        activeIndex
      ].itemContainers[itemContainerIndex].items.findIndex(
        (item) => item.containerItemID === containerItemID
      );

      let copyOfStagingBuilders = [...state.stagingBuilders];
      // change quantity
      copyOfStagingBuilders[activeIndex].itemContainers[
        itemContainerIndex
      ].items[containerItemIndex].quantity = newQuantity;

      copyOfStagingBuilders = calcPrices(copyOfStagingBuilders, activeIndex);
      return {
        ...state,
        stagingBuilders: [...copyOfStagingBuilders],
      };
    }
    case UPDATE_ITEM_CONTAINER_NAME: {
      const containers: {
        containerName: string;
        itemContainerID: number;
        stagingBuilderID: number;
      }[] = action.payload;

      let copyOfStagingBuilders = state.stagingBuilders;
      const stagingBuilderIndex = copyOfStagingBuilders.findIndex(
        (stagingBuilder) =>
          stagingBuilder.stagingBuilderID === containers[0].stagingBuilderID
      );
      copyOfStagingBuilders[stagingBuilderIndex].itemContainers =
        copyOfStagingBuilders[stagingBuilderIndex].itemContainers.map(
          (itemContainer) => {
            const containerIndex = containers.findIndex(
              (newContainer) =>
                itemContainer.itemContainerID === newContainer.itemContainerID
            );
            if (containerIndex !== -1)
              return {
                ...itemContainer,
                containerName: containers[containerIndex].containerName,
              };
            else return { ...itemContainer };
          }
        );

      return { ...state, stagingBuilders: copyOfStagingBuilders };
    }
    case REMOVE_ITEM_FROM_ITEM_CONTAINER: {
      const {
        itemContainerID,
        itemID,
      }: {
        itemContainerID: number;
        itemID: number;
      } = action.payload;
      const stagingBuilderID = state.user.activeStagingBuilder;
      // const item = state.inventory.find((item) => itemID === item.itemID);
      const currentStagingBuilderIndex = state.stagingBuilders.findIndex(
        (stagingBuilder) => stagingBuilder.stagingBuilderID === stagingBuilderID
      );
      const itemContainerIndexToUpdate = state.stagingBuilders[
        currentStagingBuilderIndex
      ].itemContainers.findIndex(
        (itemContainer) => itemContainer.itemContainerID === itemContainerID
      );

      const itemIndex = state.stagingBuilders[
        currentStagingBuilderIndex
      ].itemContainers[itemContainerIndexToUpdate].items.findIndex(
        (item) => itemID === item.itemID
      );

      let copyOfStagingBuilders = [...state.stagingBuilders];

      copyOfStagingBuilders[currentStagingBuilderIndex].itemContainers[
        itemContainerIndexToUpdate
      ].items.splice(itemIndex, 1);

      return { ...state, stagingBuilders: [...state.stagingBuilders] };
    }
    case UPDATE_CONTAINER_ITEM_STATUS: {
      const {
        itemContainerID,
        itemID,
        itemStatus,
      }: {
        itemContainerID: number;
        itemID: number;
        itemStatus: number;
      } = action.payload;

      const stagingBuilderID = state.user.activeStagingBuilder;
      // const item = state.inventory.find((item) => itemID === item.itemID);
      const currentStagingBuilderIndex = state.stagingBuilders.findIndex(
        (stagingBuilder) => stagingBuilder.stagingBuilderID === stagingBuilderID
      );
      const itemContainerIndexToUpdate = state.stagingBuilders[
        currentStagingBuilderIndex
      ].itemContainers.findIndex(
        (itemContainer) => itemContainer.itemContainerID === itemContainerID
      );

      const itemIndex = state.stagingBuilders[
        currentStagingBuilderIndex
      ].itemContainers[itemContainerIndexToUpdate].items.findIndex(
        (item) => itemID === item.itemID
      );
      let copyOfStagingBuilders = [...state.stagingBuilders];

      copyOfStagingBuilders[currentStagingBuilderIndex].itemContainers[
        itemContainerIndexToUpdate
      ].items[itemIndex].itemStatus = itemStatus;

      copyOfStagingBuilders = calcPrices(copyOfStagingBuilders, activeIndex);
      return { ...state, stagingBuilders: [...state.stagingBuilders] };
    }
    case FILL_STAGING_BUILDERS: {
      let payload = action.payload as StagingBuilder[];
      let activeStagingBuilder = 0,
        activeStagingBuilderIndex = -1;
      if (state.user.activeStagingBuilder !== 0) {
        activeStagingBuilder = state.user.activeStagingBuilder;
        activeStagingBuilderIndex = payload.findIndex(
          (stagingBuilder) =>
            stagingBuilder.stagingBuilderID === state.user.activeStagingBuilder
        );
        if (
          state.stagingBuilders[activeIndex] !== undefined &&
          state.stagingBuilders[activeIndex].stagingBuilderID ===
            state.user.activeStagingBuilder
        ) {
          payload[activeStagingBuilderIndex].itemContainers =
            state.stagingBuilders[activeIndex].itemContainers;

          payload[activeStagingBuilderIndex] = {
            ...state.stagingBuilders[activeIndex],
            ...payload[activeStagingBuilderIndex],
          };
        }
      }
      return {
        ...state,
        user: {
          ...state.user,
          activeStagingBuilder,
          activeStagingBuilderIndex,
        },
        stagingBuilders: [...payload],
      };
    }
    case SET_TIMESTAMP_STAGINGBUILDER: {
      let copyOfStagingBuilders = [...state.stagingBuilders];
      copyOfStagingBuilders[activeIndex].editedOn = action.payload;
      return {
        ...state,
        stagingBuilders: copyOfStagingBuilders,
      };
    }
    case LOAD_ACTIVE_STAGINGBUILDER: {
      // The stagingBuilder is already loaded/partially loaded
      let allStagingBuilders = state.stagingBuilders.map((stagingBuilder) =>
        stagingBuilder.stagingBuilderID === action.payload.stagingBuilderID
          ? action.payload
          : stagingBuilder
      );
      let stagingBuilderIndex = state.stagingBuilders.findIndex(
        (builder) =>
          builder.stagingBuilderID === parseInt(action.payload.stagingBuilderID)
      );
      if (stagingBuilderIndex === -1 && allStagingBuilders.length === 0) {
        allStagingBuilders = [action.payload];
        stagingBuilderIndex = 0;
      }
      return {
        ...state,
        user: {
          ...state.user,
          activeStagingBuilder: action.payload.stagingBuilderID,
          activeStagingBuilderIndex: stagingBuilderIndex,
        },
        stagingBuilders: allStagingBuilders,
      };
    }
    case SET_ACTIVE_STAGINGBUILDER: {
      const stagingBuilderIndex = state.stagingBuilders.findIndex(
        (builder) => builder.stagingBuilderID === parseInt(action.payload)
      );
      return {
        ...state,
        user: {
          ...state.user,
          activeStagingBuilder: action.payload,
          activeStagingBuilderIndex: stagingBuilderIndex,
        },
      };
    }
    case NEW_STAGINGBUILDER: {
      const { stagingBuilder }: { stagingBuilder: StagingBuilder } =
        action.payload;
      return {
        ...state,
        stagingBuilders: [stagingBuilder, ...state.stagingBuilders],
      };
    }
    case ADD_NEW_ITEM_CONTAINER: {
      // get the index of the StagingBuilder we are going to update
      const objIndex = state.stagingBuilders.findIndex(
        (stagingBuilder) =>
          stagingBuilder.stagingBuilderID === state.user.activeStagingBuilder
      );
      // add the stagingBuilderID to the payload before pushing
      console.log(action.payload);
      const newItemContainer: StagingBuilderContainer = {
        itemContainerID: action.payload.itemContainerID,
        stagingBuilderID: state.user.activeStagingBuilder,
        containerType: action.payload.containerType,
        containerName: action.payload.containerName,
        locked: false,
        items: [],
        active: true,
        containerRate: 0,
        containerRetail: 0,
      };
      const newStagingBuilders = [...state.stagingBuilders];
      newStagingBuilders[objIndex].itemContainers.push(newItemContainer);
      console.log("from redux reducer", objIndex);
      return {
        ...state,
        stagingBuilders: newStagingBuilders,
      };
    }
    case GET_ALL_STAGINGBUILDER_NOTES: {
      let copyOfStagingBuilders = state.stagingBuilders;
      copyOfStagingBuilders[activeIndex].messages = action.payload.messages;

      return {
        ...state,
        stagingBuilders: [...copyOfStagingBuilders],
      };
    }
    case "PUSH_STAGING_BUILDER_NOTE": {
      const {
        message,
        stagingBuilderID,
      }: {
        message: StagingBuilderNote;
        stagingBuilderID: number;
      } = action.payload;

      const stagingBuilderIndex = state.stagingBuilders.findIndex(
        (sb) => sb.stagingBuilderID === stagingBuilderID
      );
      if (stagingBuilderIndex === -1) return { ...state };
      let copyOfStagingBuilders = state.stagingBuilders;
      let messages = copyOfStagingBuilders[stagingBuilderIndex].messages;

      if (messages === undefined) messages = [];
      copyOfStagingBuilders[stagingBuilderIndex].messages = Array.from(
        new Set([...messages, message])
      );
      copyOfStagingBuilders[stagingBuilderIndex].messages = GetUniqueObjects(
        Array.from(new Set([...messages, message])),
        "stagingBuilderNoteID"
      );

      return {
        ...state,
        stagingBuilders: [...copyOfStagingBuilders],
      };
    }
    case "DELETE_CONTAINERITEM": {
      // this is called when a containerItem is moved to an itemContainer
      // and that itemContainer already has an item with that itemID
      // server side we are deleting that row from sql
      const { itemContainerID, containerItemID } = action.payload;
      let copyOfStagingBuilders = state.stagingBuilders;

      const itemContainerIndex = copyOfStagingBuilders[
        activeIndex
      ].itemContainers.findIndex(
        (ic) => ic.itemContainerID === itemContainerID
      );
      if (itemContainerIndex === -1) return { ...state };

      const containerItemIndex = copyOfStagingBuilders[
        activeIndex
      ].itemContainers[itemContainerIndex].items.findIndex(
        (ci) => ci.containerItemID === containerItemID
      );
      if (containerItemIndex === -1) return { ...state };
      copyOfStagingBuilders[activeIndex].itemContainers[
        itemContainerIndex
      ].items.splice(containerItemIndex, 1);
      copyOfStagingBuilders = calcPrices(copyOfStagingBuilders, activeIndex);
      return {
        ...state,
        stagingBuilders: copyOfStagingBuilders,
      };
    }
    case "UPDATE_STAGINGBUILDER_META":
      if (!action.payload) return { ...state };
      const {
        stagingBuilderID,
        ...rest
      }: {
        stagingBuilderID: number;
        rest: Partial<StagingBuilder>;
      } = action.payload;

      if (isNaN(Number(stagingBuilderID)))
        return {
          ...state,
        };
      // rest is stagingBuilder information (so is the ID technically but not rewriting that)
      let copyOfStagingBuilders = [...state.stagingBuilders];
      // doing this incase they change stagingBuilders while axios is going in background
      const SBindex = copyOfStagingBuilders.findIndex(
        (sb) => sb.stagingBuilderID === stagingBuilderID
      );

      if (SBindex === -1)
        return {
          ...state,
        };

      copyOfStagingBuilders[SBindex] = {
        ...copyOfStagingBuilders[SBindex],
        ...rest,
      };
      if (copyOfStagingBuilders[SBindex].itemContainers)
        copyOfStagingBuilders = calcPrices(copyOfStagingBuilders, SBindex);
      return {
        ...state,
        stagingBuilders: copyOfStagingBuilders,
      };

    case "ADD_NEW_CONTAINER_ITEM": {
      const containerItem = action.payload.containerItem as ContainerItem;

      const stagingBuilderIndex = state.stagingBuilders.findIndex(
        (stagingBuilder) =>
          stagingBuilder.stagingBuilderID === containerItem.stagingBuilderID
      );
      // if we cant find the stagingBuilder, then give up
      if (stagingBuilderIndex === -1) return { ...state };

      const itemContainerIndex = state.stagingBuilders[
        stagingBuilderIndex
      ].itemContainers.findIndex(
        (itemContainer) =>
          itemContainer.itemContainerID === containerItem.itemContainerID
      );

      // if we cant find the itemContainer, then give up
      if (itemContainerIndex === -1) return { ...state };

      let copyOfStagingBuilders = [...state.stagingBuilders];

      // if the itemID already exists then something weird is happening...
      // but lets check anyway I guess
      const alreadyExists = copyOfStagingBuilders[
        stagingBuilderIndex
      ].itemContainers[itemContainerIndex].items.findIndex(
        (ci) => ci.itemID === containerItem.itemID
      );
      // if we found it, it already exists and should not be adding anything
      if (alreadyExists !== -1) return { ...state };

      copyOfStagingBuilders[stagingBuilderIndex].itemContainers[
        itemContainerIndex
      ].items = [
        containerItem,
        ...copyOfStagingBuilders[stagingBuilderIndex].itemContainers[
          itemContainerIndex
        ].items,
      ];

      copyOfStagingBuilders = calcPrices(copyOfStagingBuilders, activeIndex);

      return { ...state, stagingBuilders: [...copyOfStagingBuilders] };
    }
    case "CLEAR_ACTIVE_STAGING_BUILDER": {
      return {
        ...state,
        user: {
          ...state.user,
          activeStagingBuilder: 0,
          activeStagingBuilderIndex: -1,
        },
      };
    }
    default:
      return state;
  }
};

export default stagingBuilderReducer;
