import React from "react";
import { FieldProps, Field, ErrorMessage } from "formik";
import Select, { OptionsType, ValueType } from "react-select";
import TextError from "./TextError";

interface Option {
  label: string; // what the user sees
  value: string; // what the value of the option is (usually the ID from SQL)
}
interface Iprops {
  name: string;
  label: React.ReactNode;
  options: any[];
  disabled?: boolean;
  menuPlacement?: "top" | "auto" | "bottom";
}
export const MultiSelectInventory = ({
  label,
  name,
  options,
  ...rest
}: Iprops) => {
  return (
    <>
      <div className="flex justify-between">
        <label
          style={{ alignSelf: "center", justifySelf: "left" }}
          htmlFor={name}
        >
          {label}
        </label>
        <ErrorMessage name={name} component={TextError} />
      </div>
      <Field name={name} component={MultiSelect} options={options} {...rest} />
    </>
  );
};

interface CustomSelectProps extends FieldProps {
  options: OptionsType<Option>;
  isMulti?: boolean;
  menuPlacement?: "top" | "auto" | "bottom";
  disabled?: boolean;
}
const MultiSelect = ({
  field,
  form,
  disabled,
  ...props
}: CustomSelectProps) => {
  const { options, isMulti } = props;
  const onChange = (option: ValueType<Option, true>) => {
    form.setFieldValue(
      field.name,
      option?.map((item: Option) => item.value)
        ? option?.map((item: Option) => item.value)
        : ""
    );
  };
  const getValue = () => {
    if (options) {
      return options.filter(
        (option) => field?.value?.indexOf(option.value) >= 0
      );
    } else {
      return [];
    }
  };
  return (
    <div className="multi-select-container" style={{ gridColumn: "2/4" }}>
      <Select
        {...field}
        {...props}
        isDisabled={disabled}
        name={field.name}
        options={options}
        isMulti={true}
        value={getValue()}
        onChange={onChange}
        menuPlacement={props.menuPlacement}
        onBlur={field.onBlur}
      />
    </div>
  );
};
