import { useMemo } from "react";
import { BlockContainer } from "./BlockContainer";
import { BlockBackDrop } from "./BlockBackDrop";
import { AiFillDollarCircle } from "react-icons/ai";
import type { FC } from "react";

export const PricingBlock: FC<
  Pick<StagingBuilder, "monthlyRate" | "retailValue">
> = ({ monthlyRate, retailValue }) => {
  const { rate, retail } = useMemo(() => {
    // return USD
    return {
      rate: Number(monthlyRate).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
      retail: Number(retailValue).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
    };
  }, [monthlyRate, retailValue]);
  return (
    <div className="block h-full lg:row-span-2 lg:h-[158px]">
      <BlockContainer>
        <div className="mr-2 grid h-8 w-8 place-items-center">
          <AiFillDollarCircle size={32} fill="#55d6e3" />
        </div>
        <BlockBackDrop />
        <div className="flex flex-col">
          <div className="mb-2">
            <span className="mb-1 block text-sm leading-tight tracking-tighter text-gray-600">
              Monthly Rent
            </span>
            <span className="block text-xl leading-none">{rate}</span>
          </div>
          <div>
            <span className="mb-1 block text-sm leading-tight tracking-tighter text-gray-600">
              Retail Value
            </span>
            <span className="block text-xl leading-none">{retail}</span>
          </div>
        </div>
      </BlockContainer>
    </div>
  );
};
