import { push } from "connected-react-router";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useMemo, ReactNode, FC } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useCalendar } from "../../../../constants/contexts/calendar/CalendarContext";
import { MHFOSubmissionValues } from "../../../../constants/hooks/fetching/useCalendarMHFO";

interface Props {
  children: (props: FormikProps<MHFOSubmissionValues>) => ReactNode;
}

export const EditMHFODeliveryLogic: FC<Props> = ({ children }) => {
  const dispatch = useDispatch();
  const mhfo = useSelector(
    (state: Istate) => state.router.location.query.mhfo,
    shallowEqual
  );
  const {
    mhfo: { events, updateMHFODelivery },
  } = useCalendar();
  const initialValues: MHFOSubmissionValues = useMemo(() => {
    const event = events.find(
      ({ MHFODeliveryID }) => MHFODeliveryID === Number(mhfo)
    );

    if (event === undefined) {
      dispatch(push("/calendar"));
      return {
        "customer-name": "",
        "task-time": 0,
        address1: "",
        address2: "",
        zip: "",
        state: "48",
        city: "",
        "invoice-number": "",
        "task-hours": "",
        "task-description": "",
      };
    }

    return {
      "customer-name": event.customer,
      "task-time": event.taskStart,
      address1: event.address1,
      address2: event.address2,
      zip: event.zip,
      state: event.state,
      city: event.city,
      "invoice-number": event.invoiceNo,
      "task-hours": event.taskHours || "",
      "task-description": event.taskDetails,
    };
  }, [events, mhfo, dispatch]);
  const onSubmit = (
    values: typeof initialValues,
    a: FormikHelpers<typeof initialValues>
  ) => {
    // consider sending initial values as well
    if (updateMHFODelivery)
      updateMHFODelivery(
        { ...values, MHFODeliveryID: Number(mhfo) },
        "/calendar"
      );
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {(props: FormikProps<typeof initialValues>) => children(props)}
    </Formik>
  );
};
