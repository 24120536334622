import { FancyCheck } from "../../HighlyReusableComponents/FormComponents/Checkbox";
import { BooleanState } from "../../HighlyReusableComponents/hooks/useBooleanState";

interface Props {
  checkState: BooleanState<{
    auto: true;
    stickerTag: false;
    normalTag: false;
    queueNormalTag: false;
    newWindowNormalTag: false;
    newTabNormalTag: false;
    zebraTag: boolean;
  }>;
}
export const TagOptions = ({ checkState }: Props) => {
  return (
    <div className="absolute top-32">
      <FancyCheck
        name="auto"
        label="Auto Choose from Tag"
        checked={checkState.values.auto}
        onClick={() => {
          if (!checkState.values.auto) {
            checkState.save();
            checkState.resetState();
          } else {
            checkState.resetState("save");
            if (checkState.values.auto) checkState.set("auto", false);
          }
        }}
      />
      <div className="pl-6">
        <FancyCheck
          name="normalTag"
          label="Standard StagingRents Tag"
          checked={checkState.values.normalTag}
          onClick={() => {
            checkState.set("auto", false);
            if (checkState.values.normalTag) {
              checkState.set("newWindowNormalTag", false);
              checkState.set("queueNormalTag", false);
            } else if (
              !checkState.values.newWindowNormalTag &&
              !checkState.values.queueNormalTag
            ) {
              checkState.set("newWindowNormalTag", true);
              checkState.set("queueNormalTag", true);
            }
            checkState.set("normalTag");
          }}
        />
        <div className="pl-6">
          <FancyCheck
            name="newWindowNormalTag"
            label="Open in New Window"
            checked={checkState.values.newWindowNormalTag}
            onClick={() => {
              checkState.set("auto", false);
              !checkState.values.newWindowNormalTag &&
                checkState.set("normalTag", true);
              checkState.set("newWindowNormalTag");
            }}
          />
          <FancyCheck
            name="queueNormalTag"
            label="Add To Queue"
            checked={checkState.values.queueNormalTag}
            onClick={() => {
              checkState.set("auto", false);
              !checkState.values.queueNormalTag &&
                checkState.set("normalTag", true);
              checkState.set("queueNormalTag");
            }}
          />
        </div>
        <FancyCheck
          name="stickerTag"
          label="Add Sticker To Queue"
          checked={checkState.values.stickerTag}
          onClick={() => {
            checkState.set("auto", false);
            checkState.set("stickerTag");
          }}
        />
        <FancyCheck
          name="zebraTag"
          label="Add Item To ZEBRA Queue"
          checked={checkState.values.zebraTag}
          onClick={() => {
            checkState.set("auto", false);
            checkState.set("zebraTag");
          }}
        />
      </div>
    </div>
  );
};
