import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Column,
  useFilters,
  useSortBy,
  useTable,
  useRowSelect,
} from "react-table";
import {
  dateOptionsShort,
  GetDate,
  LocaleNumber,
  ParseStatus,
} from "../constants";
import { FloatingCell } from "./ProposalsTable/FloatingCell";
import { ProposalTableBody } from "./ProposalsTable/ProposalTableBody";
import { ProposalTableHeader } from "./ProposalsTable/ProposalTableHeader";
import { SearchHeader } from "./ProposalsTable/SearchHeader";

export interface StagingBuilderMemo extends StagingBuilder {
  delivery?: string;
  created?: string;
  RATE?: string;
  RETAIL?: string;
  STATUS?: string;
}

function ProposalContainerColumnContainer() {
  const {
    user: { businessID },
    stagingBuilders,
  } = useSelector((state: Istate) => state.data);

  const columns = useMemo<Column<StagingBuilderMemo>[]>(
    () => [
      {
        // needs to stay at index 0
        Header: "StagingBuilderID",
        accessor: "stagingBuilderID", // accessor is the "key" in the data,
        sortType: "number",
      },
      {
        // needs to stay at index 1
        Header: "File #",
        accessor: "fileNumber", // accessor is the "key" in the data
        filter: "includesValue",
        Cell: ({ value, row }) => (
          <>{value ? `HS-${value}` : `SP-${row.allCells[0].value}`}</>
        ),
      },
      {
        // needs to stay at index 2
        Header: "Status",
        accessor: "stagingStatus",
        className: "whitespace-nowrap overflow-hidden max-w-md text-center",
        filter: "includesValue",
        style: {
          maxWidth: "13ch",
          width: "13ch",
        },
        Cell: ({ value, row }) => {
          const textStatus = ParseStatus(
            value,
            businessID,
            row.allCells[1].value
          );
          if (textStatus.length > 10)
            return <FloatingCell>{textStatus}</FloatingCell>;
          return <>{textStatus}</>;
        },
      },
      {
        Header: "Stager",
        accessor: "stagerName",
      },
      {
        Header: "Client",
        accessor: "clientName",
        className: "text-center",
      },
      {
        Header: "Address",
        accessor: "address",
        className: "whitespace-nowrap overflow-hidden max-w-md",
        style: {
          maxWidth: "22ch",
          width: "13ch",
        },
        floatText: true,
      },
      {
        Header: "Items",
        accessor: "items",
      },
      {
        Header: "Rate",
        accessor: "RATE",
        className: "sup-dollar",
        style: {
          minWidth: "5ch",
        },
        sortType: "number",
      },
      {
        Header: "Retail",
        accessor: "RETAIL",
        className: "sup-dollar",
        style: {
          minWidth: "6ch",
        },
        sortType: "number",
      },
      {
        Header: "Created",
        accessor: "createdOn",
        sortType: "number",
        Cell: ({ value }) => (
          <>{GetDate(value || 0, "TBD", dateOptionsShort)}</>
        ),
      },
      {
        Header: "Delivery",
        accessor: "deliveryActual",
        sortType: "number",
        Cell: ({ value }) => (
          <>{GetDate(value || 0, "TBD", dateOptionsShort)}</>
        ),
      },
      {
        Header: "Pickup",
        accessor: "pickupActual",
        sortType: "number",
        Cell: ({ value }) => (
          <>{GetDate(value || 0, "TBD", dateOptionsShort)}</>
        ),
      },
      {
        Header: "UNTAGGED",
        accessor: "areItemsUntagged",
      },
    ],
    [businessID]
  );

  const memoizedStagingBuilders = useMemo(() => {
    let modifiedStagingBuilders = stagingBuilders.map(
      (stagingBuilder: StagingBuilderMemo) => {
        // stagingBuilder.fileNumber =
        //   stagingBuilder.fileNumber?.toString() as unknown as number;
        stagingBuilder.RATE = LocaleNumber(stagingBuilder.monthlyRate || 0);
        stagingBuilder.RETAIL = LocaleNumber(stagingBuilder.retailValue || 0);
        return stagingBuilder;
      }
    ) as StagingBuilderMemo[];
    return modifiedStagingBuilders;
  }, [stagingBuilders]);

  const hiddenColumns = useMemo(() => {
    let hiddenColumns = ["stagingBuilderID", "areItemsUntagged"];
    const column3hidden = businessID === 1 ? "clientName" : "stagerName";
    hiddenColumns.push(column3hidden);
    return hiddenColumns;
  }, [businessID]);

  const tableInstance = useTable(
    {
      columns,
      data: memoizedStagingBuilders,
      initialState: {
        hiddenColumns,
        filters: [
          {
            id: "stagingStatus",
            value: [1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 13, 14, 15, 16, 18, 19],
          },
        ],
      },
      autoResetRowState: false,
      autoResetFilters: false,
      autoResetSortBy: false,
    },
    useFilters,
    useSortBy,
    useRowSelect
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsSelected,
    state: { filters },
    headers,
    setFilter,
    setHiddenColumns,
  } = tableInstance;

  useEffect(() => {
    setHiddenColumns(hiddenColumns);
  }, [businessID, setHiddenColumns, hiddenColumns]);

  return (
    <SearchHeader filters={filters} setFilter={setFilter} headers={headers}>
      {() => (
        <div
          style={{ scrollSnapType: "both" }}
          className="simple-border relative"
        >
          <div className="absolute inset-0">
            <div className="h-full overflowY-scroll">
              <table
                {...getTableProps()}
                className=""
                style={{ fontSize: "14px" }}
              >
                <ProposalTableHeader headerGroups={headerGroups} />
                <ProposalTableBody
                  getTableBodyProps={getTableBodyProps}
                  rows={rows}
                  prepareRow={prepareRow}
                  toggleAllRowsSelected={toggleAllRowsSelected}
                />
              </table>
            </div>
          </div>
        </div>
      )}
    </SearchHeader>
  );
}

// export default React.memo(ProposalContainerColumnContainer);
export default ProposalContainerColumnContainer;
