// UPDATE WARNING:
// I exist in the api as well update me there too
// I exist in mysql triggers as well
// USAGE WARNING:
// TBD is implemented as null in MySQL
export enum StagingItemStatuses {
  TBD = 0,
  RETURNED = 1, // back in inventory
  SOLD = 2,
  TOUCH_UP = 3, // back in inventory
  WORN_OUT = 4,
  STOLEN_MISSING = 5,
  SHRINKAGE = 6,
  DAMAGED_BILL = 7, // Bill Stager // back in inventory
  DESTROYED_BILL = 8, // Bill Stager
  DAMAGED_PAID = 9, // sager paid // back in inventory
  DESTROYED_PAID = 10, // stager paid
  REJECTED_FROM_DELIVERY = 11,
}
