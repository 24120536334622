import { useMemo } from "react";
import { BlockContainer } from "./BlockContainer";
import { BlockBackDrop } from "./BlockBackDrop";
import { TbUserCircle } from "react-icons/tb";
import { capitalizeFirstChars } from "./Address";
import { Link } from "react-router-dom";
import type { FC } from "react";

export const ClientBlock: FC<Pick<StagingBuilder, "clientName">> = ({
  clientName,
}) => {
  const { first, last } = useMemo(() => {
    const [first, ...rest] = clientName?.split(" ") || [];
    if (!(first + rest)) return { first: "Add Client", last: "Information" };
    return {
      first: capitalizeFirstChars(first),
      last: capitalizeFirstChars(rest?.join(" ")),
    };
  }, [clientName]);
  return (
    <Link
      to={{
        pathname: "/stage",
        search: "open-modal=staging-information",
      }}
      className="absolute top-[70%] left-[calc(50%+0.5rem)] block h-full w-[calc(50%-1.5rem)] lg:static lg:top-2/3 lg:col-span-2 lg:h-[70px] lg:w-full"
    >
      <BlockContainer>
        <div className="my-auto mr-2 grid h-8 w-8 place-items-center rounded-full">
          <TbUserCircle size={32} color="#f1566b" />
        </div>
        <BlockBackDrop />
        <div className="my-auto flex flex-col">
          <span className="leading-none">{first}</span>
          <span className="leading-none text-gray-700">{last}</span>
        </div>
      </BlockContainer>
    </Link>
  );
};
