import { push } from "connected-react-router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EmployeeOnly } from "../HighlyReusableComponents/EmployeeOnly";
import { BasicModal } from "./BasicModal";
import { StagerProfile } from "./StagerProfile";

interface Props {}
type MODALS = "stager-profile";

export const GlobalModals = (props: Props) => {
  const dispatch = useDispatch();
  const { pathname, query } = useSelector(
    (state: Istate) => state.router.location
  );
  const [openModal, setOpenModal] = useState<null | MODALS>(null);
  useEffect(() => {
    switch (query["open-modal"] as MODALS) {
      case "stager-profile": {
        setOpenModal("stager-profile");
        break;
      }
      default: {
        if (openModal !== null) setOpenModal(null);
      }
    }
  }, [query]);
  return (
    <>
      <BasicModal
        setOpen={() => dispatch(push(pathname))}
        open={openModal === "stager-profile"}
        employeeOnly
        modalStyle={{
          top: "25vh",
          left: "40vw",
          right: "40vw",
          bottom: "auto",
          height: "auto",
        }}
      >
        <EmployeeOnly pathname={pathname || "/"} />
        <StagerProfile userID={Number(query.user)} />
      </BasicModal>
      <div></div>
      <div></div>
    </>
  );
};
