import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import styled from "styled-components";
import Select, { ValueType } from "react-select";

import {
  fetchingData,
  fetchingDataFailure,
  fetchingDataSuccess,
  Istate,
} from "../../../../redux";
import axios from "axios";
import { hrefPrefix, ToastyError } from "../../../constants/";
import { CustomButton } from "../../../HighlyReusableComponents/CustomButton";
import { TagStyles } from "../../../constants/enums/TagStyles";
import { handleAddItemToStickerQueue } from "../../../HighlyReusableComponents/SocketEmitters/handleAddItemToStickerQueue";

let printQuanOptions: { label: string; value: string }[] = [];
for (let i = 1; i < 13; i++) {
  printQuanOptions.push({ label: i.toString(), value: i.toString() });
}

const PrintOptionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-width: 100%;
  margin-top: 50px;
`;

interface Props {
  tagInfo: {
    tagStyle: TagStyles;
    size: number;
  };
  setTagInfo: React.Dispatch<
    React.SetStateAction<{
      tagStyle: TagStyles;
      size: number;
    }>
  >;
  tagErrors: boolean;
}
export const PrintOptions = ({ tagInfo, setTagInfo, tagErrors }: Props) => {
  const dispatch = useDispatch();
  const itemNo = useSelector(
    (state: Istate) => state.data.user.activeItem?.itemNo,
    shallowEqual
  );
  const socket = useSelector(
    (state: Istate) => state.data.socket,
    shallowEqual
  );
  const [printQuantity, setPrintQuantity] = React.useState("4");

  const handleTagStyleChange = (
    e: ValueType<
      {
        label: string;
        value: string;
      },
      false
    >
  ) => {
    if (e) {
      if (e.value !== "4" && printQuantity !== "1") setPrintQuantity("1");
      else if (e.value === "4" && printQuantity !== "4") setPrintQuantity("4");
      setTagInfo({ ...tagInfo, tagStyle: ~~e.value });
    }
  };
  const handlePrintQuanChange = (
    e: ValueType<
      {
        label: string;
        value: string;
      },
      false
    >
  ) => {
    if (e) setPrintQuantity(e.value);
  };
  const handlePrintNow = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (itemNo === undefined) e.preventDefault();
  };
  const handlePrintQueue = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (tagInfo.tagStyle === TagStyles.STAGINGRENTS_STICKER)
      if (itemNo)
        handleAddItemToStickerQueue(socket, itemNo, {
          normalTag: false,
          stickerTag: true,
          auto: false,
          queueQuantity: Number(printQuantity) || 0,
        });
      else ToastyError(500);
    else if (tagInfo.tagStyle === TagStyles.STAGING_STANDARD)
      if (itemNo)
        handleAddItemToStickerQueue(socket, itemNo, {
          normalTag: true,
          stickerTag: false,
          queueNormalTag: true,
          auto: false,
          queueQuantity: Number(printQuantity) || 0,
        });
      else ToastyError(500);
    else
      axios({
        method: "POST",
        url: "/api/print/print-queue/insert",
        data: {
          itemNo,
          printQuantity,
          ...tagInfo,
        },
      });
  };
  const handleStartNewQueue = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const Confirm = window.confirm(
      "Are you sure you want to start a new print queue?\nThis action is irreversable."
    );
    if (Confirm) {
      dispatch(fetchingData());
      axios({
        method: "POST",
        url: "/api/print/print-options",
        data: {
          action: "START_NEW_PRINT_QUEUE",
        },
      })
        .then((res) => {
          dispatch(
            fetchingDataSuccess(res.status, res.data.message, res.data.success)
          );
        })
        .catch((err) => {
          if (err.response) {
            dispatch(fetchingDataFailure(err.response.status));
          }
        });
    }
  };
  const hrefURL1 = `${hrefPrefix}/api/print/single-tag?itemNo=${itemNo}&printQuantity=${printQuantity}&tagStyle=${tagInfo.tagStyle}&tagSize=${tagInfo.size}`;
  const hrefURL2 = `${hrefPrefix}/api/print/print-queue/stickers`;
  const hrefURL3 = `${hrefPrefix}/api/print/print-queue/standard/current?tagStyleID=[4]`;

  return (
    <PrintOptionsContainer>
      <form onSubmit={(e) => e.preventDefault()}>
        <input type="hidden" name="" />
        <div className="grid fr-auto gap-1">
          <label htmlFor="tagStyle">
            Tag Style - Size
            <br />
            <Select
              value={printOptions.find(
                (option) => option.value === tagInfo.tagStyle.toString()
              )}
              onChange={handleTagStyleChange}
              name="tagStyle"
              id="tagStyle"
              options={printOptions}
            />
          </label>
          <label htmlFor="printQuantity">
            Print Quantity
            <br />
            <Select
              onChange={handlePrintQuanChange}
              name="printQuantity"
              id="printQuantity"
              value={printQuanOptions.find(
                (option) => option.value === printQuantity
              )}
              options={printQuanOptions}
            />
          </label>
        </div>
        <br />
        <br />
        <CustomButton
          linkProps={{
            onClick: handlePrintNow,
            to: { pathname: hrefURL1 },
            target: "_blank",
            rel: "noopener noreferrer",
          }}
          disabled={tagErrors || tagInfo.tagStyle === 7}
          type="button"
        >
          Print {printQuantity} Tags Now
        </CustomButton>
        <CustomButton
          disabled={tagErrors}
          onClick={handlePrintQueue}
          type="button"
        >
          Add {printQuantity} Tags to Queue
        </CustomButton>
        <br />
        <br />
        <CustomButton
          linkProps={{
            to: { pathname: hrefURL2 },
            target: "_blank",
            rel: "noopener noreferrer",
          }}
          type="button"
        >
          Open Sticker Queue (pdf)
        </CustomButton>
        <CustomButton
          linkProps={{
            to: { pathname: hrefURL2 + "/html" },
            target: "_blank",
            rel: "noopener noreferrer",
          }}
          type="button"
        >
          Open Sticker Queue (HTML)
        </CustomButton>
        <CustomButton
          linkProps={{
            to: { pathname: hrefURL3 },
            target: "_blank",
            rel: "noopener noreferrer",
          }}
          type="button"
        >
          Open Standard Queue in New Tab
        </CustomButton>
        <CustomButton type="button" onClick={handleStartNewQueue}>
          Start New Print Queue
        </CustomButton>
      </form>
    </PrintOptionsContainer>
  );
};

const printOptions = [
  { label: "StagingRents - Standard", value: "4" },
  { label: "StagingRents - Sticker (Queue Only)", value: "7" },
  { label: "MHFO - Standard", value: "1" },
];
