import React from "react";
import styled from "styled-components";

import FormikControl from "../../FormikComponents/FormikControl";
import {
  QuantityComponentAvailable,
  QuantityComponentTotal,
} from "../../FormikComponents/FormikInventoryControl";
import { valueOptions } from "../../DropDownLists";
import { useFormikContext } from "formik";

const InventoryEditorWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 7vw 0;
  grid-auto-rows: minmax(2.5ch, auto);
  // margin: 8px;
  // border 1px solid lightgrey;
  // border-radius: 2px;
  max-height: 100%;
`;
const visibilityOptions = [
  { id: 3, text: "Place In Middle 25%-75%" },
  { id: 0, text: "First Item" },
  { id: 6, text: "First Page" },
  { id: 1, text: "Place In Top 10%" },
  { id: 2, text: "Place In Top 25%" },
  { id: 4, text: "Place In Last 25%" },
  { id: 5, text: "Place Last" },
];
interface IRightEditorPanel {
  activeItem?: itemVerbose;
}
export const RightEditorPanel = (props: IRightEditorPanel) => {
  const ctx = useFormikContext<itemVerbose>();
  const [placeHolder, setPlaceHolder] = React.useState({
    sellPrice: "",
    comparePrice: "",
    rentalPrice: "",
    rentalRate: "",
  });
  React.useEffect(() => {
    setPlaceHolder({
      sellPrice: ctx.values.landedCost
        ? (Number(ctx.values.landedCost) * 2).toString()
        : "",
      comparePrice: ctx.values.landedCost
        ? (Number(ctx.values.landedCost) * 2.86).toString() +
          " - " +
          (Number(ctx.values.landedCost) * 4).toString()
        : "",
      rentalPrice: ctx.values.landedCost
        ? (Number(ctx.values.landedCost) * 2).toString() +
          " - " +
          (Number(ctx.values.landedCost) * 2.86).toString()
        : "",
      rentalRate: ctx.values.landedCost
        ? (Number(ctx.values.landedCost) * 0.17).toString()
        : "",
    });
  }, [ctx.values.landedCost]);
  return (
    <InventoryEditorWrapper>
      {/* <FormikControl
        control="inventory-input"
        label="Place Holder Icon"
        name="placeholder"
        notsearchable={1}
      /> */}
      <FormikControl
        control="inventory-checkbox"
        label="Show in MHFO"
        name="mhfo"
        notsearchable={1}
      />
      <FormikControl
        control="inventory-checkbox"
        label="Show in StagingRents"
        name="ezrent"
        notsearchable={1}
      />
      <FormikControl
        control="inventory-select"
        label="Visibility / Placement"
        name="webCardPlacement"
        options={visibilityOptions}
        default={
          props.activeItem?.newItem ||
          (!props.activeItem?.mhfo && !props.activeItem?.ezrent)
            ? { id: -1, text: "Choose: " }
            : { id: 10, text: "Keep the Same" }
        }
        notsearchable={1}
      />
      <div style={{ gridColumn: "1/4" }}></div>
      <FormikControl
        control="inventory-input"
        label="Landed Cost"
        name="landedCost"
        notsearchable={1}
        fastInput={true}
      />
      {/* Do NOT use fast inputs on prices other than landed */}
      <FormikControl
        control="inventory-input"
        label="MHFO Retail"
        name="sellPrice"
        placeholder={placeHolder.sellPrice}
        notsearchable={1}
      />
      <FormikControl
        control="inventory-input"
        label="MHFO Compare"
        name="comparePrice"
        placeholder={placeHolder.comparePrice}
        notsearchable={1}
      />
      <FormikControl
        control="inventory-input"
        label="StagingRents Retail"
        name="rentalPrice"
        placeholder={placeHolder.rentalPrice}
        notsearchable={1}
        style={{
          backgroundColor: `${
            ctx.values.rentalRate > ctx.values.rentalPrice ? "red" : ""
          }`,
        }}
      />
      <FormikControl
        control="inventory-input"
        label="Rental Rate"
        name="rentalRate"
        placeholder={placeHolder.rentalRate}
        notsearchable={1}
        style={{
          backgroundColor: `${
            ctx.values.rentalRate > ctx.values.rentalPrice ? "red" : ""
          }`,
        }}
      />
      {/* @todo fix later */}
      <FormikControl
        control="inventory-select"
        label="Value Item"
        name="rentalBracket"
        options={valueOptions}
        default={{ id: 0, text: "Choose: " }}
        notsearchable={1}
      />
      <FormikControl
        control="inventory-checkbox"
        label="MHFO Hot Buy!"
        name="hotbuy"
        notsearchable={1}
      />
      <div style={{ gridColumn: "1/4" }}></div>
      <FormikControl
        control="inventory-input"
        label="Quan. Loft"
        name="quanLoft"
        notsearchable={1}
        disablefield={1}
        fastInput={true}
      />
      <FormikControl
        control="inventory-input"
        label="Quan. Huddle"
        name="quanHuddle"
        notsearchable={1}
        disablefield={1}
        fastInput={true}
      />
      <FormikControl
        control="inventory-input"
        label="Quan. MHFO"
        name="quanMHFO"
        notsearchable={1}
        disablefield={1}
        fastInput={true}
      />
      <FormikControl
        control="inventory-input"
        label="Quan. Castle"
        name="quanCastle"
        notsearchable={1}
        disablefield={1}
        fastInput={true}
      />
      <FormikControl
        control="inventory-input"
        label="Major Repair (Not avail)"
        name="quanRepair"
        notsearchable={1}
        disablefield={1}
        fastInput={true}
      />
      <FormikControl
        control="inventory-input"
        label="Quan. Staged"
        name="quanStaged"
        notsearchable={1}
        disablefield={1}
        fastInput={true}
      />
      <FormikControl
        control="inventory-input"
        label="Quan. Hold"
        name="quanHold"
        notsearchable={1}
        disablefield={1}
        fastInput={true}
      />
      <label
        style={{ alignSelf: "center", justifySelf: "left" }}
        htmlFor="quanAvail"
      >
        Quan. In Building(s)
      </label>
      <QuantityComponentAvailable
        className="inventory-input"
        name="quanAvail"
      />
      <div></div>
      {/* <FormikControl
        control="inventory-input"
        label="Quan. Available"
        name="quanAvail"
        notsearchable={1}
        disablefield={1}
      /> */}
      <label
        style={{ alignSelf: "center", justifySelf: "left" }}
        htmlFor="quantity"
      >
        Quan. Total
      </label>
      <QuantityComponentTotal className="inventory-input" name="quantity" />
      <div></div>
      {/* <FormikControl
        control="inventory-input"
        label="Quan. Total"
        name="quantity"
        notsearchable={1}
        disablefield={1}
      /> */}
    </InventoryEditorWrapper>
  );
};
