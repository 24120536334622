import { FC, ReactNode } from "react";
import logo1 from "../../assets/logo.png";
import logo2 from "../../assets/stagingrents-web-logo.png";
import laptop from "../../assets/create-account-laptop.jpg";

// styles
import { LoginForm } from "./forms/LoginForm";
import { CreateForm } from "./forms/CreateForm";
import { RecoverForm } from "./forms/RecoverForm";
import { ResetPasswordForm } from "./forms/ResetPasswordForm";

interface Props {
  logo?: string;
  children: ReactNode;
}
const UserFormContainer: FC<Props> = ({ children, logo = logo1 }) => {
  return (
    <div className="grid place-items-center w-full h-full m-0">
      <div className="min-w-1/5 h-min border border-gray-200 pb-8 rounded-lg">
        <div className="relative h-16 w-72 mx-auto mt-3">
          <img
            className="block w-full h-full absolute top"
            src={logo}
            alt="StagingRents Logo"
          />
        </div>
        {children}
      </div>
    </div>
  );
};
export const Login = () => {
  return (
    <UserFormContainer>
      <h1 className="text-center mt-2 text-3xl">Login</h1>
      <LoginForm />
    </UserFormContainer>
  );
};

const CreateAccountLeftSide: FC = () => {
  return (
    <div className="hidden md:block md:col-span-2 md:mt-16">
      <h1 className="text-5xl text-center">
        Home Staging Made Easy
        <br />
        Just Click and Drag
      </h1>
      <div className="grid place-items-center">
        <img className="w-4/5 my-8" src={laptop} alt="" />
      </div>
      <h2 className="text-2xl text-center">
        Thousands of Available Staging Furniture, Artwork <br />
        and Accessory Items Ready for Delivery Now.
      </h2>
    </div>
  );
};
export const Create = () => {
  return (
    <div className="block fixed inset-0 md:relative sm:grid sm:grid-cols-1 md:mx-16 md:grid-cols-3 md:h-full overflow-y-auto">
      <CreateAccountLeftSide />
      <div className="h-full relative">
        <UserFormContainer logo={logo2}>
          <CreateForm />
        </UserFormContainer>
      </div>
    </div>
  );
};
export const Recover = () => {
  return (
    <UserFormContainer>
      <h1 className="text-center mt-2 text-3xl">Recover Account</h1>
      <RecoverForm />
    </UserFormContainer>
  );
};
interface ResetPasswordProps {
  recoveryString: string | null;
}
export const ResetPassword = ({ recoveryString }: ResetPasswordProps) => {
  return (
    <UserFormContainer>
      <h1 className="text-center mt-2 text-3xl">Change Password</h1>
      <ResetPasswordForm recoveryString={recoveryString} />
    </UserFormContainer>
  );
};
