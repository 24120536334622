import { push } from "connected-react-router";
import React from "react";
import { ImCross } from "react-icons/im";
import { useDispatch } from "react-redux";
import { AppThunk } from "../../../redux";
import { handleStagingBuilderScan } from "../../../redux/stagingBuilder/actions";
import { BarcodeInputField } from "./BarcodeInputField";

interface Props {}

export const StagingBuilderScanner = (props: Props) => {
  const dispatch: AppThunk = useDispatch();

  const barcodeRef = React.useRef<HTMLInputElement>(null);
  const [color, setColor] = React.useState("");
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // need to make sure all of the MySQL not null fields are filled out
    // make an easy to submit object
    // let scanType = "";
    if (
      barcodeRef.current?.value === undefined ||
      barcodeRef.current?.value === ""
    )
      return;

    const valuesToSubmit = {
      barcode: barcodeRef.current.value,
      action: "ADD_SCANNED_ITEM" as const,
    };
    // scan type becomes part of the url
    dispatch(
      handleStagingBuilderScan({ ...valuesToSubmit, setChangeColor: setColor })
    );
    barcodeRef.current.value = "";
  };
  return (
    <div className="relative">
      <div
        onClick={() => dispatch(push("/stage"))}
        className="absolute z-5 hidden sm:block"
        style={{ right: "-3rem", top: "15px" }}
      >
        <ImCross cursor="pointer" size="2em" />
      </div>
      <div className="absolute" style={{ right: "200%" }}>
        <div
          style={{ backgroundColor: color, transform: "translateX(-200%)" }}
          className="place-items-center w-full h-full relative sm:grid"
        >
          <form onSubmit={onSubmit}>
            <BarcodeInputField barcodeRef={barcodeRef} />
            <button style={{ display: "none" }} type="submit"></button>
          </form>
        </div>
      </div>
    </div>
  );
};
