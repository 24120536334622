import React, { useState } from "react";
import { ContainerItem } from "../../../redux/stagingBuilder/stagingBuilderTypes";

interface Props {
  item: StagingItem | (ContainerItem & { itemNo: string });
}
export const TableThumbnail = ({ item }: Props) => {
  const [thumbnailFailed, setThumbnailFailed] = useState(true);
  return (
    <td className="relative">
      <picture>
        {thumbnailFailed && (
          <source
            srcSet={`https://storage.googleapis.com/modelhomefurnitureoutlet.com/thumbnails/item-${item.itemNo}.jpg`}
          />
        )}
        <img
          onError={() => setThumbnailFailed(false)}
          loading="lazy"
          className="inventory-image"
          src={item.pictureA}
          alt={"item-" + item.itemNo}
        />
      </picture>
    </td>
  );
};
