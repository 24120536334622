import React from "react";

interface Props {}

export const CheckInTHead = (props: Props) => {
  return (
    <thead>
      <tr>
        <th></th>
        <th>Picked Up</th>
        <th>Out Date</th>
        <th>In Date</th>
        <th>Dimensions</th>
        <th>Item Name</th>
        <th>Item #</th>
        <th>Grouped</th>
      </tr>
    </thead>
  );
};
