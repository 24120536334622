import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { AppThunk, fetchFullStagingBuilder, Istate } from "../../../redux";
import {
  dateOptionsShortVerbose,
  GetDate,
  handleFileNumberClick,
  ParseStatus,
  ParseStatusAsItem,
  rateReducer,
  retailReducer,
  StagingBuilderStatuses,
} from "../../constants";
import { FiPrinter } from "react-icons/fi";
import { FaRegCheckCircle } from "react-icons/fa";
import { PrintDropDown } from "../../Prints/menus";
import { push } from "connected-react-router";
import { Options } from "./Menu";
import ContainerMetaButtons from "./ContainerMetaButtons";

import { Link } from "react-router-dom";
import { containerItemsAsArray } from "../../constants/functions/containerItemsAsArray";
import { socketStatusChange } from "../../../redux/stagingBuilder/actions/socketStatusChange";
import { GenericHoldTagsGenerator } from "./Modals/GenericHoldTags";
import { StagingBuilderHeaderV2 } from "./info/HeaderV2";

const StagingBuilderHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6,1fr);
  border 1px solid lightgrey;
  border-radius: 2px;
  background-color: var(--logo-purple);
`;

const HeaderGrid = styled.div`
  display: grid;
  background-color: var(--heading-light);
  grid-gap: 0 0.5em;
  padding: 8px;
  grid-template-columns: auto 2fr;
  @media only screen and (min-width: 1024px) {
    grid-template-columns: auto 2fr auto 2fr auto 1fr;
  }
`;

const AddressLine = styled.span`
  grid-column: 2/-1;
  @media only screen and (min-width: 1024px) {
    grid-column: 2/5;
  }
`;

interface Props {
  edit: boolean;
  showTrash?: boolean;
  setShowTrash?: React.Dispatch<React.SetStateAction<boolean>>;
  headerHeight: number;
  setHeaderHeight: React.Dispatch<React.SetStateAction<number>>;
}
function StagingBuilderHeader({
  showTrash,
  setShowTrash,
  edit,
  setHeaderHeight,
  headerHeight,
}: Props) {
  const dispatch: AppThunk = useDispatch();
  const { businessID } = useSelector(
    (state: Istate) => state.data.user,
    shallowEqual
  );
  const {
    stagingBuilderID,
    fileNumber,
    stagingStatus,
    address,
    createdOn,
    editedOn,
    monthlyRate,
    retailValue,
    stagerName,
    clientName,
    deliveryDesired,
    deliveryActual,
    pickupDesired,
    pickupActual,
    userID,
  } = useSelector((state: Istate) => {
    let stagingBuilder: StagingBuilder =
      state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex];
    stagingBuilder.monthlyRate =
      containerItemsAsArray(stagingBuilder)?.reduce(rateReducer, 0) ||
      stagingBuilder.monthlyRate;
    stagingBuilder.retailValue =
      containerItemsAsArray(stagingBuilder)?.reduce(retailReducer, 0) ||
      stagingBuilder.retailValue;
    return stagingBuilder;
  });

  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [timeOut, setTimeOut] = useState<number | NodeJS.Timeout>(0);
  const stagingBuilderRefresh = (stagingBuilderID: number) => {
    if (shouldRefresh) {
      dispatch(fetchFullStagingBuilder(stagingBuilderID, shouldRefresh));
      setShouldRefresh(false);
      setTimeOut(
        setTimeout(() => {
          setShouldRefresh(true);
        }, 5000)
      );
    }
  };
  useEffect(() => {
    return () => {
      if (timeOut) clearTimeout(timeOut);
    };
  }, [timeOut]);

  const createdOnDate = GetDate(createdOn, "ERROR LOADING DATE");
  const lastEditedOnDate = GetDate(editedOn, "ERROR LOADING DATE");
  const deliveryDate = GetDate(
    deliveryActual || deliveryDesired,
    "TBD",
    dateOptionsShortVerbose
  );
  const pickupDate = GetDate(
    pickupActual || pickupDesired,
    "TBD",
    dateOptionsShortVerbose
  );
  const fileNumberText = fileNumber
    ? `HS-${fileNumber}`
    : `SP-${stagingBuilderID}`;

  const [StagingStatus, setStagingStatus] = useState(
    ParseStatus(stagingStatus, businessID, fileNumber)
  );

  useEffect(() => {
    setStagingStatus(ParseStatus(stagingStatus, businessID, fileNumber));
  }, [stagingStatus, businessID, fileNumber]);

  const [itemStatus, setItemStatus] = useState(
    ParseStatusAsItem(stagingStatus)
  );

  useEffect(() => {
    setStagingStatus(ParseStatus(stagingStatus, businessID, fileNumber));
    setItemStatus(ParseStatusAsItem(stagingStatus));
  }, [stagingStatus, businessID, fileNumber]);

  const [printMenuOpen, setPrintMenuOpen] = useState(false);
  const [optionsMenuOpen, setOptionsMenuOpen] = useState(false);

  /**
   * @todo
   * 1) This should open up a small modal that has the important stuff needed to fill out
   */
  const handleSubmit = () => {
    const response = window.confirm(
      "Submit this StagingBuilder for confirmation?"
    );
    if (response)
      dispatch(
        socketStatusChange({
          payload: {
            stagingBuilderID,
            status: StagingBuilderStatuses.PROPOSAL_APPROVAL_REQUEST,
            currentStagingStatus: StagingBuilderStatuses.PROPOSAL_RELEASE,
          },
        })
      );
  };

  const stagingBuilderHeaderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const calcHeaderHeight = () => {
      setHeaderHeight(
        stagingBuilderHeaderRef?.current?.getBoundingClientRect().bottom || 0
      );
    };
    document.addEventListener("scroll", calcHeaderHeight);
    return () => {
      document.removeEventListener("scroll", calcHeaderHeight);
    };
  }, []);

  return (
    <div
      id="stagingbuilder-header-target"
      className="block top-px z-7 bg-white pb-1 max-w-inherit shadow mt-0 lg:mt-12"
      style={{ outline: "solid 2px white" }}
    >
      <StagingBuilderHeaderContainer
        ref={stagingBuilderHeaderRef}
        className="z-10 lg:fixed lg:h-[58px] top-px max-w-inherit lg:w-[calc(calc(100%-200px)/2)]"
      >
        {edit ? (
          <button
            className="btn btn-staging-builder p-2"
            onClick={() =>
              dispatch(push("/stage?open-modal=staging-information"))
            }
            type="button"
          >
            Staging Info
          </button>
        ) : (
          <button
            className="btn btn-staging-builder p-2"
            onClick={() => dispatch(push("/stage"))}
            type="button"
          >
            Edit
          </button>
        )}
        <div
          className="btn btn-staging-builder p-2 relative"
          onClick={() => setPrintMenuOpen(true)}
          onMouseOver={() => setPrintMenuOpen(true)}
          onMouseLeave={() => setPrintMenuOpen(false)}
        >
          <span>
            Print <FiPrinter className="inline-block leading-8" />{" "}
          </span>
          {printMenuOpen ? (
            <PrintDropDown setPrintMenuOpen={setPrintMenuOpen} />
          ) : null}
        </div>
        <GenericHoldTagsGenerator />
        <button
          onClick={() => stagingBuilderRefresh(stagingBuilderID)}
          className={
            "btn btn-staging-builder p-2 " +
            (shouldRefresh ? "" : "btn-disabled")
          }
          type="button"
          disabled={!shouldRefresh}
        >
          Refresh
        </button>
        {edit ? (
          <>
            <div
              className="btn btn-staging-builder p-2 relative"
              onMouseOver={() => setOptionsMenuOpen(true)}
              onMouseLeave={() => setOptionsMenuOpen(false)}
              onClick={() => setOptionsMenuOpen((cur) => !cur)}
            >
              <span>
                Options <FaRegCheckCircle className="inline-block leading-8" />{" "}
              </span>
              {optionsMenuOpen && <Options />}
            </div>
            {stagingStatus === StagingBuilderStatuses.PROPOSAL_RELEASE ? (
              <button
                onClick={() => handleSubmit()}
                className="btn bg-green-700 text-white hover:bg-logo-blue hover:text-black"
                type="button"
                disabled={
                  stagingStatus !== StagingBuilderStatuses.PROPOSAL_RELEASE
                }
              >
                Submit Staging
              </button>
            ) : (
              <div className="hidden lg:block"></div>
            )}
            <div
              onClick={() =>
                handleFileNumberClick(dispatch, {
                  businessID,
                  fileNumber,
                  stagingStatus,
                })
              }
              className="btn btn-staging-builder p-2"
              style={{
                backgroundColor: "var(--heading-light)",
                color: "#000",
                cursor: businessID === 1 ? "pointer" : "default",
              }}
            >
              <p style={{ fontWeight: 700 }}>
                File #:{" "}
                <span style={{ fontWeight: "normal" }}>{fileNumberText}</span>
              </p>
            </div>
          </>
        ) : (
          <>
            <div />
            {businessID === 1 ? (
              <Link
                className="btn btn-staging-builder p-2"
                to={{ search: `open-modal=stager-profile&user=${userID}` }}
              >
                Stager Profile
              </Link>
            ) : (
              <div />
            )}
            <div
              className="btn btn-staging-builder p-2"
              style={{
                backgroundColor: "var(--heading-light)",
                color: "#000",
                cursor: "default",
              }}
            >
              <p style={{ fontWeight: 700 }}>
                File #:{" "}
                <span style={{ fontWeight: "normal" }}>{fileNumberText}</span>
              </p>
            </div>
          </>
        )}
      </StagingBuilderHeaderContainer>
      <StagingBuilderHeaderV2 transfer={false} />
      {/* <HeaderGrid className="overflow-x-hidden max-w-inherit">
        <span style={{ fontWeight: 700 }}>Address:</span>
        <AddressLine>
          <span>{address}</span>
          {address && (
            <RiFileCopyLine
              onClick={(e) => {
                const textField = document.createElement("textarea");
                textField.innerText = address;
                document.body.appendChild(textField);
                if (window.navigator.platform === "iPhone") {
                  textField.setSelectionRange(0, 99999);
                } else {
                  textField.select();
                }
                document.execCommand("copy");
                textField.remove();

                toast.success("Address successfully copied", {
                  toastId: "address-copy",
                });
              }}
              className="leading-8 cursor-pointer hidden sm:inline-block"
            />
          )}
        </AddressLine>
        <span style={{ fontWeight: 700 }}>RENT: </span>
        <span className="sup-dollar">{LocaleNumber(monthlyRate, true)}</span>
        <Link to="/stage?open-modal=stager-info">
          <span
            style={{ fontWeight: 700 }}
            className={businessID === 1 && edit ? "pointer text-logo-blue" : ""}
          >
            Stager:
          </span>
        </Link>
        <span>{stagerName || null}</span>
        <span style={{ fontWeight: 700 }}>Client:</span>
        <span>{clientName}</span>
        <span style={{ fontWeight: 700 }}>RETAIL: </span>
        <span className="sup-dollar">{LocaleNumber(retailValue, true)}</span>
        <span style={{ fontWeight: 700 }}>Created On:</span>
        <span>{createdOnDate}</span>
        <span style={{ fontWeight: 700 }}>Last Edited:</span>
        <span>{lastEditedOnDate}</span>
        <span style={{ fontWeight: 700 }}>Status: </span>{" "}
        <span>{StagingStatus}</span>
        <span style={{ fontWeight: 700 }}>Delivery: </span>{" "}
        <span>{deliveryDate}</span>
        <span style={{ fontWeight: 700 }}>Pickup: </span>{" "}
        <span>{pickupDate}</span>
        <span style={{ fontWeight: 700 }}>Items: </span>{" "}
        <span>{itemStatus}</span>
      </HeaderGrid> */}
      {edit && (
        <ContainerMetaButtons
          edit={edit}
          setShowTrash={setShowTrash}
          showTrash={showTrash}
        />
      )}
    </div>
  );
}

export default React.memo(StagingBuilderHeader);
