export const valleyCities = [
  "Apache Junction",
  "Avondale",
  "Buckeye",
  "Carefree",
  "Cave Creek",
  "Chandler",
  "El Mirage",
  "Fountain Hills",
  "Gilbert",
  "Glendale",
  "Goodyear",
  "Guadalupe",
  "Laveen",
  "Litchfield Park",
  "Mesa",
  "Paradise Valley",
  "Peoria",
  "Phoenix",
  "Queen Creek",
  "Scottsdale",
  "Sun City",
  "Sun City West",
  "Surprise",
  "Tempe",
  "Tolleson",
  "Youngtown",
] as const;
