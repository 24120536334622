import React, { useState } from "react";
import { Romaine } from "romaine";
import { CroppingComponent } from "./CroppingComponent";
interface Props {
  picture: string;
  origPicture: string | null;
  updateBlobState: React.Dispatch<React.SetStateAction<Blob | null>>;
  resetPicture: React.Dispatch<React.SetStateAction<string | undefined>>;
  setFileChanged: React.Dispatch<React.SetStateAction<boolean>>;
  onRequestClose: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PerspectiveLoader = (props: Props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const onLoaded = () => {
    setIsLoaded(true);
  };

  return (
    <Romaine onLoad={onLoaded}>
      <CroppingComponent
        isLoaded={isLoaded}
        setIsLoaded={setIsLoaded}
        {...props}
      />
    </Romaine>
  );
};
