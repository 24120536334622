import { useState, useEffect, FC, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { FieldArrayRenderProps } from "formik";
import { Droppable } from "react-beautiful-dnd";
import { StagingBuilderItemContainerHeader } from "./ItemContainers/StagingBuilderItemContainerHeader";
import { StagingBuilderItem } from "./StagingItem/StagingBuilderItem";

import "./StagingBuilderRoom.scss";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Istate } from "../../../redux";
import { updateBuilderInventoryColors } from "../../../redux/inventory/inventoryActions";
import { ToastyRed } from "../../constants";

const StagingRoomContainer = styled.div`
  max-width: inherit;
  border-radius: 2px;
  min-height: 71px;
`;

export const ContainerItemWrapper = styled.div.attrs((props) => ({
  ...props,
  className:
    props.className || "grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 ",
}))`
  max-width: inherit;
  margin: 8px;
  border-radius: 2px;
  min-height: 71px;
`;
const PlaceHolder = styled.div`
  position: relative;
  place-items: center;
  margin: 0;
  width: 100%;
  padding-top: 100%;
  outline: 1px solid lightgrey;
  min-height: 80px;
`;

interface Iprops {
  index: number;
  arrayHelpers: FieldArrayRenderProps;
  trash: boolean;
  setShowTrash?: Dispatch<SetStateAction<boolean>>;
  edit: boolean; // are we in edit mode
  headerHeight: number;
}
const StagingBuilderRoom: FC<Iprops> = ({
  arrayHelpers,
  index,
  trash,
  edit,
  headerHeight,
}) => {
  const dispatch = useDispatch();
  const { itemContainerID, locked, containerName } = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex]
        .itemContainers[index],
    shallowEqual
  );

  const containerItemIds = useSelector((state: Istate) =>
    state.data.stagingBuilders[
      state.data.user.activeStagingBuilderIndex
    ].itemContainers[index].items.map((x) => x.containerItemID)
  );

  const [ignoreLoad, setIgnoreLoad] = useState(false);

  const { length: containerItemsLength } = containerItemIds;
  useEffect(() => {
    if (ignoreLoad) dispatch(updateBuilderInventoryColors());
    else if (containerItemsLength !== 0) setIgnoreLoad(true);
  }, [containerItemsLength, dispatch]);
  const [shouldWarn, setShouldWarn] = useState(false);
  const [hasNotWarned, setHasNotWarned] = useState(true);
  useEffect(() => {
    if (shouldWarn && hasNotWarned) {
      setHasNotWarned(false);
      ToastyRed(
        `Items in ${containerName} require attention. One or more of the items does not have your requested quantity available anymore.`,
        { autoClose: 20000, toastId: itemContainerID }
      );
    }
  }, [shouldWarn]);
  return (
    <StagingRoomContainer
      id={`staging-builder-room-${itemContainerID}`}
      className="relative"
    >
      <StagingBuilderItemContainerHeader
        locked={locked}
        arrayHelpers={arrayHelpers}
        index={index}
        trash={trash}
        edit={edit}
        headerHeight={headerHeight}
      />
      {trash || locked ? (
        <ContainerItemWrapper>
          {containerItemIds.map((containerItemID, i) => (
            <StagingBuilderItem
              key={containerItemID}
              containerItemIndex={i}
              itemContainerIndex={index}
              locked={locked}
              edit={edit}
            />
          ))}
        </ContainerItemWrapper>
      ) : (
        <Droppable droppableId={`${itemContainerID}`}>
          {(provided) => (
            <ContainerItemWrapper
              id={`droppable-item-container-${itemContainerID}`}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <PlaceHolder
                className="hidden lg:grid"
                {...provided.droppableProps}
              >
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    position: "absolute",
                    top: "calc(50% - 2ch)",
                    left: "0",
                    bottom: "0",
                    right: "0",
                  }}
                >
                  <div className="absolute">{provided.placeholder}</div>
                  <p className="absolute left-0 right-0 text-center">
                    Drag Picture Here
                    <br />
                    To Add Item
                  </p>
                </div>
              </PlaceHolder>
              {containerItemIds.map((containerItemID, i) => (
                <StagingBuilderItem
                  key={containerItemID}
                  containerItemIndex={i}
                  itemContainerIndex={index}
                  locked={locked}
                  edit={edit}
                  shouldWarn={shouldWarn}
                  setShouldWarn={setShouldWarn}
                />
              ))}
            </ContainerItemWrapper>
          )}
        </Droppable>
      )}
    </StagingRoomContainer>
  );
};

export default StagingBuilderRoom;
