import React from "react";
import { BasicModal } from "../../../Modals/BasicModal";
import { PerspectiveLoader } from "./Perspective";

interface Props {
  picture: string;
  origPicture: string;
  updateBlobState: React.Dispatch<React.SetStateAction<Blob | null>>;
  resetPicture: React.Dispatch<React.SetStateAction<string | undefined>>;
  setFileChanged: React.Dispatch<React.SetStateAction<boolean>>;
  modalIsOpen: boolean;
  onRequestClose: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CropperModal = ({ modalIsOpen, ...props }: Props) => {
  return (
    <BasicModal
      modalStyle={{ top: "10vh", bottom: "10vh", right: "27vw", left: "27vw" }}
      open={modalIsOpen}
      setOpen={() => {
        props.onRequestClose((current) => !current);
      }}
    >
      <PerspectiveLoader {...props} />
    </BasicModal>
  );
};
