import { push } from "connected-react-router";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../Accounts/Permissions";

interface Props {
  userID: number;
}

/**
 * A globally accessible modal that gives stager information when using the query params
 *
 * @param user as userID
 * @param `open-modal` "stager-profile"
 * @todo
 * 1) Need to get way more information about the stager.
 * 2) should probably set up an api for this using sockets
 * 3) Implement this with business maybe?
 *
 */
export const StagerProfile: React.FC<Props> = ({ userID }) => {
  const dispatch = useDispatch();
  const [stagerInfo, setStagerInfo] = useState<USER_MIN[]>([]);

  // const { socket } = useSelector((state: Istate) => state.data);
  useEffect(() => {
    getUsers(dispatch, setStagerInfo, { employees: 1, userID });
    // need to create a pretty ui
  }, [userID]);

  const userInformation: USER_MIN | undefined = { ...stagerInfo[0] };

  return (
    userInformation && (
      <div>
        <h1 className="text-2xl">Stager Profile</h1>
        <h2 className="text-xl">
          {userInformation.first} {userInformation.last}
        </h2>
        <h3>Email: {userInformation.email}</h3>
        <h3>Phone: {userInformation.phone}</h3>
      </div>
    )
  );
};
