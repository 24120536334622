import { Field } from "formik";
import React from "react";
import { titleCase } from "../constants";

interface RadioButtonProps {
  id: string;
  label?: React.ReactNode;
  className?: string;
  name: string;
  value?: string;
}
/**
 * A formik component for radio buttons
 *
 * Value will default to the id
 *
 * label will default to titleCase(id)
 */
export const RadioButton = ({
  id,
  label,
  className,
  ...props
}: RadioButtonProps) => {
  return (
    <div>
      <label htmlFor={id}>
        <Field
          id={id}
          type="radio"
          value={id} // could be something else for output?
          className={className}
          {...props}
        />
        {label ?? titleCase(id)}
      </label>
    </div>
  );
};
