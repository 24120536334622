import { ContainerItem } from "../../stagingBuilder/stagingBuilderTypes";

export const updateBuilderInventoryItem = (
  builderItem: ContainerItem
): InventoryReducer => {
  return {
    type: "UPDATE_BUILDER_ITEM",
    payload: builderItem,
  };
};
