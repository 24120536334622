import { useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { newContainerItem } from "../../../redux/stagingBuilder/actions/containerItems/NEW";
import { hrefPrefix } from "../../constants";
import { handleAddItemToStickerQueue } from "../../HighlyReusableComponents/SocketEmitters/handleAddItemToStickerQueue";

interface Props {
  itemID: number;
  itemName: string;
  itemNo: string;
}

export const RowContextMenu = ({ itemName, itemNo, itemID }: Props) => {
  const dispatch = useDispatch();
  const stagingBuilder = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex],
    shallowEqual
  );
  const socket = useSelector(
    (state: Istate) => state.data.socket,
    shallowEqual
  );
  const handleAddItemToStagingBuilder = (
    itemContainerID: number = stagingBuilder.itemContainers[1]?.itemContainerID
  ) => {
    if (stagingBuilder !== undefined)
      dispatch(
        newContainerItem({
          itemID: itemID,
          itemContainerID: itemContainerID,
          stagingBuilderID: stagingBuilder.stagingBuilderID,
        })
      );
  };

  const [showItemContainers, setShowItemContainers] = useState(false);

  const hrefURL1 = `${hrefPrefix}/api/print/single-tag?itemNo=${itemNo}&printQuantity=4&tagStyle=4&tagSize=1`;
  const stagingBuilderIdentifier = stagingBuilder?.fileNumber
    ? `HS - ${stagingBuilder?.fileNumber}`
    : `SP - ${stagingBuilder?.stagingBuilderID}`;

  const closeSubMenus = () => {
    showItemContainers && setShowItemContainers(false);
  };
  return (
    <ul>
      <li className="pl-5">{itemName}</li>
      <li>
        <ul>
          {stagingBuilder !== undefined ? (
            <li
              className="relative"
              onMouseOver={() => setShowItemContainers(true)}
              onMouseLeave={() => setShowItemContainers(false)}
            >
              <button
                onClick={(e) =>
                  e.target === e.currentTarget &&
                  handleAddItemToStagingBuilder()
                }
              >
                Add this item to {stagingBuilderIdentifier}
              </button>
              {showItemContainers && (
                <div
                  onMouseLeave={(e) => e.stopPropagation()}
                  className="absolute top left-full"
                  style={{ width: "250px" }}
                >
                  <ul>
                    {stagingBuilder.itemContainers
                      .filter(
                        ({ containerType, locked }) => containerType && !locked
                      )
                      .map((itemContainer) => (
                        <li>
                          <button
                            onClick={() => {
                              handleAddItemToStagingBuilder(
                                itemContainer.itemContainerID
                              );
                            }}
                          >
                            {itemContainer.containerName}
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
              )}
            </li>
          ) : (
            <li style={{ cursor: "not-allowed" }}>
              <span>
                Select a StagingBuilder in Home to add item to it from here
              </span>
            </li>
          )}
          <li onMouseOver={closeSubMenus}>
            <a href={hrefURL1} target="_blank" rel="noopener noreferrer">
              Print StagingRents Tags for this Item (4)
            </a>
          </li>
          <li onMouseOver={closeSubMenus}>
            <button
              onClick={() =>
                handleAddItemToStickerQueue(socket, itemNo, {
                  auto: false,
                  normalTag: false,
                  stickerTag: true,
                })
              }
              className="block"
            >
              Add to Sticker Print Queue
            </button>
          </li>
        </ul>
      </li>
    </ul>
  );
};
