import styled from "styled-components";
export const Wrapper = styled.div`
  position: relative;
  display: grid;
  @media only screen and (min-width: 640px) {
    display: none;
  }
  height: var(--header-height);
  background-color: var(--logo-blue);
  grid-column: 1/-1;
  grid-template-columns: auto var(--header-height);
`;
