/**
 * Converts the number into a local string (adds comma) will also add decimal if needed (optional)
 * @param x the number to localize
 * @param decimal default false
 * @returns string of number
 */
export const LocaleNumber = (
  x: string | number | undefined = undefined,
  decimal: boolean = false
) =>
  decimal
    ? (x ? Number(x?.toString().split(".")[0]).toLocaleString("en-us") : "0") +
      "." +
      (x?.toString().split(".")[1] !== undefined
        ? x?.toString().split(".")[1][0] +
          (x?.toString().split(".")[1][1] || "0")
        : "00")
    : Number(x?.toString().split(".")[0]).toLocaleString("en-us");
