import React from "react";
import { MHFOTagStandard } from "./MHFOTagStandard";
import { StagingRentsSticker } from "./StagingRentsSticker";
import { StagingRentsTag } from "./StagingRentsTag";
interface Props {
  tagInfo: {
    tagStyle: number;
    size: number;
  };
  setTagErrors: React.Dispatch<React.SetStateAction<boolean>>;
}

const TagPreview = ({ tagInfo, setTagErrors }: Props) => {
  setTagErrors(false);
  switch (tagInfo.tagStyle) {
    case 1:
      return (
        <div className="hundred-hundred grid grid__center">
          <MHFOTagStandard setTagErrors={setTagErrors} />{" "}
        </div>
      );
    case 4:
      return (
        <div className="hundred-hundred grid grid__center">
          <StagingRentsTag setTagErrors={setTagErrors} />
        </div>
      );
    case 7:
      return (
        <div className="hundred-hundred grid grid__center">
          <StagingRentsSticker setTagErrors={setTagErrors} />
        </div>
      );
    default:
      return <>Error</>;
  }
};
export default TagPreview;
