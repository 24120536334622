import React from "react";
import { SetFilters } from "../ProposalSearchHeader";

interface Props {
  filters: SetFilters;
}

export const FileContextMenu = ({ filters }: Props) => {
  return (
    <ul>
      <li className="pl-5">Filter Options - Home Stagings</li>
      <li>
        <ul>
          <li
            onClick={() => {
              filters.setShowHomeStagings(1);
              filters.setShowProposals(0);
            }}
          >
            Hide Staging Proposals
          </li>
          <li
            onClick={() => {
              filters.setShowProposals(1);
              filters.setShowHomeStagings(0);
            }}
          >
            Hide Home Stagings
          </li>
        </ul>
      </li>
      <li>
        <ul>
          <li
            onClick={() => {
              filters.setShowProposals(1);
              filters.setShowHomeStagings(1);
            }}
          >
            Clear These Filters
          </li>
        </ul>
      </li>
    </ul>
  );
};
interface Props2 {
  setFilter: (updater: any) => void;
}
export const FileContextMenu2 = ({ setFilter }: Props2) => {
  return (
    <ul>
      <li className="pl-5">Filter Options - Home Stagings</li>
      <li>
        <ul>
          <li
            onClick={() => {
              setFilter(8318);

              // filters.setShowHomeStagings(1);
              // filters.setShowProposals(0);
            }}
          >
            Hide Staging Proposals
          </li>
          <li
            onClick={() => {
              // filters.setShowProposals(1);
              // filters.setShowHomeStagings(0);
            }}
          >
            Hide Home Stagings
          </li>
        </ul>
      </li>
      <li>
        <ul>
          <li
            onClick={() => {
              // filters.setShowProposals(1);
              // filters.setShowHomeStagings(1);
            }}
          >
            Clear These Filters
          </li>
        </ul>
      </li>
    </ul>
  );
};
