import React from "react";
import { StagingBuilderStatuses } from "../../../constants";
import { SetFilters } from "../ProposalSearchHeader";

interface Props {
  filters?: SetFilters;
  setFilter: (updater: any) => void;
}

export const StatusContextMenu = ({ setFilter }: Props) => {
  return (
    <ul>
      <li className="pl-5 pointer-events-none">Filter Options - Status</li>
      <li>
        <ul>
          <li>
            <button
              onClick={() => {
                setFilter([
                  StagingBuilderStatuses.STAGED,
                  StagingBuilderStatuses.PICKUP_REQUEST,
                  StagingBuilderStatuses.PICKUP_SCHEDULED,
                ]);
              }}
            >
              Only Show "Staged"
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                setFilter([
                  StagingBuilderStatuses.PICKUP_REQUEST,
                  StagingBuilderStatuses.PICKUP_SCHEDULED,
                ]);
              }}
            >
              Only Show "To Be Picked Up"
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                setFilter([
                  StagingBuilderStatuses.SCHEDULED_AND_TAGGED,
                  StagingBuilderStatuses.PAID,
                ]);
              }}
            >
              Only Show "To Be Delivered"
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                setFilter([
                  StagingBuilderStatuses.LIMBO_HOLD,
                  StagingBuilderStatuses.LIMBO_RELEASE,
                  StagingBuilderStatuses.LIMBO_REQUEST_HOLD,
                  StagingBuilderStatuses.LIMBO_REQUEST,
                ]);
              }}
            >
              Only Show "In Limbo"
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                setFilter([
                  StagingBuilderStatuses.PICKUP_REQUEST,
                  StagingBuilderStatuses.LIMBO_REQUEST_HOLD,
                  StagingBuilderStatuses.LIMBO_REQUEST,
                  StagingBuilderStatuses.PROPOSAL_HOLD_REQUEST,
                  StagingBuilderStatuses.DEAL_FELL_THROUGH_REQUEST,
                  StagingBuilderStatuses.PROPOSAL_APPROVAL_REQUEST,
                  StagingBuilderStatuses.PROPOSAL_APPROVAL_REQUEST_HOLD,
                ]);
              }}
            >
              Only Show "Open Requests"
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                setFilter([
                  StagingBuilderStatuses.PROPOSAL_HOLD_CUSTOMER,
                  StagingBuilderStatuses.PROPOSAL_HOLD_EMPLOYEE,
                  StagingBuilderStatuses.PROPOSAL_APPROVAL_REQUEST_HOLD,
                  StagingBuilderStatuses.SCHEDULED_AND_TAGGED,
                  StagingBuilderStatuses.PAID,
                  StagingBuilderStatuses.APPROVAL_PROCESS_STARTED,
                  StagingBuilderStatuses.LIMBO_HOLD,
                  StagingBuilderStatuses.LIMBO_REQUEST_HOLD,
                ]);
              }}
            >
              Only Show "On Hold"
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                setFilter([
                  StagingBuilderStatuses.DEAL_FELL_THROUGH,
                  StagingBuilderStatuses.PROPOSAL_FELL_THROUGH,
                  StagingBuilderStatuses.CLOSED,
                ]);
              }}
            >
              Only Show "Closed"
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                setFilter([
                  StagingBuilderStatuses.NEW_CUSTOMER,
                  StagingBuilderStatuses.PROPOSAL_RELEASE,
                  StagingBuilderStatuses.PROPOSAL_HOLD_REQUEST,
                  StagingBuilderStatuses.PROPOSAL_APPROVAL_REQUEST,
                  StagingBuilderStatuses.PROPOSAL_APPROVAL_REQUEST_HOLD,
                ]);
              }}
            >
              Only Show "New StagingBuilders"
            </button>
          </li>
        </ul>
      </li>
      <li>
        <ul>
          <li>
            <button
              onClick={() => {
                setFilter(undefined);
              }}
            >
              Clear These Filters
            </button>
          </li>
        </ul>
      </li>
    </ul>
  );
};
