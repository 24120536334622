import React from "react";
import { StagingBuilderContainerItemsSocket } from "./Listeners/StagingBuilderContainerItemsSocket";
import { StagingBuilderNotesSocket } from "./Listeners/StagingBuilderNotesSocket";

export const StagingBuilderSocketController = () => {
  return (
    <>
      <StagingBuilderNotesSocket />
      <StagingBuilderContainerItemsSocket />
    </>
  );
};
