import { combineReducers } from "redux";
import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";
import { ThunkDispatch } from "redux-thunk";
import reduceReducers from "reduce-reducers";
import SystemState, { initialReduxState } from "./initialReduxState";
import stagingBuilderReducer from "./stagingBuilder/stagingBuilderReducer";
import userReducer from "./users/userReducer";
import inventoryReducer from "./inventory/inventoryReducer";
import routeReducer from "./route/routeReducer";

import store from "./store";

interface NewAction {
  action: string;
  payload?: any;
}
// had to edit node_modules/reduce-reducers/index.d.ts
export const reducedReducer = reduceReducers<SystemState, NewAction>(
  initialReduxState,
  stagingBuilderReducer,
  userReducer,
  inventoryReducer,
  routeReducer
);
export const createRootReducer = (history: History<unknown>) =>
  combineReducers({
    router: connectRouter(history),
    data: reducedReducer,
  });

export const rootReducer = combineReducers({
  data: reducedReducer,
  router: connectRouter,
});
export type RootState = ReturnType<typeof rootReducer>;

export interface Istate {
  router: RouterState<unknown>;
  data: SystemState;
}
declare global {
  interface Istate {
    router: RouterState<unknown>;
    data: SystemState;
  }
}

export {
  createAccount,
  logInAxios,
  fetchingData,
  fetchingDataSuccess,
  fetchingDataFailure,
  redirectSuccess,
  updateAccountInfo,
} from "./users/userActions";
export {
  fetchStagingBuilders,
  newStagingBuilder,
  fetchFullStagingBuilder,
  addNewItemContainer,
  updateItemContainerNames,
  removeItemFromItemContainer,
  removeItemContainer,
} from "./stagingBuilder/stagingBuilderActions";
export {
  clearInventory,
  fetchInventoryVerbose,
  fetchInventoryMin,
  updateBuilderInventoryOrder,
  resetActiveItem,
  setActiveItem,
  handleInventorySearch,
  postNewPictures,
  handleInventorySubmit,
  handleInventoryUpdate,
  startNewItem,
  handleInventoryEmployeeScan,
  favoriteItem,
} from "./inventory/inventoryActions";
export {} from "./stagingBuilder/actions/";
export {
  getEmployeeScannerPermission,
  setRoutePayload,
  scannerResStatus,
} from "./route/routeActions";

export type AppThunk = ThunkDispatch<{}, {}, any>;
export type AppDispatch = typeof store.dispatch;
