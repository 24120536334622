import React from "react";
import { FormikInit } from "./form/FormikInit";

interface Props {
  user: USER_PERMISSION;
}

export const UserInfo = ({ user }: Props) => {
  const fontSize = "1.5em";
  return (
    <>
      <div style={{ fontSize }} className="flex space-between">
        <span>First</span>
        <span>{user.first}</span>
      </div>
      <div style={{ fontSize }} className="flex space-between">
        <span>Last</span>
        <span>{user.last}</span>
      </div>
      <div style={{ fontSize }} className="flex space-between">
        <span>Business Name</span>
        <span>{user.businessID}</span>
      </div>
      <FormikInit user={user} />
    </>
  );
};
