import axios from "axios";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppThunk, fetchingDataFailure } from "../../../redux";
import { ChooseUser } from "./ChooseUser";
import { UserInfo } from "./UserInfo";

export const Permissions = () => {
  const dispatch: AppThunk = useDispatch();
  const [users, serUsers] = useState<USER_MIN[]>([]);
  const [userChosen, setUserChosen] = useState<USER_PERMISSION>({
    userID: 0,
    email: "Choose:",
  });
  useEffect(() => {
    getUsers(dispatch, serUsers);
  }, [dispatch]);
  return (
    <div style={{ minHeight: "710px" }}>
      <h2>User</h2>
      <ChooseUser users={users} setUserChosen={setUserChosen} />
      {userChosen.userID !== 0 ? <UserInfo user={userChosen} /> : null}
    </div>
  );
};

export const getUsers = (
  dispatch: AppThunk,
  serUsers: Dispatch<SetStateAction<USER_MIN[]>>,
  options: {
    employees?: 0 | 1;
    userID?: number;
  } = { employees: 1 }
) => {
  axios
    .get(
      `/api/effects/get/users?employees=${options.employees}${
        options.userID ? `&userID=${options.userID}` : ""
      }`
    )
    .then((res) => {
      serUsers(res.data.users);
    })
    .catch((err) => {
      dispatch(fetchingDataFailure(err.response.status));
    });
};
