import React from "react";
import { Romaine } from "romaine";
import { RomaineCanvas } from "./CropperCanvas";
interface Props {
  setIsLoaded: React.Dispatch<boolean>;
  isLoaded: boolean;
  picture: string;
  origPicture: string | null;
  updateBlobState: React.Dispatch<React.SetStateAction<Blob | null>>;
  resetPicture: React.Dispatch<React.SetStateAction<string | undefined>>;
  setFileChanged: React.Dispatch<React.SetStateAction<boolean>>;
  onRequestClose: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CroppingComponent = ({
  setIsLoaded,
  isLoaded,
  origPicture,
  updateBlobState,
  setFileChanged,
  onRequestClose,
}: Props) => {
  const setBlobStuff = (blob: Blob | null) => {
    setFileChanged(true);
    updateBlobState(blob);
    onRequestClose((current) => !current);
  };
  return (
    // <Romaine>
    <RomaineCanvas
      imageExportOptions={{
        type: "image/jpeg",
        quality: 0.9,
        //@ts-ignore
        jpeg: {
          transparentToWhite: true,
        },
      }}
      setBlob={setBlobStuff}
      image={origPicture}
    />
    // </Romaine>
  );
};
