import React from "react";
import { PrintInfo, PrintOptions } from "./";
import TagPreview from "./Preview/";
import styled from "styled-components";
import { TagStyles } from "../../../constants/enums/TagStyles";

const FityFity = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 100%;
  min-width: 100%;
`;
export const ModalContainer = () => {
  const [tagInfo, setTagInfo] = React.useState<{
    tagStyle: TagStyles;
    size: number;
  }>({
    tagStyle: TagStyles.STAGING_STANDARD,
    size: 1,
  });
  const [tagErrors, setTagErrors] = React.useState(false);

  return (
    <FityFity>
      <div>
        <PrintInfo />
        <PrintOptions
          tagInfo={tagInfo}
          setTagInfo={setTagInfo}
          tagErrors={tagErrors}
        />
      </div>
      <div>
        <TagPreview tagInfo={tagInfo} setTagErrors={setTagErrors} />
      </div>
    </FityFity>
  );
};
