import { DraggableInventoryHeader } from "./DraggableInventoryHeader";
import { DraggableInventoryMap } from "./DraggableInventoryMap";
import Search from "./Search";

export const DraggableInventoryContainer = () => {
  return (
    <div className="simple-border flex column draggable-inventory-container relative">
      <Search />
      <DraggableInventoryHeader />
      <DraggableInventoryMap />
    </div>
  );
};
