import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Modal from "react-modal";
import { useFormikContext } from "formik";
import FormikControl from "../../../FormikComponents/FormikControl";
import { useSelector, shallowEqual } from "react-redux";
import { Istate } from "../../../../redux";
import { ItemsInOtherGroups } from "./ItemsInOtherGroups";
import { CustomButton } from "../../../HighlyReusableComponents/CustomButton";

interface Props {
  groupModalOpen: boolean;
  setGroupModalOpen: Dispatch<SetStateAction<boolean>>;
}
type items = string[];
const GroupReducer = (
  groups: { [groupIndex: string]: items },
  item: itemVerbose
) => {
  // this gets all groups, not just the ones it is in
  item.itemGroups.forEach((groupNo) => {
    if (groupNo.toString() === "") return;
    if (groups[groupNo.toString()] === undefined)
      groups[groupNo.toString()] = [];
    groups[groupNo.toString()].push(item.itemID.toString());
  });
  return groups;
};
export const GroupModal = ({ groupModalOpen, setGroupModalOpen }: Props) => {
  const ctx = useFormikContext<itemVerbose>();
  // Inventory is to fill local state
  const Inventory = useSelector(
    (state: Istate) => state.data.inventory,
    shallowEqual
  );
  const [groups, setGroups] = useState(Inventory.reduce(GroupReducer, {}));
  const [groupsArray, setGroupsArray] = useState<{
    [index: string]: number[];
  }>({});
  useEffect(() => {
    setGroups(Inventory.reduce(GroupReducer, {}));
    let tempGroupsArray: { [index: string]: number[] } = {};
    Inventory.forEach(({ itemID, itemGroups }, index) => {
      itemGroups.forEach((stringyIndex: string) => {
        if (stringyIndex === "") return;
        if (tempGroupsArray[stringyIndex] === undefined)
          tempGroupsArray[stringyIndex] = [];
        tempGroupsArray[stringyIndex].push(itemID);
      });
    });
    setGroupsArray(tempGroupsArray);
  }, [Inventory]);
  // Next Key stuff
  const [nextKey, setNextKey] = useState("0");
  const [groupKeys, setGroupKeys] = useState(Object.keys(groups));
  useEffect(() => {
    setGroupKeys(Object.keys(groups));
  }, [groups]);
  useEffect(() => {
    if (groupKeys.length !== 0)
      setNextKey(findFirstEmptyGroup(groups, groupKeys.length));
    else setNextKey("1"); // this is so that the initial group isn't 0
  }, [groupKeys]);
  const handleNewKey = () => {
    if (ctx.values.itemGroups[0].toString() !== "")
      ctx.setFieldValue(
        "itemGroups",
        Array.from(new Set([...ctx.values.itemGroups, nextKey]))
      );
    // ctx.setFieldValue("itemGroups", [...ctx.values.itemGroups, nextKey]);
    else ctx.setFieldValue("itemGroups", [nextKey]);
    if (ctx.values.itemID !== undefined)
      setGroups((current) => {
        return {
          ...current,
          [nextKey]: [ctx.values.itemID.toString()],
        };
      });
    else {
      // This SHOULD only run for new items that don't have an itemID yet
      // Ironically this may be a better way to do this...
      // However, it might be more error prone

      setGroupKeys(Array.from(new Set([...Object.keys(groups), nextKey])));
    }
  };
  // multi-select options
  const [groupOptions, setGroupOptions] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  useEffect(() => {
    const GO = groupKeys.map((group) => {
      return {
        value: group,
        label: group,
      };
    });
    setGroupOptions(GO);
  }, [groupKeys]);

  // need to add effect for deleting a group from the multi select
  // this could cause the group to become empty!

  return (
    <Modal
      isOpen={groupModalOpen}
      onRequestClose={() => setGroupModalOpen(false)}
      style={{ content: { inset: "150px 400px" } }}
    >
      <div className="grid col-3 gap-1 hundred-hundred">
        <div className="hundred-hundred">
          <h1 className="text-center text-xl">Add Item to Group</h1>
          <FormikControl
            control="multi-select"
            label="Choose Groups for this Item"
            name="itemGroups"
            menuPlacement="bottom"
            options={groupOptions}
          />
          <CustomButton onClick={() => handleNewKey()}>
            Add Item to NEW Group
          </CustomButton>
          <h1 className="text-center text-xl">Reference a Group</h1>
          <FormikControl
            control="multi-select"
            label="Choose References for this Item"
            name="itemReferences"
            menuPlacement="bottom"
            options={groupOptions}
          />
          <br />
          <h2 className="text-lg">What is the difference?</h2>
          <dl>
            <dt>Group</dt>
            <dd>
              Adding an item to a group will place the item inside the group.
              This means that the item will show up for all items that reference
              the group.
            </dd>
            <dt>Reference</dt>
            <dd>
              Referencing a group will show all the items of the group when in a
              StagingBuilder; however, it will not be seen by other items in the
              group.
            </dd>
          </dl>
        </div>
        <div className="overflowY-scroll" style={{ gridColumn: "2/4" }}>
          <h1 className="text-center mb-1">Grouped With This Item</h1>
          <ItemsInOtherGroups
            itemGroups={ctx.values.itemGroups}
            groupsArray={groupsArray}
            setGroupModalOpen={setGroupModalOpen}
          />
          <h1 className="text-center mb-1">References By This Item</h1>
          <ItemsInOtherGroups
            itemGroups={ctx.values.itemReferences}
            groupsArray={groupsArray}
            setGroupModalOpen={setGroupModalOpen}
          />
        </div>
      </div>
    </Modal>
  );
};

const findFirstEmptyGroup = (
  groups: { [groupIndex: string]: items },
  length: number
) => {
  for (let i = 1; i < length + 2; i++) {
    if (groups[i.toString()] === undefined) return i.toString();
  }
  return "0";
};
