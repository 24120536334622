import { useSelector, shallowEqual } from "react-redux";
import { FormLogic } from "./form/FormLogic";
export const MarkTagged = () => {
  const containerItem = useSelector((state: Istate) => {
    let { "container-item": containerItemID } = state.router.location.query as {
      "container-item": string | number;
    };

    if (containerItemID === "") return undefined;
    containerItemID = Number(containerItemID);

    return state.data.stagingBuilders[
      state.data.user.activeStagingBuilderIndex
    ]?.itemContainers
      .find((itemContainer) =>
        itemContainer.items.find(
          (containerItem) => containerItem.containerItemID === containerItemID
        )
      )
      ?.items.find(
        (containerItem) => containerItem.containerItemID === containerItemID
      );
  }, shallowEqual);

  return (
    <div>
      <h1 className="text-2xl">Tag Number(s) if applicable.</h1>
      {containerItem && <FormLogic containerItem={containerItem} />}
    </div>
  );
};
