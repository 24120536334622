import React from "react";
import { MenuLogic } from "./MenuLogic";

interface Props {}

export const Options = (props: Props) => {
  return (
    <div className="absolute sb-dropdown z-6">
      <div>
        <ul>
          <MenuLogic />
        </ul>
      </div>
    </div>
  );
};
