import React from "react";

interface Props {}

export const PrintInfo = ({}: Props) => {
  return (
    <div>
      <h1 className="text-4xl">Tag Printing</h1>
      <p>Tag printing basics:</p>
      <ol className="list-decimal pl-4 max-w-prose">
        <li>Choose the type of tag you want to print.</li>
        <li>Select how many tags you want of that item.</li>
        <ul style={{ listStyle: "initial", paddingLeft: "0.5cm" }}>
          <li>Default for MHFO is 1.</li>
          <li>Default for StagingRents is 8.</li>
        </ul>
        <li>Select print method:</li>
        <ul style={{ listStyle: "initial", paddingLeft: "0.5cm" }}>
          <li>
            Print Queue: (default) add the item to a queue to bulk print
            multiple types of tags at the same time.
          </li>
          <li>Single: Print immediately with the current configuration.</li>
        </ul>
        <li>Important Information:</li>
        <ul style={{ listStyle: "initial", paddingLeft: "0.5cm" }}>
          <li>
            If you are missing key information that is required to print the
            tag, an Error will be shown.
          </li>
          <li>
            If you are missing key information that is required to print the
            tag, The "Print Now" and "Add to Queue" buttons will be disabled.
          </li>
          <li>
            Rowley tags are not currently supported - use the MHFO website for
            them.
          </li>
        </ul>
      </ol>
    </div>
  );
};
