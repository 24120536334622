import React, { useEffect, useState } from "react";
import { useWakeLock } from "../../../util/nav/useWakeLock";

interface Props {
  barcodeRef: React.RefObject<HTMLInputElement>;
}

export const BarcodeInputField = ({ barcodeRef }: Props) => {
  const { status, error, requestLock, releaseLock } = useWakeLock();
  useEffect(() => {
    requestLock();
    return () => {
      releaseLock();
    };
  }, []);

  const [interval, setRefocusInterval] = useState<NodeJS.Timeout>();

  useEffect(() => {
    const refocus = () => {
      barcodeRef.current?.focus();
      setTimeout(() => {
        barcodeRef.current?.focus();
      }, 0);
    };
    const barcodeReference = barcodeRef.current;
    barcodeReference?.addEventListener("blur", refocus);
    barcodeReference?.focus();
    clearInterval(interval);
    const temp = setInterval(() => {
      barcodeReference?.focus();
    }, 3000);
    setRefocusInterval(temp);
    return () => {
      clearInterval(interval);
      barcodeReference?.removeEventListener("blur", refocus);
    };
  }, [barcodeRef]);
  return (
    <label htmlFor="barcode">
      Please Scan a Barcode
      <br />
      <input
        autoFocus
        onBlur={({ target }) => target.focus()}
        ref={barcodeRef}
      />
    </label>
  );
};
