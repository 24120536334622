import type { RefObject } from "react";
import { ScannerForm } from "./ScannerForm";
import { BinningContext, useBinningContext } from "./useBinNumber";

interface Props {
  barcodeRef: RefObject<HTMLInputElement>;
}

export const Scanner = ({ barcodeRef }: Props) => {
  return (
    <BinningContext>
      <ScannerWithContext barcodeRef={barcodeRef} />
    </BinningContext>
  );
};

const ScannerWithContext = ({ barcodeRef }: Props) => {
  const { locationName } = useBinningContext();
  return (
    <div>
      <div style={{ top: "1em", right: "1em" }} className="absolute grid">
        <h1>Scanning Into: {locationName ?? "No Location Selected"}</h1>
      </div>
      <ScannerForm barcodeRef={barcodeRef}></ScannerForm>
    </div>
  );
};
