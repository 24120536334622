import React from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";

interface Props {
  open: boolean;
  setOpen: (path?: string) => void;
  modalStyle?: React.CSSProperties;
  employeeOnly?: boolean;
  children: React.ReactNode;
}

export const BasicModal: React.FC<Props> = ({
  open,
  setOpen,
  children,
  modalStyle = {
    inset: "15vh 30vw",
    height: "",
  },
  employeeOnly,
}) => {
  const shouldRender = useSelector((state: Istate) =>
    employeeOnly ? state.data.user.businessID === 1 : true
  );
  return shouldRender ? (
    <Modal
      style={{ content: modalStyle }}
      isOpen={open}
      onRequestClose={() => setOpen()}
    >
      {children}
    </Modal>
  ) : null;
};
