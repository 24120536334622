import { useEffect, useState } from "react";
import { ImHammer } from "react-icons/im";
import { MobileNav } from "../../../mobile-nav";
import { useNavigation } from "../../../mobile-nav/context/NavigationContext";
import { MegaMenu } from "./MegaMenu";
export type MegaMenuProps =
  | "bedroom"
  | "living"
  | "dining"
  | "office"
  | "entry"
  | "entertainment"
  | "patio";
const mainMenus: MegaMenuProps[] = [
  "bedroom",
  "living",
  "dining",
  "office",
  "entry",
  "entertainment",
  "patio",
];

const Search = () => {
  const [whatIsOpen, setWhatIsOpen] = useState<MegaMenuProps | null>(null);
  const [menuClasses, setMenuClasses] = useState<string[]>(
    new Array(mainMenus.length)
  );
  const handleOpenMenu = (room: MegaMenuProps, type: "hover" | "click") => {
    if (room === whatIsOpen && type === "click") setWhatIsOpen(null);
    else setWhatIsOpen(room);
  };

  useEffect(() => {
    if (whatIsOpen === null) {
      let menuClassesCopy = new Array(mainMenus.length).map(() => "");
      setMenuClasses(menuClassesCopy);
    } else {
      const index = mainMenus.findIndex((title) => title === whatIsOpen);
      if (index !== -1) {
        let menuClassesCopy = new Array(mainMenus.length).map(() => "");
        menuClassesCopy[index] = "bg-back-drop-light";
        setMenuClasses(menuClassesCopy);
      }
    }
  }, [whatIsOpen]);
  const { updateMobileViewSide } = useNavigation();
  return (
    <>
      <MobileNav>
        <p>
          <ImHammer
            cursor="pointer"
            style={{
              height: "36px",
              width: "36px",
              marginLeft: "3px",
              display: "inline",
            }}
            onClick={() => updateMobileViewSide(1)}
          />{" "}
          See StagingBuilder
        </p>
      </MobileNav>
      {whatIsOpen && (
        <div
          onClick={() => setWhatIsOpen(null)}
          className="sm:hidden h-full w-full absolute top bottom right left z-4"
          style={{ backgroundColor: "rgba(255,255,255,.6)" }}
        />
      )}
      <ul
        onMouseLeave={() => setWhatIsOpen(null)}
        className="search-header sm:h-11 bg-white sticky top-px flex flex-wrap sm:flex-nowrap justify-between z-5 min-h-min"
      >
        {mainMenus.map((title, index) => (
          <li
            key={index}
            className={"main " + menuClasses[index] || "white"}
            onMouseOver={() => handleOpenMenu(title, "hover")}
            onClick={() => handleOpenMenu(title, "click")}
            style={{ flex: "1 1 20vw" }}
          >
            {title.toUpperCase()}
          </li>
        ))}
        {whatIsOpen !== null && (
          <MegaMenu setWhatIsOpen={setWhatIsOpen} menu={whatIsOpen} />
        )}
      </ul>
    </>
  );
};
export default Search;
