import React from "react";
import { Field, ErrorMessage, FieldProps } from "formik";
import TextError from "./TextError";
import Select, { OptionsType } from "react-select";
import Creatable from "react-select/creatable";
interface IpropsSelect {
  label: React.ReactNode;
  name: string;
  styledClass: string;
  controlContainerclass?: string;
  options: { value: number; label: string }[];
  disablefield?: number;
  notsearchable?: number;
  default?: { id?: string | number; text: String };
  labelClass?: string;
  creatable?: boolean;
  menuPlacement?: "top" | "auto" | "bottom";
}
const basicSelect: React.FunctionComponent<IpropsSelect> = ({
  label,
  name,
  options,
  styledClass = "form-select mt-1 block w-full",
  disablefield,
  labelClass,
  creatable,
  controlContainerclass,
  ...rest
}) => {
  const disableValue: boolean = disablefield ? true : false;
  return (
    <div className={controlContainerclass}>
      <label
        className={labelClass}
        style={{ alignSelf: "center", justifySelf: "left", display: "block" }}
        htmlFor={name}
      >
        {label}
      </label>
      <Field
        as="select"
        id={name}
        name={name}
        disabled={disableValue}
        {...rest}
        options={options}
        // className={styledClass}
        component={creatable ? CreatableSelect : SingleSelect}
      />
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};
export const BasicSelect = basicSelect;
interface Option {
  label: string; // what the user sees
  value: string; // what the value of the option is (usually the ID from SQL)
}
interface CustomSelectProps extends FieldProps {
  options: OptionsType<Option>;
  menuPlacement?: "top" | "auto" | "bottom";
  disabled?: boolean;
}
const SingleSelect = ({
  field,
  form,
  options,
  disabled,
  ...props
}: CustomSelectProps) => {
  return (
    <div className="multi-select-container" style={{ gridColumn: "2/4" }}>
      <Select
        options={options}
        name={field.name}
        value={options.find((option) => option.value === field.value)}
        onChange={(option) => {
          form.setFieldValue(field.name, (option as any)?.value);
        }}
        menuPlacement={props.menuPlacement}
        onBlur={field.onBlur}
        isClearable
        isDisabled={disabled}
      />
    </div>
  );
};
const CreatableSelect = ({
  field,
  form,
  options,
  disabled,
  ...props
}: CustomSelectProps) => {
  return (
    <div className="multi-select-container" style={{ gridColumn: "2/4" }}>
      <Creatable
        options={options}
        name={field.name}
        value={options.find((option) => option.value === field.value)}
        onChange={(option) => {
          form.setFieldValue(field.name, (option as any)?.value);
        }}
        menuPlacement={props.menuPlacement}
        onBlur={field.onBlur}
        isClearable
        isDisabled={disabled}
      />
    </div>
  );
};
