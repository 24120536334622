import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Istate } from "../../../../../redux";
import { LocaleNumber, ToastyRed } from "../../../../constants";

interface Props {
  itemID: number;
  itemsToAdd: {
    items: number[];
    upush: (itemID: number) => void;
    upop: (itemID: number) => void;
  };
}

export const GroupItems = ({ itemID, itemsToAdd }: Props) => {
  const {
    pictureA,
    itemName,
    rentalRate,
    rentalPrice,
    dimensions,
    quanDraggable,
  } = useSelector(
    (state: Istate) => state.data.builderInventory[itemID.toString()],
    shallowEqual
  );
  const [itemSelected, setItemSelected] = React.useState(false);
  const handleItemClick = () => {
    if (quanDraggable < 1) {
      ToastyRed(
        "Sorry, there are not enough of this item to add to your staging"
      );
    } else {
      if (itemSelected) itemsToAdd.upop(itemID);
      else itemsToAdd.upush(itemID);
      setItemSelected((current) => !current);
    }
  };

  return (
    <div
      className="grid p-2 m-1 simple-border cursor-pointer"
      style={{
        backgroundColor: itemSelected ? "lightgreen" : "white",
        gridTemplateColumns: "auto 1fr",
      }}
      onClick={() => handleItemClick()}
    >
      <img
        style={{
          maxHeight: "190px",
          maxWidth: "190px",
        }}
        src={pictureA}
        alt={itemName}
      />
      <div
        className="bg-white p-2 grid gap-y-4"
        style={{
          gridTemplateColumns: "auto 1fr",
          gridAutoRows: "2rem",
        }}
      >
        <span>Item Name:</span>
        <span>{itemName}</span>
        <span>Rental Rate:</span>
        <span className="sup-dollar">{LocaleNumber(rentalRate)}</span>
        <span>Retail Value:</span>
        <span className="sup-dollar">{LocaleNumber(rentalPrice)}</span>
        <span>{dimensions ? "Dimensions:" : ""}</span>
        <span>{dimensions}</span>
      </div>
    </div>
  );
};
