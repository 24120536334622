import React from "react";

interface Props {
  src: string;
  alt: string;
}

export const EnlargedPicture = ({ src, alt }: Props) => {
  return (
    <img
      className="w-auto h-full mx-auto object-contain"
      style={{ maxHeight: "100%", height: "100%" }}
      src={src}
      alt={alt}
    />
  );
};
