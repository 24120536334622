export function compare(
  a: { label: string; value: string },
  b: typeof colorOptions[0]
) {
  const labelA = a.label.toUpperCase();
  const labelB = b.label.toUpperCase();

  let comparison = 0;
  if (labelA > labelB) {
    comparison = 1;
  } else if (labelA < labelB) {
    comparison = -1;
  }
  return comparison;
}
export const colorOptions = [
  { value: "1", label: "Black" },
  { value: "2", label: "White" },
  { value: "3", label: "B&W" },
  { value: "4", label: "Grey" },
  { value: "11", label: "Brown" },
  { value: "5", label: "Red" },
  { value: "6", label: "Orange" },
  { value: "12", label: "Gold" },
  { value: "7", label: "Yellow" },
  { value: "8", label: "Blue" },
  { value: "9", label: "Indigo" },
  { value: "10", label: "Violet" },
  { value: "13", label: "Green" },
  { value: "14", label: "Silver" },
  { value: "15", label: "Copper" },
  { value: "16", label: "Cream" },
  { value: "17", label: "Beige/Tan" },
  { value: "18", label: "Teal" },
  { value: "19", label: "Clear" },
  { value: "20", label: "Bronze" },
  { value: "21", label: "Pink" },
].sort(compare);
export const rooms = [
  { id: 1, text: "Living Room" },
  { id: 2, text: "BedRoom" },
  { id: 3, text: "Casual Dining" },
  { id: 4, text: "Dining Room" },
  { id: 5, text: "Home Office" },
  { id: 6, text: "Patio" },
  { id: 7, text: "Accent Furniture" },
  { id: 8, text: "Art" },
  { id: 9, text: "Rugs" },
  { id: 10, text: "Entertainment" },
  { id: 11, text: "Accessories" },
];
export const styleOptions = [
  { value: "1", label: "Traditional" },
  { value: "2", label: "Industrial" },
  { value: "3", label: "Contemporary" },
  { value: "4", label: "Mid-Centery" },
  { value: "5", label: "Bling" },
  { value: "6", label: "Tuscan" },
];
export const valueOptions = [
  { id: 1, text: "Budget" },
  { id: 2, text: "Standard" },
  { id: 3, text: "High-End" },
];
export const professionOptions = [
  { value: "0", label: "Choose: " },
  { value: "1", label: "Stager" },
  { value: "2", label: "Real Estate Professional" },
  { value: "3", label: "Home Owner" },
  { value: "4", label: "Design Student" },
];
export const locationOptions = [
  { value: 1, label: "ACC" },
  { value: 2, label: "ACH" },
  { value: 3, label: "ART" },
  { value: 4, label: "CH1" },
  { value: 5, label: "CH2" },
  { value: 6, label: "CH3" },
  { value: 7, label: "CH4" },
  { value: 8, label: "CH5" },
  { value: 9, label: "COF" },
  { value: 10, label: "CON" },
  { value: 11, label: "CVS" },
  { value: 12, label: "DCH" },
  { value: 13, label: "DSK" },
  { value: 14, label: "DSR" },
  { value: 15, label: "ENT" },
  { value: 16, label: "GAM" },
  { value: 17, label: "HBF" },
  { value: 18, label: "HBK" },
  { value: 19, label: "HBQ" },
  { value: 20, label: "HBT" },
  { value: 21, label: "LMP" },
  { value: 22, label: "MHF" },
  { value: 23, label: "OTT" },
  { value: 24, label: "PAT" },
  { value: 25, label: "RG1" },
  { value: 26, label: "RG2" },
  { value: 27, label: "RG3" },
  { value: 28, label: "SEC" },
  { value: 29, label: "SLV" },
  { value: 30, label: "TB1" },
  { value: 31, label: "TB2" },
  { value: 32, label: "TB3" },
  { value: 33, label: "MAT" },
];

export const yesno = [
  { id: 0, text: "No" },
  { id: 1, text: "Yes" },
];

export const MarkItem = [
  { id: 26, text: "Unavailable" },
  { id: -1, text: "Available" },
  { id: -2, text: "Denied On Location" },
  { id: -3, text: "Move To Trash" },
];

export const RetentionOptions = [
  { id: 1, text: "Abhor it" },
  { id: 2, text: "Hate it" },
  { id: 3, text: "Dislike it" },
  { id: 4, text: "Meh" },
  { id: 5, text: "Alright" },
  { id: 6, text: "Good" },
  { id: 7, text: "Like it" },
  { id: 8, text: "Love it" },
  { id: 9, text: "A Favorite" },
];
