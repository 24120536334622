import React, { useEffect, useCallback, useState } from "react";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { InitialValues, formInit } from "./InitialValues";
import { FormFields } from "./FormFields";
import { PromptIfDirty } from "../../../../../FormikComponents/PromptIfDirty";
import { push } from "connected-react-router";
import { CustomButton } from "../../../../../HighlyReusableComponents/CustomButton";
import { getUsers } from "../../../../../Accounts/Permissions";

export const FormLogic = () => {
  const dispatch = useDispatch();

  // get socker and user info
  const {
    socket,
    user: { id: userID, businessID },
  } = useSelector((state: Istate) => state.data);
  // get target stagingBuilder
  const stagingBuilder = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex]
  );

  const [options, setOptions] = useState<USER_MIN[]>([]);
  useEffect(() => {
    // employees: 0 to not get employees to show up
    getUsers(dispatch, setOptions, { employees: 0 });
  }, [dispatch]);

  const onSubmit = (
    values: InitialValues,
    actions: FormikHelpers<InitialValues>
  ) => {
    businessID === 1 &&
      socket?.emit(
        "STAGING_BUILDER_UPDATE",
        JSON.stringify({
          type: "META",
          action: "UPDATE",
          payload: {
            stagingBuilder: {
              stagingBuilderID: stagingBuilder.stagingBuilderID,
              userID: values.userID,
            },
            userID,
            businessID,
          },
        })
      );

    actions.setSubmitting(false);
    dispatch(push("/stage"));
  };

  const initializedForm = useCallback(() => {
    return formInit(stagingBuilder);
  }, [stagingBuilder]);

  return (
    <Formik
      initialValues={initializedForm().initialValues}
      validationSchema={initializedForm().validationSchema}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      {(formikProps: FormikProps<InitialValues>) => (
        <Form>
          <FormFields
            stagingBuilder={stagingBuilder}
            formikProps={formikProps}
            options={[...options]}
          />
          <PromptIfDirty />
          <div className="grid grid-cols-2 gap-1">
            <CustomButton
              type="button"
              onClick={() => formikProps.submitForm()}
            >
              Change Ownership
            </CustomButton>
            <CustomButton type="button" linkProps={{ to: "/stage" }}>
              Cancel
            </CustomButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};
