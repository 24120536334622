import React from "react";
import { tagInfo } from "..";
import { SingleTag } from "./SingleTag";

interface Iprops {
  tags: tagInfo[];
}
export const Sheet = ({ tags }: Iprops) => {
  if (tags.length > 4)
    throw new Error("Cannot have more than 4 tags per sheet currently");

  return tags.length ? (
    <div className="sheet-A4 print-grid-4">
      {tags.map((tag) => (
        <SingleTag tag={tag} />
      ))}
    </div>
  ) : null;
};
