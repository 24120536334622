import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";

interface IpropsInput {
  label: React.ReactNode;
  name: string;
  type: string;
  styledClass: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  placeholder?: string;
  controlContainerclass?: string;
  fieldWrapperClass?: string;
  labelClass?: string;
  inLineLabelError?: boolean;
}

const Input: React.FunctionComponent<IpropsInput> = (props) => {
  const {
    label,
    name,
    styledClass,
    labelClass,
    controlContainerclass = "basic-input w-full",
    fieldWrapperClass = "block",
    inLineLabelError = false,
    ...rest
  } = props;
  const disabled = props.disabled === undefined ? false : props.disabled;

  return (
    <div className={controlContainerclass}>
      <div className="flex justify-between">
        <label className={labelClass} htmlFor={name}>
          {label}
        </label>
        <label>
          {inLineLabelError && (
            <ErrorMessage name={name} component={TextError} />
          )}
        </label>
      </div>
      <div className={fieldWrapperClass}>
        <Field
          id={name}
          name={name}
          disabled={disabled}
          {...rest}
          className={styledClass}
        />
      </div>
      {!inLineLabelError && <ErrorMessage name={name} component={TextError} />}
    </div>
  );
};

export default Input;
