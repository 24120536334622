interface Props {
  itemContainerID: number;
  containerItemID: number;
}
export const deleteContainerItemRedux = ({
  itemContainerID,
  containerItemID,
}: Props): StagingBuilderReducer => {
  return {
    type: "DELETE_CONTAINERITEM",
    payload: { itemContainerID, containerItemID },
  };
};
