import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Istate } from "../../../../../redux";
import { LocaleNumber } from "../../../../constants";

interface Props {
  selfID: number;
}

export const ItemInfo = ({ selfID }: Props) => {
  const {
    itemNo,
    pictureA,
    itemName,
    rentalPrice,
    rentalRate,
    dimensions,
  } = useSelector(
    (state: Istate) => state.data.builderInventory[selfID],
    shallowEqual
  );
  return (
    <div>
      <img
        className="block"
        style={{ margin: "0 auto", width: "400px" }}
        src={pictureA}
        alt={`item - ${itemNo}`}
      />
      <h2 className="text-center m-0">{itemName}</h2>
      <div className="grid col-4" style={{ padding: "1rem 0" }}>
        <span>Item Number:</span>
        <span>{itemNo}</span>
        <span>Rental Rate: </span>
        <span className="sup-dollar">{LocaleNumber(rentalRate)}</span>
        <span>Retail Price:</span>
        <span className="sup-dollar">{LocaleNumber(rentalPrice)}</span>
        <span>{dimensions ? "Dimensions:" : ""}</span>
        <span>{dimensions}</span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};
