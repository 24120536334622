import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchFullStagingBuilder } from "../redux";
import ProposalContainer from "./StagingBuilderProposals/ProposalContainer";
import { StagingBuilderView } from "./StagingBuilderProposals/StagingBuilderView/StagingBuilderView";
import { MobileNav } from "./mobile-nav";
import { useNavigation } from "./mobile-nav/context/NavigationContext";
import { ImHammer } from "react-icons/im";

import styled from "styled-components";
export const MobileOneSideViewContainer = styled.div<{ side: 0 | 1 }>`
  & > div:first-of-type {
    ${({ side }) => side === 0 && "display: grid;"}
  }
  & > div:first-of-type + div {
    ${({ side }) => side === 1 && "display: block;"}
  }
`;

const Home = () => {
  const dispatch = useDispatch();
  const stagingBuilderID = useSelector(
    (state: Istate) => state.data.user.activeStagingBuilder
  );
  const { leftRight, updateMobileViewSide } = useNavigation();

  const { query } = useSelector((state: Istate) => state.router.location);

  useEffect(() => {
    if (Number(query["staging-proposal"])) {
      dispatch(fetchFullStagingBuilder(Number(query["staging-proposal"])));
    }
  }, [query, stagingBuilderID]);

  return (
    <MobileOneSideViewContainer
      side={leftRight}
      className="grid sm:grid-cols-2 w-full h-full max-w-inherit"
    >
      <div
        id="item-scroll-target"
        className="simple-border w-full h-full overflow-hidden hidden sm:grid"
        style={{ gridTemplateRows: "auto auto auto 1fr" }}
      >
        <MobileNav>
          {stagingBuilderID ? (
            <p>
              <ImHammer
                cursor="pointer"
                style={{
                  height: "36px",
                  width: "36px",
                  marginLeft: "3px",
                  display: "inline",
                }}
                onClick={() => updateMobileViewSide(1)}
              />{" "}
              Active StagingBuilder
            </p>
          ) : (
            <span className="ml-4 text-2xl whitespace-nowrap">
              Welcome to StagingRents
            </span>
          )}
        </MobileNav>
        <ProposalContainer />
      </div>
      <div className="w-full h-full hidden sm:block max-w-inherit overflow-y-auto">
        <StagingBuilderView />
      </div>
    </MobileOneSideViewContainer>
  );
};

export default Home;
