import { useEffect, ReactElement } from "react";
import { toast, ToastOptions, TypeOptions } from "react-toastify";
import "./assets/tailwind.css";
import "./assets/App.scss";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import AppRouter from "./AppRouter";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchingDataFailure,
  fillSocket,
  loggedInCheck,
} from "./redux/users/userActions";
import { fillStagingBuilderFromRefresh } from "./redux/stagingBuilder/actions/fillStagingBuilderFromRefresh";

import { ToastyError } from "./components/constants";
import { Istate } from "./redux";
import { SocketControl } from "./SocketControl";
import { GlobalModals } from "./components/Modals";
import { fetch_full_StagingBuilder } from "./redux/stagingBuilder/stagingBuilderActions";
import { NavigationContext } from "./components/mobile-nav/context/NavigationContext";
const App = (): ReactElement => {
  const dispatch = useDispatch();
  const {
    user: { loggedIn },
    socket,
    version,
  } = useSelector((state: Istate) => state.data, shallowEqual);
  const {
    router: {
      location: {
        query: { "file-number": fileNumber },
      },
    },
  } = useSelector((state: Istate) => state, shallowEqual);

  useEffect(() => {
    if (loggedIn === undefined) dispatch(loggedInCheck());
  }, [dispatch, loggedIn]);

  useEffect(() => {
    if (loggedIn && fileNumber)
      dispatch(fetch_full_StagingBuilder({ fileNumber: Number(fileNumber) }));
    else if (loggedIn) dispatch(fillStagingBuilderFromRefresh());
  }, [dispatch, loggedIn, fileNumber]);

  useEffect(() => {
    if (loggedIn && socket) {
      socket.on("connection", () => {
        // ToastyWhite("connected");
      });
      socket.on("ERROR", (stringyData) => {
        dispatch(fetchingDataFailure());
        const data = JSON.parse(stringyData);
        ToastyError(data.status, data.message);
      });
      socket.on("TOAST", (stringyData: string) => {
        interface ToastData {
          message: string;
          options: ToastOptions | undefined;
          key: TypeOptions;
        }
        const data: ToastData = JSON.parse(stringyData);
        const { message, options, key } = data;
        if (key !== "default") toast[key](message, options);
        else toast(message, options);
      });

      socket.on("PROMPT_REFRESH", (dbVersion) => {
        if (dbVersion !== version) window.location.reload();
      });
    } else if (loggedIn === false) {
      dispatch(fillSocket(undefined));
    }
  }, [dispatch, loggedIn, socket, version]);
  useEffect(() => {
    window.addEventListener("resize", () => {
      try {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      } catch (error) {
        console.error(error);
      }
    });
  }, []);
  return (
    <NavigationContext>
      <div className="App">
        <ToastContainer />
        <SocketControl />
        <GlobalModals />
        <AppRouter />
      </div>
    </NavigationContext>
  );
};

export default App;
