import React, { useState, useEffect } from "react";
import { Portal } from "react-portal";
import "./ContextMenu.scss";

interface Props {
  style?: React.CSSProperties;
  className?: string;
  children: (
    handleOpenContextMenu: (
      e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => void
  ) => JSX.Element;
  menu?: React.ReactNode;
}

export const ContextMenu = ({
  style,
  children,
  menu,
  className = "ctx-menu",
  ...rest
}: Props) => {
  const [showContextMenu, setShowContextMenu] = useState(false);

  const ShouldMenuClose = () => {
    if (showContextMenu) {
      // if we are closing the menu lets do some forced clean up
      // the use effect returns are not always firing....
      removeEventListeners();
      setShowContextMenu(false);
    }
  };
  useEffect(() => {
    // remove event listeners added below
    if (showContextMenu) {
    } else {
      document.removeEventListener("click", ShouldMenuClose);
      document.removeEventListener("contextmenu", ShouldMenuClose);
      document.removeEventListener("blur", ShouldMenuClose);
    }
  }, [showContextMenu]);
  useEffect(() => {
    // remove event listeners added below
    return () => {
      document.removeEventListener("click", ShouldMenuClose);
      document.removeEventListener("contextmenu", ShouldMenuClose);
      document.removeEventListener("blur", ShouldMenuClose);
    };
  }, []);
  const [contextMenuXY, setContextMenuXY] = useState({ x: 0, y: 0 });
  const handleOpenContextMenu = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.stopPropagation();
    e.preventDefault();
    if (!showContextMenu) {
      setShowContextMenu(true);
      setContextMenuXY({ x: e.clientX - 5, y: e.clientY - 5 });
    }
  };
  const handleClickPropogation = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    removeEventListeners();
    const div = e.target as HTMLDivElement;
    if (div.onclick !== null) setShowContextMenu(false);
  };
  const addEventListeners = () => {
    document.addEventListener("click", ShouldMenuClose);
    document.addEventListener("contextmenu", ShouldMenuClose);
    document.addEventListener("blur", ShouldMenuClose);
  };
  const removeEventListeners = () => {
    document.removeEventListener("click", ShouldMenuClose);
    document.removeEventListener("contextmenu", ShouldMenuClose);
    document.removeEventListener("blur", ShouldMenuClose);
  };
  return (
    <>
      {children(handleOpenContextMenu)}
      {showContextMenu ? (
        <Portal>
          <div
            style={{
              width: "275px",
              ...style,
              left: contextMenuXY.x,
              top: contextMenuXY.y,
              position: "fixed",
              float: "left",
            }}
            className={className}
            onClick={handleClickPropogation}
            onMouseLeave={addEventListeners}
            onMouseEnter={removeEventListeners}
            onAuxClickCapture={(e) => e.stopPropagation()}
            onContextMenuCapture={(e) => e.stopPropagation()}
            {...rest}
          >
            {menu}
          </div>
        </Portal>
      ) : null}
    </>
  );
};
