import React from "react";
import Modal from "react-modal";
import { ModalContainer } from "./ModalContainer";

interface Iprops {
  printingModalOpen: boolean;
  setPrintingModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export const PrintModal = ({
  printingModalOpen,
  setPrintingModalOpen,
}: Iprops) => {
  const closeModal = () => {
    setPrintingModalOpen(false);
  };

  return (
    <Modal isOpen={printingModalOpen} onRequestClose={closeModal}>
      <ModalContainer />
    </Modal>
  );
};

export { PrintInfo } from "./PrintInfo";
export { PrintOptions } from "./PrintOptions";
