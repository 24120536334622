import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import {
  AppDispatch,
  fetchingData,
  fetchingDataSuccess,
  fetchingDataFailure,
} from "../";
import {
  path,
  SET_ROUTE_PERMISSION,
  SET_ROUTE_PAYLOAD,
  SET_RESPONSE_STATUS,
  paths,
} from "./routeTypes";

import axios from "axios";
export type ThunkResult<R> = ThunkAction<R, AppDispatch, unknown, AnyAction>;

export const setRoutePermission = ({
  key, // key to route object
  permission,
}: {
  key: keyof paths;
  permission: boolean;
}) => {
  return {
    type: SET_ROUTE_PERMISSION,
    payload: {
      key,
      permission,
    },
  };
};
export const setRoutePayload = ({
  key, // key to route object
  payload,
}: {
  key: keyof paths;
  payload: path["payload"];
}) => {
  return {
    type: SET_ROUTE_PAYLOAD,
    payload: {
      key,
      payload,
    },
  };
};
export const serRouteErrors = ({
  key, // key to route object (e.g. Stage)
  error,
  value,
}: {
  key: keyof paths;
  error: string;
  value?: boolean;
}) => {
  return {
    type: "SET_ROUTE_ERRORS",
    payload: {
      key,
      error,
      value,
    },
  };
};

export const getEmployeeScannerPermission = (): ThunkAction<
  Promise<void>,
  {},
  {},
  AnyAction
> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(fetchingData());
    axios({
      method: "get",
      url: "/api/scan/employee/inventory",
    })
      .then(() => {
        dispatch(
          setRoutePermission({ key: "EmployeeScanner", permission: true })
        );
        dispatch(fetchingDataSuccess());
      })
      .catch((err) => {
        dispatch(fetchingDataFailure(err.response.status));
      });
  };
};

export const scannerResStatus = ({
  key, // key to route object
  status,
}: {
  key: string;
  status: path["status"];
}) => {
  return {
    type: SET_RESPONSE_STATUS,
    payload: {
      key,
      status,
    },
  };
};
