import axios from "axios";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { fetchingData, Istate } from "../..";
import {
  fetchingDataFailure,
  fetchingDataSuccess,
} from "../../users/userActions";
import { newContainerItem } from "./containerItems/NEW";

interface Props {
  barcode: string;
  action: "ADD_SCANNED_ITEM";
  setChangeColor?: React.Dispatch<React.SetStateAction<string>>;
}
export const handleStagingBuilderScan = ({
  barcode,
  action,
  setChangeColor,
}: Props): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: Function
  ): Promise<void> => {
    dispatch(fetchingData()); // active loading sign
    const { data }: { data: Istate["data"] } = getState();
    // const builderInventory = data.builderInventory;
    axios({
      method: "POST",
      url: "/api/scan/staging-builder/check-item-availability",
      data: {
        stagingBuilderID: data.user.activeStagingBuilder,
        barcode,
        action,
        location: "LOFT",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          const resData = res.data;
          const {
            success,
            message,
            item,
          }: { success: boolean; message: string; item: itemVerbose } = resData;
          if (success) {
            // if (builderInventory[item.itemID] !== undefined) {
            dispatch(fetchingDataSuccess());
            dispatch(
              newContainerItem({
                itemID: item.itemID,
                itemContainerID:
                  data.stagingBuilders[data.user.activeStagingBuilderIndex]
                    .itemContainers[1].itemContainerID,
                stagingBuilderID: data.user.activeStagingBuilder,
              })
            );
            return success;
          } else dispatch(fetchingDataSuccess(res.status, message, success));
        }
      })
      .then((success) => {
        if (success && setChangeColor) setChangeColor("mediumspringgreen");
        else if (setChangeColor) setChangeColor("red");
        setTimeout(() => {
          setChangeColor && setChangeColor("");
        }, 500);
      })
      .catch((err) => {
        if (err.response !== undefined) {
          dispatch(fetchingDataFailure(err.response.status));
        } else {
          console.error(err);
          dispatch(fetchingDataFailure());
        }
      });
  };
};
