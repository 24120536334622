import React, { useEffect, useState } from "react";
import { RiLinksFill } from "react-icons/ri";
import { shallowEqual, useSelector } from "react-redux";
import { Istate } from "../../../../redux";
import { ContainerItem } from "../../../../redux/stagingBuilder/stagingBuilderTypes";
import { GroupsModal } from "../Modals";
interface Props {
  containerItem: Partial<ContainerItem> & {
    itemContainerID: number;
    itemID: number;
  };
}

export const ItemGrouping = ({
  containerItem: { itemContainerID, itemID },
}: Props) => {
  const { itemGroups, itemReferences } = useSelector((state: Istate) => {
    return {
      ...state.data.builderInventory[itemID.toString()],
    };
  }, shallowEqual);
  const [groupsAndReferences, setGroupsAndReferences] = useState<
    null | string[]
  >(null);
  useEffect(() => {
    if (
      itemGroups &&
      itemGroups[0] !== "" &&
      itemReferences &&
      itemReferences[0] !== ""
    )
      setGroupsAndReferences([...itemGroups, ...itemReferences]);
    else if (
      itemGroups &&
      itemGroups[0] === "" &&
      itemReferences &&
      itemReferences[0] !== ""
    )
      setGroupsAndReferences([...itemReferences]);
    else if (
      itemGroups &&
      itemGroups[0] !== "" &&
      itemReferences &&
      itemReferences[0] === ""
    )
      setGroupsAndReferences([...itemGroups]);
    else if (groupsAndReferences !== null) setGroupsAndReferences(null);
  }, [itemGroups, itemReferences]);

  const [groupsModalOpen, setGroupsModalOpen] = React.useState(false);

  const groupedItems = useSelector(
    (state: Istate) =>
      groupsAndReferences?.map((groupID) =>
        state.data.builderInventoryGroups[groupID]?.reduce((acc, cur) => {
          return acc || state.data.builderInventory[cur] !== undefined;
        }, false)
      )[0],
    shallowEqual
  );
  return groupsAndReferences === null ? null : (
    <div className="absolute left p-1 z-1" style={{ bottom: "1.5rem" }}>
      {groupsModalOpen && groupsAndReferences !== null && (
        <GroupsModal
          selfID={itemID}
          itemContainerID={itemContainerID}
          open={groupsModalOpen}
          setOpen={setGroupsModalOpen}
          groups={groupsAndReferences}
        />
      )}
      {groupedItems && (
        <abbr title="Click Here to Add Related Items">
          <RiLinksFill
            cursor="pointer"
            color="var(--link-blue)"
            onClick={() => setGroupsModalOpen(true)}
          />
        </abbr>
      )}
      {groupedItems === false && (
        <abbr title="Click Here to Add Related Items">
          <RiLinksFill cursor="not-allowed" color="gray" />
        </abbr>
      )}
    </div>
  );
};
