import { ImCross } from "react-icons/im";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { sortTypes } from ".";
import type { Dispatch, SetStateAction, CSSProperties } from "react";
import { CustomButton } from "../../HighlyReusableComponents/CustomButton";
import Select from "react-select";
import { BasicModal } from "../../Modals/BasicModal";
import { useField } from "formik";
const modalStyle: CSSProperties = {
  top: "30vh",
  left: "35vw",
  right: "35vw",
  bottom: "auto",
  overflow: "visible",
  height: "auto",
  padding: "3rem",
};
interface Props {
  submitForm: (() => Promise<void>) & (() => Promise<any>);
  openPrint: boolean;
  printLength: number;
}

const options: { label: string; value: sortTypes }[] = [
  {
    label: "Item ID",
    value: "itemID",
  },
  {
    label: "Rental Rate",
    value: "rentalRate",
  },
  {
    label: "Retail Price",
    value: "rentalPrice",
  },
  {
    label: "Earnback Period",
    value: "mValue",
  },
];
export const PrintInventoryModal = ({
  openPrint,
  printLength,
  submitForm,
}: Props) => {
  const dispatch = useDispatch();
  const { query, pathname } = useSelector(
    (state: Istate) => state.router.location,
    shallowEqual
  );
  const closeModal = () => {
    dispatch(push(pathname));
  };
  const [field, , actions] = useField("sort");
  return (
    <BasicModal
      modalStyle={modalStyle}
      open={query["open-modal"] === "print-inventory-config"}
      setOpen={() => dispatch(push(pathname))}
      employeeOnly
    >
      <div className="staging-information-wrapper hundred-hundred hundred-hundred__min relative">
        <div
          onClick={() => closeModal()}
          className="absolute z-5"
          style={{ right: "15px", top: "0" }}
        >
          <ImCross cursor="pointer" size="2em" />
        </div>
        <h1 className="text-2xl">What would you like to sort by?</h1>
        <Select
          options={options}
          name="sort"
          value={options.find((option) => option.value === field.value)}
          onChange={(option) => {
            actions.setValue((option as any)?.value);
          }}
          menuPlacement="bottom"
        />

        <CustomButton
          onClick={() => submitForm()}
          disabled={openPrint || printLength > 300}
        >
          Print
        </CustomButton>
      </div>
    </BasicModal>
  );
};
