import { useSelector, shallowEqual } from "react-redux";
import { Istate } from "../../../../../redux";
import { StagingInformation } from "./StagingInformation";
import { FormLogic } from "./form/FormLogic";

export const SchedulingModal = () => {
  const stagingBuilder = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex],
    shallowEqual
  );
  const alreadyScheduled = stagingBuilder.pickupActual ? true : false;
  return (
    <div className="scheduling-modal grid auto-1fr gap-2">
      <div style={{ maxWidth: "55ch" }}>
        <h1 className="text-4xl">
          {alreadyScheduled ? "Res" : "S"}chedule a Pickup
        </h1>
        <p>
          Note that we will not always be able to pick up the staging on your
          requested date; however, we will try to do our best!
        </p>
        <br />
        <p>You will be able to submit this form as many times as you need.</p>
        <br />
        <p>
          You will receive either a phone call or an email to confirm the pickup
          date.
        </p>
        <br />
        <p>
          If you want to cancel your pickup, just clear the date and click
          Submit.
        </p>
        <FormLogic />
      </div>
      <StagingInformation stagingBuilder={stagingBuilder} />
    </div>
  );
};
