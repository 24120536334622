export const SET_ROUTE_PERMISSION = "SET_ROUTE_PERMISSION";
export const SET_ROUTE_PAYLOAD = "SET_ROUTE_PAYLOAD";
export const SET_RESPONSE_STATUS = "SET_RESPONSE_STATUS";

type BuilderEvent = null | "INVENTORY_ORDER_UPDATE" | "BUILDER_SEARCH";

export interface path {
  route: string; // totally useless currently
  permission: boolean;
  status?: number;
  payload?: itemVerbose | BuilderEvent;
  errors: { [index: string]: boolean };
}

export interface paths {
  EmployeeScanner: path;
  Inventory: path;
  Stage: path;
}

export const routes: paths = {
  EmployeeScanner: {
    route: "scanner/employee",
    permission: false,
    errors: {},
  },
  Inventory: {
    route: "/inventory",
    permission: false,
    errors: {},
  },
  Stage: {
    route: "/stage",
    permission: true,
    errors: {},
  },
};
