import React from "react";
import { Link } from "react-router-dom";
import { dateOptionsShort, GetDate } from "../../../../constants";
import { StagingItemStatusesDD } from "../../../../DropDownLists/StagingItemStatusesDD";
import FormikControl from "../../../../FormikComponents/FormikControl";
import { TableThumbnail } from "../../TableThumbnail";

interface Props {
  stagingItems: StagingItem[];
}

export const CheckInTBody = ({ stagingItems }: Props) => {
  return (
    <tbody>
      {stagingItems.map((stagingItem, index) => (
        <tr key={stagingItem.stagingItemID}>
          <TableThumbnail item={stagingItem} />
          <td>
            <FormikControl
              control="select"
              label=""
              name={`stagingItems.${index}.itemReturnStatus`}
              options={StagingItemStatusesDD}
            />
          </td>
          <td className="pl-2">
            {GetDate(stagingItem.stagedStart, undefined, dateOptionsShort)}
          </td>
          <td>{GetDate(stagingItem.stagedEnd)}</td>
          <td>{stagingItem.dimensions}</td>
          <td>{stagingItem.itemName}</td>
          <td>
            <Link to={`/inventory/edit/${stagingItem.itemNo}`}>
              {stagingItem.itemNo}
            </Link>
          </td>
        </tr>
      ))}
    </tbody>
  );
};
