import { push } from "connected-react-router";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

interface Props {
  pathname?: string;
}
/**
 *
 * @todo
 * 1) add permision props
 */
export const EmployeeOnly: React.FC<Props> = ({ pathname }) => {
  const businessID = useSelector((state: Istate) => state.data.user.businessID);
  const dispatch = useDispatch();

  useEffect(() => {
    if (businessID !== 1) dispatch(push(pathname || "/"));
  }, [dispatch]);
  return null;
};
