import React from "react";
import { Field, ErrorMessage, FieldProps } from "formik";
import TextError from "./TextError";

interface Props {
  controlContainerclass?: string;
  labelClass?: string;
  fieldWrapperClass?: string;
  styledClass: string;
  label: React.ReactNode;
  name: string;
  options: { id: number; text: string }[];
  disablefield?: number;
  defaultField?: { id?: string | number; text: String };
}

export const SelectField: React.FunctionComponent<Props> = ({
  controlContainerclass,
  styledClass,
  fieldWrapperClass,
  label,
  name,
  options,
  disablefield,
  defaultField,
}) => {
  const disableValue: boolean = disablefield ? true : false;

  const FieldWrapperClass = fieldWrapperClass
    ? fieldWrapperClass
    : "inline-block";
  const ControlContainerclass = controlContainerclass
    ? controlContainerclass
    : "flex space-between";
  return (
    <div className={ControlContainerclass}>
      <label htmlFor={name}>{label}</label>
      <div className={FieldWrapperClass}>
        <Field
          as="select"
          id={name}
          name={name}
          disabled={disableValue}
          className={styledClass}
        >
          {defaultField ? (
            <option value={defaultField.id}>{defaultField.text}</option>
          ) : null}
          {options.map((option: { id: number; text: string }) => {
            return (
              <option key={option.id} value={option.id}>
                {option.text}
              </option>
            );
          })}
        </Field>
      </div>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};
