import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useApartmentCommunities = () => {
  const socket = useSelector((state: Istate) => state.data.socket);

  const [isCreatingCommunity, setIsCreatingCommunity] = useState(false);
  const createApartmentCommunity = useCallback(() => {
    // need a post-like method in here
  }, []);

  interface LOAD_APARTMENT_COMMUNITIES_PROPS {
    communities: {
      appartmentCommunityID: number;
      appartmentCommunityName: string;
    }[];
  }
  const [apartmentCommunities, setApartmentCommunities] = useState<
    LOAD_APARTMENT_COMMUNITIES_PROPS["communities"]
  >([]);
  const saveApartmentCommunities = useCallback((stringyData: string) => {
    const data = JSON.parse(stringyData) as LOAD_APARTMENT_COMMUNITIES_PROPS;

    const { communities } = data;
    setApartmentCommunities(communities);
    setIsCreatingCommunity(false);
  }, []);

  useEffect(() => {
    socket?.off("LOAD_APARTMENT_COMMUNITIES", saveApartmentCommunities);
    socket?.on("LOAD_APARTMENT_COMMUNITIES", saveApartmentCommunities);
    socket?.emit(
      "META",
      JSON.stringify({
        type: "APARTMENT_COMMUNITIES",
        action: "LOAD",
        payload: null,
      })
    );
    return () => {
      socket?.off("LOAD_APARTMENT_COMMUNITIES", saveApartmentCommunities);
    };
  }, [socket, saveApartmentCommunities]);

  return {
    apartmentCommunities,
    createApartmentCommunity,
    isCreatingCommunity,
  };
};
