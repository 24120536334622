import { push } from "connected-react-router";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { containerItemsAsArray } from "../../../../constants/functions/containerItemsAsArray";
import { CustomButton } from "../../../../HighlyReusableComponents/CustomButton";
import { BasicModal } from "../../../../Modals/BasicModal";
import { FormFields } from "./form/FormFields";
import { FormLogic } from "./form/FormLogic";

const modalStyle: React.CSSProperties = {
  top: "30vh",
  left: "35vw",
  right: "35vw",
  bottom: "auto",
  overflowY: "hidden",
  height: "auto",
};

// interface Props {
//   isOpen: boolean;
// }
export const CICommentModal = () => {
  const dispatch = useDispatch();
  const { pathname, query } = useSelector(
    (state: Istate) => state.router.location
  );

  const containerItemID = useSelector(
    (state: Istate) => Number(state.router.location.query["containerItemID"]),
    shallowEqual
  );
  const containerItem = useSelector(
    (state: Istate) =>
      containerItemsAsArray(
        state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex],
        true
      )?.find(({ containerItemID: CI_ID }) => CI_ID === containerItemID),
    shallowEqual
  );
  return (
    <BasicModal
      modalStyle={modalStyle}
      open={query["open-modal"] === "container-item-comment-form"}
      setOpen={() => dispatch(push(pathname))}
      employeeOnly
    >
      <div className="flex">
        <div>
          <h1 className="text-2xl pb-2">
            Add Item Comments to Day of Delivery Report
          </h1>
          <FormLogic containerItem={containerItem}>
            {(FormikProps) => (
              <FormFields FormikProps={FormikProps}>
                <div className="grid grid-cols-2 gap-1">
                  <CustomButton linkProps={{ to: { pathname: "/stage" } }}>
                    Cancel
                  </CustomButton>
                  <CustomButton>Save</CustomButton>
                </div>
              </FormFields>
            )}
          </FormLogic>
        </div>
        <div className="ml-6 grid place-items-center">
          <img src={containerItem?.pictureA} alt={containerItem?.itemName} />
        </div>
      </div>
    </BasicModal>
  );
};
