import { push } from "connected-react-router";
import React, { useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { ToastyRed } from "../components/constants";
import { fetchingDataFailure, fetchingDataSuccess, Istate } from "../redux";
import { updateBuilderInventoryItem } from "../redux/inventory/actions/updateBuilderInventoryItem";
import { deleteContainerItemRedux } from "../redux/stagingBuilder";
import { updateStagingBuilderMeta } from "../redux/stagingBuilder/actions/updateStagingBuilderMeta";
import {
  updateContainerItem,
  updateTimeStamp,
  moveContainerItemRedux,
} from "../redux/stagingBuilder/stagingBuilderActions";
import { ContainerItem } from "../redux/stagingBuilder/stagingBuilderTypes";
import { StagingBuilderSocketController } from "./StagingBuilder/";
import { StagingBuilderMetaInfoSocket } from "./StagingBuilder/Listeners/StagingBuilderMetaInfoSocket";

export const SocketControl = () => {
  const dispatch = useDispatch();
  const socket = useSelector(
    (state: Istate) => state.data.socket,
    shallowEqual
  );
  const containerItemAdded = (stringyData: string) => {
    const data = JSON.parse(stringyData);
    const {
      containerItem,
      builderItem,
    }: { containerItem: ContainerItem; builderItem: ContainerItem } = data;
    dispatch(updateBuilderInventoryItem(builderItem));
    dispatch(updateContainerItem(containerItem));
  };
  const containerItemEmployeeUpdate = (stringyData: string) => {
    const data = JSON.parse(stringyData);
    const {
      containerItem,
      builderItem,
    }: { containerItem: ContainerItem; builderItem: ContainerItem } = data;
    dispatch(updateContainerItem(containerItem));
    builderItem && dispatch(updateBuilderInventoryItem(builderItem));
    data.push && dispatch(push(data.push));
  };
  const containerItemMove = (stringyData: string) => {
    const data: {
      success: boolean;
      method: string;
      containerItem: ContainerItem;
      builderItem: ContainerItem;
      editedOn: number;
      oldItemContainerID: number;
      containerItemID: number;
    } = JSON.parse(stringyData);
    const {
      builderItem,
      containerItem,
      editedOn,
      method,
      oldItemContainerID,
      containerItemID,
    } = data;

    if (editedOn) dispatch(updateTimeStamp(editedOn));
    if (method === "MOVE") {
      dispatch(
        moveContainerItemRedux({
          stagingBuilderID: containerItem.stagingBuilderID,
          itemContainerID: oldItemContainerID,
          containerItemID: containerItem.containerItemID as number,
          newItemContainerID: containerItem.itemContainerID,
          active: containerItem.active,
        })
      );
      dispatch(updateBuilderInventoryItem(builderItem));
      dispatch(fetchingDataSuccess());
    } else if (method === "DELETE") {
      dispatch(
        deleteContainerItemRedux({
          containerItemID: containerItemID,
          itemContainerID: oldItemContainerID,
        })
      );
      dispatch(fetchingDataSuccess());
    } else if ("FAILURE")
      ToastyRed("You cannot remove items from locked rooms");
    dispatch(fetchingDataFailure());
  };
  const stagingBuilderMeta = (stringyData: string) => {
    const data = JSON.parse(stringyData);
    const { stagingBuilder } = data as { stagingBuilder: StagingBuilder };
    dispatch(updateStagingBuilderMeta(stagingBuilder));
    dispatch(fetchingDataSuccess());
  };

  const fullStagingBuilder = (stringyData: string) => {
    const data = JSON.parse(stringyData);
    dispatch(updateStagingBuilderMeta(data.stagingBuilder));
    dispatch(fetchingDataSuccess());
  };

  const openBlank = (stringyData: string) => {
    const data = JSON.parse(stringyData);
    window.open(data.url, data.target, data.features);
  };

  useEffect(() => {
    if (socket) {
      socket.on("CONTAINER_ITEM_UPDATE", containerItemEmployeeUpdate);
      socket.on("CONTAINER_ITEM_MOVE", containerItemMove);
      socket.on("CONTAINER_ITEM_ADD", containerItemAdded);
      socket.on("STAGING_BUILDER_UPDATE", stagingBuilderMeta);
      socket.on("FULL_STAGING_BUILDER_UPDATE", fullStagingBuilder);
      socket.on("OPEN_BLANK", openBlank);
    }
    return () => {
      if (socket) {
        socket.off("CONTAINER_ITEM_UPDATE", containerItemEmployeeUpdate);
        socket.off("CONTAINER_ITEM_MOVE", containerItemMove);
        socket.off("CONTAINER_ITEM_ADD", containerItemAdded);
        socket.off("STAGING_BUILDER_UPDATE", stagingBuilderMeta);
        socket.off("FULL_STAGING_BUILDER_UPDATE", fullStagingBuilder);
        socket.off("OPEN_BLANK", openBlank);
      }
    };
    // eslint-disable-next-line
  }, [socket]);
  return (
    <>
      <StagingBuilderSocketController />
      <StagingBuilderMetaInfoSocket />
    </>
  );
};

export { StagingBuilderSocketController } from "./StagingBuilder/";
