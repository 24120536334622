import { Formik, FormikProps, Form, FormikValues } from "formik";
import React, { useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  AppThunk,
  fetchingData,
  handleInventorySearch,
  Istate,
} from "../../../redux";
import {
  colorOptions,
  locationOptions,
  styleOptions,
} from "../../DropDownLists";
import FormikControl from "../../FormikComponents/FormikControl";

interface Props {
  formikRef: React.MutableRefObject<FormikProps<any> | undefined>;
  measuredRef: React.RefObject<HTMLDivElement>;
  setOffsetHeight: React.Dispatch<React.SetStateAction<number | undefined>>;
}
export const SearchInventory = ({
  formikRef,
  setOffsetHeight,
  measuredRef,
}: Props) => {
  const dispatch: AppThunk = useDispatch();
  const searchParams = useSelector(
    (state: Istate) => state.data.inventorySearch,
    shallowEqual
  );
  const initialValues = {
    showAvail: searchParams.showAvail.keyValue,
    showHold: searchParams.showHold.keyValue,
    showStaged: searchParams.showStaged.keyValue,
    showOutOfStock: searchParams.showOutOfStock.keyValue,
    stagingSite: searchParams.stagingSite.keyValue,
    MHFOSite: searchParams.MHFOSite.keyValue,
    noSite: searchParams.noSite.keyValue,
    srLocation: searchParams.srLocation.keyValue,
    srColors: searchParams.srColors.keyValue,
    srStyles: searchParams.srStyles.keyValue,
  };
  const handleSearch = (values: typeof initialValues) => {
    dispatch(fetchingData());
    const searchParametersB = {
      showAvail: values.showAvail,
      showHold: values.showHold,
      showStaged: values.showStaged,
      showOutOfStock: values.showOutOfStock,
      stagingSite: values.stagingSite,
      MHFOSite: values.MHFOSite,
      noSite: values.noSite,
      srLocation: values.srLocation,
      srColors: values.srColors || [],
      srStyles: values.srStyles || [],
    };
    // needed to allow the loading indicator to start before this starts
    setTimeout(() => {
      dispatch(handleInventorySearch({ searchParametersB }));
    }, 0);
  };
  const validate = () => {
    setTimeout(() => {
      setOffsetHeight(measuredRef?.current?.getBoundingClientRect().height);
    }, 0);
  };
  // extend colorOptions with
  // { value: "", label: "No Color" },
  const extendedColorOptions = useMemo(() => {
    return [...colorOptions, { value: "", label: "No Color" }];
  }, [colorOptions]);
  return (
    <Formik
      innerRef={formikRef as any}
      initialValues={initialValues}
      onSubmit={handleSearch}
      validate={validate}
      validateOnChange
      validateOnBlur
    >
      {() => (
        <Form>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
            <div>
              <h2>Status</h2>
              <div className="checkbox-container">
                <FormikControl
                  control="left-checkbox"
                  type="checkbox"
                  label={<p className="p-label__checkbox">Avail.</p>}
                  labelClass="grid auto-1fr"
                  name="showAvail"
                  notsearchable={1}
                />
              </div>
              <div className="checkbox-container">
                <FormikControl
                  control="left-checkbox"
                  type="checkbox"
                  label={<p className="p-label__checkbox">On Hold</p>}
                  labelClass="grid auto-1fr"
                  name="showHold"
                  notsearchable={1}
                />
              </div>
              <div className="checkbox-container">
                <FormikControl
                  control="left-checkbox"
                  type="checkbox"
                  label={<p className="p-label__checkbox">Staged</p>}
                  labelClass="grid auto-1fr"
                  name="showStaged"
                  notsearchable={1}
                />
              </div>
              <div className="checkbox-container">
                <FormikControl
                  control="left-checkbox"
                  type="checkbox"
                  label={<p className="p-label__checkbox">Out of Stock</p>}
                  labelClass="grid auto-1fr"
                  name="showOutOfStock"
                  notsearchable={1}
                />
              </div>
            </div>
            <div>
              <h2>Dept.</h2>
              <div className="checkbox-container">
                <FormikControl
                  control="left-checkbox"
                  type="checkbox"
                  label={<p className="p-label__checkbox">Staging Site</p>}
                  labelClass="grid auto-1fr"
                  name="stagingSite"
                  notsearchable={1}
                />
              </div>
              <div className="checkbox-container">
                <FormikControl
                  control="left-checkbox"
                  type="checkbox"
                  label={<p className="p-label__checkbox">MHFO Site</p>}
                  labelClass="grid auto-1fr"
                  name="MHFOSite"
                  notsearchable={1}
                />
              </div>
              <div className="checkbox-container">
                <FormikControl
                  control="left-checkbox"
                  type="checkbox"
                  label={<p className="p-label__checkbox">Not on Websites</p>}
                  labelClass="grid auto-1fr"
                  name="noSite"
                  notsearchable={1}
                />
              </div>
            </div>
            <div>
              <FormikControl
                control="multi-select"
                label="Location"
                name="srLocation"
                options={locationOptions}
              />
              <FormikControl
                control="multi-select"
                label="Color"
                name="srColors"
                options={extendedColorOptions}
              />
              <FormikControl
                control="multi-select"
                label="Style"
                name="srStyles"
                options={styleOptions}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
