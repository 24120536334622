import React, { useState, useEffect, useMemo } from "react";
import { StagingInformation } from "./StagingInformation";
import { StagingBuilderNotes } from "./StagingBuilderNotes";
import { LocationMap } from "./LocationMap";
import { useSelector, shallowEqual } from "react-redux";
import { Istate } from "../../../../../redux";
import { calculateDistance } from "../../../../constants/";

export const ModalContainer = () => {
  const stagingInformation = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex],
    shallowEqual
  );
  const { lat, lng } = stagingInformation;
  useEffect(() => {
    setCenter({
      lat: Number(lat),
      lng: Number(lng),
    });
  }, [lat, lng]);
  const [center, setCenter] = useState<{
    lat: number | null;
    lng: number | null;
  }>({
    lat: Number(lat),
    lng: Number(lng),
  });

  const dist = useMemo(() => calculateDistance(center), [center]);

  const [deliveryFee, setDeliveryFee] = useState<number | null>(null);
  useEffect(() => {
    if (dist) {
      if (dist < 5) setDeliveryFee(250);
      else if (dist < 15) setDeliveryFee(300);
      else if (dist < 25) setDeliveryFee(350);
      else if (dist < 35) setDeliveryFee(400);
      else if (dist < 45) setDeliveryFee(450);
    }
  }, [dist]);

  return (
    <div className="hundred-hundred hundred-hundred__min grid col-3 gap-2 mobile__col-1 mobile__gap-1 staging-information relative">
      <div className="mobile-hide">
        <div>
          <h2>{stagingInformation.address}</h2>
        </div>
        <div>{center && <LocationMap center={center} />}</div>
        <div style={{ width: "100%" }}>
          <img
            style={{ width: "100%" }}
            src={stagingInformation.streetView}
            alt=""
          />
        </div>
      </div>
      <div className="form column-2 rows-2 ">
        <StagingInformation deliveryFee={deliveryFee} />
      </div>
      <div className="staging-builder__stager-notes mobile-hide">
        <StagingBuilderNotes />
        <div>
          <h1>Coming Soon:</h1>
          <ul>
            <li>
              <h2>File uploader for staging images</h2>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
