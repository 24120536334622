import axios from "axios";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { fetchingData, Istate } from "../..";
import {
  fetchingDataFailure,
  fetchingDataSuccess,
} from "../../users/userActions";
import { updateStagingStatus } from "..";
import {
  StagingBuilderStatuses,
  ToastySuccess,
} from "../../../components/constants";
import { push } from "connected-react-router";
import { loadActiveStagingBuilder } from "../stagingBuilderActions";

export const handleStatusChange = ({
  currentStagingStatus,
  action,
}: {
  currentStagingStatus: StagingBuilderStatuses;
  action: string;
}): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: Function
  ): Promise<void> => {
    dispatch(fetchingData()); // active loading sign
    const { data }: { data: Istate["data"] } = getState();
    axios({
      method: "patch",
      url: "/api/form/staging-builder/staging-status",
      data: {
        stagingBuilderID: data.user.activeStagingBuilder,
        currentStagingStatus,
        action,
      },
    })
      .then((res) => {
        if (res.status === 201) {
          const data = JSON.parse(res.data);
          const { success, message, stagingBuilder, ...metaInfo } = data;
          ToastySuccess(res.status, message, success);
          if (stagingBuilder === null)
            dispatch(
              updateStagingStatus({
                ...metaInfo,
              })
            );
          else if (stagingBuilder !== null)
            dispatch(loadActiveStagingBuilder(stagingBuilder));

          if (
            metaInfo.stagingStatus ===
            StagingBuilderStatuses.APPROVAL_PROCESS_STARTED
          )
            dispatch(push("/stage?open-modal=confirmation-menu"));
        } else throw new Error("Something went wrong");
      })
      .then(() => {
        dispatch(fetchingDataSuccess());
      })
      .catch((err) => {
        if (err.response !== undefined) {
          dispatch(fetchingDataFailure(err.response.status));
        } else {
          console.error(err);
          dispatch(fetchingDataFailure());
        }
      });
  };
};
