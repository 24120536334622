import React from "react";
import { EventProps } from "../index";
import { CalendarEventErrors, CalendarEventTypes } from ".";
import { TaskProps } from "../../constants/hooks/fetching/useCalendarTasks";

interface Props {
  task: TaskProps;
  length: number;
  index: number;
}
/**
 * This function takes in a stagingBuilder (with no fileNumber) and return a calendar event
 *
 * @param param0
 * @returns
 * the type of this is always STAGING_UP
 *
 */
export const calendarTask = ({
  task,
  length,
  index,
}: Props): EventProps | undefined => {
  let className: string | undefined;
  let errorType = CalendarEventErrors.NO_ERROR;

  const toolTipInformation: Partial<EventProps> & {
    toolTip: (e: EventProps) => string;
  } = {
    toolTip: (e) => {
      return task.taskDetails;
    },
  };
  const title = (
    <p className="flex justify-between">
      <span>{task.taskTitle}</span>
      <span>{task.taskHours}</span>
      <span></span>
    </p>
  );
  return {
    ...toolTipInformation,
    id: `task-${task.taskID}`,
    title,
    type: CalendarEventTypes.IMPORTANT_TASK,
    allDay: true,
    start: new Date(task.taskStart),
    end: new Date(task.taskStart),
    domAttributes: { className },
    styles: {
      backgroundColor: className === "" ? "transparent" : "navy",
      color: className === "" ? "black" : "white",
      fontWeight: className === "" ? "bold" : "normal",
    },
    errorType,
  };
};
