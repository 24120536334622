import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
// import { Draggable } from "react-beautiful-dnd";
import { itemMinimal } from "../../../../redux/inventory/inventoryTypes";
import { DraggableStateSnapshot } from "react-beautiful-dnd";
import { ItemInfo } from "./ItemInfo";
import { Istate } from "../../../../redux";
import { shallowEqual, useSelector } from "react-redux";
import { ToastyRed } from "../../../constants";
import { ContainerItemStatuses } from "../../../constants/enums/ContainerItemStatuses";
import { ItemHoverView } from "./ItemHoverView";

const parseColorFromStatus = (status: number) => {
  switch (status) {
    case ContainerItemStatuses.PROPOSED_ITEM:
      return "lightgreen";
    case ContainerItemStatuses.HOLD:
      return "lightgreen";
    case ContainerItemStatuses.STAGED:
      return "lightgreen";
    case ContainerItemStatuses.REMOVED_FROM_PROPOSAL:
      return "#eee";
    case ContainerItemStatuses.ITEM_FELL_THROUGH:
      return "#eee";
    case ContainerItemStatuses.AUTOMATED_REMOVAL:
      return "pink";
    default:
      return "white";
  }
};

const Container = styled.div<{
  readonly isDragging: boolean;
  readonly itemStatus?: number;
  readonly isMouseDown: boolean;
}>`
    border:1px solid lightgrey;
    border-radius:2px;
    padding 8px;
    margin-bottom:8px;
    ${({ itemStatus }) =>
      `background-color: ${
        itemStatus ? `${parseColorFromStatus(itemStatus)};` : "white"
      }`};
    ${({ isDragging, isMouseDown }) =>
      isDragging || isMouseDown ? "background-color: lightgreen" : ""};
    width: ${(props) => (props.isMouseDown ? "192px !important" : "")};
    height: ${(props) => (props.isMouseDown ? "192px !important" : "")};
    overflow: ${(props) =>
      props.isMouseDown || props.isDragging ? "hidden" : ""};
    display:flex;
`;
const ProductImage = styled.img<{
  readonly isDragging: boolean;
  readonly isActive?: boolean;
  readonly isMouseDown: boolean;
}>`
  background-color: ${(props) =>
    props.isDragging ? "lightgreen" : "transparent"};
  ${(props) => (props.isActive ? "background-color: lightgreen" : "")};

  // width: ${(props) => (props.isMouseDown ? "176px !important" : "80px")};
  // height: ${(props) => (props.isMouseDown ? "176px !important" : "80px")};
  width: 100%;
  min-width: ${(props) => (props.isMouseDown ? "176px !important" : "80px")};

  min-height: ${(props) => (props.isMouseDown ? "176px !important" : "80px")};
  height: 100%;
  max-height: ${(props) => (props.isMouseDown ? "unset" : "80px")};
  overflow: ${(props) =>
    props.isMouseDown || props.isDragging ? "hidden" : ""};
  display: flex;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1024px) {
    height: 168px;
    width: 168px;
    max-height: unset;
  }
`;
const DragHandle = styled.div`
  min-width: 80px;
  min-height: 80px;
  width: 100%;
  margin-right: 4px;
  height: 100%;
`;
const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: 80px 48px 48px 66px 90px 1fr 70px 56px;
  place-items: center;
  width: 100%;
  min-height: 60px;
  @media (max-width: 1024px) {
    min-height: 170px;
    grid-template-columns: 190px 1fr;
    grid-template-rows: repeat(6, 1fr);
    & > div {
      text-align: left;
      width: 100%;
      grid-column: 2/3;
    }
    & > div:last-of-type {
      grid-row: 1/-3;
      grid-column: 1/2;
    }
    & > div:nth-of-type(4) {
      grid-column: 2/3;
    }
    & > div:nth-of-type(5) {
      grid-column: 1/2;
      grid-row: -1/-2;
      text-align: center;
    }
    & > div:nth-of-type(6) {
      // item name
      grid-column: 1/2;
      grid-row: -2/-3;
    }
    & > div:nth-of-type(7) {
      grid-row: 1/2;
    }
    & > div:nth-of-type(8) {
      grid-column: 2/3;
      grid-row: 5/6;
      width: auto;
    }
  }
`;

const MOUSE_D = "MOUSE_D";
const MOUSE_U = "MOUSE_U";
interface Iprops {
  rowItem: itemMinimal;
  provided: any;
  snapshot: DraggableStateSnapshot;
}
export const DraggableInventoryItemFC = ({
  rowItem,
  snapshot,
  ...props
}: Iprops) => {
  const itemStatus = useSelector((state: Istate) => {
    let item = state.data.builderInventory[rowItem.itemID];
    let status = item.itemStatus;
    if (item.quanDraggable < 0)
      status = ContainerItemStatuses.AUTOMATED_REMOVAL;
    return status;
  }, shallowEqual);
  const [isMouseDown, updateIsMouseDown] = useState(false);
  const adjustParentSize = useCallback(
    (type: String) => {
      switch (type) {
        case MOUSE_D:
          if (!isMouseDown) updateIsMouseDown(true);
          break;
        case MOUSE_U:
          if (isMouseDown) updateIsMouseDown(false);
          break;
        default:
          throw new Error("AdjustParentSize was called without a type.");
      }
    },
    [isMouseDown]
  );
  const { isDragging } = snapshot;
  useEffect(() => {
    if (isDragging) adjustParentSize(MOUSE_D);
    else adjustParentSize(MOUSE_U);
    // having adjustParentSize creates a feedback loop that ends on MOUSE_U being fired
    // and the draggable item being small while being dragged
    // eslint-disable-next-line
  }, [isDragging]);
  const [isAlertingDragDisabled, setIsAlertingDragDisabled] = useState(
    Date.now() - 5000
  );

  useEffect(() => {
    if (
      isMouseDown &&
      rowItem.quanDraggable < 1 &&
      isAlertingDragDisabled < Date.now() - 5000
    ) {
      ToastyRed(
        "Sorry, there are not enough of this item to add to your staging"
      );
      setIsAlertingDragDisabled(Date.now());
    }
    // only want this to fire if the user tries to drag it, not if it is just < 1
    // eslint-disable-next-line
  }, [isMouseDown, isAlertingDragDisabled]);
  const [expandThumbnail, setExpandThumbnail] = useState(false);
  return (
    <Container
      className="scroll-margin-DI"
      // onContextMenu={(e) => e.preventDefault()}
      isDragging={isDragging}
      itemStatus={itemStatus}
      isMouseDown={isMouseDown}
      ref={props.provided.innerRef}
      {...props.provided.draggableProps}
      // {...provided.dragHandleProps}
    >
      {expandThumbnail && !isMouseDown && (
        <ItemHoverView
          rowItem={rowItem}
          setExpandThumbnail={setExpandThumbnail}
          target="staging-builder-hover-target"
        />
      )}
      <ItemGrid className="2xl:gap-6">
        <div
          className="w-full h-full hidden lg:block"
          onMouseDown={() => adjustParentSize(MOUSE_D)}
          onTouchStart={() => adjustParentSize(MOUSE_D)}
          onMouseUp={() => adjustParentSize(MOUSE_U)}
          onTouchMove={() => adjustParentSize(MOUSE_D)}
          onTouchCancel={() => adjustParentSize(MOUSE_U)}
          onMouseEnter={() => setExpandThumbnail(true)}
          onMouseOut={() => setExpandThumbnail(false)}
          style={{ placeSelf: "baseline" }}
        >
          <DragHandle
            {...props.provided.dragHandleProps}
            onDragEnd={() => adjustParentSize(MOUSE_U)}
            style={{ cursor: rowItem.quanDraggable < 1 ? "not-allowed" : "" }}
          >
            <ProductImage
              isDragging={isDragging}
              isActive={Boolean(rowItem.active)}
              isMouseDown={isMouseDown}
              // ref={props.provided.innerRef}
              // {...props.provided.draggableProps}
              src={rowItem.pictureA}
              alt=""
            />
          </DragHandle>
        </div>
        {isMouseDown || isDragging ? null : <ItemInfo {...rowItem} />}
        <div className="lg:hidden">
          <ProductImage
            isDragging={false}
            isActive={Boolean(rowItem.active)}
            isMouseDown={false}
            src={rowItem.pictureA}
            alt=""
          />
        </div>
      </ItemGrid>
    </Container>
  );
};
function areEqual(prevProps: Iprops, nextProps: Iprops) {
  if (prevProps === nextProps) return true;
  else return false;
}

export const DraggableInventoryItem = React.memo(
  DraggableInventoryItemFC,
  areEqual
);
