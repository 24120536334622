import {
  FC,
  DOMAttributes,
  useMemo,
  HTMLAttributes,
  ReactNode,
  SyntheticEvent,
} from "react";
import {
  Calendar,
  dateFnsLocalizer,
  Event,
  EventWrapperProps,
  stringOrDate,
  View,
} from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { fetchFullStagingBuilder } from "../../redux";
import { CSSProperties } from "react";
import styled from "styled-components";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { push } from "connected-react-router";
import { CalendarModals } from "./Modals";
import {
  CalendarEventErrors,
  CalendarEventTypes,
  proposalEvent,
  stagingEvent,
} from "./events";
import { socketDateChange } from "../../redux/stagingBuilder/actions/handleSocketDateChange";
import { calendarTask } from "./events/calendarTask";
import {
  CALENDAR,
  useCalendar,
} from "../constants/contexts/calendar/CalendarContext";
import { MHFODelivery } from "./events/MHFODelivery";
import { useNavigation } from "../mobile-nav/context/NavigationContext";

const CalendarContainer = styled.div<{ showSideBar: boolean }>`
  transition: width 750ms;

  @media only screen and (min-width: 640px) {
    width: calc(
      100vw - ${({ showSideBar }) => (!showSideBar ? "200px -" : "1rem -")} 1rem
    );
  }
  height: calc(97vh - var(--header-height) - var(--footer-height));
`;

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: (
    date: number | Date,
    options?:
      | {
          locale?: Locale | undefined;
          weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined;
        }
      | undefined
  ) => startOfWeek(date, { ...options, weekStartsOn: 1 }),
  getDay,
  locales: {
    "en-US": require("date-fns/locale/az"),
  },
});

function removeUndefined<S>(e: S | undefined | 0): e is S {
  return e !== 0 && e !== undefined;
}
export interface EventProps extends Omit<Event, "title">, Object {
  id: string;
  className?: string;
  title: ReactNode;
  type: CalendarEventTypes;
  allDay: boolean;
  start: Date;
  end: Date;
  styles?: CSSProperties;
  domAttributes?: HTMLAttributes<HTMLDivElement> &
    DOMAttributes<HTMLDivElement>;
  toolTip: (e: EventProps) => string;
  errorType: CalendarEventErrors;
}

export const CalendarView = () => {
  return (
    <CALENDAR>
      <CalendarLogic />
    </CALENDAR>
  );
};

export const CalendarLogic = () => {
  const { showSideBar } = useNavigation();
  const dispatch = useDispatch();
  const _stagingBuilders = useSelector(
    (state: Istate) => state.data.stagingBuilders,
    shallowEqual
  );
  const stagingBuilders = useMemo(
    () => [..._stagingBuilders, ..._stagingBuilders],
    [_stagingBuilders]
  );

  const {
    mhfo: { events: MHFOEvents, updateMHFODelivery },
    tasks: { events: calendarTasks, updateTask },
  } = useCalendar();

  const events = useMemo(
    () =>
      [
        ...stagingBuilders.map((sb, index): EventProps | undefined | 0 => {
          // proposal
          if (sb.fileNumber === null)
            return proposalEvent({
              stagingBuilder: sb,
              index,
              length: _stagingBuilders.length,
            });
          return stagingEvent({
            stagingBuilder: sb,
            index,
            length: _stagingBuilders.length,
          });
        }),
        ...calendarTasks.map((task, index): EventProps | undefined | 0 =>
          calendarTask({ task, index, length: calendarTasks.length })
        ),
        ...MHFOEvents.map((task, index): EventProps | undefined | 0 =>
          MHFODelivery({ task, index, length: calendarTasks.length })
        ),
      ].filter(removeUndefined),
    [MHFOEvents, stagingBuilders, calendarTasks, _stagingBuilders.length]
  );
  function eventPropGetter(
    event: EventProps,
    _start: stringOrDate,
    _end: stringOrDate,
    _isSelected: boolean
  ) {
    return {
      style: {
        ...event?.styles,
      },
    };
  }
  const eventDoubleClicked = (
    { id }: EventProps,
    _e: SyntheticEvent<HTMLElement, globalThis.Event>
  ) => {
    if (id) {
      if (/(su|sd|sp)/gi.test(id)) {
        const sbID = Number(id.split("-")[1]);
        dispatch(fetchFullStagingBuilder(sbID, true, false));
      } else if (/task/gi.test(id)) {
        // should push a new modal for EditTask.tsx
        // add the modal to ./Modals/index.tsx
        const taskID = Number(id.split("-")[1]);
        if (isNaN(taskID)) throw new Error("Something went wrong");
        dispatch(push(`/calendar?open-modal=edit-task&task=${taskID}`));
        // need to create the form

        // need to create the backend for this to handle edits
      } else if (/mhfo/gi.test(id)) {
        const MHFODeliveryID = Number(id.split("-")[1]);
        dispatch(push(`/calendar?open-modal=edit-mhfo&mhfo=${MHFODeliveryID}`));
      }
    }
  };

  const onEventDrop = ({
    event,
    start,
  }: {
    event: EventProps;
    start: stringOrDate;
    end: stringOrDate;
    isAllDay: boolean;
  }) => {
    if ((start as Date).getTime() === event.start.getTime()) return;
    const Confirm = window.confirm(
      `You just moved this event from ${format(
        event.start,
        "MMMM dd, yyyy"
      )} to ${format(start as Date, "MMMM dd, yyyy")}`
    );
    if (!Confirm) return;
    if (/(su|sd|sp)/gi.test(event.id)) {
      const stagingBuilderID = Number(event.id.split("-")[1]);
      if (stagingBuilderID) {
        if (event.type === CalendarEventTypes.STAGING_DOWN) {
          dispatch(
            socketDateChange({
              payload: {
                stagingBuilder: {
                  stagingBuilderID,
                  pickupActual: (start as Date).getTime(),
                },
              },
            })
          );
        }
        if (event.type === CalendarEventTypes.STAGING_UP) {
          dispatch(
            socketDateChange({
              payload: {
                stagingBuilder: {
                  stagingBuilderID,
                  deliveryActual: (start as Date).getTime(),
                },
              },
            })
          );
        }
      }
      return;
    }
    if (/task/gi.test(event.id)) {
      if (updateTask)
        return updateTask({
          "task-time": (start as Date).getTime(),
          taskID: event.id.split("-")[1],
        });
    }
    if (/mhfo/gi.test(event.id)) {
      if (updateMHFODelivery)
        return updateMHFODelivery({
          "task-time": (start as Date).getTime(),
          MHFODeliveryID: Number(event.id.split("-")[1]),
        });
    }
  };
  const onSelectEvent = (
    event: EventProps,
    _e: SyntheticEvent<HTMLElement, globalThis.Event>
  ) => {
    switch (event.errorType) {
      case CalendarEventErrors.UNDEFINED_DELIVERY_ACTUAL:
        dispatch(push("/calendar?open-modal=confirm-delivery"));
        return;
      case CalendarEventErrors.UNDEFINED_PICKUP_ACTUAL:
        dispatch(push("/calendar?open-modal=confirm-pickup"));
        return;
      default:
        // need to add a push /calendar if any of the errors change location.query but don't open a modal
        return;
    }
  };
  const DnDCalendar = useMemo(() => {
    return withDragAndDrop<EventProps>(Calendar as any);
  }, [Calendar]);

  return (
    <>
      <CalendarModals />
      <CalendarContainer showSideBar={showSideBar}>
        <DnDCalendar
          className="h-full w-full"
          defaultView="month"
          events={events}
          localizer={localizer}
          eventPropGetter={eventPropGetter}
          components={{
            eventWrapper: EventWrapper,
            month: { dateHeader: DateHeader as FC<{}> },
          }}
          tooltipAccessor={(event) => event.toolTip(event)}
          onEventDrop={onEventDrop}
          onDoubleClickEvent={eventDoubleClicked}
          onSelectEvent={onSelectEvent}
          // resizable
          onSelectSlot={(slotInfo) => {
            dispatch(
              push(
                `/calendar?open-modal=date-selection&date=${Date.parse(
                  format(new Date(slotInfo.start), "MMMM dd, yyyy")
                )}`
              )
            );
          }}
          selectable
        />
      </CalendarContainer>
    </>
  );
};

const EventWrapper: FC<
  EventWrapperProps<EventProps> & {
    children?: ReactNode;
  }
> = (props) => {
  return <div {...props.event.domAttributes}>{props.children}</div>;
};
interface DateHeaderProps {
  date: Date;
  drilldownView: View;
  label: string;
  isOffRange: boolean;
  onDrillDown: () => {};
}
const DateHeader: FC<DateHeaderProps> = (props) => {
  return (
    <div className="flex justify-between">
      <span></span>
      <a href="#" onClick={props.onDrillDown} role="cell">
        {props.label}
      </a>
    </div>
  );
};
