import { FormikProps } from "formik";
import React from "react";
import { ContainerItem } from "../../../../../../redux/stagingBuilder/stagingBuilderTypes";
import FormikControl from "../../../../../FormikComponents/FormikControl";
import { CustomButton } from "../../../../../HighlyReusableComponents/CustomButton";

import { InitialValues } from "./initialValues";

interface Props {
  initialValues: InitialValues;
  formikProps: FormikProps<InitialValues>;
  containerItem: ContainerItem;
}

export const FormFields = ({
  formikProps: { submitForm, values, dirty, isSubmitting, errors },
  formikProps,
}: Props) => {
  const options = Array.from(Array(101).keys()).map((_, index) => {
    return {
      label: (index + 1).toString(),
      value: (index + 1).toString(),
    };
  });
  return (
    <div>
      <FormikControl
        control="left-checkbox"
        label={<span className="text-bold">Has this item been tagged?</span>}
        name="tagged"
        controlContainerclass="flex space-between mr-16"
      />
      <div className="grid grid-cols-2 gap-2">
        <div>
          <FormikControl
            disabled={!values.tagged}
            options={options}
            control="multi-select"
            label={<span className="text-bold">Tag Numbers</span>}
            name="tagNumbers"
            controlContainerclass="flex space-between mr-16"
          />
        </div>
        <div>
          <FormikControl
            options={options}
            control="basic-select"
            label="Art Bin"
            name="loc"
            controlContainerclass="flex space-between mr-16"
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-2">
        <CustomButton
          disabled={!dirty || isSubmitting}
          type="button"
          onClick={() => {
            console.log(formikProps);
            submitForm();
          }}
        >
          Submit
        </CustomButton>
        <CustomButton linkProps={{ to: "/stage" }}>Cancel</CustomButton>
      </div>
    </div>
  );
};
