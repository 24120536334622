import React from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { AppThunk, fetchingDataFailure } from "../../../redux";
import { push } from "connected-react-router";

interface Props {
  users: { userID: number; email: string }[] | undefined;
  setUserChosen: React.Dispatch<React.SetStateAction<USER_PERMISSION>>;
}

export const ChooseUser = ({ users, setUserChosen }: Props) => {
  const dispatch: AppThunk = useDispatch();
  const [selected, setSelected] = React.useState(0);
  const handleUserChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    dispatch(push(`/accounts/permissions?user=${e.target.value}`));
    setSelected(~~e.target.value);
    if (~~e.target.value === 0)
      setUserChosen({
        userID: 0,
        email: "Choose:",
      });
    else {
      const userID = ~~e.target.value;
      const user = await axios({
        method: "get",
        url: `/api/form/users/user?userID=${e.target.value}`,
      })
        .then((res) => {
          if (res.data.user) return res.data.user;
        })
        .catch((err) => {
          if (err.response) dispatch(fetchingDataFailure(err.response.status));
          else console.error(err);
        });
      if (user.userID === userID) setUserChosen(user);
    }
  };
  return (
    <div className="flex space-between">
      <label style={{ fontSize: "1.5em" }} htmlFor="userEmail">
        Email
      </label>
      <select value={selected} onChange={handleUserChange}>
        {selected === 0 ? <option value={0}>Choose: </option> : null}
        {users &&
          users.map((user, index) => (
            <option key={index} value={user.userID}>
              {user.email}
            </option>
          ))}
      </select>
    </div>
  );
};
