import { useEffect, useState } from "react";

// type states = "start" | "intermediate1" | "intermediate2" | "end";
interface Props {
  className: {
    start: string;
    intermediate1: string;
    intermediate2?: string;
    end: string;
  };
  toggle: boolean;
  time: number;
}
enum states {
  start = 0,
  intermediate1 = 1,
  intermediate2 = 2,
  end = 3,
}

export const useClassChange = ({
  className: inputClass,
  toggle,
  time,
}: Props): string => {
  if (inputClass.intermediate2 === undefined)
    inputClass.intermediate2 = inputClass.intermediate1;

  const [outputClass, setOutputClass] = useState(inputClass.start);
  const [currentClass, setCurrentClass] = useState<states>(states.start);
  const [timeout, setTimeoutState] = useState<NodeJS.Timeout>();

  // clear timeout value when output class changes to start or end
  useEffect(() => {
    if (currentClass === states.start || currentClass === states.end)
      clearTimeout(timeout);
    return () => clearTimeout(timeout);
  }, [currentClass, timeout]);

  // immediately set class to intermediateX and set timeout to switch to start/end
  useEffect(() => {
    switch (currentClass) {
      case states.start: {
        setOutputClass(inputClass.intermediate1);
        setCurrentClass(states.intermediate1);
        const timeoutValue = setTimeout(() => {
          setCurrentClass(states.end);
          setOutputClass(inputClass.end);
        }, time);
        setTimeoutState(timeoutValue);
        break;
      }
      case states.end: {
        setOutputClass(inputClass.intermediate1);
        setCurrentClass(states.intermediate2);
        const timeoutValue = setTimeout(() => {
          setCurrentClass(states.start);
          setOutputClass(inputClass.end);
        }, time);
        setTimeoutState(timeoutValue);
        break;
      }
    }
  }, [toggle]);

  // change current class to new class if the input class has changed
  useEffect(() => {
    switch (currentClass) {
      case states.start: {
        setOutputClass(inputClass.start);
        break;
      }
      case states.intermediate1: {
        setOutputClass(inputClass.intermediate1);
        break;
      }
      case states.intermediate2: {
        setOutputClass(inputClass.intermediate2 || inputClass.intermediate1);
        break;
      }
      case states.end: {
        setOutputClass(inputClass.end);
        break;
      }
    }
    // dont need to add currentClass because we only need to change it here if the input changed
    // eslint-disable-next-line
  }, [inputClass]);
  return outputClass;
};
