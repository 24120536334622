import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { containerItemComment } from "../../../../../../redux/stagingBuilder/actions/containerItems/COMMENT";
import { ContainerItem } from "../../../../../../redux/stagingBuilder/stagingBuilderTypes";
import { PromptIfDirty } from "../../../../../FormikComponents/PromptIfDirty";

import { useFormInit, InitialValues } from "./initialValues";

interface Props {
  children: (FormikProps: FormikProps<InitialValues>) => React.ReactNode;
  containerItem: ContainerItem | undefined;
}
export const FormLogic = ({ children, containerItem }: Props) => {
  const dispatch = useDispatch();
  const onSubmit = (
    values: InitialValues,
    actions: FormikHelpers<InitialValues>
  ) => {
    if (containerItem?.containerItemID)
      dispatch(
        containerItemComment({
          containerItemID: containerItem.containerItemID,
          stagingBuilderID: containerItem.stagingBuilderID,
          comments: values["container-item-comment"] || "",
        })
      );
  };

  return (
    <Formik
      initialValues={useFormInit(containerItem).initialValues}
      validationSchema={useFormInit(containerItem).validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validateOnMount={true}
    >
      {(FormikProps: FormikProps<InitialValues>) => (
        <Form>
          <PromptIfDirty />
          {children(FormikProps)}
        </Form>
      )}
    </Formik>
  );
};
