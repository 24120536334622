import { Component } from "react";
import { Route } from "react-router";
import { Switch } from "react-router-dom";

class RightSideBar extends Component {
  render() {
    return (
      <Switch>
        {/* <Route exact path="/calendar">
          <CalendarSideBar>Staging Builder</CalendarSideBar>
        </Route> */}
        <Route path="/">
          <div id="RightSideBar" className="side-bar side-bar__right">
            <div></div>
          </div>
        </Route>
      </Switch>
    );
  }
}

export default RightSideBar;
