import axios from "axios";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { fetchingData } from "../..";
import {
  fetchingDataFailure,
  fetchingDataSuccess,
} from "../../users/userActions";
import { ContainerItem } from "../stagingBuilderTypes";
import { updateContainerItem } from "../stagingBuilderActions";
import { SystemState } from "../../initialReduxState";

interface ResponseData {
  containerItem: ContainerItem;
  success: boolean;
  message: string;
}

export const updateContainerItemLockedFields = (data: {
  containerItemID: number;
}): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: Function
  ): Promise<void> => {
    const {
      user: { activeStagingBuilder: stagingBuilderID },
    } = getState().data as SystemState;

    dispatch(fetchingData()); // active loading sign
    axios({
      method: "POST",
      url: "/api/cursor/staging-builder/container-item/update-locked-info",
      data: { ...data, stagingBuilderID },
    })
      .then((res) => {
        const { containerItem, message, success } = res.data as ResponseData;
        dispatch(updateContainerItem(containerItem));
        if (success) dispatch(fetchingDataSuccess(res.status, message));
      })
      .catch((err) => {
        if (err.response) dispatch(fetchingDataFailure(err.response.status));
      });
  };
};
