import { push } from "connected-react-router";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export const useHandleFetchErrors = () => {
  const dispatch = useDispatch();
  const errorHandler = useMemo(
    () => (status: number) => {
      switch (status) {
        case 400:
          return toast(
            "Something was wrong with the information you entered. "
          );
        case 401:
          return dispatch(push("/user/logout"));
        case 403:
          return toast(
            "You do not have sufficient permission to request this resource.",
            { type: "error", toastId: "generic-403" }
          );
        case 500:
          return toast(
            "Something went wrong during this request. This was most likely not something that you did.",
            { type: "error", toastId: "generic-500" }
          );
        case 402:
        default:
          return;
      }
    },
    [dispatch]
  );
  return { errorHandler };
};
