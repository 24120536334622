export interface Cats {
  title: string;
  urlParam: string;
}
export interface Menu {
  title: string;
  urlParam: string;
  cats: Cats[];
  showAll?: () => Cats[];
}
interface Menus {
  bedroom: Menu[];
  living: Menu[];
  dining: Menu[];
  office: Menu[];
  entry: Menu[];
  entertainment: Menu[];
  patio: Menu[];
}
const accessories = {
  title: "Accessories",
  urlParam: "",
  cats: [
    { title: "Floor Accessories", urlParam: "1101" },
    { title: "Floor Silks", urlParam: "1102" },
    { title: "Metal", urlParam: "1103" },
    { title: "Bottles", urlParam: "1104" },
    { title: "Glass & Porcelain", urlParam: "1105" },
    { title: "Wood", urlParam: "1106" },
    { title: "Tabletop Silks", urlParam: "1107" },
    { title: "Books", urlParam: "1108" },
    { title: "Games", urlParam: "1109" },
    { title: "Globes", urlParam: "1110" },
    { title: "Chests & Boxes", urlParam: "1111" },
    { title: "Dishes & Place Settings", urlParam: "1112" },
    { title: "Bathroom Accessories", urlParam: "1113" },
    { title: "Candles", urlParam: "1114" },
  ],
};
const art = {
  title: "Art",
  urlParam: "",
  cats: [
    { title: "Framed Prints", urlParam: "802" },
    { title: "Framed Canvas", urlParam: "" },
    { title: "Gallery Wraps", urlParam: "801" },
    { title: "Mirrors", urlParam: "803" },
    { title: "Metal", urlParam: "804" },
    { title: "Mixed Media", urlParam: "" },
  ],
};
const Bedroom = {
  title: "Bedroom Essentials",
  urlParam: "",
  cats: [
    { title: "Dressers", urlParam: "202" },
    { title: "NightStands", urlParam: "203" },
    { title: "Chests", urlParam: "201" },
    { title: "Accent Cabinets", urlParam: "704" },
    { title: "Twin Headboards & footboards", urlParam: "207" },
    { title: "Full Headboards & footboards", urlParam: "206" },
    { title: "Queen Headboards & footboards", urlParam: "205" },
    { title: "King Headboards & footboards", urlParam: "204" },
  ],
};
const bedroomUpholstery = {
  title: "Upholstery",
  urlParam: "",
  cats: [
    { title: "Accent Chairs", urlParam: "701" },
    { title: "Ottomans", urlParam: "106" },
    { title: "Chaises", urlParam: "104" },
    { title: "Benches", urlParam: "403,702" },
  ],
  showAll: function () {
    return upholstery.cats;
  },
};

const consolesAndCabinetsSeparated = {
  title: "Consoles & Cabinets",
  urlParam: "",
  cats: [
    { title: "Sofa Tables", urlParam: "109" },
    { title: "Dining Servers", urlParam: "404" },
    { title: "Entertainment Consoles", urlParam: "114" },
    { title: "Accent Cabinets", urlParam: "704" },
    {
      title: "Consoles & Cabinets (all of the above)",
      urlParam: "109,404,114,704",
    },
  ],
};
const consolesAndCabinets = {
  title: "Consoles & Cabinets",
  urlParam: "",
  cats: [{ title: "Consoles & Cabinets", urlParam: "109,404,114,704" }],
  showAll: function () {
    return consolesAndCabinetsSeparated.cats;
  },
};
const Dining = {
  title: "Dining Essentials",
  urlParam: "",
  cats: [
    { title: "Standard Tables", urlParam: "401,301" },
    { title: "Standard Chairs", urlParam: "402,403" },
    { title: "Counter Tables", urlParam: "302" },
    { title: 'Counter Stools (24")', urlParam: "305" },
    { title: "Bar Tables", urlParam: "303" },
    { title: 'Bar Stools (30")', urlParam: "306" },
    { title: 'Adjustable Stools (24"-30")', urlParam: "307" },
    { title: "Buffets & Servers", urlParam: "404" },
  ],
};
const lamps = {
  title: "Lamps",
  urlParam: "",
  cats: [
    { title: "Table Lamps", urlParam: "707" },
    { title: "Floor Lamps", urlParam: "" },
    { title: "Desk Lamps", urlParam: "" },
  ],
};
const occasionalTables = {
  title: "Occasional Tables",
  urlParam: "",
  cats: [
    { title: "Coffee Tables", urlParam: "107" },
    { title: "End Tables", urlParam: "108" },
    ...consolesAndCabinets.cats,
    { title: "Benches", urlParam: "403,702" },
  ],
};
const rugs = {
  title: "Rugs",
  urlParam: "",
  cats: [
    { title: "5' x 8'", urlParam: "901" },
    { title: "8' x 10'", urlParam: "902" },
    { title: "9' x 12'", urlParam: "903" },
  ],
};
const office = {
  title: "Office",
  urlParam: "",
  cats: [
    { title: "Desks", urlParam: "501" },
    { title: "Desk Chairs", urlParam: "502" },
    { title: "Book Shelves", urlParam: "503" },
    { title: "Credenzas", urlParam: "" },
  ],
  showAll: function () {
    return Office.cats;
  },
};
const Office = {
  title: "Office Essentials",
  urlParam: "",
  cats: [
    ...office.cats,
    { title: "File Cabinets", urlParam: "" },
    { title: "Accent Chairs", urlParam: "" },
    { title: "Fireplaces", urlParam: "" },
  ],
};
const patioUpholstery = {
  title: "Patio Upholstery",
  urlParam: "",
  cats: [
    { title: "Sofas", urlParam: "603" },
    { title: "Loveseats", urlParam: "604" },
    { title: "Chaises", urlParam: "606" },
    { title: "Sectionals", urlParam: "607" },
    { title: "Accent Chairs", urlParam: "605" },
    { title: "Ottomans", urlParam: "" },
  ],
};
const patioAccentTables = {
  title: "Patio Accent Tables",
  urlParam: "",
  cats: [
    { title: "Coffee Tables", urlParam: "" },
    { title: "End Tables", urlParam: "" },
  ],
};
const PatioDining = {
  title: "Patio Dining",
  urlParam: "",
  cats: [
    { title: "Dining Tables", urlParam: "601" },
    { title: "Dining Chairs", urlParam: "602" },
  ],
};
const PatioAccessories = {
  title: "Patio Accessories",
  urlParam: "",
  cats: [
    { title: "Umbrellas", urlParam: "" },
    { title: "Fireplaces", urlParam: "" },
  ],
};
const upholstery = {
  title: "Upholstery",
  urlParam: "",
  cats: [
    { title: "Sofas", urlParam: "101" },
    { title: "Loveseats", urlParam: "102" },
    { title: "Sectionals", urlParam: "103" },
    { title: "Sofa Chairs", urlParam: "105" },
    { title: "Accent Chairs", urlParam: "701" },
    { title: "Ottomans", urlParam: "106" },
    { title: "Chaises", urlParam: "104" },
    { title: "Benches", urlParam: "403,702" },
    { title: "Sleepers", urlParam: "" },
  ],
};
const entertainmentUnits: Menu = {
  title: "Entertainment Units",
  urlParam: "",
  cats: [
    { title: "TV Stands", urlParam: "114" },
    { title: "Wall Units", urlParam: "" },
    { title: "Prop Televisions", urlParam: "805" },
  ],
  showAll: function () {
    return entertainment.cats;
  },
};
const entertainment = {
  title: "Entertainment",
  urlParam: "",
  cats: [
    ...entertainmentUnits.cats,
    { title: "Consoles", urlParam: "114" },
    { title: "Theatre Seating", urlParam: "" },
  ],
};
const Entertainment = {
  title: "Entertainment Essentials",
  urlParam: "",
  cats: [
    { title: "Foosball Tables", urlParam: "1005" },
    { title: "Pool Tables", urlParam: "1004" },
    { title: "Air Hockey Tables", urlParam: "1006" },
    { title: "Poker Table & Chairs", urlParam: "" },
    { title: "Arcade Games", urlParam: "" },
    { title: "Mini Bars", urlParam: "" },
    { title: "Treadmills", urlParam: "1001" },
    { title: "Elliptical Trainers", urlParam: "1002" },
    { title: "Exercise Bikes", urlParam: "" },
  ],
};
export const menus: Menus = {
  bedroom: [
    Bedroom,
    art,
    rugs,
    accessories,
    lamps,
    occasionalTables,
    bedroomUpholstery,
    office,
    entertainmentUnits,
  ],
  living: [
    upholstery,
    consolesAndCabinets,
    occasionalTables,
    lamps,
    accessories,
    art,
    rugs,
    entertainment,
    office,
  ],
  dining: [
    Dining,
    occasionalTables,
    lamps,
    accessories,
    art,
    rugs,
    entertainment,
  ],
  office: [
    Office,
    upholstery,
    occasionalTables,
    lamps,
    accessories,
    art,
    rugs,
    entertainment,
  ],
  entry: [occasionalTables, lamps, accessories, art, rugs],
  entertainment: [
    Entertainment,
    upholstery,
    entertainment,
    Dining,
    occasionalTables,
    lamps,
    accessories,
    art,
    rugs,
    entertainment,
  ],
  patio: [patioUpholstery, patioAccentTables, PatioDining, PatioAccessories],
};
