import { StagingBuilderStatuses } from "../../components/constants/enums/StagingBuilderStatuses";
//items
export const MOVE_CONTAINER_ITEM: string = "MOVE_CONTAINER_ITEM";
export const UPDATE_CONTAINER_ITEM = "UPDATE_CONTAINER_ITEM";
export const ADD_CONTAINER_ITEM_ID_TO_CONTAINER_ITEM: string =
  "ADD_CONTAINER_ITEM_ID_TO_CONTAINER_ITEM";
export const CHANGE_CONTAINER_ITEM_QUANTITY: string =
  "CHANGE_CONTAINER_ITEM_QUANTITY";
export const REMOVE_ITEM_FROM_ITEM_CONTAINER: string =
  "REMOVE_ITEM_FROM_ITEM_CONTAINER";
export const UPDATE_CONTAINER_ITEM_STATUS: string =
  "UPDATE_CONTAINER_ITEM_STATUS";
// stagingBuilders
export const FILL_STAGING_BUILDERS: string = "FILL_STAGING_BUILDERS";
export const ADMIN_NEW_STAGINGBUILDER: string = "ADMIN_NEW_STAGINGBUILDER";
// export const DELETE_PROPOSAL: string = "DELETE_PROPOSAL"; // I do not exist yet
export const NEW_STAGINGBUILDER: string = "NEW_STAGINGBUILDER";
export const SET_ACTIVE_STAGINGBUILDER: string = "SET_ACTIVE_STAGINGBUILDER";
export const LOAD_ACTIVE_STAGINGBUILDER: string = "LOAD_ACTIVE_STAGINGBUILDER";
//itemContainers
export const ADD_NEW_ITEM_CONTAINER: string = "ADD_NEW_ITEM_CONTAINER";

export const GET_ALL_STAGINGBUILDER_NOTES: string =
  "GET_ALL_STAGINGBUILDER_NOTES";

export const UPDATE_ITEM_CONTAINER_STATUS: string =
  "UPDATE_ITEM_CONTAINER_STATUS";
export const UPDATE_ITEM_CONTAINER_NAME: string = "UPDATE_ITEM_CONTAINER_NAME";
export const SET_TIMESTAMP_STAGINGBUILDER: string =
  "SET_TIMESTAMP_STAGINGBUILDER";

interface NewProposalAction {
  type: typeof NEW_STAGINGBUILDER;
}

interface AdminNewStagingBuilder {
  type: typeof ADMIN_NEW_STAGINGBUILDER;
}

// I exist at runtime, I am not updated here
export interface Item {
  readonly itemID: number;
  readonly itemName: string;
  readonly pictureA: string;
  readonly pictureB: null | string;
  readonly vendor: string;
  readonly stagingSellPrice: number;
  readonly stagingRentalRate: number;
}
export interface Items extends Array<Item> {}

// Single room in a SagingBuilder
export interface StagingBuilderContainer {
  itemContainerID: number;
  stagingBuilderID: number; // I reference a specific StagingBuilder
  containerType: number;
  containerName: string;
  locked: boolean;
  items: ContainerItem[];
  active: boolean;
  containerRate: number;
  containerRetail: number;
}

declare global {
  interface StagingBuilderNote {
    stagingBuilderNoteID: number;
    stagingBuilderID: number;
    userID: number;
    first: string;
    created: number;
    message: string;
  }
}
export interface StagingLocation {
  stagingBuilderID: number;
  address1?: string;
  address2?: string;
  city?: string;
  stateInitials?: string;
  state?: number;
  zip?: string;
  lockBoxCode?: string;
  occupied: boolean;
  streetView: string;
  lat: null | number;
  lng: null | number;
}
export interface StagingLessee {
  clientName?: string;
  clientPhone?: string;
  clientEmail?: string;
  selfSign?: number;
}
// I CAN exist at runtime, I can ALSO be createdby a Stager/employee
declare global {
  export interface StagingBuilder
    extends StagingLocation,
      StagingLessee,
      StagerInformation,
      StagingFees {
    shared: 0 | 1;
    userID: number;
    businessID: number;
    stagingBuilderID: number;
    fileNumber: number | null;
    nextNewStagingBuilder?: boolean;
    stager?: StagerInformation;
    employeeCreated: number;
    items: number;
    stagingStatus: StagingBuilderStatuses;
    address: string;
    client?: Client | null;
    itemContainers: StagingBuilderContainer[];
    monthlyRate: number;
    retailValue: number;
    loaded?: boolean;
    messages?: StagingBuilderNote[];
    createdOn?: number;
    editedOn?: number;
    submittedOn?: number;
    scheduledOn?: number;
    deliveryDesired?: number;
    pickupDesired?: number;
    pickupActual?: number;
    deliveryActual?: number;
    leaseRenewal?: number;
    city?: string;
    first?: string;
    clashes?: { itemContainerID: number; itemID: number; itemStatus: number }[];
    areItemsUntagged: number;
  }
}
// export interface StagingBuilderRooms extends Array<StagingBuilderRoom> {}

// bottom of the barrel
export interface ContainerItem {
  containerItemID?: number;
  stagingBuilderID: number;
  itemContainerID: number; // what room I belong to
  itemID: number; // what item I reference
  itemName: string;
  pictureA: string;
  quantity: number;
  rentalRate: number | string;
  rentalPrice: number | string;
  itemStatus: number;
  discount: number;
  active: number;
  tagged: number;
  tagNumbers: string | null;
  loc: number | null;
  comments: string;
}

export type proposalActionTypes = AdminNewStagingBuilder | NewProposalAction;
