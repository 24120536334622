import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { fetchingDataSuccess } from "../../../redux";
import { updateBuilderInventoryItem } from "../../../redux/inventory/actions/updateBuilderInventoryItem";
import { addNewContainerItem } from "../../../redux/stagingBuilder/actions/containerItems/NEW";
import { ContainerItem } from "../../../redux/stagingBuilder/stagingBuilderTypes";

export const StagingBuilderContainerItemsSocket = () => {
  const dispatch = useDispatch();
  const socket = useSelector(
    (state: Istate) => state.data.socket,
    shallowEqual
  );
  const handleContainerItemResponse = useCallback(
    (stringyData: string) => {
      const data: {
        type: string;
        payload: {
          containerItem: ContainerItem;
          builderItem?: ContainerItem;
        };
      } = JSON.parse(stringyData);

      data.payload.builderItem &&
        dispatch(updateBuilderInventoryItem(data.payload.builderItem));
      setTimeout(() => {
        // make sure the the updateBuilderInventoryItem function completes
        dispatch(addNewContainerItem(data.payload.containerItem));
        dispatch(fetchingDataSuccess());
      }, 0);
    },
    [dispatch]
  );

  useEffect(() => {
    socket?.off("STAGING_BUILDER_CONTAINER_ITEM", handleContainerItemResponse);
    socket?.on("STAGING_BUILDER_CONTAINER_ITEM", handleContainerItemResponse);
    return () => {
      socket?.off(
        "STAGING_BUILDER_CONTAINER_ITEM",
        handleContainerItemResponse
      );
    };
  }, [socket, handleContainerItemResponse]);
  return <></>;
};
