import { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

// import Navigation from "./components/Navigation";
import { Footer2 as Footer } from "./components/Footer/";
import RightSideBar from "./components/SideBars/RightSideBar";
import Home from "./components/Home";
import { UserRouter } from "./components/UserRouter";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppThunk, Istate, redirectSuccess } from "./redux";
import { InventoryRouter } from "./components/InventoryRouter";
import { Scanner } from "./components/Scanner";
import { PrintRouter } from "./components/PrintRouter";
import { AccountsRouter } from "./components/Accounts/";
import { StartStagingModal } from "./components/StartStagingBuilder";
import { DotLoader } from "react-spinners";
import { LogOut } from "./components/user/LogOut";
import { SideBarWrapper } from "./components/SideBars/SideBarWrapper";
import { CalendarView } from "./components/Calendar";
import { LoadStagingBuilders } from "./components/LoadStagingBuilders";
import { EmployeeOnly } from "./components/HighlyReusableComponents/EmployeeOnly";
import Stage from "./components/Stage";
import { useNavigation } from "./components/mobile-nav/context/NavigationContext";
import { EmployeePortalModal } from "./components/Modals/EmployeePortal";

// const AppRouter: React.FunctionComponent<any> = () => {
function AppRouter() {
  const dispatch: AppThunk = useDispatch();
  const { setDesiredPath } = useNavigation();
  const { loggedIn, unauthorizedAccess: accessRevoked } = useSelector(
    (state: Istate) => state.data.user,
    shallowEqual
  );
  const { pathname } = useSelector((state: Istate) => state.router.location);
  useEffect(() => {
    accessRevoked && dispatch(redirectSuccess());
  }, [accessRevoked, dispatch]);
  useEffect(() => {
    if (!loggedIn) setDesiredPath(pathname);
  }, []);
  // undefined means the app is still loading
  return loggedIn !== undefined ? (
    <>
      {loggedIn ? (
        <div className="absolute inset-0  grid grid-cols-[auto_1fr_auto] grid-rows-[auto_1fr_auto] overflow-hidden sm:relative sm:top-0 sm:h-full">
          <div className="col-span-full" />
          {accessRevoked ? <Redirect to="/" /> : null}
          <SideBarWrapper />
          <div className="body-container min-h-screen overflow-y-scroll">
            <EmployeePortalModal />
            <Switch>
              <Route exact path="/about">
                <About />
              </Route>
              <Route exact path="/users">
                <Users />
              </Route>
              <Route exact path="/stage">
                <Stage />
              </Route>
              <Route exact path="/">
                <LoadStagingBuilders />
                <Home />
              </Route>
              <Route exact path="/calendar">
                <LoadStagingBuilders />
                <CalendarView />
              </Route>
              <Route exact path="/start-staging">
                <LoadStagingBuilders />
                <Home />
                <StartStagingModal />
              </Route>
              <Route path="/inventory">
                <EmployeeOnly />
                <InventoryRouter />
              </Route>
              <Route exact path={["/scanner", "/scanner/*"]}>
                <EmployeeOnly />
                <Scanner />
              </Route>
              <Route exact path="/print/*">
                <PrintRouter />
              </Route>
              <Route exact path="/accounts/*">
                <AccountsRouter />
              </Route>
              <Route exact path="/user/logout">
                <LogOut />
              </Route>
              <Route exact path="/user/*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </div>
          <RightSideBar />
          <Footer />
        </div>
      ) : (
        <>
          <Switch>
            <Route exact path="/user/logout">
              <Redirect to="/user/login" />
            </Route>
            <Route exact path="/user/*">
              <UserRouter />
            </Route>
            <Redirect to="/user/login" />
          </Switch>
        </>
      )}
    </>
  ) : (
    <div className="center hundred-hundred grid">
      <DotLoader size={80} loading={true} />
    </div>
  );
}

function About() {
  return <h2>About</h2>;
}
function Users() {
  return <h2>Users</h2>;
}
export default AppRouter;
