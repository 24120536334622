import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Istate } from "../../../../../redux";
import { PrintErrorMessage } from "./PrintErrorMessage";
import logo from "../../../../../assets/logo.png";
import { LocaleNumber } from "../../../../constants";

interface Props {
  setTagErrors: React.Dispatch<React.SetStateAction<boolean>>;
}

export const StagingRentsSticker = ({ setTagErrors }: Props) => {
  const tag = useSelector(
    (state: Istate) => state.data.user.activeItem,
    shallowEqual
  );
  let Errors: string[] = [];
  if (tag) {
    if (!tag.pictureA) {
      Errors.push("No Picture");
      setTagErrors(true);
    }
    if (!tag.itemNo) {
      Errors.push("No item Number");
      setTagErrors(true);
    }
    if (!tag.itemName) {
      Errors.push("No item Name");
      setTagErrors(true);
    }
    if (!tag.rentalPrice) {
      Errors.push("No Rental Price");
      setTagErrors(true);
    }
    if (!tag.rentalRate) {
      Errors.push("No Rental Rate");
      setTagErrors(true);
    }
  }
  return tag && !Boolean(Errors.length) ? (
    <div className="printable-container">
      <div className="sheet-A print-grid-30">
        <link
          rel="stylesheet"
          type="text/css"
          href="https://api.stagingrents.com/api/css/print-styles.css"
        />
        <div className="sticker-container">
          <div className="image-container">
            <img src={tag.pictureA} alt="" />
          </div>
          <div className="grid grid-center relative">
            <div className="logo-container-sticker">
              <img src={logo} alt="StagingRents logo" />
            </div>
            <div className="prices">
              <span>${LocaleNumber(tag.rentalPrice)}</span>
              <span>${LocaleNumber(tag.rentalRate)}</span>
            </div>
            <div className="">
              <div className="barcode text-center">{tag.itemNo}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <PrintErrorMessage errors={Errors} />
  );
};
