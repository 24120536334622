import { FieldArray, FormikProps } from "formik";
import React from "react";
import { InitialValues } from "./form/initialValues";
import { CheckInTHead } from "./CheckInTHead";
import { FormLogic } from "./form/FormLogic";
import { Header } from "./Header";
import { CheckInTBody } from "./form/CheckInTBody";
import { PromptIfDirty } from "../../../FormikComponents/PromptIfDirty";

interface Props {}

export const CheckIn = (props: Props) => {
  return (
    <div className="hundred-hundred">
      <FormLogic
        render={(
          props: FormikProps<InitialValues>,
          stagingItems: StagingItem[]
        ) => (
          <>
            <Header {...props} />
            <table>
              <CheckInTHead />
              <FieldArray
                name="stagingItems"
                render={(arrayHelpers) => (
                  <CheckInTBody stagingItems={stagingItems} />
                )}
              />
            </table>
            <PromptIfDirty />
          </>
        )}
      />
    </div>
  );
};
