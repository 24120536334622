import React from "react";
import Modal from "react-modal";

const modalStyle: React.CSSProperties = {
  inset: "20vh 30vw",
};
interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  errors: {
    errorType: "CONNECTION" | "INVENTORY_LEVELS";
    itemID?: number;
    itemContainerID?: number;
  }[];
}

export const ErrorModal = ({ open, setOpen, errors }: Props) => {
  const requestClose = () => {
    setOpen(false);
  };
  return (
    <Modal
      style={{ content: modalStyle }}
      isOpen={open}
      onRequestClose={requestClose}
    ></Modal>
  );
};
