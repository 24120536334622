import { push } from "connected-react-router";
import { Formik, FormikHelpers, FormikProps } from "formik";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { AppThunk, fetchingData, Istate } from "../../../../../redux";
import { ContainerItem } from "../../../../../redux/stagingBuilder/stagingBuilderTypes";
import { ContainerItemStatuses } from "../../../../constants/enums/ContainerItemStatuses";
import { StagingItemStatuses } from "../../../../constants/enums/StagingItemStatuses";
import { MarkItem } from "../../../../DropDownLists";
import FormikControl from "../../../../FormikComponents/FormikControl";
import { CustomButton } from "../../../../HighlyReusableComponents/CustomButton";

interface Props {
  containerItem: ContainerItem;
  setMarkupMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export const EmployeeEditItemForm = ({
  containerItem,
  setMarkupMenuOpen,
}: Props) => {
  const dispatch: AppThunk = useDispatch();

  const socket = useSelector(
    (state: Istate) => state.data.socket,
    shallowEqual
  );
  const userID = useSelector(
    (state: Istate) => state.data.user.id as number,
    shallowEqual
  );
  // the itemContainerID of the trash for the active stagingBuilder
  const trashItemContainerID = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[
        state.data.user.activeStagingBuilderIndex
      ].itemContainers.find(({ containerType }) => containerType === 0)
        ?.itemContainerID,
    shallowEqual
  );

  const initialValues = {
    containerItemID: containerItem.containerItemID,
    discount: containerItem.discount,
    containerItemStatus: containerItem.itemStatus,
  };
  const validationSchema = Yup.object({
    discount: Yup.number().typeError("Value must be number"),
    containerItemStatus: Yup.number(),
  });

  const onSubmit = async (
    values: typeof initialValues,
    actions: FormikHelpers<typeof initialValues>
  ) => {
    dispatch(fetchingData());
    if (Number(values.containerItemStatus) === -2) {
      // staging item status (sis)
      dispatch(
        push(
          `/stage?left-side=check-in&containerItemID=${containerItem.containerItemID}&sis=${StagingItemStatuses.REJECTED_FROM_DELIVERY}`
        )
      );
    } else if (Number(values.containerItemStatus) === -3) {
      socket?.emit(
        "STAGING_BUILDER_UPDATE",
        JSON.stringify({
          type: "CONTAINER_ITEM",
          action: "MOVE",
          payload: {
            stagingBuilderID: containerItem.stagingBuilderID,
            containerItemID: containerItem.containerItemID,
            itemStatus: Number(values.containerItemStatus),
            newItemContainerID: trashItemContainerID,
            oldItemContainerID: containerItem.itemContainerID,
          },
        })
      );
    }
    const dataToSend: {
      event: string;
      payload: {
        containerItem: ContainerItem;
        userID: number;
      };
    } = {
      event: "DISCOUNT_AND_AVAILABILITY",
      payload: {
        containerItem: {
          ...containerItem,
          discount: values.discount,
          itemStatus: Number(values.containerItemStatus),
        } as ContainerItem,
        userID,
      },
    };
    socket?.emit("CONTAINER_ITEM_UPDATE", JSON.stringify(dataToSend));
    setMarkupMenuOpen(false);
  };
  const options = MarkItem.filter(
    (options) => options.id !== containerItem.itemStatus
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {(formikProps: FormikProps<typeof initialValues>) => (
        <>
          <FormikControl
            disabled={containerItem.itemStatus === 4}
            control="input"
            label="Discount Item"
            name="discount"
            placeholder="$"
          />
          <FormikControl
            control="select"
            label="Mark as: (e.g. unavailable)"
            name="containerItemStatus"
            controlContainerclass="block w-100p"
            fieldWrapperClass="w-100p"
            styledClass="w-100p"
            default={{
              id: initialValues.containerItemStatus,
              text: "Keep Same",
            }}
            options={options}
          />
          <div className="grid col-2 gap-1" style={{ height: "75px" }}>
            <CustomButton
              appendClassName="h-full"
              type="button"
              onClick={() => {
                dispatch(
                  push(
                    `/stage?open-modal=mark-tagged&container-item=${containerItem.containerItemID}`
                  )
                );
                setMarkupMenuOpen(false);
              }}
              disabled={containerItem.itemStatus !== ContainerItemStatuses.HOLD}
            >
              Mark
              <br />
              as <br />
              Tagged
            </CustomButton>
            <CustomButton
              appendClassName="h-full"
              type="button"
              onClick={formikProps.submitForm}
            >
              Save <br />& <br />
              Exit
            </CustomButton>
          </div>
        </>
      )}
    </Formik>
  );
};
