import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import type { FC } from "react";
import { useSelector } from "react-redux";

const binningContext = createContext({
  locationName: "loading..." as string | null,
  itemLocation: {
    locationName: "loading...",
    locationID: 0,
  } as Partial<Location> | null,
  newBarcodeScan: undefined as ((barcode: string) => void) | undefined,
  getItemLocation: undefined as ((locationID: number) => void) | undefined,
});

export const useBinningContext = () => useContext(binningContext);

type Location = null | {
  locationID: number; // pk
  locationName: string;
  lastEditedBy: number; // fk.userID
  editedOn: string; // timestamp
  activeUser: number; // fk.userID
};

export const BinningContext: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const {
    socket,
    user: { id: userID },
  } = useSelector((state: Istate) => state.data);
  const [locationID, setLocationID] = useState(0);
  const [locationName, setLocationName] = useState<string | null>(null);
  const [itemLocation, setItemLocation] = useState<Partial<Location> | null>(
    null
  );
  /**
   * Update the current bin that users are scanning items with
   */
  const updateUsersLocationEmitter = useCallback(
    (barcode: string) => {
      socket?.emit(
        "INVENTORY",
        JSON.stringify({
          type: "BINNING_CONTROL",
          action: "UPDATE_CURRENT_LOCATION",
          payload: { barcode },
        })
      );
    },
    [socket]
  );

  useEffect(() => {
    updateUsersLocationEmitter("0-20");
  }, []);

  useEffect(() => {
    const updateUsersLocationSocketCB = (stringyData: string) => {
      const data: { location: Location } = JSON.parse(stringyData);
      if (data.location) {
        const { locationID, activeUser, locationName } = data.location;
        // make sure that the userID is correct
        // this is a redundancy
        if (activeUser === userID) {
          setLocationID(locationID);
          setLocationName(locationName);
        }
      } else {
        setLocationID(0);
        setLocationName(null);
      }
    };
    const updateLocationNameSocketCB = (stringyData: string) => {
      const data: { location: Location } = JSON.parse(stringyData);
      if (data.location) {
        setItemLocation(data.location);
      }
    };
    if (socket) {
      socket.off("NEW_USER_LOCATION", updateUsersLocationSocketCB);
      socket.on("NEW_USER_LOCATION", updateUsersLocationSocketCB);

      socket.off("SET_ITEM_LOCATION_NAME", updateLocationNameSocketCB);
      socket.on("SET_ITEM_LOCATION_NAME", updateLocationNameSocketCB);
    }
    return () => {
      if (socket) socket.off("NEW_USER_LOCATION", updateUsersLocationSocketCB);
      if (socket)
        socket.off("SET_ITEM_LOCATION_NAME", updateLocationNameSocketCB);
    };
  }, [socket, userID]);

  const handleChangeItemLocation = useCallback(
    (barcode: string) => {
      socket?.emit(
        "INVENTORY",
        JSON.stringify({
          type: "BINNING_CONTROL",
          action: "ASSIGN_ITEM_TO_LOCATION",
          payload: { barcode, location: { locationID } },
        })
      );
    },
    [socket, locationID]
  );
  const handleBarcodeScan = useCallback(
    (barcode: string) => {
      if (barcode.split("-").includes("20"))
        updateUsersLocationEmitter(barcode);
      else handleChangeItemLocation(barcode);
    },
    [updateUsersLocationEmitter, handleChangeItemLocation]
  );

  const getItemLocationEmitter = useCallback(
    (locationID: number) => {
      if (locationID === null || locationID === undefined)
        return setItemLocation({ locationName: "unknown" });
      if (locationID === itemLocation?.locationID) return;
      socket?.emit(
        "INVENTORY",
        JSON.stringify({
          type: "BINNING_CONTROL",
          action: "GET_LOCATION_NAME",
          payload: { location: { locationID } },
        })
      );
    },
    [socket, itemLocation]
  );

  return (
    <binningContext.Provider
      value={{
        locationName,
        newBarcodeScan: handleBarcodeScan,
        itemLocation,
        getItemLocation: getItemLocationEmitter,
      }}
    >
      {children}
    </binningContext.Provider>
  );
};
