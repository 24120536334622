import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";

interface IpropsInput {
  label: React.ReactNode;
  name: string;
  type: string;
  styledClass: string;
  fieldWrapperClass?: string;
  disabled?: boolean;
  labelClass?: string;
}

// function Input(props: IpropsInput) {
export const RightInput: React.FunctionComponent<IpropsInput> = (props) => {
  const { label, name, styledClass, ...rest } = props;
  const FieldWrapperClass = props.fieldWrapperClass
    ? props.fieldWrapperClass
    : "inline-block";
  const disabled = props.disabled === undefined ? false : props.disabled;
  return (
    <div className="flex space-between">
      <label className={props.labelClass} htmlFor={name}>
        {label}
      </label>
      <ErrorMessage name={name} component={TextError} />
      <div className={FieldWrapperClass}>
        <Field
          id={name}
          name={name}
          disabled={disabled}
          {...rest}
          className={styledClass}
        />
      </div>
    </div>
  );
};
