import { useEffect, useState } from "react";
import Modal from "react-modal";
import { ErrorMessage, FormikProps } from "formik";

import styled from "styled-components";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Istate, AppThunk, postNewPictures } from "../../../redux";
import { EditorUpdateQuantities } from "./EditorUpdateQuantities";
import TextError from "../../FormikComponents/TextError";
import { PrintModal } from "./TagPrinting";
import { HandleItemActivityClick } from "../../../SocketControl/Inventory/";
import { CustomButton } from "../../HighlyReusableComponents/CustomButton";
import { CropperModal } from "./Cropper";

const InventoryEditorHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
`;
interface ContainerProps {
  newItem?: boolean;
  picture?: string;
}
const Picture = styled.div<ContainerProps>`
  border-top: 1px solid black;
  &:first-of-type {
    border-left: 1px solid black;
  }
  outline: 1px solid black;
  ${(props) =>
    !props.newItem && props.picture
      ? `background-image: url('${props.picture}')`
      : ""};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
`;
interface IEditorHeaderProps {
  pictureState: {
    fileChangedA: boolean;
    setFileChangedA: React.Dispatch<React.SetStateAction<boolean>>;
    fileChangedB: boolean;
    setFileChangedB: React.Dispatch<React.SetStateAction<boolean>>;
  };
  quantities?: {
    loft?: number;
    huddle?: number;
    castle?: number;
    mhfo?: number;
    repair?: number;
    staged?: number;
    hold?: number;
    total?: number;
  };
  FormikProps: FormikProps<any>;
}

Modal.setAppElement("#root");
export const EditorHeader = ({
  pictureState,
  FormikProps,
  quantities,
}: IEditorHeaderProps) => {
  const dispatch: AppThunk = useDispatch();
  const { fileChangedA, setFileChangedA, fileChangedB, setFileChangedB } =
    pictureState;
  const activeItem = useSelector(
    (state: Istate) => state.data.user.activeItem,
    shallowEqual
  );
  const pictures = useSelector((state: Istate) => {
    return {
      pictureA: state.data.user.activeItem?.pictureA,
      pictureB: state.data.user.activeItem?.pictureB,
    };
  }, shallowEqual);
  const loading = useSelector(
    (state: Istate) => state.data.user.loading,
    shallowEqual
  );
  const [printingModalOpen, setPrintingModalOpen] = useState(false);
  const [pictureA, setPictureA] = useState<string | undefined>(
    pictures.pictureA
  );
  const [pictureB, setPictureB] = useState<string | undefined>(
    pictures.pictureB
  );
  const [pictureBlobA, setPictureBlobA] = useState<Blob | null>(null);
  const [pictureBlobB, setPictureBlobB] = useState<Blob | null>(null);
  // const [fileChangedA, setFileChangedA] = useState(false);
  // const [fileChangedB, setFileChangedB] = useState(false);
  const [submittingPictures, setSubmittingPictures] = useState(false);

  useEffect(() => {
    pictureBlobA && setPictureA(URL.createObjectURL(pictureBlobA));
    pictureBlobB && setPictureB(URL.createObjectURL(pictureBlobB));
  }, [pictureBlobA, pictureBlobB]);
  useEffect(() => {
    if (fileChangedA && activeItem?.newItem)
      FormikProps.setFieldValue("pictureAdded", true);
  }, [fileChangedA]);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [pictureToCrop, setPictureToCrop] = useState<string>("");

  const updatePictureModal = (pictureChosen: string) => {
    setPictureToCrop(pictureChosen);
    if (pictureBlobA === null && pictureA !== null && pictureChosen === "A") {
      (async () => {
        let blob: Blob | null = pictureA
          ? await fetch(pictureA).then((res) => res.blob())
          : null;
        blob && setPictureBlobA(blob);
      })();
    }
    if (pictureBlobB === null && pictureB !== null && pictureChosen === "B") {
      (async () => {
        let blob: Blob | null = pictureB
          ? await fetch(pictureB).then((res) => res.blob())
          : null;

        blob && setPictureBlobB(blob);
      })();
    }
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  // reset with new active item
  useEffect(() => {
    setPictureA(pictures.pictureA);
    setPictureB(pictures.pictureB);
    setPictureBlobA(null);
    setPictureBlobB(null);
  }, [activeItem]);

  // submit call
  useEffect(() => {
    if (
      FormikProps.isValid &&
      FormikProps.isSubmitting &&
      !submittingPictures
    ) {
      if (fileChangedA || fileChangedB) {
        setSubmittingPictures(true);
        // blobA and blobB will load when the cropper tool opens
        // That is why we have the variable "fileChanged*"
        // instead of just checking if the blob is not null
        const state = {
          pictureA: setFileChangedA,
          pictureB: setFileChangedB,
          submittingPictures: setSubmittingPictures,
        };
        setTimeout(() => {
          dispatch(
            postNewPictures(
              FormikProps.initialValues.newItem,
              FormikProps.initialValues.itemNo,
              fileChangedA ? pictureBlobA : null,
              fileChangedB ? pictureBlobB : null,
              state
            )
          );
        }, 0);
      }
    }
  }, [FormikProps.isSubmitting, loading, submittingPictures]);

  useEffect(() => {
    if (fileChangedA || fileChangedB)
      FormikProps.setFieldValue("picturesHaveBeenUpdated", true);
  }, [fileChangedA, fileChangedB]);
  const [pictureIncrement, setPictureIncrement] = useState(1);
  const [showSelect, setShowSelect] = useState(false);
  const [pictureSelectOpen, setPictureSelectOpen] = useState(false);

  return activeItem?.itemNo ? (
    <InventoryEditorHeader>
      {pictureToCrop === "A" ? (
        <CropperModal
          origPicture={activeItem.pictureA ? activeItem.pictureA : ""}
          picture={pictureA ? pictureA : ""}
          updateBlobState={setPictureBlobA}
          resetPicture={setPictureA}
          setFileChanged={setFileChangedA}
          modalIsOpen={modalIsOpen}
          onRequestClose={closeModal}
        />
      ) : pictureToCrop === "B" ? (
        <CropperModal
          origPicture={activeItem.pictureB ? activeItem.pictureB : ""}
          picture={pictureB ? pictureB : ""}
          updateBlobState={setPictureBlobB}
          resetPicture={setPictureB}
          setFileChanged={setFileChangedB}
          modalIsOpen={modalIsOpen}
          onRequestClose={closeModal}
        />
      ) : null}
      <PrintModal
        printingModalOpen={printingModalOpen}
        setPrintingModalOpen={setPrintingModalOpen}
      />
      {activeItem.newItem ? (
        <Picture />
      ) : (
        <Picture
          picture={`https://storage.googleapis.com/modelhomefurnitureoutlet.com/originals/item-${activeItem.itemNo}-${pictureIncrement}.jpg`}
          onMouseEnter={() => setShowSelect(true)}
          onMouseLeave={() => setShowSelect(false)}
        >
          {showSelect || pictureSelectOpen ? (
            <div className="absolute right bottom">
              <select
                onChange={(e) => {
                  setPictureIncrement(Number(e.target.value));
                  setPictureSelectOpen(false);
                }}
                value={pictureIncrement}
                onClick={() => setPictureSelectOpen((current) => !current)}
              >
                {Array(...Array(activeItem.pictureIncrement)).map(
                  (_, index) => (
                    <option key={index} value={index + 1}>
                      picture: {index + 1}
                    </option>
                  )
                )}
              </select>
            </div>
          ) : null}
        </Picture>
      )}
      <Picture picture={pictureA}>
        <ErrorMessage name="pictureAdded" component={TextError} />
        <div className="absolute bottom-0 right-0 m-1">
          <CustomButton
            appendClassName="px-2"
            type="button"
            onClick={() => updatePictureModal("A")}
          >
            {pictureA ? "Change" : "Add"}
          </CustomButton>
        </div>
      </Picture>
      <Picture picture={pictureB}>
        <div className="absolute bottom-0 right-0 m-1">
          <CustomButton
            appendClassName="px-2"
            type="button"
            onClick={() => updatePictureModal("B")}
          >
            {pictureB ? "Change" : "Add"}
          </CustomButton>
        </div>
      </Picture>
      <div className="relative">
        {fileChangedA ? "Pictue A has changed" : null}
        <br />
        {fileChangedB ? "Pictue B has changed" : null}
        <div className="absolute my-1 mx-2 bottom left right">
          <HandleItemActivityClick item={activeItem}>
            {(handleClick) => (
              <CustomButton
                disabled={activeItem.newItem}
                type="button"
                onClick={handleClick}
              >
                See SB Activity
              </CustomButton>
            )}
          </HandleItemActivityClick>
        </div>
      </div>
      <div style={{ gridColumn: "-1/-2" }}>
        <EditorUpdateQuantities FormikProps={FormikProps} />
        <div className="mb-1 mx-2">
          <CustomButton
            disabled={activeItem.newItem}
            type="button"
            onClick={() => setPrintingModalOpen(true)}
          >
            Print Tags
          </CustomButton>
        </div>
      </div>
    </InventoryEditorHeader>
  ) : null;
};
