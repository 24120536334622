import { FC, memo, useCallback, useMemo, ReactNode } from "react";
import { ColumnInstance, Filters } from "react-table";
import { StagingBuilderStatuses } from "../../constants";
import { StagingBuilderMemo } from "../ProposalContainerColumnContainer";

interface Props {
  headers: ColumnInstance<StagingBuilderMemo>[];
  setFilter: (columnId: string, updater: any) => void;
  filters: Filters<StagingBuilderMemo>;
  children: () => ReactNode;
}

export const SearchHeader: FC<Props> = ({
  children,
  headers,
  setFilter,
  filters,
}) => {
  const statusHeader = headers.find(
    (col) => col.Header === "Status"
  ) as ColumnInstance<StagingBuilderMemo>;

  if (process.env.NODE_ENV === "development")
    if (statusHeader === undefined)
      throw new Error("statusHeader is undefined");

  const clearFilters = () => {
    headers.forEach((col) => {
      col.setFilter(undefined);
    });
  };
  const setStatusFilter = (value: StagingBuilderStatuses[] | undefined) =>
    setFilter("stagingStatus", (currentFilters: any) => {
      return value;
    });

  const setStagerFilter = (value: StagingBuilderStatuses[] | undefined) =>
    setFilter("stagerName", (currentFilters: any) => {
      return value;
    });
  const setClientFilter = (value: StagingBuilderStatuses[] | undefined) =>
    setFilter("clientName", (currentFilters: any) => {
      return value;
    });

  const currentStatusFilter: StagingBuilderStatuses[] = useMemo(
    () => filters.find((filter) => filter.id === "stagingStatus")?.value || [],
    [filters]
  );

  const currentStagerFilter: StagingBuilderStatuses[] =
    filters.find((filter) => filter.id === "stagerName")?.value || [];

  const currentClientFilter: StagingBuilderStatuses[] =
    filters.find((filter) => filter.id === "clientName")?.value || [];

  //   const currentStatusFilter: StagingBuilderStatuses[] =
  //     statusHeader.filterValue || [];

  let showProposals = 1 as 0 | 1 | 2,
    showHomeStagings = 1 as 0 | 1 | 2;
  // showRequests = 1 as 0 | 1 | 2,

  const isShown = useCallback(
    (
      statusToCheck: StagingBuilderStatuses | StagingBuilderStatuses[]
    ): -1 | 0 | 1 | 2 => {
      if (typeof statusToCheck !== "object")
        return Number(currentStatusFilter.includes(statusToCheck)) as 0 | 1;
      // if no filter this is true
      if (!currentStatusFilter.length) return -1;
      // an array of length 1 was given
      if (statusToCheck.length === 1)
        return Number(currentStatusFilter.includes(statusToCheck[0])) as 0 | 1;
      // an array of length > 1 was given
      return Number(
        statusToCheck.some((x) => currentStatusFilter.includes(x))
      ) as 0 | 1;
    },
    [currentStatusFilter]
  );

  const StagingBuilderStatusesLength = useMemo(
    () => Object.keys(StagingBuilderStatuses).length,
    []
  );

  return (
    <>
      <div className="mb-5">
        <div className="flex space-between flex-row-wrap">
          <PillButton
            type="STATUS"
            show={isShown([
              StagingBuilderStatuses.CLOSED,
              StagingBuilderStatuses.DEAL_FELL_THROUGH,
              StagingBuilderStatuses.PROPOSAL_FELL_THROUGH,
            ])}
            // show={isShown([StagingBuilderStatuses.CLOSED])}
            text={[`Show "Closed" SB's?`, `Hide "Closed" SB's?`]}
            setShow={setStatusFilter}
            currentFilter={currentStatusFilter}
            filterValue={[
              StagingBuilderStatuses.CLOSED,
              StagingBuilderStatuses.DEAL_FELL_THROUGH,
              StagingBuilderStatuses.PROPOSAL_FELL_THROUGH,
            ]}
          />
          <PillButton
            type="STATUS"
            show={isShown([StagingBuilderStatuses.STAGED])}
            text={[`Show Staged SB's?`, `Hide Staged SB's?`]}
            setShow={setStatusFilter}
            currentFilter={currentStatusFilter}
            filterValue={[StagingBuilderStatuses.STAGED]}
          />
          <PillButton
            type="STATUS"
            text={[`Show Upcoming SD's?`, `Hide Upcoming SD's?`]}
            setShow={setStatusFilter}
            currentFilter={currentStatusFilter}
            show={isShown([
              StagingBuilderStatuses.PICKUP_REQUEST,
              StagingBuilderStatuses.PICKUP_SCHEDULED,
            ])}
            filterValue={[
              StagingBuilderStatuses.PICKUP_REQUEST,
              StagingBuilderStatuses.PICKUP_SCHEDULED,
            ]}
          />
          <PillButton
            type="STATUS"
            show={isShown([])}
            text={[`Hide Requests?`, `Show Requests?`]}
            setShow={setStatusFilter}
            currentFilter={currentStatusFilter}
            filterValue={[StagingBuilderStatuses.CLOSED]}
          />
          <PillButton
            type="CALLBACK"
            show={0}
            text={[`Use Default Filters`]}
            setShow={() => {
              clearFilters();
              setStatusFilter([
                1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 13, 14, 15, 16, 18, 19,
              ]);
            }}
            currentFilter={currentStatusFilter}
            filterValue={[
              1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 13, 14, 15, 16, 18, 19,
            ]}
          />
          {showProposals === 0 && (
            <PillButton
              type="STATUS"
              text={['Clear "Hide Proposals"', 'Clear "Hide Proposals"']}
              setShow={setStatusFilter}
              currentFilter={currentStatusFilter}
              filterValue={[StagingBuilderStatuses.CLOSED]}
              show={isShown([])}
            />
          )}
          {showHomeStagings === 0 && (
            <PillButton
              type="FILE_NUMBER"
              show={showHomeStagings}
              text={['Clear "Hide Home Staging"', 'Clear "Hide Home Staging"']}
              setShow={setStatusFilter}
              currentFilter={currentStatusFilter}
              filterValue={[StagingBuilderStatuses.CLOSED]}
            />
          )}
          {currentStagerFilter.length !== 0 && (
            <PillButton
              type="CLEAR_FILTER"
              show={0}
              text={[`Clear Stager Filter`]}
              setShow={setStagerFilter}
              currentFilter={currentStatusFilter}
              filterValue={undefined as unknown as string}
            />
          )}
          {currentClientFilter.length !== 0 && (
            <PillButton
              type="CLEAR_FILTER"
              show={0}
              text={[`Clear Stager Filter`]}
              setShow={setClientFilter}
              currentFilter={currentStatusFilter}
              filterValue={undefined as unknown as string}
            />
          )}
          {currentStatusFilter.length !== 0 &&
            currentStatusFilter.length !== StagingBuilderStatusesLength && (
              <PillButton
                type="CLEAR_ALL"
                show={0}
                text={[`Clear All Filters`]}
                setShow={clearFilters}
                currentFilter={currentStatusFilter}
                filterValue={undefined as unknown as StagingBuilderStatuses[]}
              />
            )}
        </div>
      </div>
      {children()}
    </>
  );
};

interface PillProps {
  show: -1 | 0 | 1 | 2;
  setShow: (val: StagingBuilderStatuses[] | undefined) => void;
  text: string[];
  disabled?: boolean;
  filterValue: StagingBuilderStatuses[] | string;
  currentFilter?: StagingBuilderStatuses[];
  type?: "CALLBACK" | "STATUS" | "FILE_NUMBER" | "CLEAR_ALL" | "CLEAR_FILTER";
}

const PillButton = memo(function ({
  show,
  setShow,
  text,
  disabled = false,
  filterValue,
  currentFilter,
  type,
}: PillProps) {
  const width = useMemo(
    () => text.reduce((acc, cur) => (cur.length > acc ? cur.length : acc), 0),
    [text]
  );

  const colorClass = useMemo(
    () => ["bg-logo-blue", "bg-logo-purple text-white", "bg-logo-blue"],
    []
  );

  const handleFileNumberClick = () => {};

  const handleClick = () => {
    if (type === "FILE_NUMBER") return handleFileNumberClick();
    if (type === "CLEAR_ALL") return setShow(undefined);
    if (type === "CLEAR_FILTER") return setShow(undefined);

    if (typeof filterValue === "string") return;

    if (show === -1) {
      // no filter case
      setShow(
        (
          Object.values(StagingBuilderStatuses).filter((val) =>
            Number(val)
          ) as StagingBuilderStatuses[]
        ).reduce(
          (acc, cur) => (filterValue.includes(cur) ? [...acc] : [...acc, cur]),
          [] as StagingBuilderStatuses[]
        )
      );
    } else if (show === 1)
      currentFilter &&
        setShow(
          currentFilter.reduce(
            (acc, cur) =>
              filterValue.includes(cur) ? [...acc] : [...acc, cur],
            [] as StagingBuilderStatuses[]
          )
        );
    else if (show === 2) {
      setShow(undefined);
    } else currentFilter && setShow([...currentFilter, ...filterValue]);
  };
  // handling of no filter case
  const textIndex = show === -1 ? 1 : show;
  return (
    <button
      disabled={disabled}
      type="button"
      style={{ width: `${width + 1}ch` }}
      onClick={handleClick}
      className={`m-1 text-sm md:text-base btn btn-pill-checkbox ${
        colorClass[textIndex]
      } ${show === 1 ? "active" : ""}`}
    >
      {text[textIndex]}
      <input type="hidden" value={show} />
    </button>
  );
});
