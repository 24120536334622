import { useCallback } from "react";
import { useSelector } from "react-redux";

export interface TaskProps {
  taskID: number;
  taskTitle: string;
  taskStart: string | number;
  taskEnd: string | number;
  taskDetails: string;
  taskHours: number;
  createdOn: string | number;
  createdBy: number;
}

export interface TaskSubmissionValues {
  taskID?: string;
  "task-time": string | number | null;
  hours: string;
  "task-description": string;
  title: string;
}
export type DeleteTask = (values: { taskID: number }, path?: string) => void;
export type CreateTask = (values: TaskSubmissionValues, path?: string) => void;
export type UpdateTask = (
  values: Partial<TaskSubmissionValues>,
  path?: string
) => void;

interface Props {
  setRedirect: React.Dispatch<React.SetStateAction<string>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean[]>>;
}
export const useCalendarTasks = ({ setRedirect, setIsLoading }: Props) => {
  const socket = useSelector((state: Istate) => state.data.socket);

  const createTask: CreateTask = useCallback(
    (values, path = "") => {
      socket?.emit(
        "CALENDAR",
        JSON.stringify({
          type: "TASKS",
          action: "CREATE",
          payload: values,
        })
      );
      setIsLoading((current) => {
        current[0] = true;
        return [...current];
      });
      if (path) setRedirect(path);
    },
    [socket, setIsLoading, setRedirect]
  );
  const updateTask: UpdateTask = useCallback(
    (values, path = "") => {
      socket?.emit(
        "CALENDAR",
        JSON.stringify({
          type: "TASKS",
          action: "UPDATE",
          payload: values,
        })
      );
      setIsLoading((current) => {
        current[0] = true;
        return [...current];
      });
      if (path) setRedirect(path);
    },
    [socket, setIsLoading, setRedirect]
  );
  const deleteTask: DeleteTask = useCallback(
    (values, path = "") => {
      socket?.emit(
        "CALENDAR",
        JSON.stringify({
          type: "TASKS",
          action: "DELETE",
          payload: values,
        })
      );
      setIsLoading((current) => {
        current[0] = true;
        return [...current];
      });
      if (path) setRedirect(path);
    },
    [socket, setIsLoading, setRedirect]
  );

  return {
    createTask,
    updateTask,
    deleteTask,
  };
};
