import React, { useState, useEffect, FC } from "react";
import { useSelector } from "react-redux";
import { MyBusiness } from "./MyBusiness";

interface Props {}

export const Business: FC<Props> = () => {
  const {
    socket,
    user: { businessID },
  } = useSelector((state: Istate) => state.data);
  const [business, setBusiness] = useState<BUSINESS | null>(null);
  const parseBusiness = (data: string) => {
    const { business } = JSON.parse(data);
    console.log(business);
    setBusiness(business);
  };
  useEffect(() => {
    socket?.off("LOAD_BUSINESS_RESPONSE", parseBusiness);
    socket?.on("LOAD_BUSINESS_RESPONSE", parseBusiness);
    return () => {
      socket?.off("LOAD_BUSINESS_RESPONSE", parseBusiness);
    };
  }, [socket]);
  useEffect(() => {
    businessID &&
      socket?.emit(
        "BUSINESS",
        JSON.stringify({
          action: "LOAD_BUSINESS",
          type: "LOAD_BUSINESS",
          payload: "test paylaod",
        })
      );
  }, [businessID, socket]);
  return (
    <div className="w-full">
      {businessID && business ? <MyBusiness business={business} /> : <></>}
    </div>
  );
};
