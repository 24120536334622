import * as Yup from "yup";
import { StagingItemStatuses } from "../../../../constants/enums/StagingItemStatuses";

export interface InitialValues {
  stagingBuilderID: number;
  stagingItems: {
    stagingItemID: number;
    itemReturnStatus: StagingItemStatuses;
  }[];
}
interface returnValues {
  initialValues: InitialValues;
  validationSchema: Yup.ObjectSchema<
    | {
        stagingBuilderID: any;
        returned:
          | Yup.Shape<
              object | undefined,
              { itemReturnStatus: StagingItemStatuses }
            >[]
          | undefined;
      }
    | undefined,
    object
  >;
}
export const formInit = ({
  stagingBuilderID,
  stagingItems,
}: {
  stagingBuilderID: number;
  stagingItems: StagingItem[];
}): returnValues => {
  const validationSchema = Yup.object({
    stagingBuilderID: Yup.number(),
    returned: Yup.array().of(
      Yup.object().shape({
        stagingItemID: Yup.number().required("Something went wrong"),
        itemReturnStatus: Yup.number(),
      })
    ),
  });

  const returned = stagingItems.map(({ stagingItemID, itemReturnStatus }) => {
    return {
      stagingItemID,
      itemReturnStatus,
    };
  }) as InitialValues["stagingItems"];

  return {
    initialValues: {
      stagingBuilderID,
      stagingItems: returned,
    },
    validationSchema,
  };
};
