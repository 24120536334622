import { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { SortType } from ".";
import { LocaleNumber } from "../../../constants";
import { containerItemsAsArray } from "../../../constants/functions/containerItemsAsArray";
import { InventoryAddRemove, ItemContainerSelect } from "./interactivity";
interface Props {
  sortType: SortType;
}
export const ScannerItems = ({ sortType }: Props) => {
  const { containerItems } = useSelector(
    (state: Istate) => ({
      containerItems: containerItemsAsArray(
        state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex]
      )?.sort(
        (
          { containerItemID: a = 0, itemContainerID: c },
          { containerItemID: b = 0, itemContainerID: d }
        ) => (sortType === SortType.RECENT ? b - a : c - d)
      ),
    }),
    shallowEqual
  );
  const [_thumbnailFailed, setThumbnailFailed] = useState(true);

  return (
    <div>
      {containerItems?.map(
        ({
          containerItemID,
          itemContainerID,
          stagingBuilderID,
          quantity,
          itemName,
          pictureA,
          rentalRate,
        }) => (
          <div
            className="grid"
            key={containerItemID}
            style={{ gridTemplateColumns: "80px 1fr 2fr" }}
          >
            <picture>
              {/* {thumbnailFailed && (
                <source
                  srcSet={`https://storage.googleapis.com/modelhomefurnitureoutlet.com/thumbnails/item-${item.itemNo}.jpg`}
                />
              )} */}
              <img
                onError={() => setThumbnailFailed(false)}
                loading="lazy"
                className="inventory-image"
                width={80}
                src={pictureA}
              />
            </picture>
            <div>
              <p className="ml-1 text-bold">
                <span
                  className="table-cell text-center border-black border align-middle"
                  style={{ width: "3ch", height: "3ch" }}
                >
                  {quantity}
                </span>
                <span className="sup-dollar pl-3 table-cell text-center align-middle">
                  {LocaleNumber(rentalRate)}
                </span>
              </p>
              {containerItemID && (
                <InventoryAddRemove
                  containerItemID={containerItemID}
                  itemContainerID={itemContainerID}
                  stagingBuilderID={stagingBuilderID}
                  quantity={quantity}
                />
              )}
            </div>
            <div className="grid grid-rows-2">
              {containerItemID && (
                <ItemContainerSelect
                  containerItemID={containerItemID}
                  itemContainerID={itemContainerID}
                  stagingBuilderID={stagingBuilderID}
                />
              )}
              <div className="text-indigo-700 text-right">{itemName}</div>
            </div>
          </div>
        )
      )}
    </div>
  );
};
