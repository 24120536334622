import React from "react";
import { tagInfo } from "..";
import logo from "../../../assets/logo.png";

interface Iprops {
  tag: tagInfo;
}
export const SingleTag = ({ tag }: Iprops) => {
  return (
    <div className="tag">
      <img src={tag.pictureA} alt={tag.itemNo} />
      <div className="tag-info-container">
        <h1>{tag.itemName}</h1>
        <h1>{tag.dimensions}</h1>
        <div className="writable-container">
          <div className="writable-container__writable-area">Notes</div>
          <div className="writable-container__writable-area">Quan.</div>
        </div>
        <div className="tag-info">
          <div>
            <div className="barcode">{"000000-" + tag.itemNo}</div>
            <div
              style={{ fontFamily: "Libre Barcode 128" }}
              className="below-barcode"
            >
              <h3 className="dollar-sign-target">279</h3>
              <h3>&middot;</h3>
              <h3>{tag.itemNo}</h3>
            </div>
          </div>
          <div>
            <img src={logo} alt="StagingRents logo" />
            <h2 className="dollar-sign-target">19/mo.</h2>
          </div>
        </div>
      </div>
    </div>
  );
};
