import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FaEye } from "react-icons/fa";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchingData, Istate } from "../../../../redux";
import {
  changeContainerItemQuantity,
  moveContainerItem,
} from "../../../../redux/stagingBuilder/stagingBuilderActions";
import { LocaleNumber, StagingBuilderStatuses } from "../../../constants";
import { ContainerItemStatuses } from "../../../constants/enums/ContainerItemStatuses";
import { ItemHoverView } from "../../draggableInventory/DraggableItem.tsx/ItemHoverView";
import { EmployeeOptions } from "./EmployeeOptions";
import { ItemGrouping } from "./ItemGrouping";
import { TrashItem } from "./TrashItem";

interface Props {
  containerItemIndex: number;
  itemContainerIndex: number;
  locked: boolean;
  edit: boolean;
  shouldWarn?: boolean;
  setShouldWarn?: Dispatch<SetStateAction<boolean>>;
}
export function StagingBuilderItem({
  containerItemIndex,
  itemContainerIndex,
  locked,
  edit,
  setShouldWarn,
}: Props) {
  const dispatch = useDispatch();

  const { stagingStatus } = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex],
    shallowEqual
  );
  const { containerType } = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex]
        .itemContainers[itemContainerIndex],
    shallowEqual
  );
  const {
    containerItemID,
    itemContainerID,
    stagingBuilderID,
    itemStatus,
    itemName,
    tagged,
    quantity,
    itemID,
    pictureA,
    discount,
    rentalPrice,
    rentalRate,
  } = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex]
        .itemContainers[itemContainerIndex].items[containerItemIndex],
    shallowEqual
  );

  const itemContainerOptions = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[
        state.data.user.activeStagingBuilderIndex
      ].itemContainers
        .filter((itemContainer) => itemContainer.containerType !== 0)
        .map((itemContainer) => {
          return {
            id: itemContainer.itemContainerID,
            text: itemContainer.containerName,
          };
        }),
    shallowEqual
  );
  const { businessID } = useSelector(
    (state: Istate) => state.data.user,
    shallowEqual
  );

  const { quanDraggable, ...item } = useSelector((state: Istate) => {
    return { ...state.data.builderInventory[`${itemID}`] };
  }, shallowEqual);

  const [quanArray, setQuanArray] = useState([
    { id: quantity, text: quantity.toString() },
  ]);

  useEffect(() => {
    if (quanDraggable < 0) {
      setQuanArray([]);
    } else if (quanDraggable === 0) {
      let tempArray = [];
      for (let i = 0; i < quantity; i++)
        tempArray.push({ id: i + 1, text: (i + 1).toString() });
      setQuanArray(tempArray);
    } else {
      // ToastyWhite(`${~~quanDraggable + ~~quantity}`);
      let tempArray = [];
      for (let i = 0; i < ~~quanDraggable + ~~quantity; i++)
        tempArray.push({ id: i + 1, text: (i + 1).toString() });
      setQuanArray(tempArray);
    }
  }, [quanDraggable]);
  const [isAlertingDragDisabled, setIsAlertingDragDisabled] = useState(
    Date.now() + 100000
  );
  useEffect(() => {
    setIsAlertingDragDisabled(Date.now() - 5000);
  }, [quanDraggable]);
  useEffect(() => {
    if (isAlertingDragDisabled < Date.now() - 5000) {
      if (
        (quanDraggable < 0 ||
          itemStatus === 26 ||
          itemStatus === 27 ||
          itemStatus === 28) &&
        containerType !== 0 &&
        itemStatus !== ContainerItemStatuses.STAGED
      ) {
        setShouldWarn && setShouldWarn(true);
        setIsAlertingDragDisabled(Date.now());
      }
    }
  }, [quanArray, containerItemID]);

  const changeQuantity = async (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    dispatch(fetchingData());
    if (containerItemID !== undefined) {
      if (~~e.target.value !== 0)
        dispatch(
          changeContainerItemQuantity({
            patchData: {
              stagingBuilderID: stagingBuilderID,
              itemContainerID: itemContainerID,
              containerItemID,
              newQuantity: ~~e.target.value,
            },
          })
        );
    }
  };
  const switchItemContainer = async (e: ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    dispatch(fetchingData());
    if (containerItemID !== undefined && ~~e.target.value !== 0) {
      dispatch(
        moveContainerItem({
          patchData: {
            stagingBuilderID: stagingBuilderID,
            itemContainerID: itemContainerID,
            containerItemID,
            newItemContainerID: ~~e.target.value,
          },
        })
      );
    }
  };

  const { filterColor, ribbonText } = useMemo(
    () =>
      getBannerFromStatus(businessID, itemStatus, stagingStatus, {
        tagged: Boolean(tagged),
        deniedOnLocation: false,
        quanDraggable: Number(quanDraggable),
      }),
    [businessID, itemStatus, tagged]
  );
  const [expandedThumbnail, setExpandThumbnail] = useState(false);
  return (
    <div className="relative simple-outline-pad itemContainer item-container__item grid scroll-margin-IC">
      {filterColor === "" ? null : (
        <div
          className={`absolute top right left z-1 filter-item`}
          style={{
            backgroundColor: filterColor,
          }}
        />
      )}
      {ribbonText === "" ? null : (
        <div className="ribbon-wrapper absolute top right left bottom">
          <div className="ribbon">
            <span>{ribbonText}</span>
          </div>
        </div>
      )}
      {businessID === 1 && edit && (
        <EmployeeOptions
          containerItemIndex={containerItemIndex}
          itemContainerIndex={itemContainerIndex}
        />
      )}
      {edit && (
        <div
          onMouseOut={() => setExpandThumbnail(false)}
          onMouseOver={() => setExpandThumbnail(true)}
          className="absolute top-0 left-0 p-2 z-1"
        >
          <div className="rounded-full flex items-center justify-center p-1px bg-white"></div>
          <FaEye />
          {expandedThumbnail && (
            <ItemHoverView
              rowItem={item as any}
              setExpandThumbnail={setExpandThumbnail}
              target="draggable-inventory-hover-target"
            />
          )}
        </div>
      )}
      {edit && !locked && (
        <ItemGrouping containerItem={{ itemContainerID, itemID }} />
      )}
      <div
        className="item-container__item__image"
        style={{
          backgroundImage: `url("${pictureA}")`,
        }}
      />

      <div className={`item-container__item__info grid col-5`}>
        {edit && !locked ? (
          <>
            <div style={{ paddingLeft: "5px" }}>
              {itemStatus === 26 ||
              (quanDraggable < 1 && containerType === 0) ? null : (
                <select
                  onChange={changeQuantity}
                  className="containerItem-select"
                  disabled={!Boolean(containerItemID)}
                  value={quantity}
                >
                  {quanArray.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.text}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <div>
              {itemStatus === 26 ||
              (containerType === 0 && quanDraggable < 1) ? null : (
                <select
                  onChange={switchItemContainer}
                  className="containerItem-select"
                  disabled={!Boolean(containerItemID)}
                  value="0"
                >
                  <option value="0">move</option>
                  {itemContainerOptions
                    .filter((option) => option.id !== itemContainerID)
                    .map((option, index) => (
                      <option key={index} value={option.id}>
                        {option.text}
                      </option>
                    ))}
                </select>
              )}
            </div>
            {containerItemID && (
              <TrashItem
                containerItem={{
                  itemStatus,
                  containerItemID,
                  stagingBuilderID,
                  itemContainerID,
                  itemID,
                }}
              />
            )}
          </>
        ) : (
          <span style={{ gridColumn: "1/4" }} className="pl-5">
            {quantity}
          </span>
        )}
        <div>
          <p className="item-container__item__info__price">
            {LocaleNumber(Number(rentalRate) - Number(discount))}
          </p>
        </div>
        <div>
          <p className="item-container__item__info__price">
            {LocaleNumber(rentalPrice)}
          </p>
        </div>
        <p
          className="text-center item-container__item-name"
          style={{ gridColumn: "1/-1", gridRow: "1/2" }}
        >
          {itemName}
        </p>
      </div>
    </div>
  );
}

interface OverRides {
  tagged?: boolean;
  deniedOnLocation?: boolean;
  quanDraggable?: number;
}
const getBannerFromStatus = (
  businessID: number = 0,
  itemStatus: number = 0,
  stagingStatus: StagingBuilderStatuses,
  { tagged = false, deniedOnLocation = false, quanDraggable }: OverRides
) => {
  if (quanDraggable !== undefined)
    if (quanDraggable < 0) itemStatus = ContainerItemStatuses.AUTOMATED_REMOVAL;
  return {
    filterColor: pickColor(businessID, itemStatus, stagingStatus, {
      tagged,
      deniedOnLocation,
    }),
    ribbonText: pickText(businessID, itemStatus, stagingStatus, {
      tagged,
      deniedOnLocation,
    }),
  };
};

const pickColor = (
  businessID: number = 0,
  itemStatus: ContainerItemStatuses = 0,
  stagingStatus: StagingBuilderStatuses,
  { tagged, deniedOnLocation }: OverRides
): string => {
  if (businessID === 1) {
    if (
      tagged === false &&
      (stagingStatus === StagingBuilderStatuses.SCHEDULED_AND_TAGGED ||
        stagingStatus === StagingBuilderStatuses.PAID)
    )
      return "orange";

    switch (itemStatus) {
      case ContainerItemStatuses.STAGED:
        if (deniedOnLocation) return "var(--logo-blue)";
        return "";
      case ContainerItemStatuses.TURNED_AWAY_AT_STAGING:
        return "red";
      case 26:
        return "pink";
      case 27:
        return "#80d7ff";
      // case 28:
      //   return "#FFFF99";
      // case 9:
      //   return "mediumspringgreen";
      default:
        return "";
    }
  } else {
    if (itemStatus === 26 || itemStatus === 27) return "pink";
    else return "";
  }
};

const pickText = (
  businessID: number = 0,
  itemStatus: ContainerItemStatuses = 0,
  stagingStatus: StagingBuilderStatuses,
  { tagged, deniedOnLocation }: OverRides
): string => {
  if (
    tagged === false &&
    (stagingStatus === StagingBuilderStatuses.SCHEDULED_AND_TAGGED ||
      stagingStatus === StagingBuilderStatuses.PAID)
  )
    return "Needs Tagged";

  if (businessID === 1) {
    switch (itemStatus) {
      case ContainerItemStatuses.STAGED:
        if (deniedOnLocation) return "Denied on Location";
        return "";
      case ContainerItemStatuses.TURNED_AWAY_AT_STAGING:
        return "Turned Away";
      case 26:
        return "Can't Find Enough";
      case 27:
        return "Out of Stock in DB";
      // case 28:
      //   return "Not Enough in DB";
      default:
        return "";
    }
  } else {
    switch (itemStatus) {
      case ContainerItemStatuses.STAGED:
        if (deniedOnLocation) return "Denied on Location";
        return "";
      case ContainerItemStatuses.TURNED_AWAY_AT_STAGING:
        return "Turned Away";
      case 26:
        return "Out of Stock";
      case 27:
        return "Out of Stock";
      // case 28:
      //   return "Out of Stock";
      default:
        return "";
    }
  }
};
