import React from "react";
import { Link } from "react-router-dom";

interface Props {
  disabled: boolean;
}

export const ScanItems = ({ disabled }: Props) => {
  return (
    <Link to={{ pathname: "/stage", search: "left-side=scan" }}>
      <button
        type="button"
        disabled={disabled}
        className={`h-full btn ${
          disabled ? "btn-disabled btn-item-container" : "btn-item-container"
        } p-1`}
      >
        Scan Items
      </button>
    </Link>
  );
};
