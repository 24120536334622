import { useCallback, useRef, useState } from "react";
import { Canvas, RomaineRef, useRomaine, isCrossOriginURL } from "romaine";
import type { ImageExportOptions } from "romaine";
import {
  CropperIcon,
  FolderSelection,
  FullReset,
  PerspectiveIcon,
  RotateLeft,
  RotateRight,
  UndoIcon,
} from "romaine-components";
import { useMeasure } from "react-use";
import { useEffect } from "react";
interface RomaineExampleProps {
  setBlob?: (blob: Blob | null) => void;
  image?: string | null;
  imageExportOptions?: Partial<ImageExportOptions>;
}
/**
 * @todo 1) move the get blob button into its own file
 */
export const RomaineCanvas = ({
  setBlob,
  image = null,
  imageExportOptions = { quality: 0.8, type: "image/jpeg" },
}: RomaineExampleProps) => {
  const RomaineRef = useRef<RomaineRef>(null);
  const { loaded, setMode } = useRomaine();
  const [state, setstate] = useState<File | string | null>(image);
  const [containerRef, { x, y, width, height, top, right, bottom, left }] =
    useMeasure<HTMLDivElement>();
  console.log(width, height);
  useEffect(() => {
    function pasteEventListenter(e: ClipboardEvent) {
      console.log(e);

      const { clipboardData } = e as any;
      if (clipboardData) {
        const items = clipboardData.items;
        if (items) {
          for (let i = 0; i < items.length; i++) {
            // file
            if (items[i].kind === "file") {
              console.log("running file");
              const file = items[i].getAsFile();
              if (file) {
                setstate(file);
                // only do first file until layers is working
                return;
              }
            }
          }
          for (let i = 0; i < items.length; i++) {
            // url
            if (items[i].kind === "string") {
              console.log("running url");
              let shouldReturn = false;
              items[i].getAsString((url: string) => {
                if (url) {
                  setstate(url);
                  shouldReturn = true;
                }
              });
              if (shouldReturn) return;
            }
          }
        }
      }
    }
    window.addEventListener("paste", pasteEventListenter as any);
    return () => {
      window.removeEventListener("paste", pasteEventListenter as any);
    };
  }, []);

  // This function along with the api route removed cors errors
  // when the image is loaded from a url that is not the same origin
  // and has a cors setting that is not *
  const handleCrossOriginUrlCheck = useCallback(
    (image: File | string | null) => {
      if (typeof window !== "undefined") {
        if (typeof image === "string") {
          const isCrossOrigin = isCrossOriginURL(image as string);
          if (isCrossOrigin) {
            return "/api/images/un-cors-image?url=" + image;
          }
          return image;
        }
        return image;
      }
      return null;
    },
    []
  );

  return (
    <div className="absolute h-[95%] w-[95%]">
      <div
        className="relative h-full w-full border border-black pr-64"
        ref={containerRef}
      >
        {loaded && (
          <>
            <Canvas
              saltId={Math.random().toString()}
              ref={RomaineRef}
              image={handleCrossOriginUrlCheck(state)}
              maxHeight={height}
              maxWidth={width}
              onChange={() => {}}
              onDragStop={() => {}}
              pointSize={5}
              lineWidth={1}
            />

            <FolderSelection
              image={state}
              getFiles={(files: string | FileList | null) =>
                files && setstate(typeof files === "string" ? files : files[0])
              }
            >
              <span style={{ display: "grid", placeItems: "center" }}>
                {state ? (
                  "Choose a Different File"
                ) : (
                  <p className="capitalize">
                    <span>Choose an image to get started:</span>
                    <br />
                    <span>1) Click here to browse for a File</span>
                    <br />
                    <span>2) Drag a File over</span>
                    <br />
                    <span>
                      3) Paste an image or an image url from your clipboard
                    </span>
                  </p>
                )}
              </span>
            </FolderSelection>
            <button
              style={{
                position: "absolute",
                right: 0,
                bottom: "3ch",
                zIndex: 400,
                outline: "thin solid black",
                borderRadius: 0,
                fontSize: "16px",
                background: "white",
                width: "240px",
                border: "none",
                cursor: "pointer",
              }}
              onClick={async () => {
                setMode && setMode(null);
                // need to let mode actually get set to null
                // React 18 useTransition would be nice here...
                // but for backwards compatability currently doing this...
                setTimeout(async () => {
                  console.log(
                    await RomaineRef.current?.getDataURL?.({
                      ...imageExportOptions,
                    })
                  );
                  if (setBlob && RomaineRef.current?.getBlob) {
                    const newBlob =
                      (await RomaineRef.current?.getBlob({
                        ...imageExportOptions,
                      })) || null;
                    console.log(newBlob);
                    setBlob(newBlob);
                  } else {
                    console.warn(
                      "You must give the example setBlob as an input"
                    );
                  }
                }, 0);
              }}
            >
              Export Image
            </button>
            <div
              style={{
                position: "absolute",
                top: "0",
                bottom: "0",
                right: "0",
                width: "240px",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(6, 1fr)",
                }}
              >
                <RotateLeft />
                <RotateRight />
                <CropperIcon />
                <PerspectiveIcon />
                <FullReset />
                <div></div>
                <UndoIcon />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
