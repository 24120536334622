import { Dispatch, SetStateAction, useEffect } from "react";
import { Portal } from "react-portal";
import { itemMinimal } from "../../../../redux/inventory/inventoryTypes";
import { LocaleNumber } from "../../../constants";
import { EnlargedPicture } from "../../../Inventory/EnlargedPicture";
import { useBinningContext } from "../../../Scanner/BinControl/useBinNumber";

interface Props {
  rowItem: itemMinimal | itemVerbose;
  setExpandThumbnail: Dispatch<SetStateAction<boolean>>;
  target: string;
}
export const ItemHoverView = ({
  rowItem: item,
  setExpandThumbnail,
  target,
}: Props) => {
  const { itemLocation, getItemLocation } = useBinningContext();
  //@ts-ignore because it is not on itemVerbose and I am lazy (also it is only used in here)
  //@ts-ignore
  const { locationID }: { locationID: number } = item;
  useEffect(() => {
    getItemLocation && getItemLocation(locationID as unknown as number);
    // disabling because getItemLocation is updated when it is run which creates an infinite loop
    // eslint-disable-next-line
  }, [locationID]);
  return (
    <Portal node={document?.getElementById(target)}>
      <div
        onMouseEnter={() => setExpandThumbnail(false)}
        className="z-50 absolute top-0 left-0 bottom-0 right-0 bg-gray-300 grid grid-rows-2"
      >
        <div className="grid col-2">
          <EnlargedPicture src={item.pictureA} alt={"item-" + item.itemNo} />
          <EnlargedPicture src={item.pictureB} alt=" " />
        </div>
        <div className="px-6">
          <h1 className="text-4xl p-3">{item.itemName}</h1>
          <div className="grid grid-cols-2 gap-6">
            <article>
              <h2 className="text-white pl-4 pt-2 mb-3 text-2xl pb-2 bg-gray-800">
                Item Information
              </h2>
              <div className="grid auto-1fr gap-x-3 gap-y-1 text-xl">
                <p>Item Number: </p>
                <p>{item.itemNo}</p>
                <p>Item Name: </p>
                <p>{item.itemName}</p>
                <p>Location:</p>
                <p>{itemLocation?.locationName}</p>
                <p>Dimensions: </p>
                <p>{item.dimensions}</p>
                <p>Monthly Rate: </p>
                <p className="sup-dollar">
                  {LocaleNumber(item.rentalRate, false)}
                </p>
                <p>Retail Price: </p>
                <p className="sup-dollar">
                  {LocaleNumber(item.rentalPrice, false)}
                </p>
              </div>
            </article>
            <article>
              <h2 className="text-white pl-4 pt-2 mb-3 text-2xl pb-2 bg-gray-800">
                Comments
              </h2>
              {/* <p>{item?.webDescription}</p> */}
            </article>
          </div>
        </div>
      </div>
    </Portal>
  );
};
