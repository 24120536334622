import { TransferInTHead } from "./TransferInTHead";
import { TransferInTBody } from "./TransferInTBody";
import { Header } from "./Header";

export const TransferIn = () => {
  return (
    <div className="hundred-hundred">
      <Header />
      <table>
        <TransferInTHead />
        <TransferInTBody />
      </table>
    </div>
  );
};
