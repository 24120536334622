import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { fetchingData, Istate } from "../..";
import {
  StagingBuilderStatuses,
  ToastyRed,
} from "../../../components/constants";
import { updateStagingBuilderMeta } from "./updateStagingBuilderMeta";

export type SocketMetaChange =
  | {
      type?: "META";
      action?: "UPDATE";
      payload: {
        userID?: number;
        stagingBuilder: {
          stagingBuilderID?: number;
          deliveryActual?: number;
          deliveryDesired?: number;
          pickupActual?: number;
          pickupDesired?: number;
        };
      };
    }
  | {
      type?: "META";
      action?: "STATUS";
      payload: {
        userID?: number;
        status: StagingBuilderStatuses;
        stagingBuilder: {
          stagingBuilderID?: number;
          deliveryActual?: number;
          deliveryDesired?: number;
          pickupActual?: number;
          pickupDesired?: number;
        };
      };
    };

/**
 * payload defined by socket structure in api... see `@interface`
 *
 * @emits STAGING_BUILDER_UPDATE
 *
 * @listens FULL_STAGING_BUILDER_UPDATE - reply sent to this listener
 *
 * @interface SocketMetaChange
 * ```
 *  {
 *.   type: "META";     // defaults to "META"
 *.   action: "UPDATE" | "STATUS"; // defaults to "UPDATE"
 *.   payload: {
 *.     stagingBuilderID: number; // will default to activeStagingBuilderID
 *.     deliveryActual: number;
 *.   };
 * }
 * ```
 * @template nominal dispatch(socketDeliveryChange({payload:{stagingBuilderID,status:newStagingStatus,currentStagingStatus}}));
 */
export const socketDateChange = (
  payload: SocketMetaChange
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: Function
  ): Promise<void> => {
    try {
      const {
        deliveryActual,
        deliveryDesired,
        pickupActual,
        pickupDesired,
        stagingBuilderID: SBid,
      } = payload.payload.stagingBuilder;

      if (
        !deliveryActual &&
        !deliveryDesired &&
        !pickupActual &&
        !pickupDesired
      )
        throw new Error(
          "Something went wrong while processing that request. This is most likely a problem with the server. We are sorry for the inconvenience"
        );

      dispatch(fetchingData()); // active loading sign
      const {
        router: {
          location: { pathname },
        },
        data: {
          socket,
          user: { id: userID, activeStagingBuilder: stagingBuilderID },
          stagingBuilders,
        },
      } = getState() as Istate;

      if (socket?.disconnected) {
        ToastyRed(
          "Something went wrong when connecting to the server. Attempting to reconnect..."
        );
        socket.connect();
      } else if (/calendar/gi.test(pathname))
        UpdateReduxBeforeSocket: {
          // some fairly annoying code to prevent calendar events from "jumping"
          const stagingBuilder = stagingBuilders.find(
            ({ stagingBuilderID }) => stagingBuilderID === SBid
          );
          if (stagingBuilder === undefined) break UpdateReduxBeforeSocket;
          (
            Object.keys(
              payload.payload.stagingBuilder
            ) as (keyof SocketMetaChange["payload"]["stagingBuilder"])[]
          ).forEach((key) => {
            if (/stagingBuilderID/.test(key)) return;
            stagingBuilder[key] = payload.payload.stagingBuilder[key] || 0;
          });
          dispatch(updateStagingBuilderMeta(stagingBuilder));
        }
      // Makes userID optional, but take it from redux so we dont get a userID mismatch
      payload.payload.userID = payload.payload.userID
        ? payload.payload.userID
        : userID;

      // dont let calendar use this api with undefined StagingBuilders
      // this is because the calendar does not use activeStagingBuilder
      if (
        /calendar/gi.test(pathname) &&
        payload.payload.stagingBuilder.stagingBuilderID === undefined
      )
        throw new Error(
          "Something went wrong while processing that request. Refreshing the page make fix this issue."
        );

      payload.payload.stagingBuilder.stagingBuilderID = payload.payload
        .stagingBuilder.stagingBuilderID
        ? payload.payload.stagingBuilder.stagingBuilderID
        : stagingBuilderID;

      if (!(Number(payload.payload.stagingBuilder.stagingBuilderID) > 0))
        throw new Error(
          "Something went wrong while processing that request. Refreshing the page make fix this issue."
        );

      // default action and type, these should maybe just be hardcoded to prevent abuse...
      if (payload.type === undefined) payload.type = "META";
      if (payload.action === undefined) payload.action = "UPDATE";

      socket?.emit("STAGING_BUILDER_UPDATE", JSON.stringify(payload));
    } catch (err) {
      console.error(err);
      if (typeof err === "string") ToastyRed(err);
    }
  };
};
