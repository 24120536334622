import * as Yup from "yup";

export interface InitialValues {
  email: string;
  phone: string | undefined;
  first: string;
  last: string;
  phoneAgreement1: boolean;
  phoneAgreement2: boolean;
  emailAgreement1: boolean;
  emailAgreement2: boolean;
}
interface returnValues {
  initialValues: InitialValues;
  validationSchema: Yup.ObjectSchema<InitialValues | undefined, object>;
}
export const formInit = (user: User): returnValues => {
  const validationSchema =
    user.businessID !== 1
      ? Yup.object({
          email: Yup.string().required("Email is a required field"),
          phone: Yup.string()
            .required("Required")
            .min(10, "Too Short")
            .matches(
              /^(\+1)?( |-)?\(?\d{3}\)?( |-)?\d{3}( |-)?\d{4}$/,
              "Incorrect Phone Number Format"
            ),
          first: Yup.string().required("First Name is a required field"),
          last: Yup.string().required("Last Name is a required field"),
          phoneAgreement1: Yup.boolean().required(),
          phoneAgreement2: Yup.boolean().required(),
          emailAgreement1: Yup.boolean().required(),
          emailAgreement2: Yup.boolean().required(),
        })
      : Yup.object({
          email: Yup.string().required("Email is a required field"),
          phone: Yup.string()
            .min(10, "Too Short")
            .matches(
              /^(\+1)?( |-)?\(?\d{3}\)?( |-)?\d{3}( |-)?\d{4}$/,
              "Incorrect Phone Number Format"
            ),
          first: Yup.string().required("First Name is a required field"),
          last: Yup.string().required("Last Name is a required field"),
          phoneAgreement1: Yup.boolean().required(),
          phoneAgreement2: Yup.boolean().required(),
          emailAgreement1: Yup.boolean().required(),
          emailAgreement2: Yup.boolean().required(),
        });
  return {
    initialValues: {
      email: user.email ?? "",
      phone: user.phone ?? "",
      first: user.first ?? "",
      last: user.last ?? "",
      phoneAgreement1: Boolean(user.agreement1),
      phoneAgreement2: Boolean(user.agreement2),
      emailAgreement1: Boolean(user.agreement3),
      emailAgreement2: Boolean(user.agreement4),
    },
    validationSchema,
  };
};
