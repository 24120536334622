import { useState } from "react";

type restoreType = "initial" | "save";
export type UseBooleanState = <T>(x: T) => BooleanState<T>;

export interface BooleanState<T> {
  values: {
    [Property in keyof T]?: boolean | undefined;
  };
  savedValues: {
    readonly [Property in keyof T]?: boolean | undefined;
  };
  initialValues: {
    readonly [Property in keyof T]?: boolean | undefined;
  };
  dirty: boolean;
  /**
   *  resets the state to the initially provided state
   */
  resetState: (restore?: restoreType) => void;
  /**
   *
   */
  set: (index: keyof T, value?: T[keyof T] | undefined) => void;
  /**
   * Saves the current state for use again. Can get it back by resetState("save")
   */
  save: () => void;
}

export function useBooleanState<T>(initialValues: T) {
  const [checkState, setCheckState] = useState({
    values: { ...initialValues },
    savedValues: { ...initialValues },
    initialValues,
    dirty: false,
    resetState: (restore: restoreType = "initial") => {
      if (restore === "initial")
        setCheckState((current) => {
          return { ...current, dirty: false, values: current.initialValues };
        });
      else
        setCheckState((current) => {
          return { ...current, dirty: true, values: current.savedValues };
        });
    },
    set: (
      index: keyof typeof initialValues,
      value?: typeof initialValues[keyof typeof initialValues]
    ) =>
      setCheckState((current) => {
        let values = { ...current.values } as typeof initialValues;
        if (value !== undefined) values[index] = value;
        else
          values[index] = !values[
            index
          ] as unknown as typeof initialValues[keyof typeof initialValues];

        return {
          ...current,
          dirty: true,
          values,
        };
      }),
    save: () =>
      setCheckState((current) => {
        return { ...current, dirty: false, savedValues: current.values };
      }),
  });

  return checkState;
}
