import { Dispatch } from "react";
import { toast } from "react-toastify";
import { StagingBuilderStatuses } from "../..";
import { handleFileNumberChange } from "../../../../redux/stagingBuilder/thunks/handleFileNumberChange";

process.env.NODE_ENV === "development" &&
  console.warn("Need to add a permission");
interface HandleFileNumberClickProps {
  stagingStatus: StagingBuilderStatuses;
  fileNumber: number | null;
  businessID: number | undefined;
}
export const handleFileNumberClick = (
  dispatch: Dispatch<any>,
  { businessID, fileNumber, stagingStatus }: HandleFileNumberClickProps
) => {
  const canChangeFileNumber = CanChangeFileNumber(stagingStatus);
  if (!canChangeFileNumber && fileNumber !== null)
    notifyFailure(
      "Sorry, you cannot change the File Number of a StagingBuilder at this current point."
    );
  else if (businessID === 1) {
    const SnewFileNumber = prompt(
      'Please Enter a File Number.\nType "clear" to remove file number.',
      fileNumber !== null && fileNumber !== 0 ? fileNumber.toString() : ""
    );
    if (SnewFileNumber === null || SnewFileNumber === "") return;
    if (SnewFileNumber.toLowerCase() === "clear") {
      dispatch(
        handleFileNumberChange({
          fileNumber: 0,
          notify: notifyFileChangedSuccess,
        })
      );
      return;
    }
    const newFileNumber = Number(SnewFileNumber);
    if (
      !isNaN(newFileNumber) &&
      newFileNumber !== 0 &&
      newFileNumber !== Number(fileNumber)
    ) {
      const accept = window.confirm(
        "You are about to change the file number for this StagingBuilder.\nAre you sure you want to do this?"
      );
      if (accept)
        dispatch(
          handleFileNumberChange({
            fileNumber: newFileNumber,
            notify: notifyFileChangedSuccess,
          })
        );
      else return;
    } else notifyFailure("File Number not changed. Error: Invalid File Number");
  }
};

const notifyFileChangedSuccess = (content: string) =>
  toast.success(content, { position: "top-left" });

const notifyFailure = (content: string) => toast.error(content);

const CanChangeFileNumber = (stagingStatus: StagingBuilderStatuses) => {
  switch (stagingStatus) {
    case StagingBuilderStatuses.STAGED:
      return false;
    case StagingBuilderStatuses.CLOSED:
      return false;
    case StagingBuilderStatuses.SCHEDULED_AND_TAGGED:
      return false;
    case StagingBuilderStatuses.PAID:
      return false;
    case StagingBuilderStatuses.PICKUP_REQUEST:
      return false;
    case StagingBuilderStatuses.PICKUP_SCHEDULED:
      return false;
    default:
      return true;
  }
};
