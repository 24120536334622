import React from "react";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";

import FormikControl from "../../../../FormikComponents/FormikControl";
import { PromptIfDirty } from "../../../../FormikComponents/PromptIfDirty";
import { useDispatch } from "react-redux";
import { AppThunk } from "../../../../../redux";
import { newStagingBuilderNote } from "../../../../../redux/stagingBuilder/stagingBuilderActions";
import { CustomButton } from "../../../../HighlyReusableComponents/CustomButton";

interface Props {}
export const CommentForm = (props: Props) => {
  const dispatch: AppThunk = useDispatch();
  const initialValues = {
    comment: "",
  };
  const validationSchema = Yup.object({
    comment: Yup.string().required(""),
  });
  const onSubmit = (
    values: typeof initialValues,
    actions: FormikHelpers<typeof initialValues>
  ) => {
    // need to pass actions
    // need to clear field on success
    dispatch(newStagingBuilderNote({ message: values, actions: actions }));
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validateOnBlur={false}
      >
        {(formikProps: FormikProps<any>) => (
          <Form>
            <PromptIfDirty />
            <div style={{ display: "grid", gridTemplateColumns: "1fr 20ch" }}>
              <FormikControl
                control="input"
                label="Comment"
                labelClass="leading-8"
                name="comment"
                styledClass="focus:outline-none focus:ring"
                inLineLabelError
              />
              <CustomButton
                appendClassName="w-full py-1.5 ml-1 mt-8"
                type="submit"
                disabled={!(formikProps.isValid && formikProps.dirty)}
              >
                Submit Comment
              </CustomButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
