import React from "react";
import { HeaderGroup, TableHeaderProps } from "react-table";
import { ContextMenu } from "../../../HighlyReusableComponents/ContextMenu";
import { FileContextMenu2 } from "./FileContextMenu";
import { StagerContextMenu } from "./StagerContextMenu";
import { StatusContextMenu } from "./StatusContextMenu";

interface Props {
  column: HeaderGroup<StagingBuilder & object>;
  headerProps?: TableHeaderProps;
  children?: React.ReactNode;
}

const ProposalContextMenu = ({ column }: Props) => {
  const { setFilter } = column;
  //fileNumber is currently turned off via regex
  return {
    fileNumber: <FileContextMenu2 setFilter={setFilter} />,
    stagingStatus: <StatusContextMenu setFilter={setFilter} />,
    stagerName: <StagerContextMenu column="stagerName" setFilter={setFilter} />,
    clientName: <StagerContextMenu column="clientName" setFilter={setFilter} />,
  };
};
type ReturnTypes = "fileNumber" | "stagingStatus" | "stagerName" | "clientName";
export const ProposalTHWrapper: React.FC<Props> = ({
  children,
  column,
  ...headerProps
}) => {
  // const { key, ...headerProps } = column.getHeaderProps(
  //   column.getSortByToggleProps()
  // );
  return column.id.match(/stagingStatus|stagerName|clientName/) ? (
    <ContextMenu
      style={{ backgroundColor: "var(--logo-blue)" }}
      menu={ProposalContextMenu({ column })[column.id as ReturnTypes]}
    >
      {(contextEvent) => (
        <th onContextMenu={contextEvent} {...headerProps}>
          {column.render("Header")}
        </th>
      )}
    </ContextMenu>
  ) : (
    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
      {column.render("Header")}
    </th>
  );
};

export { FileContextMenu } from "./FileContextMenu";
export { StatusContextMenu } from "./StatusContextMenu";
export { StagerContextMenu } from "./StagerContextMenu";
