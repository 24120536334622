import React, { useState, useEffect } from "react";
import { ErrorMessage, Field, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import TextError from "./TextError";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, shallowEqual } from "react-redux";
import { Istate } from "../../redux";
interface Props {
  label: React.ReactNode;
  name: string;
  styledClass: string;
  disabled?: boolean;
  controlContainerclass?: string;
  labelClass?: string;
  inLineLabelError?: boolean;
}

export const DatePickerField = ({
  label,
  name,
  styledClass,
  disabled,
  controlContainerclass = "flex space-between",
  labelClass = "datepicker-label",
  inLineLabelError = false,
}: Props) => {
  return (
    <div className={controlContainerclass}>
      <label className={labelClass} htmlFor={name}>
        {label}
      </label>
      {inLineLabelError && <ErrorMessage name={name} component={TextError} />}
      <Field
        id={name}
        name={name}
        canBeDisabled={true}
        disabled={disabled}
        className={styledClass}
        component={DatePickerComponent}
      />
      {!inLineLabelError && <ErrorMessage name={name} component={TextError} />}
    </div>
  );
};

interface Props {}
const DatePickerComponent = (props: any) => {
  console.warn("Need to add better permissions");
  const canBackDateStagingBuilders = useSelector(
    (state: Istate) => state.data.user.businessID === 1,
    shallowEqual
  );
  const name: string = props.field.name;
  const formik = useFormikContext<any>();
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    formik.initialValues[name]
  );
  useEffect(() => {
    // prevents getting form dirty on initialization
    if (formik.values[name] !== selectedDate)
      formik.setFieldValue(
        name,
        selectedDate ? new Date(selectedDate).getTime() : 0
      );
  }, [selectedDate]);

  return (
    <DatePicker
      disabled={props.disabled}
      selected={selectedDate}
      onChange={(date) => setSelectedDate(date as any)}
      filterDate={(date) => {
        const day = date.getDay();
        return day !== 0;
      }}
      // min Date should be the day after tomorrow
      // if it is friday, then it should be tuesday
      minDate={
        canBackDateStagingBuilders
          ? null
          : new Date().getDay() !== 5
          ? new Date(new Date().setDate(new Date().getDate() + 2))
          : new Date(new Date().setDate(new Date().getDate() + 3))
      }
      dateFormat="eeee MMMM d, yyyy"
      isClearable={!props.disabled}
      scrollableMonthYearDropdown
      showDisabledMonthNavigation
      popperModifiers={{
        preventOverflow: {
          enabled: true,
        },
      }}
    />
  );
};
