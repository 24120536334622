import React from "react";
import { useSelector } from "react-redux";
import { CustomButton } from "../HighlyReusableComponents/CustomButton";

interface Props {}

export const ChooseScanner = (props: Props) => {
  const stagingBuilderID = useSelector(
    (state: Istate) => state.data.user.activeStagingBuilder
  );
  return (
    <div className="grid sm:grid-cols-3 gap-1">
      <CustomButton
        linkProps={{
          to: "/scanner/inventory-control",
        }}
        appendClassName="h-16 sm:h-52 w-32 px-4"
      >
        Inventory Control <br /> (Add/Remove Inventory)
      </CustomButton>
      <CustomButton
        linkProps={{
          to: "/scanner/pricing-control",
        }}
        appendClassName="h-16 sm:h-52 w-32 px-4"
      >
        Pricing Control <br /> (Change Prices)
      </CustomButton>
      <CustomButton
        linkProps={{ to: "/stage?left-side=scan" }}
        disabled={!stagingBuilderID}
        appendClassName="h-16 sm:h-52 w-32 px-4"
      >
        Scan Items <br />
        Into Staging
      </CustomButton>
      <CustomButton
        linkProps={{ to: "/scanner/tag-control" }}
        appendClassName="h-16 sm:h-52 w-32 px-4"
      >
        Tag Control <br /> (Print and Queue)
      </CustomButton>
      <CustomButton
        linkProps={{ to: "/scanner/bin-control" }}
        appendClassName="h-16 sm:h-52 w-32 px-4"
      >
        Bin Control <br /> (Set Item Locations)
      </CustomButton>
      <CustomButton
        linkProps={{ to: "/scanner/delta-inventory" }}
        appendClassName="h-16 sm:h-52 w-32 px-4"
      >
        Delta Inventory <br /> (For Inventory Reports)
      </CustomButton>
    </div>
  );
};
