import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";

import { BasicModal } from "../../Modals/BasicModal";
import { EditImportantTaskLogic } from "./Forms/ImportantTask/EditImportantTaskLogic";
import { ImportantTaskFields } from "./Forms/ImportantTask/ImportantTaskFields";

const modalStyle = {
  top: "30vh",
  left: "22vw",
  right: "22vw",
  bottom: "auto",
  overflow: "visible",
  height: "auto",
};

export const EditImportantTask = () => {
  const dispatch = useDispatch();
  const { query } = useSelector((state: Istate) => state.router.location);

  return (
    <BasicModal
      modalStyle={modalStyle}
      open={query["open-modal"] === "edit-task"}
      setOpen={() => dispatch(push("/calendar"))}
      employeeOnly
    >
      <h1 className="text-2xl">Edit Important Task</h1>
      <EditImportantTaskLogic>
        {(formikProps) => <ImportantTaskFields formikProps={formikProps} />}
      </EditImportantTaskLogic>
    </BasicModal>
  );
};
