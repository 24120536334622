import { FormikProps, useField } from "formik";
import { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useCalendar } from "../../../../constants/contexts/calendar/CalendarContext";
import { TaskSubmissionValues } from "../../../../constants/hooks/fetching/useCalendarTasks";
import FormikControl from "../../../../FormikComponents/FormikControl";
import { CustomButton } from "../../../../HighlyReusableComponents/CustomButton";
import { TextAreaCounterMemo } from "../../../../HighlyReusableComponents/FormComponents/TextArea_counter";

interface Props {
  formikProps: FormikProps<TaskSubmissionValues>;
}

export const ImportantTaskFields = ({
  formikProps: {
    submitForm,
    values: { "task-description": taskDescription },
  },
}: Props) => {
  const [commentsField, ,] = useField("task-description");
  const placeHolder = useMemo(
    () => taskDescription.match(/(\w| ){0,25}(\n|$)/gi)?.[0] || "",
    [taskDescription]
  );
  const {
    tasks: { deleteTask },
  } = useCalendar();
  const taskID = useSelector(
    (state: Istate) => state.router.location.query["task"],
    shallowEqual
  );
  return (
    <div className="mt-6 grid-cols-4 grid gap-x-2">
      <div className="col-span-2">
        <FormikControl
          styledClass="h-4ch"
          controlContainerclass="basic-input w-3/4"
          control="input"
          label="Title"
          name="title"
          placeholder={placeHolder}
        />
        <div className="mt-3">
          <label htmlFor="task-description">Task Description</label>
          <TextAreaCounterMemo
            id="task-description"
            className="border border-solid rounded p-1 border-gray-400 focus:outline-none focus:ring"
            style={{ width: "52ch", height: "12rem" }}
            {...commentsField}
            maxLength={512}
          />
        </div>
      </div>
      <div className="flex flex-col justify-between">
        <div>
          <FormikControl
            labelClass="block"
            controlContainerclass="basic-input block span-3"
            label="Scheduled Task Date"
            name="task-time"
            control="date-picker"
          />
          <FormikControl
            styledClass="h-4ch"
            controlContainerclass="basic-input w-40 mt-3"
            control="input"
            label="Est. Hours Req'd"
            name="hours"
            placeholder="Suite #, Apt #, etc. "
          />
        </div>
        <CustomButton appendClassName="mb-1" onClick={submitForm}>
          Submit Calendar Event
        </CustomButton>
      </div>
      <div className="flex flex-col justify-between">
        <span></span>
        {deleteTask && taskID && (
          <CustomButton
            appendClassName="mb-1 bg-black text-white"
            onClick={() => deleteTask({ taskID: Number(taskID) })}
          >
            Delete Calendar Event
          </CustomButton>
        )}
      </div>
    </div>
  );
};
