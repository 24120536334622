import { useEffect } from "react";

import { shallowEqual, useSelector } from "react-redux";
import { Istate } from "../../../redux";

import { FieldArray, FormikProps } from "formik";

import StagingBuilderRoom from "./StagingBuilderRoom";

interface Iprops {
  FormikProps: FormikProps<any>;
  showTrash: boolean;
  setShowTrash: React.Dispatch<React.SetStateAction<boolean>>;
  edit: boolean;
  headerHeight: number;
}
function StagingBuilderContainers({
  FormikProps,
  showTrash,
  setShowTrash,
  edit,
  headerHeight,
}: Iprops) {
  const itemContainers = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex]
        ?.itemContainers,
    shallowEqual
  );
  const rerender = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex]
        ?.itemContainers?.length
  );
  useEffect(() => {
    FormikProps.resetForm();
    // eslint-disable-next-line
  }, [rerender]);
  return itemContainers ? (
    <FieldArray
      name="itemContainers"
      render={(arrayHelpers) => (
        <div id="item-containers">
          {itemContainers?.map((itemContainer, index) =>
            itemContainer.active && itemContainer.containerType !== 0 ? (
              <StagingBuilderRoom
                key={`container-${itemContainer.itemContainerID}`}
                index={index}
                arrayHelpers={arrayHelpers}
                trash={false}
                edit={edit}
                headerHeight={headerHeight}
              />
            ) : showTrash ? (
              <StagingBuilderRoom
                key={`container-${itemContainer.itemContainerID}`}
                index={index}
                arrayHelpers={arrayHelpers}
                trash={true}
                setShowTrash={setShowTrash}
                edit={edit}
                headerHeight={headerHeight}
              />
            ) : null
          )}
        </div>
      )}
    />
  ) : null;
}

export default StagingBuilderContainers;
