import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { Login, Create, Recover, ResetPassword } from "./user/";

export const UserRouter = () => {
  const recoveryString = useSelector(
    (state: Istate) => state.router.location.query.recoveryString
  );
  return (
    <Switch>
      <Route exact path="/user/login">
        <Login />
      </Route>
      <Route exact path="/user/recover-account">
        <Recover />
      </Route>
      <Route exact path="/user/create-account">
        <Create />
      </Route>
      <Route path="/user/reset-password">
        <ResetPassword recoveryString={recoveryString as string | null} />
      </Route>
    </Switch>
  );
};
