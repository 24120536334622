import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { tagInfo } from "..";
import { Istate } from "../../../redux";
import { Sheet } from "./Sheet";
import "../print.scss";

export const Queue = () => {
  // get print queue
  // make sure that all the tags that are trying to be printed are supported
  //    if not take them out of the queue (here)
  // split tags into groups of 4 then pass to Sheet
  const queue = useSelector(
    (state: Istate) => state.data.inventory,
    shallowEqual
  );
  const printableAreaRef = React.useRef<HTMLDivElement>(null);
  const sheets = queue.map<tagInfo[]>((tag, index) => {
    if (queue[index + 3]) {
      return [
        {
          itemNo: queue[index].itemNo,
          itemName: queue[index].itemName,
          dimensions: queue[index].dimensions,
          pictureA: queue[index].pictureA,
        },
        {
          itemNo: queue[index + 1].itemNo,
          itemName: queue[index + 1].itemName,
          dimensions: queue[index + 1].dimensions,
          pictureA: queue[index + 1].pictureA,
        },
        {
          itemNo: queue[index + 2].itemNo,
          itemName: queue[index + 2].itemName,
          dimensions: queue[index + 2].dimensions,
          pictureA: queue[index + 2].pictureA,
        },
        {
          itemNo: queue[index + 3].itemNo,
          itemName: queue[index + 3].itemName,
          dimensions: queue[index + 3].dimensions,
          pictureA: queue[index + 3].pictureA,
        },
      ];
    } else if (queue[index + 2]) {
      return [
        {
          itemNo: queue[index].itemNo,
          itemName: queue[index].itemName,
          dimensions: queue[index].dimensions,
          pictureA: queue[index].pictureA,
        },
        {
          itemNo: queue[index + 1].itemNo,
          itemName: queue[index + 1].itemName,
          dimensions: queue[index + 1].dimensions,
          pictureA: queue[index + 1].pictureA,
        },
        {
          itemNo: queue[index + 2].itemNo,
          itemName: queue[index + 2].itemName,
          dimensions: queue[index + 2].dimensions,
          pictureA: queue[index + 2].pictureA,
        },
      ];
    } else if (queue[index + 1]) {
      return [
        {
          itemNo: queue[index].itemNo,
          itemName: queue[index].itemName,
          dimensions: queue[index].dimensions,
          pictureA: queue[index].pictureA,
        },
        {
          itemNo: queue[index + 1].itemNo,
          itemName: queue[index + 1].itemName,
          dimensions: queue[index + 1].dimensions,
          pictureA: queue[index + 1].pictureA,
        },
      ];
    } else
      return [
        {
          itemNo: queue[index].itemNo,
          itemName: queue[index].itemName,
          dimensions: queue[index].dimensions,
          pictureA: queue[index].pictureA,
        },
      ];
  });
  const handlePrintClick = () => {
    if (printableAreaRef.current?.innerHTML) {
      const w = window.open();
      w?.document.write(`
      <!DOCTYPE html>
      <html lang="en">
        <head>
          <meta charset="UTF-8"/>
          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
          <link href="https://fonts.googleapis.com/css2?family=Libre+Barcode+128&display=swap" rel="stylesheet"> 
          <link rel="stylesheet" type="text/css" href="/api/css/print-styles.css">
          <title>Print Tags</title>
        </head>
        <body>
          <div class="printable-container">
            ${printableAreaRef.current.innerHTML}
          </div>
          <script>
          const interval = setInterval(()=>{
              if (document.styleSheets.length>1){
                window.requestAnimationFrame(()=>{
                  setTimeout(()=>{
                    window.print();
                  },100)
                });
                clearInterval(interval)
              }
            },50)
          </script>
        </body>
      </html>`);
    }
  };

  return (
    <div className="printable-container">
      <button
        style={{ width: "200px", height: "4ch" }}
        onClick={handlePrintClick}
      >
        Click To Print
      </button>
      <div ref={printableAreaRef}>
        {sheets.map((sheet, index) =>
          index < 4 ? <Sheet tags={sheet} /> : null
        )}
      </div>
    </div>
  );
};
