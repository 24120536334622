import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import useSWR from "swr";
import { fetcher } from "../../../../fetcher";
import { ToastyRed } from "../../../constants";
import { Data } from "../../inventory/delta/controls/Main";
import { options } from "../../inventory/delta/options";
import { ScannerForm } from "./ScannerForm";
import { useBinningContext } from "../../../Scanner/BinControl/useBinNumber";

interface Props {
  barcodeRef: React.RefObject<HTMLInputElement>;
}

export const Main = ({ barcodeRef }: Props) => {
  const { data, error } = useSWR<Data>(
    "/api/effects/get/user-section",
    fetcher
  );
  const dispatch = useDispatch();
  const { locationName } = useBinningContext();

  if (data?.section === "0") dispatch(push("/inventory/delta-inventory"));
  if (error) ToastyRed(`something went wrong. ${error}`, { toastId: "error" });
  return (
    <div>
      <div style={{ top: "1em", right: "1em" }} className="absolute grid">
        <h1>
          Scanning For Section:{" "}
          {options.find(({ value }) => value === data?.section)?.label}
        </h1>
        <h2>{locationName}</h2>
      </div>
      <ScannerForm barcodeRef={barcodeRef} />
    </div>
  );
};
