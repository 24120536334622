import React from "react";
import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";
import { AppThunk, logInAxios } from "../../../redux";

import { Formik, Form, FormikProps } from "formik";
import FormikControl from "../../FormikComponents/FormikControl";
import * as Yup from "yup";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { CustomButton } from "../../HighlyReusableComponents/CustomButton";

interface Props {
  recoveryString: string | null;
}
export const ResetPasswordForm = ({ recoveryString }: Props) => {
  const dispatch: AppThunk = useDispatch();
  const notify = (content: string) => toast(content);
  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const validationSchema = Yup.object({
    password: Yup.string()
      .required("Required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password must contain a number letter and special character"
      ),
    confirmPassword: Yup.string()
      .required()
      .label("Confirm password")
      .test("passwords-match", "Passwords do not match", function (value) {
        return this.parent.password === value;
      }),
  });

  const onSubmit = ({ password, confirmPassword }: typeof initialValues) => {
    axios({
      method: "post",
      url: `/api/form/users/recover-account/${recoveryString}`,
      data: {
        password: password,
        confirmPassword: confirmPassword,
      },
    })
      .then((res) => {
        console.log(res.data);
        if (res.data.success === true) {
          dispatch(logInAxios({ email: res.data.email, password }));
        }
      })
      .catch((err) => {
        if (err.response) notify(err.response.data.toast);
      });
  };

  return recoveryString && recoveryString.length > 127 ? (
    <div className="mt-3 mx-8">
      <ToastContainer />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(props: FormikProps<any>) => (
          <Form>
            <FormikControl
              control="input"
              type="password"
              label="New Password"
              name="confirmPassword"
              inLineLabelError={true}
              styledClass="focus:outline-none focus:ring"
            />
            <FormikControl
              control="input"
              type="password"
              label="Confirm Password"
              name="password"
              inLineLabelError={true}
              styledClass="focus:outline-none focus:ring"
            />
            <button
              type="submit"
              className="bg-logo-blue w-full py-1.5 mt-2 rounded-lg border border-gray-400 focus:outline-none focus:ring"
            >
              Log In
            </button>
          </Form>
        )}
      </Formik>
      <div className="flex justify-between">
        <Link to="/user/recover-account">Forgot Password?</Link>
        <Link className="right" to="/user/create-account">
          Create Account
        </Link>
      </div>
    </div>
  ) : (
    <div className="mt-3 mx-8">
      <p className="input-container">
        Something went wrong, please try recovering your account again by
        clicking the button below
      </p>
      <Link style={{ width: "100%" }} to="/user/recover-account">
        <CustomButton type="button">Recover Account</CustomButton>
      </Link>
    </div>
  );
};
