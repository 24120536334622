import React, { useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Istate } from "../../../redux";
import { NavLi } from "../../Menu/NavLi";
import { AiFillTags } from "react-icons/ai";
import { GiMagnifyingGlass, GiSofa } from "react-icons/gi";
import { BsListCheck } from "react-icons/bs";
import { hrefPrefix } from "../../constants";
import { FaTags } from "react-icons/fa";
import { TfiWrite } from "react-icons/tfi";
import { format } from "date-fns";
interface Props {
  setPrintMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export const PrintDropDown = ({ setPrintMenuOpen }: Props) => {
  const { businessID, activeStagingBuilder: stagingBuilderID } = useSelector(
    (state: Istate) => state.data.user,
    shallowEqual
  );
  const { fileNumber, deliveryActual, deliveryDesired } = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex],
    shallowEqual
  );
  const closeMenu = () => {
    setPrintMenuOpen(false);
  };
  useEffect(() => {
    window.removeEventListener("click", closeMenu);
    window.addEventListener("click", closeMenu);
    return () => {
      window.removeEventListener("click", closeMenu);
    };
  }, []);
  return (
    <div
      className="absolute sb-dropdown z-6"
      // onClickCapture={(e) => e.stopPropagation()}
    >
      <div>
        <ul>
          {/* Email to customer */}
          {/* <NavLi
            Icon={GiSofa}
            label="Unpriced Staging By Room My Logo"
            href={`/api/print/staging-builder/${stagingBuilderID}/hold-tags`}
          /> */}
          {/* Can email to customer with our prices on it */}
          {/* <NavLi
            Icon={GiSofa}
            label="Priced Staging By Room My Logo"
            href={`/api/print/staging-builder/${stagingBuilderID}/hold-tags`}
          /> */}
          <NavLi
            Icon={GiSofa}
            label="Inventory For Lease"
            href={`${hrefPrefix}/api/print/staging-builder/${stagingBuilderID}/staging-inventory`}
          />
          <NavLi
            Icon={GiSofa}
            label="Inventory For Lease (download)"
            href={`${hrefPrefix}/api/print/staging-builder/${stagingBuilderID}/staging-inventory/download`}
          />
          {businessID === 1 ? (
            <>
              <NavLi
                Icon={FaTags}
                label="Generic Hold Tags"
                query="open-modal=generic-hold-tags"
                target=""
              />
              <NavLi
                Icon={FaTags}
                label="Generic Hold Stickers"
                href={`https://tags.modelhomefurnitureoutlet.com/api/pdf-route?route=${encodeURIComponent(
                  `staging-rents/generic-hold-tags/stickers?file-number=${fileNumber}&delivery=${
                    deliveryActual || deliveryDesired
                  }&tag-start=1001`
                )}`}
                target="_blank"
              />
              <NavLi
                Icon={AiFillTags}
                label="Hold Tags"
                href={`${hrefPrefix}/api/print/staging-builder/${stagingBuilderID}/hold-tags`}
              />
              <NavLi
                Icon={AiFillTags}
                label="Hold Tags (untagged)"
                href={`${hrefPrefix}/api/print/staging-builder/${stagingBuilderID}/hold-tags`}
                query="only-untagged=true"
              />
              <NavLi
                Icon={GiMagnifyingGlass}
                label="Easter Egg Report"
                href={`${hrefPrefix}/api/print/staging-builder/${stagingBuilderID}/easter-egg`}
              />
              <NavLi
                Icon={BsListCheck}
                label="Lease To Sign"
                href={`${hrefPrefix}/api/print/staging-builder/${stagingBuilderID}/staging-lease/download`}
              />
              <NavLi
                Icon={BsListCheck}
                label="Day of Delivery - Inventory"
                href={`${hrefPrefix}/api/print/staging-builder/${stagingBuilderID}/check-in/download`}
              />
              <NavLi
                Icon={TfiWrite}
                label="Ledger Card For Binder"
                href={`https://service.stagingrents.com/api/page-to-pdf?pathname=${encodeURIComponent(
                  `/print/staging-builder/${stagingBuilderID}/ledger`
                )}`}
              />
              <NavLi
                Icon={TfiWrite}
                label="Invoice (today)"
                href={`https://service.stagingrents.com/api/page-to-pdf?pathname=${encodeURIComponent(
                  `/print/staging-builder/${stagingBuilderID}/invoice/${format(
                    new Date(),
                    "yyyy-MM-dd"
                  )}`
                )}`}
              />
            </>
          ) : null}
        </ul>
      </div>
    </div>
  );
};
