import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { BasicModal } from "../../Modals/BasicModal";
import { EditMHFODeliveryLogic } from "./Forms/MHFO/EditMHFODeliveryLogic";
import { MHFODeliveryFields } from "./Forms/MHFO/MHFODeliveryFields";

const modalStyle = {
  top: "30vh",
  left: "22vw",
  right: "22vw",
  bottom: "auto",
  overflow: "visible",
  height: "auto",
};

export const EditMHFODelivery = () => {
  const dispatch = useDispatch();
  const { query } = useSelector((state: Istate) => state.router.location);
  return (
    <BasicModal
      modalStyle={modalStyle}
      open={query["open-modal"] === "edit-mhfo"}
      setOpen={() => dispatch(push("/calendar"))}
      employeeOnly
    >
      <h1 className="text-2xl">Edit MHFO Delivery</h1>
      <EditMHFODeliveryLogic>
        {(formikProps) => <MHFODeliveryFields formikProps={formikProps} />}
      </EditMHFODeliveryLogic>
    </BasicModal>
  );
};
