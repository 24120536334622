import { StagingBuilderStatuses } from "../";

// export const ParseStatus = (
//   stagingStatus: StagingBuilderStatuses,
//   businessID: number | undefined,
//   fileNumber: number | null
// ) => {
//   if (fileNumber === null) fileNumber = 0;
//   if (businessID === 1) {
//     // employee
//     switch (stagingStatus) {
//       case StagingBuilderStatuses.PROPOSAL_RELEASE:
//         return "PROPOSAL_RELEASE";
//       case StagingBuilderStatuses.NEW_CUSTOMER:
//         return "NEW_CUSTOMER";
//       case StagingBuilderStatuses.PROPOSAL_HOLD_REQUEST:
//         return "PROPOSAL_HOLD_REQUEST";
//       case StagingBuilderStatuses.PROPOSAL_HOLD_CUSTOMER:
//         return "PROPOSAL_HOLD_CUSTOMER";
//       case StagingBuilderStatuses.PROPOSAL_HOLD_EMPLOYEE:
//         return "PROPOSAL_HOLD_EMPLOYEE";
//       case StagingBuilderStatuses.PROPOSAL_FELL_THROUGH:
//         return "PROPOSAL_FELL_THROUGH";
//       case StagingBuilderStatuses.PROPOSAL_APPROVAL_REQUEST:
//         return "PROPOSAL_APPROVAL_REQUEST";
//       case StagingBuilderStatuses.PROPOSAL_APPROVAL_REQUEST_HOLD:
//         return "PROPOSAL_APPROVAL_REQUEST_HOLD";
//       case StagingBuilderStatuses.APPROVAL_PROCESS_STARTED:
//         return "APPROVAL_PROCESS_STARTED";
//       case StagingBuilderStatuses.SCHEDULED_AND_TAGGED:
//         return "SCHEDULED_AND_TAGGED";
//       case StagingBuilderStatuses.PAID:
//         return "PAID";
//       case StagingBuilderStatuses.STAGED:
//         return "STAGED";
//       case StagingBuilderStatuses.LIMBO_REQUEST:
//         return "LIMBO_REQUEST";
//       case StagingBuilderStatuses.LIMBO_HOLD:
//         return "Limbo - Inventory HOLD";
//       case StagingBuilderStatuses.LIMBO_RELEASE:
//         return "Limbo - Inventory RELEASE";
//       case StagingBuilderStatuses.DEAL_FELL_THROUGH_REQUEST:
//         return "Deal Fell Through Request";
//       case StagingBuilderStatuses.DEAL_FELL_THROUGH:
//         return "DEAL_FELL_THROUGH";
//       case StagingBuilderStatuses.PICKUP_REQUEST:
//         return "PICKUP_REQUEST";
//       case StagingBuilderStatuses.PICKUP_SCHEDULED:
//         return "PICKUP_SCHEDULED";
//       case StagingBuilderStatuses.CLOSED:
//         return "CLOSED";
//       case StagingBuilderStatuses.LIMBO_REQUEST_HOLD:
//         return "Postpone Request";
//       default:
//         return "ERROR: Status Failed to Load";
//     }
//   } else {
//     // normal people
//     switch (stagingStatus) {
//       case StagingBuilderStatuses.PROPOSAL_RELEASE:
//         return "Proposal";
//       case StagingBuilderStatuses.NEW_CUSTOMER:
//         return "Proposal";
//       case StagingBuilderStatuses.PROPOSAL_HOLD_REQUEST:
//         return "Hold Requested";
//       case StagingBuilderStatuses.PROPOSAL_HOLD_CUSTOMER:
//         return "Proposal";
//       case StagingBuilderStatuses.PROPOSAL_HOLD_EMPLOYEE:
//         return "Proposal";
//       case StagingBuilderStatuses.PROPOSAL_FELL_THROUGH:
//         return "Canceled";
//       case StagingBuilderStatuses.PROPOSAL_APPROVAL_REQUEST:
//         return "Approval Requested";
//       case StagingBuilderStatuses.PROPOSAL_APPROVAL_REQUEST_HOLD:
//         return "Approval Requested";
//       case StagingBuilderStatuses.APPROVAL_PROCESS_STARTED:
//         return "Approval Pending";
//       case StagingBuilderStatuses.SCHEDULED_AND_TAGGED:
//         return "Awaiting Payment";
//       case StagingBuilderStatuses.PAID:
//         return "Awaiting Delivery";
//       case StagingBuilderStatuses.STAGED:
//         return "STAGED";
//       case StagingBuilderStatuses.LIMBO_REQUEST:
//         return "Postponed";
//       case StagingBuilderStatuses.LIMBO_HOLD:
//         return "Postponed";
//       case StagingBuilderStatuses.LIMBO_RELEASE:
//         return "Postponed";
//       case StagingBuilderStatuses.DEAL_FELL_THROUGH_REQUEST:
//         return "Deal Fell Through Request";
//       case StagingBuilderStatuses.DEAL_FELL_THROUGH:
//         return "Canceled";
//       case StagingBuilderStatuses.PICKUP_REQUEST:
//         return "Pending Pickup";
//       case StagingBuilderStatuses.PICKUP_SCHEDULED:
//         return "Pending Pickup";
//       case StagingBuilderStatuses.CLOSED:
//         return "Closed";
//       case StagingBuilderStatuses.LIMBO_REQUEST_HOLD:
//         return "Postponed";
//       default:
//         return "ERROR: Status Failed to Load";
//     }
//   }
// };

export const ParseStatus = (
  stagingStatus: StagingBuilderStatuses | undefined,
  businessID: number | null | undefined = null,
  fileNumber: string | number | null
) => {
  if (fileNumber === null) fileNumber = 0;
  if (businessID === 1 && false) {
    // employee
    switch (stagingStatus) {
      case StagingBuilderStatuses.PROPOSAL_RELEASE:
        return "PROPOSAL_RELEASE";
      case StagingBuilderStatuses.NEW_CUSTOMER:
        return "NEW_CUSTOMER";
      case StagingBuilderStatuses.PROPOSAL_HOLD_REQUEST:
        return "PROPOSAL_HOLD_REQUEST";
      case StagingBuilderStatuses.PROPOSAL_HOLD_CUSTOMER:
        return "PROPOSAL_HOLD_CUSTOMER";
      case StagingBuilderStatuses.PROPOSAL_HOLD_EMPLOYEE:
        return "PROPOSAL_HOLD_EMPLOYEE";
      case StagingBuilderStatuses.PROPOSAL_FELL_THROUGH:
        return "PROPOSAL_FELL_THROUGH";
      case StagingBuilderStatuses.PROPOSAL_APPROVAL_REQUEST:
        return "PROPOSAL_APPROVAL_REQUEST";
      case StagingBuilderStatuses.PROPOSAL_APPROVAL_REQUEST_HOLD:
        return "PROPOSAL_APPROVAL_REQUEST_HOLD";
      case StagingBuilderStatuses.APPROVAL_PROCESS_STARTED:
        return "APPROVAL_PROCESS_STARTED";
      case StagingBuilderStatuses.SCHEDULED_AND_TAGGED:
        return "SCHEDULED_AND_TAGGED";
      case StagingBuilderStatuses.PAID:
        return "PAID";
      case StagingBuilderStatuses.STAGED:
        return "STAGED";
      case StagingBuilderStatuses.LIMBO_REQUEST:
        return "LIMBO_REQUEST";
      case StagingBuilderStatuses.LIMBO_HOLD:
        return "Limbo - Inventory HOLD";
      case StagingBuilderStatuses.LIMBO_RELEASE:
        return "Limbo - Inventory RELEASE";
      case StagingBuilderStatuses.DEAL_FELL_THROUGH_REQUEST:
        return "Deal Fell Through Request";
      case StagingBuilderStatuses.DEAL_FELL_THROUGH:
        return "DEAL_FELL_THROUGH";
      case StagingBuilderStatuses.PICKUP_REQUEST:
        return "PICKUP_REQUEST";
      case StagingBuilderStatuses.PICKUP_SCHEDULED:
        return "PICKUP_SCHEDULED";
      case StagingBuilderStatuses.CLOSED:
        return "CLOSED";
      case StagingBuilderStatuses.LIMBO_REQUEST_HOLD:
        return "Postpone Request";
      default:
        return "ERROR: Status Failed to Load";
    }
  } else {
    // normal people
    switch (stagingStatus) {
      case StagingBuilderStatuses.PROPOSAL_RELEASE:
        return "Proposal";
      case StagingBuilderStatuses.NEW_CUSTOMER:
        return "Proposal";
      case StagingBuilderStatuses.PROPOSAL_HOLD_REQUEST:
        return "Hold Requested";
      case StagingBuilderStatuses.PROPOSAL_HOLD_CUSTOMER:
        return "Items On Hold";
      case StagingBuilderStatuses.PROPOSAL_HOLD_EMPLOYEE:
        return "Items On Hold";
      case StagingBuilderStatuses.PROPOSAL_FELL_THROUGH:
        return "Canceled";
      case StagingBuilderStatuses.PROPOSAL_APPROVAL_REQUEST:
        return "Approval Requested";
      case StagingBuilderStatuses.PROPOSAL_APPROVAL_REQUEST_HOLD:
        return "Approval Requested";
      case StagingBuilderStatuses.APPROVAL_PROCESS_STARTED:
        return "Approval Pending";
      case StagingBuilderStatuses.SCHEDULED_AND_TAGGED:
        return "Awaiting Payment";
      case StagingBuilderStatuses.PAID:
        return "Awaiting Delivery";
      case StagingBuilderStatuses.STAGED:
        return "STAGED";
      case StagingBuilderStatuses.LIMBO_REQUEST:
        return "Postponed";
      case StagingBuilderStatuses.LIMBO_HOLD:
        return "Postponed";
      case StagingBuilderStatuses.LIMBO_RELEASE:
        return "Postponed";
      case StagingBuilderStatuses.DEAL_FELL_THROUGH_REQUEST:
        return "Deal Fell Through Request";
      case StagingBuilderStatuses.DEAL_FELL_THROUGH:
        return "Canceled";
      case StagingBuilderStatuses.PICKUP_REQUEST:
        return "Pending Pickup";
      case StagingBuilderStatuses.PICKUP_SCHEDULED:
        return "Pending Pickup";
      case StagingBuilderStatuses.CLOSED:
        return "Closed";
      case StagingBuilderStatuses.LIMBO_REQUEST_HOLD:
        return "Postponed";
      default:
        return "ERROR: Status Failed to Load";
    }
  }
};
