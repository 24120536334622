import { CustomButton } from "../../../../HighlyReusableComponents/CustomButton";
import { Data } from "./Main";

export const ContinueScanning = ({
  section,
  sectionLabel,
}: Partial<Data & { sectionLabel: string | null }>) => {
  return (
    <div className="relative">
      <h2 className="text-xl">Continue Using Previous Section</h2>
      <p>
        {sectionLabel
          ? "You were previously scanning items in section: "
          : "You do not currently have a section you were previously working on. Please select on to the left."}
        {sectionLabel}
      </p>
      <div className="max-w-xs">
        <div className="absolute bottom-0 w-full max-w-xs">
          <CustomButton
            linkProps={{ to: "/scanner/delta-inventory" }}
            type="button"
            disabled={section === "0"}
          >
            Continue Scanning
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
