export enum StagingBuilderStatuses {
  PROPOSAL_RELEASE = 1,
  NEW_CUSTOMER = 2,
  PROPOSAL_HOLD_REQUEST = 3,
  PROPOSAL_HOLD_CUSTOMER = 4,
  PROPOSAL_HOLD_EMPLOYEE = 5,
  PROPOSAL_FELL_THROUGH = 6,
  PROPOSAL_APPROVAL_REQUEST = 7,
  PROPOSAL_APPROVAL_REQUEST_HOLD = 8,
  APPROVAL_PROCESS_STARTED = 9,
  SCHEDULED_AND_TAGGED = 10,
  PAID = 11,
  STAGED = 12,
  LIMBO_REQUEST = 13,
  LIMBO_HOLD = 14,
  LIMBO_RELEASE = 15,
  DEAL_FELL_THROUGH_REQUEST = 16,
  DEAL_FELL_THROUGH = 17,
  PICKUP_REQUEST = 18,
  PICKUP_SCHEDULED = 19,
  CLOSED = 20,
  LIMBO_REQUEST_HOLD = 21,
}
