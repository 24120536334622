import * as Yup from "yup";
import { ContainerItem } from "../../../../../../redux/stagingBuilder/stagingBuilderTypes";

export interface InitialValues {
  tagged: boolean; // boolean so checkbox works, saved as number (userID)
  tagNumbers: string[] | null | undefined;
  loc: string;
}
interface returnValues {
  initialValues: InitialValues;
  validationSchema: Yup.ObjectSchema<InitialValues | undefined, object>;
}
export const formInit = (containerItem: ContainerItem): returnValues => {
  const validationSchema = Yup.object({
    tagged: Yup.boolean().required(""),
    tagNumbers: Yup.array(Yup.string().required("Error, please report"))
      .max(containerItem.quantity, "You entered too many tag numbers.")
      .nullable(),
    loc: Yup.number(),
  });
  return {
    initialValues: {
      tagNumbers: containerItem.tagNumbers
        ? JSON.parse(containerItem.tagNumbers)
        : "",
      tagged: Boolean(containerItem.tagged),
      loc: containerItem.loc?.toString() || "",
    },
    validationSchema,
  };
};
