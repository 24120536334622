import React from "react";
import Modal from "react-modal";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { AppThunk, Istate } from "../../../../../redux";
import { StagingBuilderContainer } from "../../../../../redux/stagingBuilder/stagingBuilderTypes";
import { ItemInfo } from "./ItemInfo";
import { GroupItems } from "./GroupItems";
import { newContainerItem } from "../../../../../redux/stagingBuilder/actions/containerItems/NEW";

interface Props {
  selfID: number;
  itemContainerID: number;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  groups: string[];
}

export const GroupsModal = ({
  selfID,
  itemContainerID,
  open,
  setOpen,
  groups,
}: Props) => {
  const dispatch: AppThunk = useDispatch();
  const groupedItems = useSelector(
    (state: Istate) =>
      groups.map((groupID) => state.data.builderInventoryGroups[groupID]),
    shallowEqual
  );

  const stagingBuilderID = useSelector(
    (state: Istate) => state.data.user.activeStagingBuilder,
    shallowEqual
  );
  const { containerName } = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[
        state.data.user.activeStagingBuilderIndex
      ].itemContainers.find(
        (itemContainer) => itemContainer.itemContainerID === itemContainerID
      ),
    shallowEqual
  ) as StagingBuilderContainer;
  const [itemsToAdd, setItemsToAdd] = React.useState({
    items: [] as number[],
    upush: (itemID: number) =>
      setItemsToAdd((current) => {
        const newItems = new Set([...current.items, itemID]);
        return {
          ...current,
          items: Array.from(newItems),
        };
      }),
    upop: (itemID: number) =>
      setItemsToAdd((current) => {
        const newItems = Array.from(new Set([...current.items])).filter(
          (ID) => ID !== itemID
        );
        return {
          ...current,
          items: newItems,
        };
      }),
  });

  const submitNewContainerItems = () => {
    itemsToAdd.items.forEach((itemID) => {
      dispatch(
        newContainerItem({
          itemID,
          itemContainerID,
          stagingBuilderID,
        })
      );
    });
    setOpen(false);
  };
  const requestClose = () => {
    if (Boolean(itemsToAdd.items.length)) {
      const Confirm = window.confirm(
        "Are you sure you want to close this without saving the selected items?"
      );
      if (!Confirm) return;
    }
    setOpen(false);
  };
  return (
    <Modal isOpen={open} onRequestClose={requestClose}>
      <div className="grid col-4 gap-2">
        <div>
          <ItemInfo selfID={selfID} />
          <button
            className="btn btn-staging-builder"
            onClick={() => submitNewContainerItems()}
            disabled={!Boolean(itemsToAdd.items.length)}
          >
            Add {itemsToAdd.items.length} Item
            {itemsToAdd.items.length > 1 ? "s" : ""} to {containerName}
          </button>
          <br />
          <button onClick={requestClose}>Cancel</button>
        </div>
        <div style={{ gridColumn: "2/5" }}>
          <h1>Click on Items to Add them to "{containerName}"</h1>
          {groupedItems &&
            groupedItems.map((group, index) =>
              !group ? null : (
                <React.Fragment key={index}>
                  <div
                    style={{
                      backgroundColor: "var(--logo-purple",
                      height: "10px",
                    }}
                  />
                  <div key={index} className="grid col-3">
                    {group.map(
                      (itemID, index) =>
                        itemID !== selfID && (
                          <GroupItems
                            key={index}
                            itemsToAdd={itemsToAdd}
                            itemID={itemID}
                          />
                        )
                    )}
                  </div>
                </React.Fragment>
              )
            )}
        </div>
      </div>
    </Modal>
  );
};
