import { useEffect, useState, useRef } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import styled from "styled-components";
import { AppThunk, Istate } from "../redux";
import { getEmployeeScannerPermission } from "../redux/";
import { HomeButton } from "./HighlyReusableComponents/HomeButton";
import { EmployeeOnly } from "./HighlyReusableComponents/EmployeeOnly";
import { ScannerRouter } from "./Scanner/ScannerRouter";
interface IdivProps {
  color: string;
}
const ScannerBackground = styled.div<IdivProps>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  transition: background-color 500ms;
  background-color: ${(props) => props.color};
  z-index: 2;
`;

/**
 *
 * @todo
 * 1) route permission
 */
export const Scanner = () => {
  const dispatch: AppThunk = useDispatch();
  const routePermission = useSelector(
    (state: Istate) => state.data.route.EmployeeScanner.permission,
    shallowEqual
  );

  useEffect(() => {
    dispatch(getEmployeeScannerPermission());
  }, []);

  const barcodeRef = useRef<HTMLInputElement>(null);

  const [backgroundColor, setBackgroundColor] = useState("white");
  const [timer, setTimer] = useState<NodeJS.Timeout | number>(0);

  useEffect(() => {
    // clear the old timer
    clearTimeout(timer);
    // get a new timer that will set bgc to white
    const newTimer = () =>
      setTimeout(() => {
        setBackgroundColor("white");
      }, 750);
    // assign it to state
    setTimer(newTimer);
    return () => {
      clearTimeout(timer);
    };
  }, [backgroundColor]);
  return routePermission ? (
    <ScannerBackground
      color={backgroundColor}
      onClick={() => barcodeRef.current?.focus()}
    >
      <EmployeeOnly />
      <HomeButton />
      <ScannerRouter
        setBackgroundColor={setBackgroundColor}
        barcodeRef={barcodeRef}
      />
    </ScannerBackground>
  ) : null;
};
