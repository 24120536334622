import { ToastyRed } from "./ToastyRed";

export function ToastyError(
  status: number,
  content: string | undefined = undefined
) {
  switch (status) {
    case 400:
      ToastyRed(
        content ??
          "There was something wrong with the information that you submitted. Please try again.",
        { toastId: "STATUS-400" }
      );
      break;
    case 401:
      ToastyRed(
        content ??
          "It seems that you have been logged out. Due to this we were not able to process your request.",
        { toastId: "STATUS-401" }
      );
      break;
    case 403:
      ToastyRed(content ?? "Sorry, you do not have permission to do this.", {
        toastId: "STATUS-403",
      });
      break;
    case 500:
      ToastyRed(
        content ??
          "There was a server error while trying to process your request. Please try again later.",
        { toastId: "STATUS-500" }
      );
      break;
    default:
      ToastyRed(
        content ??
          "There was an unknown error while trying to process your request. Please try again later."
      );
  }
}
