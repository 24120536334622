import React from "react";
import FormikControl from "../../../FormikComponents/FormikControl";
// need Form control

interface Props {}

export const FormFields = (props: Props) => {
  return (
    <>
      <FormikControl
        control="right-input"
        fieldWrapperClass="basic-input"
        label="First Name"
        labelClass="leading-10"
        name="first"
      />
      <FormikControl
        control="right-input"
        fieldWrapperClass="basic-input"
        label="Last Name"
        labelClass="leading-10"
        name="last"
      />
      <FormikControl
        control="right-input"
        fieldWrapperClass="basic-input"
        label="Email"
        labelClass="leading-10"
        name="email"
      />
      <div className="flex space-between">
        <FormikControl
          control="phone-number"
          label="Phone"
          labelClass="leading-10"
          name="phone"
        />
      </div>
      <br />
      <FormikControl
        control="left-checkbox"
        type="checkbox"
        label={
          <p className="p-label__checkbox">
            I agree to receive automated status updates about my stagings via
            text. Msg&Data rates may apply.
          </p>
        }
        labelClass="grid auto-1fr"
        name="phoneAgreement1"
        notsearchable={1}
      />
      <br />
      <FormikControl
        control="left-checkbox"
        type="checkbox"
        label={
          <p className="p-label__checkbox">
            I agree to recieve occasional StagingRents promotions & notices via
            text. Msg&Data rates may apply.
          </p>
        }
        labelClass="grid auto-1fr"
        name="phoneAgreement2"
        notsearchable={1}
      />
      <br />
      <FormikControl
        control="left-checkbox"
        type="checkbox"
        label={
          <p className="p-label__checkbox">
            I agree to receive automated status updates about my stagings via
            email.
          </p>
        }
        labelClass="grid auto-1fr"
        name="emailAgreement1"
        notsearchable={1}
      />
      <br />
      <FormikControl
        control="left-checkbox"
        type="checkbox"
        label={
          <p className="p-label__checkbox">
            I agree to recieve occasional StagingRents promotions & notices via
            email.
          </p>
        }
        labelClass="grid auto-1fr"
        name="emailAgreement2"
        notsearchable={1}
      />
      <br />
    </>
  );
};
