interface Props {}

export const Sections = (props: Props) => {
  return (
    <>
      <ul className="col-span-full md:ml-6 grid grid-cols-3 gap-4 mt-4">
        <li>
          <ul>
            <li>
              <span className="font-bold">Day 1-A</span>
              <ul className="ml-3">
                <li>Accent Chairs</li>
              </ul>
            </li>
            <li>
              <span className="font-bold">Day 1-B</span>
              <ul className="ml-3">
                <li>Ottomans</li>
                <li>Benches</li>
              </ul>
            </li>
            <li>
              <span className="font-bold">Day 1-C</span>
              <ul className="ml-3">
                <li>Sofas</li>
                <li>Loveseats</li>
                <li>Chaises</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <ul>
            <li>
              <span className="font-bold">Day 2-A</span>
              <ul className="ml-3">
                <li>Coffee Tables</li>
                <li>End Tables</li>
              </ul>
            </li>
            <li>
              <span className="font-bold">Day 2-B</span>

              <ul className="ml-3">
                <li>Silks (Trees)</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <ul>
            <li>
              <span className="font-bold">Day 3-A</span>
              <ul className="ml-3">
                <li>Headboards</li>
              </ul>
            </li>
            <li>
              <span className="font-bold">Day 3-B</span>
              <ul className="ml-3">
                <li>Dining Tables</li>
                <li>Counter Tables</li>
                <li>Bar Tables</li>
              </ul>
            </li>
            <li>
              <span className="font-bold">Day 3-C</span>
              <ul className="ml-3">
                <li>Book Shelves</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <ul>
            <li>
              <span className="font-bold">Day 4-A</span>
              <ul className="ml-3">
                <li>Adjustable Stools</li>
                <li>Bar Stools</li>
                <li>Counter Stools</li>
              </ul>
            </li>
            <li>
              <span className="font-bold">Day 4-B</span>
              <ul className="ml-3">
                <li>Dining Chairs</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <ul>
            <li>
              <span className="font-bold">Day 5-A</span>
              <ul className="ml-3">
                <li>Lamps</li>
              </ul>
            </li>
            <li>
              <span className="font-bold">Day 5-B</span>
              <ul className="ml-3">
                <li>Pillows</li>
              </ul>
            </li>
            <li>
              <span className="font-bold">Day 5-C</span>
              <ul className="ml-3">
                <li>Rugs</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <ul>
            <li>
              <span className="font-bold">Day 6-A</span>
              <ul className="ml-3">
                <li>Mirrors</li>
              </ul>
            </li>
            <li>
              <span className="font-bold">Day 6-B</span>
              <ul className="ml-3">
                <li>Framed Art</li>
              </ul>
            </li>
            <li>
              <span className="font-bold">Day 6-C</span>
              <ul className="ml-3">
                <li>Canvases</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <ul>
            <li>
              <span className="font-bold">Day 7-A</span>
              <ul className="ml-3">
                <li>Desks</li>
              </ul>
            </li>
            <li>
              <span className="font-bold">Day 7-B</span>
              <ul className="ml-3">
                <li>TV Stands</li>
                <li>Ent. Consoles</li>
              </ul>
            </li>
            <li>
              <span className="font-bold">Day 7-C</span>
              <ul className="ml-3">
                <li>Mixed Media Art</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <ul>
            <li>
              <span className="font-bold">Day 8-A</span>
              <ul className="ml-3">
                <li>Accent Cabinents</li>
                <li>Sofa Tables</li>
                <li>Chests</li>
                <li>Servers</li>
                <li>Dressers</li>
                <li>Night Stands</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <ul>
            <li>
              <span className="font-bold">Day 9-A</span>
              <ul className="ml-3">
                <li>Accessories</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </>
  );
};
