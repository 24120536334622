import React, { useEffect } from "react";
import { CgPushChevronLeftO, CgPushChevronRightO } from "react-icons/cg";
import LeftSideBar from "./LeftSideBar";
import styled from "styled-components";
import { Portal } from "react-portal";
import { useSelector } from "react-redux";
import { useClassChange } from "../HighlyReusableComponents/hooks/";
import { useNavigation } from "../mobile-nav/context/NavigationContext";

const SideBarContainer = styled.div<{ open: boolean }>`
  --left-side-bar-width: 200px;
  --left-side-bar-width-mobile: 250px;
  z-index: 99;

  grid-column: 1/2;

  transition: width 750ms;

  height: calc(97vh - var(--footer-height) - var(--header-height));
  max-height: 100vh;
  min-height: calc(100vh - var(--footer-height) - var(--header-height));
  overflow-y: hidden;

  min-width: 1rem;
  width: 1rem;

  @media only screen and (min-width: 1024px) {
    width: ${({ open }) => (open ? "1rem" : "var(--left-side-bar-width)")};
  }
  & > div {
    & > div > div + div {
      z-index: 25;
      // padding-left: 10px;
    }
    ${({ open }) => (open ? "z-index: 20;" : "z-index:1;")}
    ${({ open }) => !open && "transition: z-index 1250ms;"}

    ${({ open }) => open && "width: var(--left-side-bar-width-mobile);"}
    @media only screen and (min-width: 1024px) {
      ${({ open }) => open && "width: var(--left-side-bar-width);"}
    }

    & > div {
      transition: transform 750ms;
    }
    .show-hide {
      ${({ open }) =>
        !open &&
        "transform: translateX(calc(0px - var(--left-side-bar-width)));"}
    }

    @media only screen and (min-width: 1024px) {
      transition: padding 750ms;
      z-index: 20;

      padding-right: 0;
      .show-hide {
        ${({ open }) =>
          open
            ? "transform: translateX(calc(1rem - var(--left-side-bar-width)));"
            : "transform: translateX(0);"}
        ${({ open }) => open && "padding-right: 1rem;"}
      }
    }

    @media only screen and (min-height: 640px) {
      .welcome-banner {
        display: block;
      }
      .nav-link-extra {
        display: block;
      }
    }
  }
`;
const Glass = styled.div<{ open: boolean }>`
  animation: frost-glass 1.5s ease;
  filter: opacity(0.5);
  @keyframes frost-glass {
    from {
      filter: opacity(0);
    }
    to {
      filter: opacity(0.5);
    }
  }
`;

export const SideBarWrapper = () => {
  const {
    location: { pathname },
  } = useSelector((state: Istate) => state.router);

  const { updateSidebarVisibility, showSideBar } = useNavigation();
  useEffect(() => {
    updateSidebarVisibility(false);
  }, [pathname, updateSidebarVisibility]);

  const classDivClassName = useClassChange({
    className: { start: "fixed", end: "static", intermediate1: "fixed" },
    time: 750,
    toggle: showSideBar,
  });
  return (
    <SideBarContainer open={showSideBar} className="relative">
      <div
        onClick={() => updateSidebarVisibility(true)}
        className={"side-bar lg:absolute h-full " + classDivClassName}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="relative w-full h-full show-hide"
        >
          <LeftSideBar />
          <div className="absolute top-0 bottom-0 right-0 grid w-5 center center-right">
            <div>
              {!showSideBar && (
                <CgPushChevronLeftO
                  className="hidden lg:block"
                  cursor="pointer"
                  onClick={() => updateSidebarVisibility(true)}
                />
              )}
              {showSideBar && (
                <CgPushChevronRightO
                  className="hidden lg:block"
                  cursor="pointer"
                  onClick={() => updateSidebarVisibility(false)}
                />
              )}
            </div>
            {showSideBar && (
              <Portal>
                <Glass
                  id="glass-target"
                  open={showSideBar}
                  className="absolute top-0 bottom-0 right-0 left-0 z-4 lg:hidden bg-white glass"
                  style={{ zIndex: 10 }}
                  onClick={(e) => {
                    e.preventDefault();
                    updateSidebarVisibility(false);
                  }}
                />
              </Portal>
            )}
          </div>
        </div>
      </div>
    </SideBarContainer>
  );
};
