import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppThunk, Istate, logInAxios } from "../../../../../redux";
import { FormFields } from "./FormFields";

export const FormLogic = () => {
  const dispatch: AppThunk = useDispatch();
  const { email } = useSelector(
    (state: Istate) => state.data.user,
    shallowEqual
  );
  const initialValues = {
    email,
    password: "",
  };
  type InitialValues = typeof initialValues;
  const onSubmit = (
    values: InitialValues,
    actions: FormikHelpers<InitialValues>
  ) => {
    console.log(email);
    email && dispatch(logInAxios({ email, password: values.password }));
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {(FormikProps: FormikProps<InitialValues>) => (
        <Form>
          <FormFields />
          <button
            className="col-span-full bg-logo-blue w-full py-1.5 mt-2 rounded-lg border border-gray-400 focus:outline-none focus:ring"
            type="submit"
          >
            Login
          </button>
        </Form>
      )}
    </Formik>
  );
};
