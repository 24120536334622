import {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from "react";

interface NavigationState {
  leftRight: 0 | 1;
  showSideBar: boolean;
  desiredPath: string;
  updateMobileViewSide: (LeftRight?: 0 | 1 | undefined) => void;
  updateSidebarVisibility: (LeftRight?: boolean) => void;
  setDesiredPath: (pathname: string) => void;
}
//@ts-ignore
const initialNavigationState: NavigationState = {
  leftRight: 0,
  showSideBar: false,
  desiredPath: "",
};

export type EventReducer =
  | {
      type: "UPDATE_LEFT_RIGHT";
      payload?: 0 | 1;
    }
  | {
      type: "UPDATE_SHOW_SIDEBAR";
      payload?: boolean;
    }
  | {
      type: "SET_DESIRED_PATH";
      payload: string;
    };
export const navigationReducer = (
  state: NavigationState,
  action: EventReducer
): NavigationState => {
  switch (action.type) {
    case "UPDATE_LEFT_RIGHT":
      return {
        ...state,
        leftRight:
          typeof action.payload === "number"
            ? action.payload
            : (Number(!state.leftRight) as 0 | 1),
      };
    case "UPDATE_SHOW_SIDEBAR":
      return {
        ...state,
        showSideBar:
          typeof action.payload === "boolean"
            ? action.payload
            : !state.leftRight,
      };
    case "SET_DESIRED_PATH":
      return {
        ...state,
        desiredPath: action.payload,
      };
    default:
      return { ...state };
  }
};

const navigationContext = createContext<NavigationState>({
  ...initialNavigationState,
});

const { Provider: NavigationProvider } = navigationContext;

export const useNavigation = () => useContext(navigationContext);
export const NavigationContext: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [state, dispatchEvents] = useReducer(
    navigationReducer,
    initialNavigationState
  );
  const updateMobileViewSide = useCallback(
    (LeftRight?: 0 | 1) => {
      dispatchEvents({ type: "UPDATE_LEFT_RIGHT", payload: LeftRight });
    },
    [dispatchEvents]
  );
  const updateSidebarVisibility = useCallback(
    (showSideBar?: boolean) => {
      dispatchEvents({ type: "UPDATE_SHOW_SIDEBAR", payload: showSideBar });
    },
    [dispatchEvents]
  );
  const setDesiredPath = useCallback(
    (pathname: string) => {
      dispatchEvents({ type: "SET_DESIRED_PATH", payload: pathname });
    },
    [dispatchEvents]
  );
  const memoizedProviderValue = useMemo(() => ({ ...state }), [state]);
  return (
    <NavigationProvider
      value={{
        ...memoizedProviderValue,
        updateMobileViewSide,
        updateSidebarVisibility,
        setDesiredPath,
      }}
    >
      {children}
    </NavigationProvider>
  );
};
