import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchStagingBuilders } from "../redux";
import { fillStagingBuilderFromRefresh } from "../redux/stagingBuilder/actions/fillStagingBuilderFromRefresh";

export const LoadStagingBuilders = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStagingBuilders());
    dispatch(fillStagingBuilderFromRefresh());
  }, [dispatch]);
  return null;
};
