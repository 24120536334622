import { useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { AppThunk, Istate, logInAxios } from "../../../redux";

import { Formik, Form } from "formik";
import FormikControl from "../../FormikComponents/FormikControl";
import * as Yup from "yup";
import { CustomButton } from "../../HighlyReusableComponents/CustomButton";
import { useNavigation } from "../../mobile-nav/context/NavigationContext";

export const LoginForm = () => {
  const dispatch: AppThunk = useDispatch();
  const { desiredPath } = useNavigation();

  const loginFailed = useSelector(
    (state: Istate) => state.data.user.loginFailed,
    shallowEqual
  );

  const initialValues = {
    email: "",
    password: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string().required("Required").email("Invalid email format"),
    password: Yup.string().required("Required"),
  });
  const onSubmit = (values: typeof initialValues) => {
    console.log("Form data", values);
    dispatch(logInAxios(values, desiredPath));
  };

  const emailRef = useRef<HTMLInputElement>();
  useEffect(() => {
    emailRef?.current?.focus();
  }, []);
  return (
    <div className="mt-3 mx-5 test-container">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {() => (
          <Form>
            {loginFailed && (
              <div className="input-container">Incorrect email or password</div>
            )}
            <FormikControl
              control="input"
              type="email"
              label="Email Address"
              name="email"
              innerRef={emailRef}
              inLineLabelError={true}
              styledClass="focus:outline-none focus:ring"
              controlContainerclass="basic-input mb-2"
            />
            <FormikControl
              control="input"
              type="password"
              label="Password"
              name="password"
              inLineLabelError={true}
              styledClass="focus:outline-none focus:ring"
            />
            <CustomButton type="submit">Log In</CustomButton>
          </Form>
        )}
      </Formik>
      <div className="flex justify-between mt-1">
        <Link to="/user/recover-account">Forgot Password?</Link>
        <Link className="right" to="/user/create-account">
          Create Account
        </Link>
      </div>
    </div>
  );
};

export default LoginForm;
