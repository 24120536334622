import React, { useState } from "react";
import { push } from "connected-react-router";
import { ImHome3 } from "react-icons/im";
import { useDispatch } from "react-redux";

interface Props {
  styles?: React.CSSProperties;
}

export const HomeButton = ({ styles }: Props) => {
  const dispatch = useDispatch();
  return (
    <div
      style={{
        position: "absolute",
        left: 10,
        top: 10,
        ...styles,
      }}
      onClick={() => dispatch(push("/"))}
    >
      <ImHome3 cursor="pointer" size={40} />
    </div>
  );
};
