import React, { useMemo } from "react";

import * as Yup from "yup";
import { ContainerItem } from "../../../../../../redux/stagingBuilder/stagingBuilderTypes";
import { CustomButton } from "../../../../../HighlyReusableComponents/CustomButton";

export interface InitialValues {
  "container-item-comment": string | undefined;
}
interface returnValues {
  initialValues: InitialValues;
  validationSchema: Yup.ObjectSchema<InitialValues | undefined, object>;
}

export const useFormInit = (
  containerItem: ContainerItem | undefined
): returnValues => {
  const validationSchema = Yup.object({
    "container-item-comment": Yup.string().required(
      (
        <>
          <span className="text-red-700">
            The address is missing from this StagingBuilder
          </span>
          <br />
          <CustomButton
            children="Go To Staging Information"
            linkProps={{
              to: "/stage?open-modal=staging-information",
            }}
            type="button"
          />
          <br />
          <br />
        </>
      ) as unknown as string
    ),
  });
  console.log(containerItem);
  return useMemo(() => {
    return {
      initialValues: {
        "container-item-comment": containerItem?.comments || "",
      },
      validationSchema,
    };
  }, [containerItem?.comments]);
};
