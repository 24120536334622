import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Istate } from "../../../redux";
import { StagingBuilderItemContainer } from "./StagingBuilderItemContainer";

interface Props {
  headerHeight: number;
}
export const StagingBuilderItemContainerMap = ({ headerHeight }: Props) => {
  const itemContainers = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex]
        .itemContainers,
    shallowEqual
  );
  return (
    <>
      {itemContainers?.map(({ itemContainerID, containerType }, index) =>
        containerType ? (
          <StagingBuilderItemContainer
            key={itemContainerID}
            itemContainerIndex={index}
            headerHeight={headerHeight}
          />
        ) : null
      )}
    </>
  );
};
