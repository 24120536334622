import React from "react";
import { Switch, Route } from "react-router-dom";
import { Queue } from "./Prints/";
export const PrintRouter = () => {
  return (
    <>
      <Switch>
        <Route path="/print/tags/queue">
          <Queue />
        </Route>
      </Switch>
    </>
  );
};
