import React from "react";
export const PrintErrorMessage = ({ errors }: { errors: string[] }) => {
  return (
    <div>
      <h2>Error(s) creating preview</h2>
      <ol>
        {errors.map((err, index) => (
          <li key={index}>{err}</li>
        ))}
      </ol>
      <h3 className="text-center">MAKE SURE YOU HAVE SUBMITTED ANY UPDATES</h3>
      <p className="text-center">This could be causing your error</p>
    </div>
  );
};
