import React from "react";
import FormikControl from "../../../../FormikComponents/FormikControl";
// need Form control

export const FormFields = () => {
  return (
    <>
      <FormikControl
        control="input"
        fieldWrapperClass="my-account-wrapper"
        type="email"
        label="Email"
        name="email"
        disabled
      />
      <FormikControl
        control="input"
        fieldWrapperClass="my-account-wrapper"
        type="password"
        label="Password"
        name="password"
      />
    </>
  );
};
