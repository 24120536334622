import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toggleBusiness } from "../../redux/users/userActions";
import { useNavigation } from "../mobile-nav/context/NavigationContext";

export const NavLinks = () => {
  const {
    id: userID,
    businessID,
    activeStagingBuilder,
  } = useSelector((state: Istate) => state.data.user, shallowEqual);
  const dispatch = useDispatch();
  const { updateMobileViewSide, updateSidebarVisibility } = useNavigation();
  return (
    <div className="" style={{ paddingLeft: "10px", margin: "10px 0 0 0" }}>
      <ul>
        <li>
          <Link
            onClick={() => {
              updateMobileViewSide(0);
              updateSidebarVisibility(false);
            }}
            to="/"
            className="active"
          >
            My Stagings
          </Link>
        </li>
        {!activeStagingBuilder ? null : (
          <li>
            <Link
              onClick={() => {
                updateMobileViewSide(1);
                updateSidebarVisibility(false);
              }}
              to="/stage"
              className="active"
            >
              Edit StagingBuilder
            </Link>
          </li>
        )}
        <li>
          <Link to="/start-staging" className="active pointer">
            Start A New Staging
          </Link>
        </li>
        {businessID !== 1 ? null : (
          <li>
            <Link to="/calendar" className="active">
              Calendar
            </Link>
          </li>
        )}
        {businessID !== 1 ? null : (
          <li>
            <Link
              to={({ pathname }) =>
                pathname.startsWith("/inventory")
                  ? "/inventory/new"
                  : "/inventory"
              }
              className="active"
            >
              Inventory/New Item
            </Link>
          </li>
        )}
        {businessID !== 1 ? null : (
          <li>
            <Link to="/inventory/delta/controls" className="active">
              Inventory/Delta
            </Link>
          </li>
        )}
        {businessID !== 1 ? null : (
          <li>
            <Link to="/scanner" className="active">
              Scanner
            </Link>
          </li>
        )}
        <li>
          <Link to="/accounts/my-account" className="active">
            My Account
          </Link>
        </li>
        {userID !== 1 ? null : (
          <li>
            <Link to="/accounts/my-business" className="active">
              My Business
            </Link>
          </li>
        )}
        {businessID !== 1 ? null : (
          <li>
            <Link to="/accounts/permissions" className="active">
              Permissions
            </Link>
          </li>
        )}
        {/* <li>
              <Link to="/contact-us" className="active">
                Contact Us
              </Link>
            </li> */}
        <li>
          <a
            className="nav-link-extra hidden"
            href="https://stagingrents.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            StagingRents.com
          </a>
        </li>
        <li>
          <Link to="/user/logout" className="active">
            Log Out
          </Link>
        </li>
        {process.env.NODE_ENV === "development" && (
          <li>
            <a
              className="cursor-pointer nav-link-extra hidden"
              onClick={() => dispatch(toggleBusiness())}
            >
              {businessID !== 1 ? "Employee View" : "Customer View"}
            </a>
          </li>
        )}
        {/* <li>
            <Link to="/print/tags/queue" className="active">
              Print Tags
            </Link>
          </li> */}
      </ul>
    </div>
  );
};
