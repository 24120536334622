export enum CalendarEventErrors {
  NO_ERROR,
  UNDEFINED_DELIVERY_ACTUAL,
  UNDEFINED_PICKUP_ACTUAL,
}
export enum CalendarEventTypes {
  NULL,
  STAGING_DOWN,
  STAGING_UP,
  IMPORTANT_TASK,
}
