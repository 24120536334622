import { initialReduxState, SystemState } from "../initialReduxState";
import { locationOptions } from "../../components/DropDownLists/";
import { ContainerItem } from "../stagingBuilder/stagingBuilderTypes";

const inventoryReducer = (
  state: SystemState = initialReduxState,
  action: InventoryReducer
): SystemState => {
  switch (action.type as InventoryReducer["type"]) {
    case "CLEAR_INVENTORY": {
      return {
        ...state,
        inventory: [],
      };
    }
    case "FILL_INVENTORY_VERBOSE": {
      return {
        ...state,
        inventory: [...action.payload],
        filteredInventory: [...action.payload],
      };
    }
    case "FILL_INVENTORY_MINIMAL": {
      return {
        ...state,
        builderInventory: { ...action.payload.itemObject },
        builderInventoryOrder: [...action.payload.orderArray],
        builderInventoryGroups: { ...action.payload.groups },
        lockedBuilderInventoryOrder: [...action.payload.orderArray],
      };
    }
    case "UPDATE_BUILDER_INVENTORY_ORDER": {
      return {
        ...state,
        builderInventoryOrder: [...action.payload],
      };
    }
    case "FILL_VENDORS": {
      return {
        ...state,
        inventoryMeta: {
          ...state.inventoryMeta,
          vendors: action.payload,
        },
      };
    }
    case "FILL_COLLECTIONS": {
      return {
        ...state,
        inventoryMeta: {
          ...state.inventoryMeta,
          collections: action.payload,
        },
      };
    }
    case "FILL_ROOMS": {
      return {
        ...state,
        inventoryMeta: {
          ...state.inventoryMeta,
          rooms: action.payload,
        },
      };
    }
    case "RESET_ACTIVE_ITEM": {
      return {
        ...state,
        user: {
          ...state.user,
          activeItem: undefined,
        },
      };
    }
    case "SET_ACTIVE_ITEM": {
      const newActiveItem = !action.payload.newItem
        ? state.inventory.find((item) => action.payload.itemID === item.itemID)
        : action.payload;

      if (newActiveItem === undefined) return { ...state };
      newActiveItem.webCardPlacement = newActiveItem?.newItem ? "" : 10;
      newActiveItem.picturesHaveBeenUpdated = false;
      return {
        ...state,
        user: {
          ...state.user,
          activeItem: { ...newActiveItem },
        },
      };
    }
    case "UPDATE_ACTIVE_ITEM": {
      if (action.payload?.itemID === undefined) return { ...state };

      let copyOfInventory = [...state.inventory];
      const index = copyOfInventory.findIndex(
        (item) => item.itemID === action.payload.itemID
      );
      // if we found it update it, else put it at the begining of the array
      if (index !== -1) copyOfInventory[index] = { ...action.payload };
      else if (index === -1) copyOfInventory.unshift({ ...action.payload });

      if (state.user.activeItem?.itemNo === action.payload.itemNo)
        return {
          ...state,
          user: {
            ...state.user,
            activeItem: { ...action.payload },
          },
          inventory: [...copyOfInventory],
        };
      else
        return {
          ...state,
          user: {
            ...state.user,
          },
          inventory: [...copyOfInventory],
        };
    }
    case "FAVORITE_ITEM_CLICKED": {
      // const itemID:number = action.payload;
      return {
        ...state,
      };
    }
    case "FAVORITE_ITEM_FAILURE": {
      // const itemID:number = action.payload;
      return {
        ...state,
      };
    }
    case "FAVORITE_ITEM_SUCCESS": {
      const { itemID }: { itemID: number } = action.payload;
      let builderInventory = { ...state.builderInventory };

      builderInventory[itemID].favorite = Number(
        !builderInventory[itemID].favorite
      );

      return {
        ...state,
        builderInventory: { ...builderInventory },
      };
    }
    case "INVENTORY_SEARCH": {
      const locations = locationOptions.map((loc) => loc.label);
      // const colors = colorOptions.map((col) => col.value);
      // const styles = styleOptions.map((style) => style.value);
      const search: {
        [key: string]: {
          keyValue: string | string[] | number | boolean | number[];
        };
      } = { ...state.inventorySearch, ...action.payload };

      let fullInventory = state.inventory;
      const includes = (
        fieldValue: string | null,
        regexVal: RegExp | false
      ) => {
        if (!regexVal) return false;
        if (fieldValue == null) return true;
        return fieldValue.toLowerCase().search(regexVal) === -1;
      };
      // vendor, collection, room, piece
      const equals = (
        fieldValue: number,
        compareValue: string | number | boolean | number[]
      ) => {
        // on "Search:"
        if (!~~compareValue) return false;
        return fieldValue !== ~~compareValue;
      };

      const regex: {
        [key: string]: RegExp | false;
      } = {
        itemNo: search.itemNo.keyValue
          ? new RegExp(`${search.itemNo.keyValue}`, "gi")
          : false,
        model: search.model.keyValue
          ? new RegExp(`${search.model.keyValue}`, "gi")
          : false,
        ain: search.ain.keyValue
          ? new RegExp(`${search.ain.keyValue}`, "gi")
          : false,
        itemName: search.itemName.keyValue
          ? new RegExp(`${search.itemName.keyValue}`, "gi")
          : false,
      };

      const locationCheck = (
        itemLocation: string,
        itemColor: string[],
        itemStyle: string[]
      ) => {
        // error check, just return true to not cause any problems
        if (
          typeof search.srLocation.keyValue != "object" ||
          typeof search.srColors.keyValue != "object" ||
          typeof search.srStyles.keyValue != "object"
        )
          return true;
        // they arent searching by a multi
        if (
          search.srLocation.keyValue[0] === undefined &&
          search.srColors.keyValue[0] === undefined &&
          search.srStyles.keyValue[0] === undefined
        )
          return true;
        // get the index of the location name
        let locTrue: boolean = true,
          colTrue: boolean = true,
          styleTrue: boolean = true;
        if (search.srLocation.keyValue[0] !== undefined) {
          const locIndex = locations.indexOf(itemLocation) + 1;
          locTrue = (search.srLocation.keyValue as number[]).includes(locIndex);
        }
        if (
          // search.srColors.keyValue[0] !== "" &&
          search.srColors.keyValue[0] !== undefined
        ) {
          // if (itemColor[0] === "") return false;
          colTrue = Boolean(
            (search.srColors.keyValue as string[])
              .map((value) => Number(itemColor.includes(value)))
              .reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0
              )
          );
        }
        if (
          // search.srStyles.keyValue[0] !== "" &&
          search.srStyles.keyValue[0] !== undefined
        ) {
          if (itemStyle[0] === "") return false;
          styleTrue = Boolean(
            (search.srStyles.keyValue as string[])
              .map((value) => Number(itemStyle.includes(value)))
              .reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0
              )
          );
        }
        if (locTrue === true && colTrue === true && styleTrue === true)
          return true;
        return false;
      };

      const filteredInventory = fullInventory.filter((item) => {
        // itemNo
        if (includes(item.itemNo, regex.itemNo)) return false;
        // model / our sku
        if (
          includes(item.model, regex.model) &&
          includes(item.ourSku, regex.model)
        )
          return false;
        // ain
        if (includes(item.ain?.toString(), regex.ain)) return false;
        // itemName
        if (includes(item.itemName, regex.itemName)) return false;

        if (equals(item.vendorID, search.vendorID.keyValue as number))
          return false;
        if (equals(item.collectionID, search.collectionID.keyValue as number))
          return false;
        if (equals(item.room, search.room.keyValue as number)) return false;
        if (equals(item.piece, search.piece.keyValue as number)) return false;

        let status = false,
          statusAny = false,
          dept = false,
          deptAny = false;
        // top search
        if (
          search.showAvail.keyValue ||
          search.showHold.keyValue ||
          search.showStaged.keyValue ||
          search.showOutOfStock.keyValue
        ) {
          statusAny = true;
          if (search.showAvail.keyValue)
            if (item.quanAvail + item.quanMHFO + item.quanRepair !== 0)
              status = locationCheck(
                item.srLocation,
                item.itemColor,
                item.itemStyle
              );
          if (search.showHold.keyValue)
            if (item.quanHold !== 0)
              status = locationCheck(
                item.srLocation,
                item.itemColor,
                item.itemStyle
              );
          if (search.showStaged.keyValue)
            if (item.quanStaged !== 0)
              status = locationCheck(
                item.srLocation,
                item.itemColor,
                item.itemStyle
              );
          if (search.showOutOfStock.keyValue)
            if (item.quantity === 0)
              status = locationCheck(
                item.srLocation,
                item.itemColor,
                item.itemStyle
              );
        }
        //departments
        if (
          search.stagingSite.keyValue ||
          search.MHFOSite.keyValue ||
          search.noSite.keyValue
        ) {
          deptAny = true;
          if (search.stagingSite.keyValue)
            if (item.ezrent !== 0)
              dept = locationCheck(
                item.srLocation,
                item.itemColor,
                item.itemStyle
              );
          if (search.MHFOSite.keyValue)
            if (item.mhfo !== 0)
              dept = locationCheck(
                item.srLocation,
                item.itemColor,
                item.itemStyle
              );
          if (search.noSite.keyValue)
            if (item.ezrent === 0 && item.mhfo === 0)
              dept = locationCheck(
                item.srLocation,
                item.itemColor,
                item.itemStyle
              );
        }

        if (statusAny && deptAny && status && dept) return true;
        if (statusAny && !deptAny && status) return true;
        if (!statusAny && deptAny && dept) return true;

        return false;
      });
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
        },
        inventorySearch: { ...search },
        filteredInventory: filteredInventory,
      };
    }
    case "BUILDER_INVENTORY_SEARCH":
      const { catNos } = action.payload;
      if (catNos === "")
        return {
          ...state,
          builderInventoryOrder: state.lockedBuilderInventoryOrder,
        };

      const categories: number[] = catNos
        .split(",")
        .map((piece: string) => ~~piece);

      const newBuilderInventoryOrder = state.lockedBuilderInventoryOrder.filter(
        ({ itemID }) => {
          for (let i = 0; i < categories.length; i++) {
            if (
              state.builderInventory[itemID.toString()].piece === categories[i]
            )
              return true;
          }
          return false;
        }
      );
      return {
        ...state,
        builderInventoryOrder: newBuilderInventoryOrder,
      };
    case "BUILDER_INVENTORY_COLORS": {
      // this adds colors to the draggable inventory when in /stage
      let builderInventory = { ...state.builderInventory };
      let containerItems: ContainerItem[] = [];
      let trashItems: ContainerItem[] = [];
      if (Object.keys(builderInventory).length !== 0) {
        state.stagingBuilders[
          state.user.activeStagingBuilderIndex
        ].itemContainers.forEach((container) => {
          if (container.containerType !== 0)
            containerItems = [...containerItems, ...container.items];
          else trashItems = [...container.items];
        });
        trashItems.forEach((item) => {
          if (item.itemID && builderInventory[item.itemID.toString()]) {
            const status = builderInventory[item.itemID.toString()].itemStatus;
            builderInventory[item.itemID.toString()].itemStatus = item.itemID
              ? 6
              : status;
          }
        });
        containerItems.forEach((item) => {
          if (item.itemID && builderInventory[item.itemID.toString()]) {
            const status = builderInventory[item.itemID.toString()].itemStatus;
            builderInventory[item.itemID.toString()].itemStatus = item.itemID
              ? 1
              : status;
          }
        });
        return {
          ...state,
          builderInventory: builderInventory,
        };
      } else return { ...state };
    }
    case "BUILDER_INVENTORY_RESET": {
      return {
        ...state,
        builderInventory: {},
        builderInventoryOrder: [],
        lockedBuilderInventoryOrder: [],
      };
    }
    case "UPDATE_BUILDER_ITEM": {
      // dont add it to an empty object
      if (Object.keys(state.builderInventory).length === 0) return { ...state };
      // take containerItemID out so we dont add it to the builderItem
      const { containerItemID, itemID, ...builderItem } = action.payload;
      let copyOfBuilderInventory = { ...state.builderInventory };
      // it is not in the current builderInventory
      if (copyOfBuilderInventory[itemID.toString()] === undefined)
        copyOfBuilderInventory[itemID.toString()] = {
          ...builderItem,
          itemID,
        };
      else
        copyOfBuilderInventory[itemID.toString()] = {
          ...copyOfBuilderInventory[itemID.toString()],
          ...builderItem,
        };
      return {
        ...state,
        builderInventory: { ...copyOfBuilderInventory },
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
export default inventoryReducer;
