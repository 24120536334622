import React from "react";
import { AddItemContainer } from "./AddItemContainer";
import { ShowTrashedItems } from "./ShowTrashedItems";
import { ScanItems } from "./ScanItems";
import { useSelector, shallowEqual } from "react-redux";
import { Istate } from "../../../../redux";
import { StagingBuilderStatuses } from "../../../constants";

interface Props {
  edit: boolean;
  showTrash?: boolean;
  setShowTrash?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContainerMetaButtons = ({ edit, ...props }: Props) => {
  const stagingStatus = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex]
        .stagingStatus,
    shallowEqual
  );
  const [disableButtons, setDisableButtons] = React.useState(false);
  React.useEffect(() => {
    switch (stagingStatus) {
      case StagingBuilderStatuses.CLOSED: {
        setDisableButtons(true);
        break;
      }
      case StagingBuilderStatuses.PROPOSAL_FELL_THROUGH: {
        setDisableButtons(true);
        break;
      }
      case StagingBuilderStatuses.DEAL_FELL_THROUGH: {
        setDisableButtons(true);
        break;
      }
      case StagingBuilderStatuses.DEAL_FELL_THROUGH_REQUEST: {
        setDisableButtons(true);
        break;
      }
      default: {
        setDisableButtons(false);
        break;
      }
    }
  }, [stagingStatus]);
  return (
    <div className="grid col-4 mt-1">
      <AddItemContainer disabled={disableButtons} />
      <div></div>
      <ScanItems disabled={disableButtons} />
      <ShowTrashedItems {...props} />
    </div>
  );
};
export default ContainerMetaButtons;
