import { push } from "connected-react-router";
import React from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { AppThunk } from "../../../../../redux";
import { FormLogic } from "./FormLogic";

const modalStyle: React.CSSProperties = {
  left: "40vw",
  right: "40vw",
  top: "30vh",
  bottom: "auto",
};

export const Login = () => {
  const dispatch: AppThunk = useDispatch();

  return (
    <Modal
      style={{ content: modalStyle }}
      isOpen={true}
      onRequestClose={() => dispatch(push("/accounts/my-account"))}
    >
      <div>
        <h1 className="text-xl">Please Log In to Edit Account Info</h1>
        <FormLogic />
      </div>
    </Modal>
  );
};
