import { useMemo } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Istate } from "../../../redux";
import { DraggableInventoryContainer } from "../draggableInventory/DraggableInventoryContainer";
import { CheckIn, TransferIn } from ".";
import { Scanner } from "./Scanner";

export const LeftSideLogic = () => {
  const queries = useSelector(
    (state: Istate) => state.router.location.query,
    shallowEqual
  );

  process.env.NODE_ENV === "development" &&
    console.warn("need to narrow permission");

  const checkInPermission = useSelector(
    (state: Istate) => state.data.user.businessID === 1,
    shallowEqual
  );
  const StagingBuilders = useSelector(
    (state: Istate) => state.data.stagingBuilders[0] !== undefined,
    shallowEqual
  );
  const { "left-side": leftSide } = queries;
  const component = useMemo(() => {
    switch (queries["left-side"]) {
      case "scan":
        return StagingBuilders ? <Scanner /> : null;
      case "check-in":
        if (checkInPermission) return <CheckIn />;
        else return <DraggableInventoryContainer />;
      case "transfer-in":
        return <TransferIn />;
      default:
        return <DraggableInventoryContainer />;
    }
  }, [leftSide, StagingBuilders]);
  return component;
};
