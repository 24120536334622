import { push } from "connected-react-router";
import { useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { AppThunk, Istate } from "../../../../redux";
import { useBooleanState } from "../../../HighlyReusableComponents/hooks/useBooleanState";
import { BasicModal } from "../../../Modals/BasicModal";
import { CICommentModal } from "./CICommentModal";
import { ConfirmationModal } from "./ConfirmationModal";
import { MarkTagged } from "./MarkTagged";
import { SchedulingModal } from "./SchedulingModal";
import { StagerInfoModal } from "./StagerInfoModal";
import { CustomerModal } from "./StagingInfoModal";

export { ErrorModal } from "./ErrorModal";
export { GroupsModal } from "./GroupsModal";

interface Props {}

/**
 * A container component that holds all the modals
 * @todo
 * 1) make this stateless
 */
export const UrlModal = (props: Props) => {
  const dispatch: AppThunk = useDispatch();

  const queries = useSelector(
    (state: Istate) => state.router.location.query,
    shallowEqual
  );
  const permission = useSelector(
    (state: Istate) => state.data.user.businessID === 1,
    shallowEqual
  );

  const modals = useBooleanState({
    confirmation: false,
    stagingInformation: false,
    schedulePickup: false,
    markTagged: false,
    stagerInfo: false,
  });
  useEffect(() => {
    modals.resetState("initial");
    switch (queries["open-modal"]) {
      case "confirmation-menu": {
        modals.set("confirmation");
        break;
      }
      case "staging-information": {
        modals.set("stagingInformation");
        break;
      }
      case "schedule-pickup": {
        modals.set("schedulePickup");
        break;
      }
      case "mark-tagged": {
        modals.set("markTagged");
        break;
      }
      case "stager-info": {
        modals.set("stagerInfo");
        break;
      }
      case undefined:
        // if its undefined dont do anything. If this isn't here promptIfDirty fires twice
        break;
      default: {
        break;
      }
    }
    console.log(modals);
  }, [queries["open-modal"]]);

  const closeModal = (path: string = "/stage") => {
    dispatch(push(path));
  };
  return (
    <>
      <BasicModal
        modalStyle={{ inset: "15vh 25vw" }}
        open={modals.values.confirmation}
        setOpen={closeModal}
      >
        {permission ? <ConfirmationModal /> : <Redirect to="/stage" />}
      </BasicModal>
      <BasicModal
        modalStyle={{ inset: "20vh 25vw", overflowY: "hidden" }}
        open={modals.values.schedulePickup}
        setOpen={closeModal}
      >
        {permission ? <SchedulingModal /> : <Redirect to="/stage" />}
      </BasicModal>
      <BasicModal
        modalStyle={{ inset: "20vh 25vw", overflowY: "hidden" }}
        open={modals.values.markTagged}
        setOpen={closeModal}
      >
        {permission ? <MarkTagged /> : <Redirect to="/stage" />}
      </BasicModal>
      <BasicModal
        modalStyle={{ inset: "20vh 25vw", overflowY: "hidden" }}
        open={modals.values.stagerInfo}
        setOpen={closeModal}
      >
        {permission ? <StagerInfoModal /> : <Redirect to="/stage" />}
      </BasicModal>
      <CICommentModal />
      <CustomerModal />
    </>
  );
};
