import { useEffect, useState } from "react";
import { useMemo } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { fetchingDataSuccess } from "../../../../redux";
import { selectContainerItems } from "../../../../redux/stagingBuilder/actions/containerItems/SELECT";
import { ContainerItem } from "../../../../redux/stagingBuilder/stagingBuilderTypes";
import { containerItemsAsArray } from "../../../constants/functions/containerItemsAsArray";
import { TableThumbnail } from "../TableThumbnail";
import { AddItemButton } from "./AddItemButton";

export const TransferInTBody = () => {
  const dispatch = useDispatch();
  const socket = useSelector(
    (state: Istate) => state.data.socket,
    shallowEqual
  );
  const fileNumber = useSelector(
    (state: Istate) => state.router.location.query["from-file-number"],
    shallowEqual
  );

  const [containerItems, setContainerItems] = useState<
    (ContainerItem & { itemNo: string })[]
  >([]);

  const currentStagingBuilderContainerItems = useSelector((state: Istate) =>
    containerItemsAsArray(
      state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex]
    )
  );

  useEffect(() => {
    const socketFillContainerItems = (stringyData: string) => {
      const data = JSON.parse(stringyData);
      const { containerItems } = data;
      setContainerItems(containerItems);
      dispatch(fetchingDataSuccess());
    };
    socket && socket.on("CONTAINER_ITEMS_REPLY", socketFillContainerItems);
    return () => {
      socket && socket.off("CONTAINER_ITEMS_REPLY", socketFillContainerItems);
    };
  }, [socket]);

  useEffect(() => {
    if (fileNumber)
      dispatch(selectContainerItems({ fileNumber: Number(fileNumber) }));
  }, [fileNumber]);

  const filteredArray = useMemo(
    () =>
      currentStagingBuilderContainerItems
        ?.filter(({ itemID: itemID_outer }) =>
          containerItems.filter(({ itemID }) => itemID_outer === itemID)
        )
        .map(({ itemID }) => itemID),
    [currentStagingBuilderContainerItems, containerItems]
  );

  return (
    <tbody>
      {containerItems.map((containerItem) => (
        <tr key={containerItem.containerItemID}>
          <TableThumbnail item={containerItem} />
          <td>{containerItem.itemName}</td>
          <td>
            <Link to={`/inventory/edit/${containerItem.itemNo}`}>
              {containerItem.itemNo}
            </Link>
          </td>
          <td>
            <AddItemButton
              overlap={filteredArray}
              containerItem={containerItem}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
};
