import { initialReduxState, SystemState } from "../initialReduxState";

const initialState = initialReduxState;

const userReducer = (
  state: SystemState = initialState,
  action: UsersReducer
): SystemState => {
  switch (action.type) {
    case "TOGGLE_BUSINESS_VIEW": {
      if (process.env.NODE_ENV !== "development" && state.user.id !== 1)
        return { ...state };

      return {
        ...state,
        user: {
          ...state.user,
          businessID: Number(!state.user.businessID),
        },
      };
    }
    case "FETCHING_DATA": {
      return {
        ...state,
        user: {
          ...state.user,
          loading: true,
        },
      };
    }
    case "FETCHING_DATA_SUCCESS": {
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
        },
      };
    }
    case "FETCHING_DATA_FAILURE": {
      console.error("FETCHING_DATA_FAILURE");
      console.warn(
        "Need to build FETCHING_DATA_FAILURE reducer: \n    Currently just setting loading to false"
      );
      let revokeAccess = false;
      if (action.payload === 401) revokeAccess = true;

      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          unauthorizedAccess: revokeAccess,
          loggedIn: action.payload !== 401,
        },
      };
    }
    case "REDIRECT_SUCCESS": {
      console.warn(
        "Need to build FETCHING_DATA_FAILURE reducer: \n    Currently just setting loading to false"
      );
      let revokeAccess = false;
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          unauthorizedAccess: revokeAccess,
        },
      };
    }
    case "NEW_USER":
      return {
        ...state,
        user: {
          ...state.user,
          loggedIn: true,
          name: "Daniel",
          email: "Daniel@gmail.com",
        },
      };
    case "LOG_IN_ATTEMPT": {
      return {
        ...state,
        user: {
          ...state.user,
          loading: true,
        },
      };
    }
    case "CREATE_ACCOUNT_ATTEMPT": {
      return {
        ...state,
        user: {
          ...state.user,
          loading: true,
        },
      };
    }
    case "LOG_IN_FAIL": {
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          loggedIn: false,
          loginFailed: false,
        },
      };
    }
    case "LOG_IN_SUCCESS":
      // should probably rename this load account info
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          loggedIn: true,
          ...action.payload.User,
        },
      };
    case "RESET_STATE":
      return {
        ...initialReduxState,
        user: {
          ...initialReduxState.user,
          loggedIn: false,
          loginFailed: false,
        },
      };
    case "FILL_SOCKET": {
      return {
        ...state,
        socket: action.payload,
      };
    }
    default:
      return state;
  }
};

export default userReducer;
