import React, { useState } from "react";
import { FaMarker } from "react-icons/fa";
import { useSelector, shallowEqual } from "react-redux";
import { EmployeeEdit } from "./EmployeeEdit";

interface Props {
  itemContainerIndex: number;
  containerItemIndex: number;
}

export const EmployeeOptions = ({
  itemContainerIndex,
  containerItemIndex,
}: Props) => {
  const [markupMenuOpen, setMarkupMenuOpen] = useState(false);
  const containerItem = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex]
        .itemContainers[itemContainerIndex].items[containerItemIndex],
    shallowEqual
  );
  return containerItem.containerItemID ? (
    <>
      <div className="absolute top right p-1 z-5">
        <FaMarker
          cursor="pointer"
          onClick={() => setMarkupMenuOpen((current) => !current)}
        />
      </div>
      {markupMenuOpen && (
        <EmployeeEdit
          setMarkupMenuOpen={setMarkupMenuOpen}
          containerItem={containerItem}
        />
      )}
    </>
  ) : null;
};
