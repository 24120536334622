import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppThunk, fetchInventoryMin, Istate } from "../redux";

import StagingBuilder from "./Stage/";

const Stage = () => {
  const dispatch: AppThunk = useDispatch();
  const { loggedIn, activeStagingBuilder } = useSelector(
    (state: Istate) => state.data.user
  );
  useEffect(() => {
    loggedIn && activeStagingBuilder && dispatch(fetchInventoryMin());
  }, [loggedIn, activeStagingBuilder]);

  return <StagingBuilder />;
};
export default Stage;
