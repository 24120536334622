import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import { PromptIfDirty } from "../../../FormikComponents/PromptIfDirty";
import { PermissionForm } from "./PermissionForm";
import * as Yup from "yup";

interface Props {
  user: USER_PERMISSION;
}

export const FormikInit = ({ user }: Props) => {
  const initialValues = user;
  const onSubmit = (
    values: typeof initialValues,
    formikHelpers: FormikHelpers<any>
  ) => {};
  const validationSchema = Yup.object({
    selfSign: Yup.boolean(),
  });
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {(FormikProps: FormikProps<any>) => (
        <Form>
          <PromptIfDirty />
          <PermissionForm user={user} FormikProps={FormikProps} />
        </Form>
      )}
    </Formik>
  );
};
