export const dateOptions: Intl.DateTimeFormatOptions = {
  month: "numeric",
  day: "numeric",
  year: "2-digit",
  hour12: true,
  hour: "numeric",
  minute: "numeric",
  timeZone: "America/phoenix",
};
export const dateOptionsShort: Intl.DateTimeFormatOptions = {
  month: "numeric",
  day: "numeric",
  year: "2-digit",
  timeZone: "America/phoenix",
};
export const dateOptionsShortVerbose: Intl.DateTimeFormatOptions = {
  weekday: "long",
  month: "long",
  day: "numeric",
  year: "numeric",
  timeZone: "America/phoenix",
};
