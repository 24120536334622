import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import { routerMiddleware } from "connected-react-router";
import ReduxThunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { createBrowserHistory } from "history";
import { createRootReducer } from "./";

export const history = createBrowserHistory();
const store = createStore(
  createRootReducer(history),
  composeWithDevTools(
    applyMiddleware(routerMiddleware(history), ReduxThunk, logger)
  )
);

export default store;
