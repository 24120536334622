import { useEffect, useState } from "react";

import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import * as Yup from "yup";

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  Istate,
  AppThunk,
  handleInventorySubmit,
  handleInventoryUpdate,
} from "../../redux";
import { push } from "connected-react-router";

import { EditorHeader } from "./Editor/EditorHeader";
import { EditorBodyContainer } from "./Editor/EditorBodyContainer";

import { PromptIfDirty } from "../FormikComponents/PromptIfDirty";

function InventoryEditorContainer() {
  const dispatch: AppThunk = useDispatch();
  const activeItem = useSelector(
    (state: Istate) => state.data.user.activeItem,
    shallowEqual
  );
  const searchParams = useSelector(
    (state: Istate) => state.data.inventorySearch,
    shallowEqual
  );
  const pathname = useSelector(
    (state: Istate) => state.router.location.pathname,
    shallowEqual
  );

  const [fileChangedA, setFileChangedA] = useState(false);
  const [fileChangedB, setFileChangedB] = useState(false);
  // convert from integer to boolean so formik works
  if (activeItem) {
    activeItem.hotbuy = activeItem && !!activeItem?.hotbuy;
    activeItem.mhfo = activeItem && !!activeItem?.mhfo;
    activeItem.ezrent = activeItem && !!activeItem?.ezrent;

    activeItem["itemNo-search"] = searchParams.itemNo.keyValue;
    activeItem["model-search"] = searchParams.model.keyValue;
    activeItem["vendorID-search"] = searchParams.vendorID.keyValue;
    activeItem["collectionID-search"] = searchParams.collectionID.keyValue;
    activeItem["room-search"] = searchParams.room.keyValue;
    activeItem["piece-search"] = searchParams.piece.keyValue;
    activeItem["itemName-search"] = searchParams.itemName.keyValue;
  }
  const [initialValues, setInitialValues] = useState(activeItem);
  useEffect(() => {
    if (activeItem) {
      let newInitialValues = { ...activeItem };
      newInitialValues.ain = activeItem?.ain ? activeItem.ain : "";
      newInitialValues.ourSku = activeItem?.ourSku ? activeItem.ourSku : "";
      newInitialValues.vendorURL = activeItem?.vendorURL
        ? activeItem.vendorURL
        : "";
      newInitialValues.length = activeItem?.length ? activeItem.length : "";
      newInitialValues.height = activeItem?.height ? activeItem.height : "";
      newInitialValues.depth = activeItem?.depth ? activeItem.depth : "";
      newInitialValues.landedCost = activeItem?.landedCost
        ? activeItem.landedCost
        : "";
      newInitialValues.sellPrice = activeItem?.sellPrice
        ? activeItem.sellPrice
        : "";
      newInitialValues.comparePrice = activeItem?.comparePrice
        ? activeItem.comparePrice
        : "";
      newInitialValues.rentalPrice = activeItem?.rentalPrice
        ? activeItem.rentalPrice
        : "";
      newInitialValues.rentalRate = activeItem?.rentalRate
        ? activeItem.rentalRate
        : "";
      newInitialValues.webCardPlacement = 10;

      // temp
      if (process.env.NODE_ENV === "development" && pathname.includes("new")) {
        // newInitialValues.height = "12";
        newInitialValues.itemName = "Abstract";
        // newInitialValues.rentalRate = 1;
        // newInitialValues.rentalPrice = 15;
        // newInitialValues.quanLoft = 1;
        newInitialValues.room = 8;
        newInitialValues.piece = 802;
        newInitialValues.vendorID = 24;
        newInitialValues.quanLoft = 1;
        newInitialValues.quanAvail = 1;
        newInitialValues.quantity = 1;
        newInitialValues.rentalPrice = 269;
        newInitialValues.rentalRate = 16;
        newInitialValues.ezrent = 1;
      }
      // end temp

      setInitialValues((oldValues) => {
        return { ...oldValues, ...newInitialValues };
      });
    }
  }, [activeItem]);

  const validationSchema = Yup.object({
    itemNo: Yup.string().min(6, "Too short.").required("Required"),
    itemName: Yup.string().required("Required"),
    vendorID: Yup.number()
      .moreThan(0, "Required: Unknown is an option")
      .required("Required: Unknown is an option"),
    room: Yup.number().moreThan(0, "Required").required("Required"),
    piece: Yup.number().moreThan(0, "Required").required("Required"),
    landedCost: Yup.number().typeError("Value must be a number").nullable(),
    mhfo: Yup.boolean(),
    sellPrice: Yup.number()
      .typeError("Value must be a number")
      .nullable()
      .when("mhfo", {
        is: true,
        then: Yup.number()
          .typeError("Value must be a number")
          .required("Required when MHFO checkbox is selected"),
      }),
    comparePrice: Yup.number()
      .typeError("Value must be a number")
      .nullable()
      .when("mhfo", {
        is: true,
        then: Yup.number()
          .typeError("Value must be a number")
          .required("Required when MHFO checkbox is selected"),
      }),
    ezrent: Yup.boolean(),
    rentalRate: Yup.number()
      .typeError("Value must be a number")
      .nullable()
      .when("ezrent", {
        is: true,
        then: Yup.number()
          .typeError("Value must be a number")
          .required("Required when StagingRents checkbox is selected"),
      }),
    rentalPrice: Yup.number()
      .typeError("Value must be a number")
      .nullable()
      .when("ezrent", {
        is: true,
        then: Yup.number()
          .typeError("Value must be a number")
          .required("Required when StagingRents checkbox is selected"),
      }),
    length: Yup.number().typeError(
      "Value must be a number (value will be displayed with inches)"
    ),
    height: Yup.number().typeError(
      "Value must be a number (value will be displayed with inches)"
    ),
    depth: Yup.number().typeError(
      "Value must be a number (value will be displayed with inches)"
    ),
    webCardPlacement: Yup.number()
      .typeError("Value must be filled out")
      .nullable()
      .when("ezrent", {
        is: true,
        then: Yup.number()
          .moreThan(0, "Required when StagingRents checkbox is selected")
          .typeError("Value must be a number")
          .required("Required when StagingRents checkbox is selected"),
      })
      .when("mhfo", {
        is: true,
        then: Yup.number()
          .moreThan(0, "Required when MHFO checkbox is selected")
          .typeError("Value must be a number")
          .required("Required when MHFO checkbox is selected"),
      }),
    pictureAdded: Yup.boolean()
      .nullable()
      .when("newItem", {
        is: true,
        then: Yup.boolean().oneOf([true], "Picture Required"),
      }),
  });
  const onSubmit = (
    values: typeof initialValues,
    actions: FormikHelpers<itemVerbose>
  ) => {
    // need to make sure all of the MySQL not null fields are filled out
    // make an easy to submit object
    const valuesToSubmit = {
      itemNo: values?.itemNo,
      itemName: values?.itemName,
      itemRating: values?.itemRating ? values?.itemRating : 0,
      itemStyle: values?.itemStyle,
      itemColor: values?.itemColor,
      itemGroups: values?.itemGroups,
      itemReferences: values?.itemReferences,
      vendorID: values?.vendorID,
      vendorURL: values?.vendorURL ? values?.vendorURL : null,
      collectionID: values?.collectionID ? values?.collectionID : 0,
      model: values?.model,
      ourSku: values?.ourSku,
      ain: values?.ain,
      tagDescription: values?.tagDescription,
      webDescription: values?.webDescription,
      landedCost: values?.landedCost,
      sellPrice: values?.sellPrice,
      excludeSale: values?.excludeSale,
      specialPrice: values?.specialPrice,
      comparePrice: values?.comparePrice,
      rentalRate: values?.rentalRate,
      rentalPrice: values?.rentalPrice,
      size: values?.size,
      length: values?.length ? values?.length : null,
      height: values?.height ? values?.height : null,
      depth: values?.depth ? values?.depth : null,
      tagType: values?.tagType,
      tagStyle: values?.tagStyle,
      stockTagID: values?.stockTagID,
      quantity: values?.quantity,
      quanAvail: values?.quanAvail,
      quanHold: values?.quanHold,
      quanStaged: values?.quanStaged,
      quanLoft: values?.quanLoft,
      quanHuddle: values?.quanHuddle,
      quanCastle: values?.quanCastle,
      quanMHFO: values?.quanMHFO,
      quanRepair: values?.quanRepair,
      room: values?.room,
      piece: values?.piece,
      // visibilityIndex: values?.visibilityIndex, // ohhh....
      hotbuy: values?.hotbuy,
      rentalBracket: values?.rentalBracket,
      mhfo: values?.mhfo,
      ezrent: values?.ezrent,
      tableType: 1, // this has to do with mhfo visibility
      webCardPlacement: values?.webCardPlacement,
      newItem: activeItem?.newItem ? activeItem?.newItem : false,
      fileChangedA: fileChangedA,
      pictureA: activeItem?.pictureA,
      fileChangedB: fileChangedB,
      pictureB: activeItem?.pictureB,
      pictureIncrement: activeItem?.pictureIncrement,
    };
    console.log("valuesToSubmit", valuesToSubmit);
    if (pathname.includes("new"))
      dispatch(handleInventorySubmit(valuesToSubmit));
    else
      dispatch(
        handleInventoryUpdate(valuesToSubmit, fileChangedA || fileChangedB)
      );
    actions.setSubmitting(false);
  };
  const redirect_newItem = () => {
    dispatch(push("/inventory/new"));
  };
  return initialValues ? (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {(FormikProps: FormikProps<any>) => (
        <Form style={{ height: "99%" }}>
          <PromptIfDirty />
          <EditorHeader
            FormikProps={FormikProps}
            pictureState={{
              fileChangedA,
              setFileChangedA,
              fileChangedB,
              setFileChangedB,
            }}
          />
          <EditorBodyContainer FormikProps={FormikProps} />
        </Form>
      )}
    </Formik>
  ) : (
    <div className="grid-center hundred-hundred">
      <h1 className="pointer" onClick={() => redirect_newItem()}>
        Create New Item
      </h1>
    </div>
  );
}

export default InventoryEditorContainer;
