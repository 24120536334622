import { FormikProps, useField } from "formik";
import type { FC, ReactNode } from "react";
import { TextAreaCounterMemo } from "../../../../../HighlyReusableComponents/FormComponents/TextArea_counter";

import { InitialValues } from "./initialValues";
// need Form control

interface Props {
  FormikProps: FormikProps<InitialValues>;
  children?: ReactNode;
}

export const FormFields: FC<Props> = ({ children }) => {
  const [field, meta] = useField("container-item-comment");
  return (
    <div>
      <TextAreaCounterMemo
        className="border resize-none focus:outline-none focus:ring"
        name="container-item-comment"
        id="container-item-comment"
        cols={30}
        rows={2}
        value={meta.value}
        onChange={field.onChange}
        maxLength={64}
        placeholder="(e.g. Flowers in vase; Baguettes in basket)"
        autoFocus
      />
      {children}
    </div>
  );
};
