import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { PulseLoader } from "react-spinners";

export const LoadingIndicator = () => {
  const loading = useSelector(
    (state: Istate) => state.data.user.loading,
    shallowEqual
  );
  return (
    <div
      style={{ height: "0px" }}
      className="side-bar side-bar-container__loading"
    >
      <div></div>
      <div>
        <PulseLoader size={15} loading={loading} />
      </div>
      <div></div>
    </div>
  );
};
