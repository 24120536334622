import React from "react";
import { Row } from "react-table";
import { StagingBuilderMemo } from "../ProposalContainerColumnContainer";
import { FloatingCell } from "./FloatingCell";

interface Props {
  row: Row<StagingBuilderMemo>;
}

export const ProposalTableCells = ({ row }: Props) => {
  return (
    <>
      {
        // Loop over the rows cells
        row.cells.map((cell) => {
          // Apply the cell props
          return (
            <td
              {...cell.getCellProps([
                {
                  className: cell.column?.className,
                  style: cell.column?.style,
                },
              ])}
            >
              {cell.column.floatText ? (
                <FloatingCell>{cell.render("Cell")}</FloatingCell>
              ) : (
                cell.render("Cell")
              )}
            </td>
          );
        })
      }
    </>
  );
};
