import {
  paths,
  SET_ROUTE_PERMISSION,
  SET_ROUTE_PAYLOAD,
  SET_RESPONSE_STATUS,
} from "./routeTypes";

import { initialReduxState, SystemState } from "../initialReduxState";
export interface IrouteReducer {
  type: string;
  payload: any;
}

const initialState = initialReduxState;

const routeReducer = (
  state: SystemState = initialState,
  action: IrouteReducer
): SystemState => {
  switch (action.type) {
    case SET_RESPONSE_STATUS: {
      const {
        key,
        status,
      }: { key: keyof paths; status: number } = action.payload;
      let routes = {
        ...state.route,
      };
      routes[key] = { ...routes[key], status: status };
      return {
        ...state,
        route: { ...routes },
      };
    }
    case SET_ROUTE_PERMISSION: {
      const {
        key,
        permission,
      }: { key: keyof paths; permission: boolean } = action.payload;
      let routes = {
        ...state.route,
      };
      routes[key] = { ...routes[key], permission };
      return {
        ...state,
        route: { ...routes },
      };
    }
    case SET_ROUTE_PAYLOAD: {
      const {
        key,
        payload,
      }: { key: keyof paths; payload: any } = action.payload;
      let routes = {
        ...state.route,
      };
      routes[key] = { ...routes[key], payload };
      return {
        ...state,
        route: { ...routes },
      };
    }
    case "SET_ROUTE_ERRORS": {
      const {
        key,
        error,
        value,
      }: { key: keyof paths; error: string; value?: boolean } = action.payload;
      // copy the routes
      let routes = { ...state.route };
      // error pointer
      const errors = routes[key].errors;
      // short hand just toggle value
      // short hand add value as true if DNE
      // add the exact value, dont care if it exists or not

      if (error in errors && value === undefined)
        errors[error] = !errors[error];
      else if (!(error in errors) && value === undefined) errors[error] = true;
      else if (value !== undefined) errors[error] = value;
      else throw new Error("I forgot an option I guess").stack;

      // set routes with the new errors object
      routes[key] = { ...routes[key], errors };
      return {
        ...state,
        route: { ...routes },
      };
    }
    default:
      return state;
  }
};

export default routeReducer;
