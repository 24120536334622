import { FormikProps } from "formik";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import FormikControl from "../../../../FormikComponents/FormikControl";

interface Props {
  formikProps: FormikProps<any>;
  children?: React.ReactNode;
}

export const FeeHandler = ({ formikProps: { values }, children }: Props) => {
  const cantEditFees = useSelector(
    (state: Istate) => state.data.user.businessID !== 1,
    shallowEqual
  );
  return (
    <div className="grid grid-cols-2 gap-x-2">
      {children}
      <div className="">
        <FormikControl
          control="input"
          label="Delivery Fee"
          name="fee1"
          disabled={cantEditFees}
        />
      </div>
      <div className="">
        <FormikControl
          control="input"
          label="Refundable Security Desposit"
          name="fee2"
          disabled={cantEditFees}
        />
      </div>
      <div className="">
        <FormikControl
          control="input"
          label="Occupied Concession Fee"
          name="fee3"
          disabled={cantEditFees || !values.occupied}
        />
      </div>
      <div className="">
        <FormikControl
          control="input"
          label="Long Haul - 3rd Fl.+"
          name="fee4"
          disabled={cantEditFees}
        />
      </div>
    </div>
  );
};
