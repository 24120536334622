import React from "react";
import { FormikProps } from "formik";
import { EmployeePermissions } from "./EmployeePermissions";
import { CustomerPermissions } from "./CustomerPermissions";

interface Props {
  user: USER_PERMISSION;
  FormikProps: FormikProps<any>;
}

export const PermissionForm = ({ FormikProps, user }: Props) => {
  return (
    <>
      <CustomerPermissions FormikProps={FormikProps} />
      {user.businessID !== 1 ? null : (
        <EmployeePermissions FormikProps={FormikProps} />
      )}
      <button className="w-100p height-3em" disabled={!FormikProps.dirty}>
        Save Information
      </button>
      <button
        onClick={() => FormikProps.resetForm()}
        className="w-100p height-3em mt-5"
        disabled={!FormikProps.dirty}
      >
        Reset Form
      </button>
    </>
  );
};
