import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { fetchingData, Istate } from "../..";
import { StagingBuilderStatuses } from "../../../components/constants";

export interface SocketStatusChange {
  type?: "META";
  action?: "STATUS";
  payload: {
    stagingBuilderID: number;
    status: StagingBuilderStatuses;
    currentStagingStatus: StagingBuilderStatuses;
  };
}

/**
 * payload defined by socket structure in api... see `@interface`
 *
 * @emits STAGING_BUILDER_UPDATE
 *
 * @listens FULL_STAGING_BUILDER_UPDATE - reply sent to this listener
 *
 * @interface SocketStatusChange
 * ```
 *  {
 *.   type: "META";     // defaults to "META"
 *.   action: "STATUS"; // defaults to "STATUS"
 *.   payload: {
 *.     stagingBuilderID: number;
 *.     status: StagingBuilderStatuses;
 *.     currentStagingStatus: StagingBuilderStatuses;
 *.   };
 * }
 * ```
 * @template nominal dispatch(socketStatusChange({payload:{stagingBuilderID,status:newStagingStatus,currentStagingStatus}}));
 */
export const socketStatusChange = (
  payload: SocketStatusChange
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: Function
  ): Promise<void> => {
    dispatch(fetchingData()); // active loading sign
    const {
      data: { socket },
    } = getState() as Istate;

    if (payload.type === undefined) payload.type = "META";
    if (payload.action === undefined) payload.action = "STATUS";

    socket?.emit("STAGING_BUILDER_UPDATE", JSON.stringify(payload));
  };
};
