import type { FC } from "react";
import { BlockContainer } from "./BlockContainer";
import { BlockBackDrop } from "./BlockBackDrop";
import { format } from "date-fns";
import { MdOutlineCreateNewFolder } from "react-icons/md";
import { RiDraftLine } from "react-icons/ri";
import { useSelector } from "react-redux";

interface Props {
  //
}

export const MetaDates: FC<Props> = () => {
  const { editedOn, createdOn } = useSelector(
    (s: Istate) => s.data.stagingBuilders[s.data.user.activeStagingBuilderIndex]
  );
  return (
    <div className="col-span-full hidden h-[70px] lg:col-span-2 lg:block">
      <BlockContainer>
        <div className="3xl:gap-8 flex h-min w-full grid-cols-2 grid-rows-2 justify-between gap-2 md:grid lg:h-full lg:grid-cols-2 lg:gap-8 lg:px-2 xl:gap-2">
          <div className="flex w-full justify-between">
            <MdOutlineCreateNewFolder
              className="block text-2xl text-green-800"
              size={32}
            />
            <div>
              <span className="block font-thin leading-tight">
                Created <span className="3xl:inline xl:hidden">On</span>
              </span>
              <span className="block leading-tight">
                {createdOn ? format(createdOn, "MM/dd/yyyy") : "TBD"}
              </span>
            </div>
          </div>
          <div className="contents w-full justify-between lg:flex">
            <RiDraftLine className="block text-2xl text-gray-700" size={32} />
            <div>
              <span className="block font-thin leading-tight">
                <span className="3xl:inline xl:hidden">Last</span> Edited
              </span>
              <span className="block min-w-[9ch] leading-tight">
                {editedOn ? format(editedOn, "MM/dd/yyyy") : "TBD"}
              </span>
            </div>
          </div>
        </div>
        <BlockBackDrop />
      </BlockContainer>
    </div>
  );
};
