import * as Yup from "yup";
import { StagingBuilderStatuses } from "../../../../../constants";
import { CustomButton } from "../../../../../HighlyReusableComponents/CustomButton";

export interface InitialValues {
  fileNumber: number | null | undefined;
  deliveryActual: number | undefined | null;
  stagingTagged: boolean;
  address: string;
  clientName: string | undefined;
  clientPhone: string | undefined;
  clientEmail: string | undefined;
}
interface returnValues {
  initialValues: InitialValues;
  validationSchema: Yup.ObjectSchema<InitialValues | undefined, object>;
}

export const formInit = (stagingBuilder: StagingBuilder): returnValues => {
  const validationSchema = Yup.object({
    address: Yup.string().required(
      (
        <>
          <span className="text-red-700">
            The address is missing from this StagingBuilder
          </span>
          <br />
          <CustomButton
            children="Go To Staging Information"
            linkProps={{
              to: "/stage?open-modal=staging-information",
            }}
            type="button"
          />
          <br />
          <br />
        </>
      ) as unknown as string
    ),
    clientName: Yup.string().required(
      (
        <>
          <span className="text-red-700">
            The name of the Lessee is missing from this StagingBuilder
          </span>
          <br />
          <CustomButton
            children="Go To Staging Information"
            linkProps={{
              to: "/stage?open-modal=staging-information",
            }}
            type="button"
          />
          <br />
          <br />
        </>
      ) as unknown as string
    ),
    clientPhone: Yup.string().required(
      (
        <>
          <span className="text-red-700">
            The phone number of the lessee is missing from this StagingBuilder
          </span>
          <br />
          <CustomButton
            children="Go To Staging Information"
            linkProps={{
              to: "/stage?open-modal=staging-information",
            }}
            type="button"
          />
          <br />
          <br />
        </>
      ) as unknown as string
    ),
    clientEmail: Yup.string().required(
      (
        <>
          <span className="text-red-700">
            The email of the lessee is missing from this StagingBuilder
          </span>
          <br />
          <CustomButton
            children="Go To Staging Information"
            linkProps={{
              to: "/stage?open-modal=staging-information",
            }}
            type="button"
          />
          <br />
          <br />
        </>
      ) as unknown as string
    ),
    fileNumber: Yup.number()
      .integer()
      .typeError("The File Number must be a number")
      .required(),
    deliveryActual: Yup.number()
      .integer("That is an invalid date format")
      .required(
        "The Staging must have a scheduled delivery date to submit this form. " +
          "This date should be the same as in the scheduling Google Sheet."
      )
      .min(1, "That date is out of range."),
    stagingTagged: Yup.boolean()
      .required("The Staging must be tagged to submit this form.")
      .equals([true], "The Staging must be tagged to submit this form."),
  });
  const stagingTagged =
    stagingBuilder.stagingStatus ===
      StagingBuilderStatuses.SCHEDULED_AND_TAGGED ||
    stagingBuilder.stagingStatus === StagingBuilderStatuses.PAID;
  return {
    initialValues: {
      address: stagingBuilder.address,
      fileNumber: stagingBuilder.fileNumber,
      deliveryActual: stagingBuilder.deliveryActual || null,
      stagingTagged,
      clientName: stagingBuilder.clientName,
      clientPhone: stagingBuilder.clientPhone,
      clientEmail: stagingBuilder.clientEmail,
    },
    validationSchema,
  };
};
