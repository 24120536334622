import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import { useCallback } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Istate } from "../../../../../../redux";
import { ContainerItem } from "../../../../../../redux/stagingBuilder/stagingBuilderTypes";
import { ToastyRed } from "../../../../../constants";
import { PromptIfDirty } from "../../../../../FormikComponents/PromptIfDirty";
import { FormFields } from "./FormFields";

import { formInit, InitialValues } from "./initialValues";

interface Props {
  containerItem: ContainerItem;
}
export const FormLogic = ({ containerItem }: Props) => {
  const { socket } = useSelector((state: Istate) => state.data, shallowEqual);

  const onSubmit = (
    values: InitialValues,
    actions: FormikHelpers<InitialValues>
  ) => {
    console.log("values: ", values);
    let dataToSend = {
      type: "CONTAINER_ITEM",
      action: "MARK_TAGGED",
      payload: {
        containerItem: { ...containerItem },
        holdTagNos: values.tagNumbers,
        tagged: values.tagged,
        loc: values.loc,
      },
    };
    console.log(dataToSend);
    if (socket) {
      socket.emit("STAGING_BUILDER_UPDATE", JSON.stringify(dataToSend));
    } else {
      ToastyRed(
        "Something went wrong while trying to submit the data. Please refresh the page before trying again."
      );
    }
    actions.setSubmitting(false);
  };
  const formInfo = useCallback(() => formInit(containerItem), [containerItem]);
  return (
    <Formik
      initialValues={formInfo().initialValues}
      validationSchema={formInfo().validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validateOnMount={true}
    >
      {(formikProps: FormikProps<InitialValues>) => (
        <Form>
          <FormFields
            initialValues={formInfo().initialValues}
            containerItem={containerItem}
            formikProps={formikProps}
          />
          <PromptIfDirty />
        </Form>
      )}
    </Formik>
  );
};
