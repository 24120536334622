import { useMemo } from "react";
import { BlockContainer } from "./BlockContainer";
import { BlockBackDrop } from "./BlockBackDrop";
import { StagingBuilderStatuses } from "../../../../constants";
import type { FC } from "react";

export const ItemHold: FC<Pick<StagingBuilder, "stagingStatus">> = ({
  stagingStatus,
}) => {
  const status = useMemo(() => {
    return isItemOnHold(stagingStatus);
  }, [stagingStatus]);

  return (
    <div className="col-start-2 mt-auto hidden h-min w-[calc(100%-1rem)] lg:col-start-3 lg:row-start-3 lg:my-0 lg:block lg:h-[70px] lg:w-full">
      <BlockContainer>
        <BlockBackDrop />
        <div className="flex flex-col">
          <span className="text-sm font-medium leading-tight">Item Status</span>
          <span className="leading-none text-gray-700">{status}</span>
        </div>
      </BlockContainer>
    </div>
  );
};

export const isItemOnHold = (
  status: StagingBuilderStatuses
): "Staged" | "On Hold" | "Not On Hold" | "Unkown" | "Returned" => {
  switch (status) {
    case StagingBuilderStatuses.PROPOSAL_HOLD_CUSTOMER:
    case StagingBuilderStatuses.PROPOSAL_HOLD_EMPLOYEE:
    case StagingBuilderStatuses.PROPOSAL_APPROVAL_REQUEST_HOLD:
    case StagingBuilderStatuses.LIMBO_HOLD:
    case StagingBuilderStatuses.LIMBO_REQUEST_HOLD:
    case StagingBuilderStatuses.APPROVAL_PROCESS_STARTED:
    case StagingBuilderStatuses.SCHEDULED_AND_TAGGED:
    case StagingBuilderStatuses.PAID:
      return "On Hold";
    case StagingBuilderStatuses.PROPOSAL_RELEASE:
    case StagingBuilderStatuses.NEW_CUSTOMER:
    case StagingBuilderStatuses.PROPOSAL_HOLD_REQUEST:
    case StagingBuilderStatuses.PROPOSAL_FELL_THROUGH:
    case StagingBuilderStatuses.PROPOSAL_APPROVAL_REQUEST:
    case StagingBuilderStatuses.LIMBO_REQUEST:
    case StagingBuilderStatuses.DEAL_FELL_THROUGH_REQUEST:
    case StagingBuilderStatuses.LIMBO_RELEASE:
    case StagingBuilderStatuses.DEAL_FELL_THROUGH:
    case StagingBuilderStatuses.PICKUP_REQUEST:
    case StagingBuilderStatuses.PICKUP_SCHEDULED:
      return "Not On Hold";
    case StagingBuilderStatuses.CLOSED:
      return "Returned";
    case StagingBuilderStatuses.STAGED:
      return "Staged";
    default:
      return "Unkown";
  }
};
