import React from "react";
import { AiOutlineStop } from "react-icons/ai";
import { BsCardChecklist, BsListCheck, BsStopwatch } from "react-icons/bs";
import { FaCalendarCheck, FaRegCheckCircle, FaTruck } from "react-icons/fa";
import { GiHastyGrave, GiStopSign } from "react-icons/gi";
import { GoIssueOpened } from "react-icons/go";
import { RiDeviceRecoverLine } from "react-icons/ri";
import { SiAdblock } from "react-icons/si";
import { CgMenuGridR } from "react-icons/cg";
import { handleStatusChange } from "../../../redux/stagingBuilder";
import { NavLi } from "../../Menu/NavLi";
import { push } from "connected-react-router";
import { FiFolderPlus, FiFolderMinus } from "react-icons/fi";
import { StagingBuilderStatuses } from "..";
import { shallowEqual, useSelector } from "react-redux";
import { Istate } from "../../../redux";
import {
  SocketStatusChange,
  socketStatusChange,
} from "../../../redux/stagingBuilder/actions/socketStatusChange";

// I have no idea why this file is in this folder -- but whatever
export const GetArrayFromStatus = ({
  stagingStatus: status,
  fileNumber,
  deliveryDesired,
  pickupActual,
  pickupDesired,
}: StagingBuilder) => {
  const unavailableItems = useSelector(
    (state: Istate) =>
      Boolean(
        state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex]
          .clashes?.length
      ),
    shallowEqual
  );
  const { businessID, activeStagingBuilder: stagingBuilderID } = useSelector(
    (state: Istate) => state.data.user,
    shallowEqual
  );
  if (fileNumber === null) fileNumber = 0;

  const requestHold = (
    <NavLi
      key={0}
      Icon={BsStopwatch}
      label="Request Hold on Items"
      reduxAction={{
        action: handleStatusChange,
        payload: {
          currentStagingStatus: status,
          action: "PROPOSAL_HOLD_REQUEST",
        },
      }}
      disabled={unavailableItems}
      confirmationText="Are you sure you want to put the items in this StagingBuilder on hold?\n"
    />
  );

  const employeeHold =
    businessID !== 1 ? (
      <></>
    ) : (
      <NavLi
        key={1}
        Icon={BsStopwatch}
        label="Hold Items (Employee Hold)"
        reduxAction={{
          action: handleStatusChange,
          payload: {
            currentStagingStatus: status,
            action: "PROPOSAL_HOLD_EMPLOYEE",
          },
        }}
        disabled={unavailableItems}
        confirmationText={
          "Are you sure you want to put the items in this StagingBuilder on hold?\n" +
          "(Note this will not effect the owner of this StagingBuilders'\n" +
          "maximum amount of items that they can put on hold.)"
        }
      />
    );
  const stopHold = (
    <NavLi
      key={2}
      Icon={GiStopSign}
      label={`Cancel Hold on Items`}
      reduxAction={{
        action: handleStatusChange,
        payload: {
          currentStagingStatus: status,
          action: "STOP_HOLD",
        },
      }}
      confirmationText="Are you sure you want to remove the hold on this StagingBuilder?"
    />
  );
  const requestConfirmation = (
    <NavLi
      key={3}
      Icon={FaRegCheckCircle}
      label="Submit Staging for Confirmation"
      reduxAction={{
        action: handleStatusChange,
        payload: {
          currentStagingStatus: status,
          action: "PROPOSAL_CONFIRMATION_REQUEST",
        },
      }}
      confirmationText="Are you sure you want to begin confirmation of this StagingBuilder?"
    />
  );
  const cancelProposal = (
    <NavLi
      key={4}
      Icon={fileNumber ? GiHastyGrave : AiOutlineStop}
      label={fileNumber ? "Deal Fell Through" : "Cancel Proposal"}
      reduxAction={{
        action: handleStatusChange,
        payload: {
          currentStagingStatus: status,
          action: "CANCEL_STAGINGBUILDER",
        },
      }}
      confirmationText={
        fileNumber
          ? "Are you sure you want to mark this StagingBuilder as Deal Fell Through?\n" +
            "ONLY do this if you are certain." +
            "An employee will have to reverse this for you."
          : "Are you sure you want to cancel this StagingBuilder?"
      }
    />
  );
  const postponeStagingRequest =
    fileNumber === 0 ? null : (
      <NavLi
        key={5}
        Icon={GoIssueOpened}
        label="Postpone this StagingBuilder Indefinitely"
        reduxAction={{
          action: handleStatusChange,
          payload: {
            currentStagingStatus: status,
            action: "POSTPONE_STAGING",
          },
        }}
        confirmationText="Are you sure you want to begin confirmation of this StagingBuilder?"
      />
    );

  const cancelCurrentRequest =
    StagingBuilderStatuses.PROPOSAL_HOLD_REQUEST === status
      ? "Cancel Request: To Hold Items"
      : StagingBuilderStatuses.PROPOSAL_APPROVAL_REQUEST === status ||
        StagingBuilderStatuses.PROPOSAL_APPROVAL_REQUEST_HOLD === status
      ? "Cancel StagingBuilder Confirmation"
      : "Unknown Request";

  const cancelRequest = (
    <NavLi
      key={6}
      Icon={GiStopSign}
      label={cancelCurrentRequest}
      reduxAction={{
        action: handleStatusChange,
        payload: {
          currentStagingStatus: status,
          action: "CANCEL_REQUEST",
        },
      }}
      confirmationText={`Are you sure you want to cancel your current request?`}
    />
  );
  const denyCurrentRequest =
    StagingBuilderStatuses.PROPOSAL_HOLD_REQUEST === status
      ? "Cancel Request: To Hold Items"
      : StagingBuilderStatuses.PROPOSAL_APPROVAL_REQUEST === status ||
        StagingBuilderStatuses.PROPOSAL_APPROVAL_REQUEST_HOLD === status
      ? "Deny StagingBuilder Confirmation"
      : "Unknown Request";
  const denyRequest =
    businessID !== 1 ? null : (
      <NavLi
        key={7}
        Icon={SiAdblock}
        label={denyCurrentRequest}
        reduxAction={{
          action: handleStatusChange,
          payload: {
            currentStagingStatus: status,
            action: "DENY_REQUEST_EMPLOYEE",
          },
        }}
        confirmationText="Are you sure you want to deny this request?"
      />
    );

  // this doesn't work currently --
  //  1) Need to open up a modal for an employee to check off inventory
  const confirmCurrentRequest =
    StagingBuilderStatuses.PROPOSAL_HOLD_REQUEST === status
      ? "Confirm Request: To Hold Items"
      : StagingBuilderStatuses.PROPOSAL_APPROVAL_REQUEST === status ||
        StagingBuilderStatuses.PROPOSAL_APPROVAL_REQUEST_HOLD === status
      ? "Start StagingBuilder Confirmation"
      : "Unknown Request";
  const confirmRequest =
    businessID !== 1 ? null : (
      <NavLi
        key={8}
        Icon={BsCardChecklist}
        label={confirmCurrentRequest}
        reduxAction={{
          action: handleStatusChange,
          payload: {
            currentStagingStatus: status,
            action: "CONFIRM_REQUEST_EMPLOYEE",
          },
        }}
        confirmationText="Are you sure you want to confirm this request?"
      />
    );
  // turn this back into a normal proposal
  const recoverProposal = (
    <NavLi
      key={9}
      Icon={RiDeviceRecoverLine}
      label="Recover StagingBuilder as Proposal"
      reduxAction={{
        action: handleStatusChange,
        payload: { currentStagingStatus: status, action: "RECOVER" },
      }}
      confirmationText="Are you sure you want to recover this StagingBuilder?"
    />
  );
  const openConfirmStagingModal = (
    <NavLi
      key={10}
      Icon={CgMenuGridR}
      label="Confirmation Menu"
      reduxAction={{
        action: push as any,
        payload: "/stage?open-modal=confirmation-menu",
      }}
    />
  );
  const stagingDelivered = (
    <NavLi
      key={11}
      Icon={FaTruck}
      label="Mark as Staged (Delivered)"
      reduxAction={{
        action: socketStatusChange,
        payload: {
          type: "META",
          action: "STATUS",
          payload: {
            currentStagingStatus: status,
            stagingBuilderID,
            status: StagingBuilderStatuses.STAGED,
          },
        } as SocketStatusChange,
      }}
      confirmationText={
        "Are you sure you want to mark this StagingBuilder as delivered?\n" +
        "ONLY DO THIS IF IT IS ALREADY AT THE LOCATION."
      }
    />
  );

  const pickupDateInput = (
    <NavLi
      key={12}
      Icon={FaCalendarCheck}
      label={pickupActual ? "Reschedule Pickup" : "Schedule Pickup"}
      reduxAction={{
        action: push as any,
        payload: "/stage?open-modal=schedule-pickup",
      }}
    />
  );
  const markLimboHold = (isLimbo = false) =>
    businessID === 1 ? (
      isLimbo ? (
        <NavLi
          key={13}
          Icon={BsStopwatch}
          label="Limbo Hold - (Employee Hold)"
          reduxAction={{
            action: socketStatusChange,
            payload: {
              type: "META",
              action: "STATUS",
              payload: {
                currentStagingStatus: status,
                stagingBuilderID,
                status: StagingBuilderStatuses.LIMBO_HOLD,
              },
            } as SocketStatusChange,
          }}
          confirmationText="Are you sure you want to place this StagingBuilder into LIMBO HOLD (items will be on HOLD.)"
        />
      ) : (
        <NavLi
          key={13}
          Icon={FiFolderPlus}
          label="Limbo Hold"
          reduxAction={{
            action: socketStatusChange,
            payload: {
              type: "META",
              action: "STATUS",
              payload: {
                currentStagingStatus: status,
                stagingBuilderID,
                status: StagingBuilderStatuses.LIMBO_HOLD,
              },
            } as SocketStatusChange,
          }}
          confirmationText="Are you sure you want to place this StagingBuilder into LIMBO HOLD (items will be on HOLD.)"
        />
      )
    ) : null;

  const markLimboRelease = (isLimbo = false) =>
    isLimbo ? (
      <NavLi
        key={14}
        Icon={GiStopSign}
        label="Cancel Hold On Items"
        reduxAction={{
          action: socketStatusChange,
          payload: {
            type: "META",
            action: "STATUS",
            payload: {
              currentStagingStatus: status,
              stagingBuilderID,
              status: StagingBuilderStatuses.LIMBO_RELEASE,
            },
          } as SocketStatusChange,
        }}
        confirmationText="Are you sure you want to place this StagingBuilder into LIMBO RELEASE (items will NOT be on hold.)"
      />
    ) : (
      <NavLi
        key={14}
        Icon={FiFolderMinus}
        label="Limbo Release"
        reduxAction={{
          action: socketStatusChange,
          payload: {
            type: "META",
            action: "STATUS",
            payload: {
              currentStagingStatus: status,
              stagingBuilderID,
              status: StagingBuilderStatuses.LIMBO_RELEASE,
            },
          } as SocketStatusChange,
        }}
        confirmationText="Are you sure you want to place this StagingBuilder into LIMBO RELEASE (items will NOT be on hold.)"
      />
    );
  const pushInventoryCheckIn =
    businessID !== 1 ? (
      <></>
    ) : (
      <NavLi
        key={15}
        Icon={BsListCheck}
        label="Check Items In"
        reduxAction={{
          action: push as any,
          payload: "/stage?left-side=check-in",
        }}
      />
    );
  switch (status) {
    case StagingBuilderStatuses.PROPOSAL_RELEASE: {
      // (1) from here the options are:
      //  1: request that the items in this proposal are put on hold (-> PROPOSAL_HOLD)
      //  2: (E) Place the items in this proposal on hold (-> PROPOSAL_HOLD_EMPLOYEE)
      //  3: request that an employee confirms this proposal
      //  4: cancel this proposal (-> PROPOSAL_CANCELED)
      return [
        businessID === 1 ? null : requestHold,
        employeeHold,
        requestConfirmation,
        cancelProposal,
        postponeStagingRequest,
      ];
    }
    case StagingBuilderStatuses.NEW_CUSTOMER: {
      // (2) from here the options are:
      //  1: request that an employee confirms this proposal (->)
      //  2: cancel this proposal (-> PROPOSAL_CANCELED)
      return [
        businessID === 1 ? null : requestHold,
        employeeHold,
        requestConfirmation,
        cancelProposal,
        postponeStagingRequest,
      ];
    }
    case StagingBuilderStatuses.PROPOSAL_HOLD_REQUEST: {
      // (3) from here the options are:
      //  1: (E) Place the items in the StagingBuilder on hold (-> PROPOSAL_HOLD_EMPLOYEE)
      //  2: (E) Do not place the items in the StagingBuilder on hold (-> )
      //  3: request that an employee confirms this proposal (->)
      //  4: Cancel the request for an employee to place the items on hold (-> PROPOSAL_RELEASE)
      //  5: cancel this proposal (-> PROPOSAL_FELL_THROUGH | DEAL_FELL_THROUGH)
      return [
        confirmRequest,
        denyRequest,
        cancelRequest,
        requestConfirmation,
        cancelProposal,
        postponeStagingRequest,
      ];
    }
    case StagingBuilderStatuses.PROPOSAL_HOLD_CUSTOMER: {
      // (4) from here the options are:
      //  1: request that an employee confirms this proposal
      //  2: cancel this proposal (-> PROPOSAL_CANCELED)
      return [
        requestConfirmation,
        businessID === 1 ? null : stopHold,
        cancelProposal,
        postponeStagingRequest,
      ];
    }
    case StagingBuilderStatuses.PROPOSAL_HOLD_EMPLOYEE: {
      // (5) from here the options are:
      //  1: Request Confirmation of StagingBuilder (-> PROPOSAL_AWAIT_APPROVAL)
      //  2: Stop Hold (-> PROPOSAL_RELEASE)
      //  3: cancel this proposal (-> PROPOSAL_CANCELED)
      return [
        requestConfirmation,
        stopHold,
        cancelProposal,
        postponeStagingRequest,
      ];
    }
    case StagingBuilderStatuses.PROPOSAL_FELL_THROUGH: {
      // (6) from here the options are:
      //  1: Recover the canceled proposal and make it a normal proposal again (-> PROPOSAL_RELEASE)
      return [recoverProposal];
    }
    case StagingBuilderStatuses.PROPOSAL_APPROVAL_REQUEST: {
      // (7) from here the options are:
      //  1: Hold Items (Employee Hold)
      //  2: Cancel Proposal / Deal Fell Through
      //  3: Postpone indefinitely
      //  4: Confirm Request
      //  5: Deny Request
      //  6: Cancel Request
      return [
        employeeHold,
        confirmRequest,
        denyRequest,
        cancelRequest,
        postponeStagingRequest,
        cancelProposal,
      ];
    }
    case StagingBuilderStatuses.PROPOSAL_APPROVAL_REQUEST_HOLD: {
      // (8) from here the options are:
      //  1: Cancel Proposal / Deal Fell Through
      //  2: Postpone indefinitely
      //  3: Confirm Request (-> APPROVAL_PROCESS_STARTED)
      //  4: Deny Request
      //  5: Cancel Request
      return [
        confirmRequest,
        denyRequest,
        cancelRequest,
        postponeStagingRequest,
        cancelProposal,
      ];
    }
    case StagingBuilderStatuses.APPROVAL_PROCESS_STARTED: {
      // (9) from here the options are:
      //  1: Cancel Proposal / Deal Fell Through
      //  2: Postpone indefinitely
      //  3: Confirm Request (-> APPROVAL_PROCESS_STARTED)
      //  4: Deny Request
      //  5: Cancel Request
      return [
        cancelProposal,
        postponeStagingRequest,
        openConfirmStagingModal,
        denyRequest,
        cancelRequest,
      ];
    }
    case StagingBuilderStatuses.SCHEDULED_AND_TAGGED: {
      return [
        cancelProposal,
        openConfirmStagingModal,
        postponeStagingRequest,
        stagingDelivered,
      ];
    }
    case StagingBuilderStatuses.STAGED: {
      return [pickupDateInput];
    }
    case StagingBuilderStatuses.LIMBO_REQUEST: {
      return [
        cancelProposal,
        cancelRequest,
        markLimboHold(),
        markLimboRelease(),
      ];
    }
    case StagingBuilderStatuses.LIMBO_HOLD: {
      return [cancelProposal, markLimboRelease(true), requestConfirmation];
    }
    case StagingBuilderStatuses.LIMBO_RELEASE: {
      return [cancelProposal, markLimboHold(true), requestConfirmation];
    }
    case StagingBuilderStatuses.DEAL_FELL_THROUGH: {
      // this is a special case that needs businessID because I am reusing this component
      return [businessID === 1 ? recoverProposal : null];
    }
    case StagingBuilderStatuses.PICKUP_REQUEST: {
      return [pickupDateInput];
    }
    case StagingBuilderStatuses.PICKUP_SCHEDULED: {
      return [pickupDateInput, pushInventoryCheckIn];
    }
    case StagingBuilderStatuses.CLOSED: {
      return [pickupDateInput, pushInventoryCheckIn];
    }
    case StagingBuilderStatuses.LIMBO_REQUEST_HOLD: {
      return [
        cancelProposal,
        cancelRequest,
        stopHold,
        markLimboHold(),
        markLimboRelease(),
      ];
    }
    default:
      return [];
  }
};
