import React from "react";
import { hrefPrefix } from "../../constants";
import { CustomButton } from "../../HighlyReusableComponents/CustomButton";
import { ScannerForm } from "./ScannerForm";

interface Props {
  barcodeRef: React.RefObject<HTMLInputElement>;
}

export const Scanner = ({ barcodeRef }: Props) => {
  const printQueuePath1 = `${hrefPrefix}/api/print/print-queue/stickers`;
  const printQueuePath2 = `${hrefPrefix}/api/print/print-queue/standard/current?tagStyleID=[4]`;
  return (
    <div>
      <div style={{ top: "1em", right: "1em" }} className="absolute grid">
        <CustomButton
          linkProps={{
            to: { pathname: printQueuePath1 },
            target: "_blank",
            rel: "noopener noreferrer",
          }}
          appendClassName="px-2"
          type="button"
        >
          Open Sticker Queue in New Tab
        </CustomButton>
        <CustomButton
          linkProps={{
            to: { pathname: printQueuePath2 },
            target: "_blank",
            rel: "noopener noreferrer",
          }}
          appendClassName="px-2"
          type="button"
        >
          Open Standard Queue in New Tab
        </CustomButton>
        <CustomButton
          linkProps={{
            to: {
              pathname:
                "https://docs.google.com/spreadsheets/d/11cXOKt0bDF6w33FO5KaxBs2K5EqwAtwQ8Hx9ureK2yU/edit#gid=0",
            },
            target: "_blank",
            rel: "noopener noreferrer",
          }}
          appendClassName="px-2"
          type="button"
        >
          Open ZEBRA Queue in New Tab
        </CustomButton>
      </div>
      <ScannerForm barcodeRef={barcodeRef}></ScannerForm>
    </div>
  );
};
