import axios from "axios";
import { push } from "connected-react-router";
import { FormikHelpers } from "formik";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ToastyError, ToastySuccess } from "../../components/constants";
import { io, Socket } from "socket.io-client";
import { Istate } from "..";
export const fetchingData = (): UsersReducer => {
  return {
    type: "FETCHING_DATA",
  };
};
export const toggleBusiness = (): UsersReducer => {
  return {
    type: "TOGGLE_BUSINESS_VIEW",
  };
};
export const fetchingDataSuccess = (
  status?: number,
  message?: string,
  success?: boolean
): UsersReducer => {
  status && message && ToastySuccess(status, message, success);
  return {
    type: "FETCHING_DATA_SUCCESS",
  };
};
export const fetchingDataFailure = (status?: number): UsersReducer => {
  status && ToastyError(status);
  return {
    type: "FETCHING_DATA_FAILURE",
    payload: status,
  };
};
export const redirectSuccess = (status?: number): UsersReducer => {
  return {
    type: "REDIRECT_SUCCESS",
    payload: status,
  };
};

export const logInAttempt = (): UsersReducer => {
  return {
    type: "LOG_IN_ATTEMPT",
  };
};
export const createAccountAttempt = (): UsersReducer => {
  return {
    type: "CREATE_ACCOUNT_ATTEMPT",
  };
};
export const logInSuccess = (payload: User): UsersReducer => {
  return {
    type: "LOG_IN_SUCCESS",
    payload,
  };
};

export const logInFailed = (status: number): UsersReducer => {
  status && ToastyError(status);
  return {
    type: "LOG_IN_FAIL",
    payload: status,
  };
};

export const logInAxios = (
  userInfo: {
    email: string;
    password: string;
  },
  path = "/"
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: Function
  ): Promise<void> => {
    dispatch(logInAttempt());
    const {
      data: { version },
    } = getState() as Istate;
    axios({
      method: "post",
      url: "/api/form/users/login",
      data: {
        usern: userInfo.email,
        passw: userInfo.password,
      },
    })
      .then((response) => {
        let data = JSON.parse(response.data);
        data.User.loggedInAt = Date.now();
        if (data.logUserIn) dispatch(logInSuccess(data));
        return [data.token, { ...data.User, loggedIn: data.logUserIn }];
      })
      .then(([token, user]) => {
        if (user.loggedIn === true) {
          const socket = io("", {
            query: { user: JSON.stringify(user), version },
          });
          dispatch(fillSocket(socket));
        }
      })
      .then(() => dispatch(push(path)))
      .catch((error) => {
        if (error.response) dispatch(logInFailed(error.response.status));
        else dispatch(logInFailed(500));
      });
  };
};
export const fillSocket = (payload: Socket | undefined): UsersReducer => {
  return {
    type: "FILL_SOCKET",
    payload,
  };
};
export const loggedInCheck = (): ThunkAction<
  Promise<void>,
  {},
  {},
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState
  ): Promise<void> => {
    const {
      data: { user, version },
    } = getState() as Istate;

    if (user.loggedIn === undefined)
      axios({
        method: "get",
        url: "/api/user/check-logged-in",
      })
        .then((response) => {
          let data = response.data;
          if (data.success === true) {
            data.User.loggedInAt = Date.now();
            dispatch(logInSuccess(data));
            const socket = io("", {
              query: { user: JSON.stringify(data.User), version },
            });
            dispatch(fillSocket(socket));
          } else {
            dispatch(push("/user/login"));
          }
        })
        .catch((error) => {
          if (error.response.status)
            if (error.response.status === 401) {
              // dispatch(logInFailed(error.response.status));
              dispatch(logOutUser());
            } else if (error.response) {
              dispatch(logInFailed(error.response.status));
            }
        });
    else dispatch(logOutUser());
  };
};
export const createAccount = (
  userInfo: {
    first: string;
    last: string;
    profession: number;
    phone: string;
    phoneAgreement1: boolean;
    phoneAgreement2: boolean;
    email: string;
    emailAgreement1: boolean;
    emailAgreement2: boolean;
    password: string;
    confirmPassword: string;
  },
  formikHelpers: FormikHelpers<typeof userInfo>
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(createAccountAttempt());
    axios({
      method: "post",
      url: "/api/form/users/create-account",
      data: userInfo,
    })
      .then((response) => {
        console.log(response.data);
        if (response.status === 201 && response.data.success === true) {
          dispatch(
            logInAxios({
              email: userInfo.email,
              password: userInfo.password,
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          const errors = error.response.data;
          if (errors.field !== "multiple-errors")
            formikHelpers.setFieldError(errors.field, errors.message);
          else if (errors.field === "multiple-errors")
            errors.message.forEach((e: { field: string; message: string }) =>
              formikHelpers.setFieldError(e.field, e.message)
            );
        }
        if (error.response) dispatch(logInFailed(error.response.status));
        else dispatch(logInFailed(500));
      });
  };
};

export const updateAccountInfo = (userInfo: {
  first: string;
  last: string;
  email: string;
  phone: string;
}): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(fetchingData());
    axios({
      method: "put",
      url: "/api/form/users/update-account",
      data: { ...userInfo },
    })
      .then((response) => {
        console.log(response);
        let data = JSON.parse(response.data);
        dispatch(logInSuccess(data));
      })
      .catch((error) => {
        if (error.response)
          dispatch(fetchingDataFailure(error.response.status));
        else dispatch(fetchingDataFailure(500));
      })
      .then(() => dispatch(push("/accounts/my-account")));
  };
};

export const logOutUser = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState
  ): Promise<void> => {
    const {
      data: { socket },
    } = getState() as Istate;
    socket?.disconnect();
    dispatch(fetchingData());
    dispatch(resetState());
    axios({
      method: "get",
      url: "/api/user/log-out",
    })
      // .then(() => {
      //   dispatch(resetState());
      // })
      .catch((error) => {
        if (error.response)
          dispatch(fetchingDataFailure(error.response.status));
        else dispatch(fetchingDataFailure(500));
      });
  };
};

export const resetState = (): UsersReducer => {
  return {
    type: "RESET_STATE",
    payload: {},
  };
};
