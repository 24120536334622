import { ErrorMessage, FormikProps } from "formik";
import { useEffect } from "react";
import { dateOptionsShortVerbose, GetDate } from "../../../../../constants";
import FormikControl from "../../../../../FormikComponents/FormikControl";
import TextError from "../../../../../FormikComponents/TextError";
import { CustomButton } from "../../../../../HighlyReusableComponents/CustomButton";
import { InitialValues } from "./initialValues";
// need Form control

interface Props {
  initialValues: InitialValues;
  FormikProps: FormikProps<InitialValues>;
  stagingBuilder: StagingBuilder;
}

export const FormFields = ({
  stagingBuilder: { deliveryDesired },
  initialValues,
  FormikProps,
}: Props) => {
  useEffect(() => {
    setTimeout(() => {
      FormikProps.setFieldTouched("deliveryActual", true, true);
      FormikProps.setFieldTouched("stagingTagged", true, true);
      FormikProps.setFieldTouched("address", true, true);
      FormikProps.setFieldTouched("clientName", true, true);
      FormikProps.setFieldTouched("clientPhone", true, true);
      FormikProps.setFieldTouched("clientEmail", true, true);
    }, 0);
  }, []);
  return (
    <>
      <h1 className="text-4xl">
        Confirm StagingBuilder (Mark as Scheduled & Tagged)
      </h1>
      <div className="grid auto-1fr gap-2">
        <div className="max-w-prose">
          <h2 className="text-2xl">File Number - It's In The SpreadSheet</h2>
          <p>
            In order to confirm the StagingBuilder their must be a file number.
            (The file number can also be entered by clicking on the File #
            button in the top right of the StagingBuilder.)
          </p>
          <FormikControl
            control="input"
            label={
              <span className="text-bold">Enter or Change the File Number</span>
            }
            name="fileNumber"
            fieldWrapperClass="w-48"
          />
          <h2 className="text-2xl">Hold Tags - Is Everything Tagged?</h2>
          <p>
            Before confirming the StagingBuilder, please make sure that all the
            items in the StagingBuilder have hold tags on them. Hold Tags can be
            printed via the "Print" Menu drop-down inside the StagingBuilder.
          </p>
          <FormikControl
            control="left-checkbox"
            label={
              <span className="text-bold">
                Have the items in this StagingBuilder been tagged?
              </span>
            }
            name="stagingTagged"
            controlContainerclass="flex space-between mr-16"
          />
          <h2 className="text-2xl">
            Delivery Date - Is It In "Current Schedule"
          </h2>
          <p>
            Before confirming the StagingBuilder, please make sure that a
            delivery date has been set. Below you can see what the customers
            desired delivery date is. Please make sure that the actual delivery
            date matches that which is in the "Current Schedule" Google
            Spreadsheet.
          </p>
          <div>
            <div className="mv-5 flex space-between">
              <span className="text-bold">Customer Desired Delivery: </span>
              {GetDate(deliveryDesired, undefined, dateOptionsShortVerbose)}
            </div>
            <div className="pb-5">
              <FormikControl
                inLineLabelError
                control="date-picker"
                controlContainerclass="basic-input flex justify-between leading"
                label={
                  <span className="text-bold leading-10">
                    Scheduled Delivery Date:
                  </span>
                }
                name="deliveryActual"
              />
            </div>
          </div>
          <CustomButton
            // className="btn btn-staging-builder"
            disabled={FormikProps.isSubmitting || !FormikProps.isValid}
          >
            Mark as Scheduled & Tagged
          </CustomButton>
        </div>
        <div>
          {FormikProps.isValid ? null : (
            <h1 className="text-red-900 mb-4">External Errors:</h1>
          )}
          <ErrorMessage name="deliveryActual" component={TextError} />
          <ErrorMessage name="stagingTagged" component={TextError} />
          <ErrorMessage name="address" component={TextError} />
          <ErrorMessage name="clientName" component={TextError} />
          <ErrorMessage name="clientPhone" component={TextError} />
          <ErrorMessage name="clientEmail" component={TextError} />
        </div>
      </div>
    </>
  );
};
