import { useState } from "react";
import styled from "styled-components";
import { useSelector, shallowEqual } from "react-redux";
import { Istate } from "../../../redux";
import StagingBuilderHeader from "../../Stage/StagingBuilder/StagingBuilderHeader";
// import { StagingBuilderHeader_FC as StagingBuilderHeader } from "../../Stage/StagingBuilder/Header";
import { StagingBuilderItemContainerMap } from "./StagingBuilderItemContainerMap";
import { MobileNav } from "../../mobile-nav";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigation } from "../../mobile-nav/context/NavigationContext";
const StagingBuilderHolder = styled.div.attrs((props) => ({
  ...props,
  className: "max-w-inherit",
}))`
  position: relative;
  border: 1px solid lightgrey;
  border-radius: 2px;
  min-height: 100px;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
`;
export interface IheaderData {
  stagingBuilderID: number;
  address: string;
  fileNumber: number;
  createdOn: number;
  editedOn: number;
  stagingStatus: number;
}
export const StagingBuilderView = () => {
  const { updateMobileViewSide } = useNavigation();
  const stagingBuilder = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex],
    shallowEqual
  );
  const businessID = useSelector(
    (state: Istate) => state.data.user.businessID,
    shallowEqual
  );
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  // toast(headerHeight);

  return (
    <StagingBuilderHolder>
      {stagingBuilder ? (
        <div
          id="item-scroll-target"
          className="simple-border draggable-inventory max-w-inherit h-full max-h-[100vh] w-full overflow-y-scroll"
          style={{
            WebkitOverflowScrolling: "touch",
          }}
        >
          <MobileNav>
            <p>
              <FiArrowLeft
                cursor="pointer"
                style={{ height: "46px", width: "46px", display: "inline" }}
                onClick={() => updateMobileViewSide(0)}
              />{" "}
              Back To Archive
            </p>
          </MobileNav>
          <StagingBuilderHeader
            headerHeight={headerHeight}
            setHeaderHeight={setHeaderHeight}
            edit={false}
          />
          <StagingBuilderItemContainerMap headerHeight={headerHeight} />
        </div>
      ) : businessID === 1 && process.env.NODE_ENV === "production" ? (
        <iframe
          title="Employee portal"
          src="/portal/portal/key-login?key=eBcJcBqpDOXSvw7pHnIvwGlyOBlAyX8bvVxmxoC9eZHQ0CIdaeaPvikESNObAzQ5jhsxEe3SZamO1MpaARUp7HLbzfnhRgBa7OniLsNVXdTrFKDWccZLfLaX6GIZF5kk8AwmC0BOZM09jtqn4LFZsQiP7EQrTRccY9hodp3B4NLJw5J6sKuots4ILDI99X8x5meCMx2ESsWhnnAFdwq4RV4SZXDXkvyisSYmXCC4YPAnvUZ3QBWZ3KBSK6klCsLu"
          className="absolute top-0 left-0 right-0 bottom-0 h-full w-full"
        />
      ) : (
        <div className="relative">
          <img
            className="absolute"
            style={{ maxWidth: "inherit", width: "151%", left: "-51%" }}
            src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fnorthshorelongislandhomes.files.wordpress.com%2F2018%2F08%2F10-madison-square-west.jpg%3Fw%3D730&f=1&nofb=1"
            alt=""
          />
        </div>
      )}
    </StagingBuilderHolder>
  );
};

// export const StagingBuilderView = React.memo(StagingBuilderViewFC);
