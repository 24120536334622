import { useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import StagingBuilder from "./StagingBuilder/StagingBuilder";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  Istate,
  AppThunk,
  updateBuilderInventoryOrder,
  setRoutePayload,
} from "../../redux";
import "./StagingBuilder.scss";
import { LeftSideLogic } from "./LeftSide/LeftSideLogic";
import { newContainerItem } from "../../redux/stagingBuilder/actions/containerItems/NEW";
import { MobileOneSideViewContainer } from "../Home";
import { useNavigation } from "../mobile-nav/context/NavigationContext";
import { MobileNav } from "../mobile-nav";
import { FiArrowLeft } from "react-icons/fi";
import { BinningContext } from "../Scanner/BinControl/useBinNumber";

const Stage = () => {
  const { leftRight, updateMobileViewSide } = useNavigation();
  const dispatch: AppThunk = useDispatch();
  /* redux selectors */
  const stagingBuilder = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders.find(
        (stagingBuilder) =>
          stagingBuilder.stagingBuilderID ===
          state.data.user.activeStagingBuilder
      ),
    shallowEqual
  );
  const orderIdArray = useSelector(
    (state: Istate) => state.data.builderInventoryOrder,
    shallowEqual
  );

  /* local state */
  // declare a local state to handle drag and drop
  const [state]: [
    {
      columns: {
        [index: string]: { id: string; title: string };
      };
    },
    any
  ] = useState({
    columns: {
      items: {
        id: "items",
        title: "All Items",
      },
    },
  });

  const onDragEnd = (result: DropResult) => {
    // destination => the container ("items" || itemContainer) that it was dropped into
    // source => The container ("items") and index it came from
    // draggableId => the itemID
    const { destination, source, draggableId } = result;
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return; // they started dragging but didn't actuallt do anything
    }
    // need to look into if destination name changes if room name is updated
    const start = state.columns[source.droppableId];
    const finish = state.columns[destination.droppableId];
    console.log("Stage/", start, finish);
    // const droppableID: string = destination.droppableId.toString();
    // const column: any = state.columns[droppableID];

    let newOrderIdArray = orderIdArray;
    // add it back in its new position to the same array
    if (start === finish) {
      // they just moved it around on the left side
      let rowToMove = newOrderIdArray.splice(source.index, 1);
      newOrderIdArray.splice(destination.index, 0, rowToMove[0]);
      console.warn("Need to make it so sorting chevrons dissapear here");
      dispatch(
        setRoutePayload({ key: "Stage", payload: "INVENTORY_ORDER_UPDATE" })
      );
    } else {
      // they put it into a room on the right side; get the information of that item
      let rowToMove = newOrderIdArray.splice(source.index, 1);
      // using the id, set its original array object as active
      // itemsAsObject[rowToMove[0].itemID].itemStatus = 1;
      newOrderIdArray.splice(source.index, 0, rowToMove[0]);
      stagingBuilder &&
        dispatch(
          newContainerItem({
            itemID: parseInt(draggableId),
            itemContainerID: parseInt(destination.droppableId),
            stagingBuilderID: stagingBuilder.stagingBuilderID,
          })
        );
    }
    // setstate({ ...state, newOrderIdArray });
    dispatch(updateBuilderInventoryOrder(newOrderIdArray));
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <MobileOneSideViewContainer
        side={leftRight}
        className="max-w-inherit grid h-full min-h-[100vh] md:max-h-screen w-full overflow-hidden sm:grid-cols-2"
      >
        <BinningContext>
          <div
            id="draggable-inventory-hover-target"
            className="left-side-wrapper relative hidden h-full max-h-full min-h-full sm:block"
            // style={{ maxHeight: "var(--body-container-height)" }}
          >
            <div
              id="item-scroll-target"
              className="simple-border draggable-inventory overflowY-scroll h-full max-h-[100vh] w-full"
            >
              <LeftSideLogic />
            </div>
          </div>
          <div
            className="max-w-inherit relative hidden h-full w-full overflow-y-auto sm:block"
            id="staging-builder-hover-target"
          >
            <div
              id="staging-builder-scroller"
              className="simple-border overflowY-scroll h-full w-full"
            >
              {stagingBuilder ? (
                <>
                  <MobileNav>
                    <p>
                      <FiArrowLeft
                        cursor="pointer"
                        style={{
                          height: "46px",
                          width: "46px",
                          display: "inline",
                        }}
                        onClick={() => updateMobileViewSide(0)}
                      />{" "}
                      See Inventory
                    </p>
                  </MobileNav>
                  <StagingBuilder edit={true} />
                </>
              ) : null}
            </div>
          </div>
        </BinningContext>
      </MobileOneSideViewContainer>
    </DragDropContext>
  );
};

export default Stage;
