import { Fragment, useMemo, useState } from "react";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import useSWR from "swr";
import { fetcher } from "../../../../../fetcher";
import { useBooleanState } from "../../../../HighlyReusableComponents/hooks/useBooleanState";
import { CheckBoxes } from "./CheckBoxes";

interface Props {}

export const Report = (props: Props) => {
  const { data, error, mutate } = useSWR<
    { success: true; items: itemVerbose[] } | { success: false }
  >("/api/effects/get/delta-report-info", fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
  });
  const initialValues = {
    previouslyMore: true,
    previouslyLess: true,
    previouslyEqual: true,
  };
  const checkState = useBooleanState(initialValues);

  const [thumbnailFailed, setThumbnailFailed] = useState(true);
  const { values } = checkState;
  const filteredItems = useMemo(() => {
    if (data?.success) {
      return data.items
        .filter(({ quanLoft, quanDelta }) =>
          values.previouslyMore ? true : quanLoft <= quanDelta
        )
        .filter(({ quanLoft, quanDelta }) =>
          values.previouslyLess ? true : quanLoft >= quanDelta
        )
        .filter(({ quanLoft, quanDelta }) =>
          values.previouslyEqual ? true : quanLoft !== quanDelta
        );
    }
    return [];
  }, [data, values]);

  if (error) return null;
  return (
    <div className="m-3 h-full overflow-y-auto relative">
      <div className="grid grid-cols-3 sticky top-0 bg-white z-20">
        <CheckBoxes checkState={checkState} />
        <div className="col-span-full grid grid-cols-8 place-items-center">
          <span>Picture</span>
          <span>Item #</span>
          <span>Name</span>
          <span>Database Quantity</span>
          <span>New Quantity</span>
          <span>Delta Quantity</span>
          <span>Change</span>
          <span>Print</span>
        </div>
      </div>
      <ul className="">
        {filteredItems.map(
          (
            { itemID, itemName, itemNo, pictureA, quanLoft, quanDelta },
            index,
            { length }
          ) => (
            <Fragment key={itemID}>
              <li
                className={`px-4 py-2 grid grid-cols-8 place-items-center ${
                  quanLoft > quanDelta
                    ? "bg-red-400"
                    : quanLoft < quanDelta
                    ? "bg-green-400"
                    : ""
                }`}
              >
                <picture style={{ width: 80, height: 80 }}>
                  {thumbnailFailed && (
                    <source
                      srcSet={`https://storage.googleapis.com/modelhomefurnitureoutlet.com/thumbnails/item-${itemNo}.jpg`}
                    />
                  )}
                  <img
                    onError={() => setThumbnailFailed(false)}
                    loading="lazy"
                    className="inventory-image"
                    src={pictureA}
                    alt={"item-" + itemNo}
                  />
                </picture>
                <Link to={`/inventory/edit/${itemNo}`}>
                  <div>{itemNo}</div>
                </Link>
                <div>{itemName}</div>
                <div className="">{quanLoft}</div>
                <div className="">{quanDelta}</div>
                <div className="">{quanDelta - quanLoft}</div>
                <div className="grid grid-cols-2 gap-x-3 text-3xl text-center">
                  <AiOutlinePlusCircle
                    className="cursor-pointer"
                    onClick={async () => {
                      await fetch("/api/scan/inventory/delta-inventory", {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ barcode: itemNo }),
                      })
                        .then(async (res) => await res.json())
                        .then(({ success, message }) => {
                          if (!success)
                            toast(message, {
                              toastId: itemNo,
                              autoClose: false,
                              type: "error",
                            });
                        })
                        .then(() => mutate());
                    }}
                  />
                  <AiOutlineMinusCircle
                    className={`${
                      quanDelta > 0 ? "cursor-pointer" : "cursor-not-allowed"
                    }`}
                    onClick={async () => {
                      if (quanDelta > 0)
                        await fetch("/api/scan/inventory/delta-inventory", {
                          method: "POST",
                          headers: { "Content-Type": "application/json" },
                          body: JSON.stringify({
                            barcode: itemNo,
                            minus: true,
                          }),
                        })
                          .then(async (res) => await res.json())
                          .then(({ success, message }) => {
                            if (!success)
                              toast(message, {
                                toastId: itemNo,
                                autoClose: false,
                                type: "error",
                              });
                          })
                          .then(() => mutate());
                    }}
                  />
                </div>
                <a
                  target="_blank"
                  href={`https://api.stagingrents.com/api/print/single-tag?itemNo=${itemNo}&printQuantity=4&tagStyle=4&tagSize=1`}
                  className="flex w-full"
                >
                  <button className="btn-pulse bg-logo-blue w-full py-1.5 my-2 mb-0 rounded-lg border border-gray-400 focus:outline-none focus:ring disabled:opacity-50 ">
                    Print
                  </button>
                </a>
              </li>

              {index !== length - 1 ? <hr className="my-2" /> : null}
            </Fragment>
          )
        )}
      </ul>
    </div>
  );
};
