import styled from "styled-components";
import { useSelector, shallowEqual } from "react-redux";
import { Link, Route } from "react-router-dom";
import { Istate } from "../../redux";
import { MyAccount } from "./MyAccount";
import { Business } from "./MyBusiness";
import { Permissions } from "./Permissions";
import { MobileNav } from "../mobile-nav";
import { useNavigation } from "../mobile-nav/context/NavigationContext";
import { ImHome3 } from "react-icons/im";

const AccountContainer = styled.div`
  width: 95vw;
  max-width: min(calc(100vw - 2em - 10px), 425px);
  min-height: 300px;
  padding: 10px;
`;

export const AccountsRouter = () => {
  const businessID = useSelector(
    (state: Istate) => state.data.user.businessID,
    shallowEqual
  );
  const { pathname } = useSelector(
    (state: Istate) => state.router.location,
    shallowEqual
  );
  let title = getHeading(pathname);
  const { updateMobileViewSide } = useNavigation();
  return (
    <>
      <MobileNav>
        <Link to="/">
          <ImHome3
            cursor="pointer"
            style={{ height: "46px", width: "46px", display: "inline" }}
            onClick={() => updateMobileViewSide(0)}
          />{" "}
          Back To Archive
        </Link>
      </MobileNav>
      <div className="grid place-items-center relative overflow-y-scroll overflow-x-hidden permissions w-full h-full">
        <div className="simple-border simple-border__mobile-hide p-5">
          <h1 className="text-5xl text-center">{title}</h1>
          <Route exact path="/accounts/my-account">
            <AccountContainer>
              <MyAccount />
            </AccountContainer>
          </Route>
          {businessID !== 1 ? null : (
            <Route exact path="/accounts/permissions">
              <AccountContainer>
                <Permissions />
              </AccountContainer>
            </Route>
          )}
          <Route exact path="/accounts/my-business">
            <Business />
          </Route>
        </div>
      </div>
    </>
  );
};

const getHeading = (pathname: string) => {
  switch (pathname.split("/")[2]) {
    case "my-account": {
      return "My Account";
    }
    case "permissions": {
      return "Permissions";
    }
    case "my-business": {
      return "My Business";
    }
    default: {
      return "Loading Failure";
    }
  }
};
