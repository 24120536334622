import React, { useState } from "react";
import { useEffect } from "react";
import { BsPlusCircleFill } from "react-icons/bs";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { newContainerItem } from "../../../../redux/stagingBuilder/actions/containerItems/NEW";
import { ContainerItem } from "../../../../redux/stagingBuilder/stagingBuilderTypes";

interface Props {
  containerItem: ContainerItem;
  overlap: (number | undefined)[] | undefined;
}

export const AddItemButton = ({ containerItem, overlap }: Props) => {
  const dispatch = useDispatch();
  const { stagingBuilderID, itemContainerID } = useSelector(
    (state: Istate) => ({
      stagingBuilderID: state.data.user.activeStagingBuilder,
      itemContainerID: state.data.stagingBuilders[
        state.data.user.activeStagingBuilderIndex
      ].itemContainers.find(({ containerType }) => containerType !== 0)
        ?.itemContainerID,
    }),
    shallowEqual
  );
  const [hasBeenClicked, setHasBeenClicked] = useState(
    overlap?.includes(containerItem.itemID)
  );
  useEffect(() => {
    if (hasBeenClicked !== true)
      setHasBeenClicked(overlap?.includes(containerItem.itemID));
  }, [overlap]);
  return (
    <BsPlusCircleFill
      color={hasBeenClicked ? "green" : "red"}
      onClick={() => {
        if (stagingBuilderID && itemContainerID) {
          dispatch(
            newContainerItem({
              itemID: containerItem.itemID,
              itemContainerID,
              stagingBuilderID,
            })
          );
          setHasBeenClicked(true);
        }
      }}
      className="ml-5"
      cursor="pointer"
      size="2em"
    />
  );
};
