import { InventoryListRow } from "./InventoryListRow";
interface Props {
  itemsToRender: number[];
}
function InventoryListBody({ itemsToRender }: Props) {
  return (
    <>
      {itemsToRender.map((itemID, index) => (
        <InventoryListRow key={itemID} itemID={itemID} index={index} />
      ))}
    </>
  );
}
export default InventoryListBody;
