import React from "react";
import { useSelector } from "react-redux";

interface Props {
  column: "stagerName" | "clientName";
  setFilter: (updater: any) => void;
}

export const StagerContextMenu = ({ setFilter, column }: Props) => {
  const TitleText =
    column === "stagerName" ? "Filter By Stager" : "Filter By Client";
  const stagingBuilders = useSelector(
    (state: Istate) => state.data.stagingBuilders
  );
  const names = Array.from(
    new Set(stagingBuilders.map((props) => props[column])) as Set<string>
  )
    .filter(
      (name) =>
        !(
          name in
          {
            "Brad Bailey": null,
            "BradCustLogin Bailey": null,
            "Denise Bailey": null,
            "Daniel Bailey": null,
          }
        )
    )
    .sort();
  return (
    <ul>
      <li className="pl-5 pointer-events-none">{TitleText}</li>
      <li>
        <ul className="overflow-y-scroll" style={{ maxHeight: "300px" }}>
          {names.map((stagerName, index) => (
            <li key={index}>
              <button
                onClick={() => {
                  setFilter(stagerName);
                }}
              >
                {stagerName}
              </button>
            </li>
          ))}
        </ul>
      </li>
      <li>
        <ul>
          <li>
            <button
              onClick={() => {
                setFilter(undefined);
              }}
            >
              Clear This Filter
            </button>
          </li>
        </ul>
      </li>
    </ul>
  );
};
