/**
 * Update the meta information for a StagingBuilder with the current information
 *
 * This reducer does NOT change the active stagingBuilder
 * @param stagingBuilder
 * @returns void
 */
export const updateStagingBuilderMeta = (
  stagingBuilder: StagingBuilder
): StagingBuilderReducer => {
  return {
    type: "UPDATE_STAGINGBUILDER_META",
    payload: stagingBuilder,
  };
};
