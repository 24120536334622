import { push } from "connected-react-router";
import { FC } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Cell, Row, TableBodyPropGetter, TableBodyProps } from "react-table";
import { AppThunk, fetchFullStagingBuilder } from "../../../redux";
import { resetBuilderInventory } from "../../../redux/inventory/inventoryActions";
import { StagingBuilderStatuses, ToastyOrange } from "../../constants";
import { useNavigation } from "../../mobile-nav/context/NavigationContext";
import { StagingBuilderMemo } from "../ProposalContainerColumnContainer";
import { ProposalTableCells } from "./ProposalTableCells";
import styled from "styled-components";

const StyledProposalTableBody = styled.tbody<{ businessID?: number }>`
  @media only screen and (max-width: 640px) {
    tr {
      display: grid;
      grid-auto-flow: row dense;
      width: 100%;
      height: 180px;
      grid-template-columns: 1fr 1fr;
      // File #
      td:nth-of-type(1) {
        font-size: 1.5rem;
      }
      td:nth-of-type(1)::before {
        content: "File # ";
      }
      // Staging Status
      td:nth-of-type(2) {
        text-align: left;
        grid-column: 1 / -1;
        width: 100% !important;
        max-width: unset !important;
      }
      // Client/Stager name
      td:nth-of-type(3) {
        grid-row: 1 / 2;
        grid-column: 2 / 3;
      }
      td:nth-of-type(3)::before {
        content: "${({ businessID }) =>
          businessID === 1 ? "Stager: " : "Client: "}";
      }
      // Address
      td:nth-of-type(4) {
        grid-column: 1 / -1;
        width: 100% !important;
        max-width: unset !important;
      }
      // # of items
      td:nth-of-type(5)::before {
        content: "Items: ";
      }
      // Rental Rate
      td:nth-of-type(6) {
        display: grid;
        grid-auto-flow: row dense;
        grid-template-columns: auto auto auto 1fr;
        grid-column: 1 / 2;
      }
      td:nth-of-type(6)::after {
        content: "Rate:";
        margin-right: 4px;
        grid-column: 1 / 2;
        grid-row: 1 / 2;
      }
      // Retail Price
      td:nth-of-type(7) {
        display: grid;
        grid-auto-flow: row dense;
        grid-template-columns: auto auto auto 1fr;
        grid-column: 1 / 2;
      }
      td:nth-of-type(7)::after {
        content: "Retail:";
        margin-right: 4px;
        grid-column: 1 / 2;
        grid-row: 1 / 2;
      }
      // createdOn
      td:nth-of-type(8) {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
      td:nth-of-type(8)::before {
        content: "Created: ";
      }
      // deliveryActual/Desired
      td:nth-of-type(9) {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
      td:nth-of-type(9)::before {
        content: "Delivery: ";
      }
      // pickupActual/Desired
      td:nth-of-type(10) {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
      td:nth-of-type(10)::before {
        content: "Pickup: ";
      }
    }
    tr:last-of-type {
      margin-bottom: 80px;
    }
  }
`;
interface Props {
  rows: Row<StagingBuilderMemo>[];
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<StagingBuilderMemo> | undefined
  ) => TableBodyProps;
  prepareRow: (row: Row<StagingBuilderMemo>) => void;
  toggleAllRowsSelected: (value?: boolean | undefined) => void;
}

export const ProposalTableBody: FC<Props> = ({
  rows,
  getTableBodyProps,
  prepareRow,
  toggleAllRowsSelected,
}) => {
  const dispatch: AppThunk = useDispatch();
  const { updateMobileViewSide } = useNavigation();
  const {
    data: {
      user: { businessID, activeStagingBuilder: stagingBuilderID },
    },
    router: {
      location: {
        query: { "staging-proposal": queryStagingBuilderID },
      },
    },
  } = useSelector((state: Istate) => state, shallowEqual);

  const stagingBuilderSelected = (
    stagingBuilderID: number,
    doubleClicked: boolean = false
  ) => {
    // setSelectedIndex(index);
    dispatch(resetBuilderInventory());
    dispatch(fetchFullStagingBuilder(stagingBuilderID, false, doubleClicked));
    if (queryStagingBuilderID !== undefined) dispatch(push("/"));
  };

  return (
    <StyledProposalTableBody businessID={businessID} {...getTableBodyProps()}>
      {
        // Loop over the table rows
        rows.map((row) => {
          // Prepare the row for display
          prepareRow(row);
          return (
            // Apply the row props
            <tr
              className={rowClassName(
                row.allCells,
                stagingBuilderSelected,
                businessID,
                stagingBuilderID
              )}
              onClick={() => {
                toggleAllRowsSelected(false);
                row.toggleRowSelected(true);
                stagingBuilderSelected(row.allCells[0].value);
                updateMobileViewSide(1);
              }}
              onDoubleClick={() => {
                stagingBuilderSelected(row.allCells[0].value, true);
              }}
              {...row.getRowProps()}
            >
              <ProposalTableCells row={row} />
            </tr>
          );
        })
      }
    </StyledProposalTableBody>
  );
};

type cellProps = [number, number, number, number, number, number];
const rowClassName = (
  cells: Cell<StagingBuilderMemo, any>[],
  stagingBuilderSelected: (
    stagingBuilderID: number,
    doubleClicked?: boolean
  ) => void,
  businessID: number | undefined,
  activeStagingBuilder?: number | undefined
): string => {
  const [
    stagingBuilderID,
    fileNumber,
    stagingStatus,
    pickup,
    areItemsUntagged,
    created,
  ]: cellProps = [
    cells[0].value,
    cells[1].value,
    cells[2].value,
    cells.find((cell) => cell.column.Header === "Pickup")?.value || 0,
    cells.find((cell) => cell.column.Header === "UNTAGGED")?.value || 0,
    cells.find((cell) => cell.column.Header === "Created")?.value,
  ];

  const baseClass = "proposal-row";
  let currentClass = baseClass;
  if (stagingBuilderID === activeStagingBuilder) currentClass += " text-bold";

  let currentColor = "";
  // someone added something to a StagingBuilder that was already tagged
  if (stagingStatus === StagingBuilderStatuses.CLOSED) {
  } else if (
    stagingStatus === StagingBuilderStatuses.SCHEDULED_AND_TAGGED &&
    Number(areItemsUntagged)
  ) {
    if (businessID === 1)
      ToastyOrange(
        `Items have been added to HS-${fileNumber}. ` +
          "Please re-confirm the StagingBuilder or mark the individual items as tagged. " +
          "Click here to edit the StagingBuilder.",
        {
          autoClose: false,
          onClick: () => stagingBuilderSelected(stagingBuilderID, true),
          bodyClassName: "text-black",
          toastId: `SB_WARN_${stagingBuilderID}`,
        }
      );
    currentColor = "bg-defaults-orange";
  } else if (stagingStatus === StagingBuilderStatuses.SCHEDULED_AND_TAGGED)
    currentColor = "bg-yellow-400";
  // a customer is making a request for us to do something
  else if (stagingStatus === StagingBuilderStatuses.PROPOSAL_HOLD_REQUEST)
    currentColor = "bg-defaults-red";
  else if (stagingStatus === StagingBuilderStatuses.DEAL_FELL_THROUGH_REQUEST)
    currentColor = "bg-defaults-red";
  // the items are on hold
  else if (stagingStatus === StagingBuilderStatuses.PROPOSAL_HOLD_CUSTOMER)
    currentColor = "bg-defaults-yellow";
  else if (stagingStatus === StagingBuilderStatuses.PROPOSAL_HOLD_EMPLOYEE)
    currentColor = "bg-defaults-yellow";
  else if (
    stagingStatus === StagingBuilderStatuses.PICKUP_SCHEDULED &&
    Date.now() > pickup
  )
    currentColor = "bg-defaults-coral";
  else if (
    (pickup &&
      pickup - 604800000 < Date.now() &&
      stagingStatus > StagingBuilderStatuses.STAGED &&
      stagingStatus !== StagingBuilderStatuses.CLOSED) ||
    stagingStatus === StagingBuilderStatuses.PROPOSAL_APPROVAL_REQUEST ||
    stagingStatus === StagingBuilderStatuses.PROPOSAL_APPROVAL_REQUEST_HOLD
  )
    currentColor = "bg-logo-blue";
  else if (
    stagingStatus === StagingBuilderStatuses.PROPOSAL_FELL_THROUGH ||
    stagingStatus === StagingBuilderStatuses.DEAL_FELL_THROUGH
  )
    currentColor = "bg-gray-400";
  else if (
    Date.now() - created > 2592000000 &&
    stagingStatus < StagingBuilderStatuses.STAGED
  )
    currentColor = "bg-gray-400";

  // make sure to add the space here
  currentClass += ` ${currentColor}`;
  return currentClass;
};
