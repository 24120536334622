import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import pinIcon from "./../../../../../assets/pin.svg";

const containerStyle = {
  width: "100%",
  height: "350px",
};
interface Props {
  center: {
    lat: number | null;
    lng: number | null;
  };
}

const LocationMapfc = ({ center }: Props) => {
  const { lat, lng } = center as { lat: number; lng: number };
  const shouldRender = lat === null || lng === null;
  return shouldRender ? null : (
    <LoadScript googleMapsApiKey="AIzaSyCylYHf3eGwOREugWKQpsVqtYZdRlScWEw">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{ lat, lng }}
        zoom={17}
      >
        <Marker position={{ lat, lng }} icon={pinIcon} />
      </GoogleMap>
    </LoadScript>
  );
};

export const LocationMap = React.memo(LocationMapfc);
