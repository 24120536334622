import axios from "axios";
import { push } from "connected-react-router";
import { Formik, Form, FormikProps, FormikHelpers } from "formik";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppThunk, Istate } from "../../../../../../redux";
import { updateStagingBuilderMeta } from "../../../../../../redux/stagingBuilder/actions/updateStagingBuilderMeta";
import { PromptIfDirty } from "../../../../../FormikComponents/PromptIfDirty";

import { FormFields } from "./FormFields";
import { formInit, InitialValues } from "./initialValues";

export const FormLogic = () => {
  const dispatch: AppThunk = useDispatch();
  const stagingBuilder = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex],
    shallowEqual
  );
  const onSubmit = (
    values: InitialValues,
    actions: FormikHelpers<InitialValues>
  ) => {
    axios({
      method: "patch",
      data: { ...values, stagingBuilderID: stagingBuilder.stagingBuilderID },
      url: "/api/form/staging-builder/handle-confirmation",
    })
      .then((res) => {
        if (res.data.success === true) {
          dispatch(
            updateStagingBuilderMeta(res.data.stagingBuilder as StagingBuilder)
          );
          dispatch(push("/stage"));
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  return (
    <Formik
      initialValues={formInit(stagingBuilder).initialValues}
      validationSchema={formInit(stagingBuilder).validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validateOnMount={true}
    >
      {(FormikProps: FormikProps<InitialValues>) => (
        <Form>
          <FormFields
            initialValues={formInit(stagingBuilder).initialValues}
            stagingBuilder={stagingBuilder}
            FormikProps={FormikProps}
          />
          <PromptIfDirty />
        </Form>
      )}
    </Formik>
  );
};
