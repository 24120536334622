import { push } from "connected-react-router";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BasicModal } from "../BasicModal";

const modalStyle = {
  inset: "10vh 20vw",
  height: "",
};
export const EmployeePortalModal = () => {
  const dispatch = useDispatch();

  const {
    pathname,
    query: { "open-modal": openModal },
  } = useSelector((state: Istate) => ({
    ...state.router.location,
  }));

  const open = useMemo(() => {
    if (openModal === "employee-portal") return true;
    return false;
  }, [openModal]);

  return (
    <BasicModal
      employeeOnly
      open={open}
      setOpen={() => {
        dispatch(push(pathname));
      }}
      modalStyle={modalStyle}
    >
      {process.env.NODE_ENV === "production" ? (
        <iframe
          title="Employee Portal Modal"
          src="/portal/portal/key-login?key=eBcJcBqpDOXSvw7pHnIvwGlyOBlAyX8bvVxmxoC9eZHQ0CIdaeaPvikESNObAzQ5jhsxEe3SZamO1MpaARUp7HLbzfnhRgBa7OniLsNVXdTrFKDWccZLfLaX6GIZF5kk8AwmC0BOZM09jtqn4LFZsQiP7EQrTRccY9hodp3B4NLJw5J6sKuots4ILDI99X8x5meCMx2ESsWhnnAFdwq4RV4SZXDXkvyisSYmXCC4YPAnvUZ3QBWZ3KBSK6klCsLu"
          className="absolute top-0 left-0 right-0 bottom-0 w-full h-full"
        />
      ) : (
        "ERROR: Production only feature..."
      )}
    </BasicModal>
  );
};
