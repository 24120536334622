import { BsPencil } from "react-icons/bs";
import { FastField } from "formik";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { updateItemContainerNames } from "../../../../redux";

interface Props {
  index: number;
}

export const EditContainerName = ({ index }: Props) => {
  const { itemContainerID, stagingBuilderID } = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex]
        .itemContainers[index],
    shallowEqual
  );
  const dispatch = useDispatch();
  return (
    <>
      <FastField
        style={{
          backgroundColor: "#0000",
          color: "white",
          fontWeight: "bold",
          border: "1px solid #0000",
          boxShadow: "none",
          borderRadius: "0",
          borderBottom: "1px solid white",
        }}
        onBlurCapture={(e: any) => {
          const containerInfo = [
            {
              itemContainerID,
              containerName: e.target.value,
              stagingBuilderID,
            },
          ];
          dispatch(updateItemContainerNames(containerInfo));
        }}
        id={itemContainerID}
        name={`itemContainers.${index}.containerName`}
        type="text"
      />
      <label htmlFor={itemContainerID.toString()}>
        <BsPencil
          style={{ color: "white", height: "100%", cursor: "pointer" }}
        />
      </label>
    </>
  );
};
