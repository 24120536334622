import { push } from "connected-react-router";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

interface Props {}

export const Header = (props: Props) => {
  const fileNumber = useSelector(
    (state: Istate) => state.router.location.query["file-number"],
    shallowEqual
  );
  const dispatch = useDispatch();
  const handleFileClick = () => {
    const value = window.prompt(
      "Choose file Number to transfer items from",
      fileNumber
    );
    if (value) {
      dispatch(
        push(`/stage?left-side=transfer-in&from-file-number=${Number(value)}`)
      );
    }
  };
  return (
    <div>
      {fileNumber ? (
        <h1 className="text-2xl">
          Add items from{" "}
          <span
            className="text-logo-blue cursor-pointer"
            onClick={handleFileClick}
          >
            HS-{fileNumber}
          </span>{" "}
          to this StagingBuilder
        </h1>
      ) : (
        <h1
          className="text-logo-blue cursor-pointer text-2xl"
          onClick={handleFileClick}
        >
          Click Here to Choose File Number to Transfer Items From
        </h1>
      )}
    </div>
  );
};
