import { ContainerItem } from "../../../redux/stagingBuilder/stagingBuilderTypes";

export const containerItemsAsArray = (
  stagingBuilder: StagingBuilder,
  trash = false
): ContainerItem[] | undefined =>
  stagingBuilder?.itemContainers?.reduce(
    (accum, curr) =>
      trash
        ? [...accum, ...curr.items]
        : curr.containerType !== 0
        ? [...accum, ...curr.items]
        : [...accum],
    [] as ContainerItem[]
  );
