import {
  DetailedHTMLProps,
  Dispatch,
  FunctionComponent,
  HTMLAttributes,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Field,
  ErrorMessage,
  useFormikContext,
  FormikContextType,
} from "formik";
import TextError from "./TextError";

export const parsePhoneNumber = (
  name: string,
  formik: FormikContextType<any>,
  state: string,
  setState: Dispatch<SetStateAction<string>>
) => {
  const phone_number = formik.values[name];
  let newStr;
  newStr = `${phone_number}`;
  newStr = newStr.replace(/[(\+)\(\) -]/g, "");
  let remainingDigits = newStr.length;
  if ((remainingDigits === 0 && phone_number === "+") || phone_number === "(")
    return;
  if (
    remainingDigits === 1 &&
    phone_number.length < state.length &&
    (newStr[0] === "0" || newStr[0] === "1")
  )
    return;
  // they are doing backspace
  if (phone_number.length < state.length) {
    if (newStr.length > 0) {
      if (newStr[0] !== "0" && newStr[0] !== "1") {
        if (newStr.length === 6) {
          const value = phone_number.replace(/-/g, "");
          formik.setFieldValue(name, value);
          setState(value);
          return;
        } else if (newStr.length === 3) {
          const value = phone_number.replace(/\) /g, "");
          formik.setFieldValue(name, value);
          setState(value);
          return;
        }
      } else if (newStr[0] === "0" || newStr[0] === "1") {
        if (newStr.length === 7) {
          const value = phone_number.replace(/-/g, "");
          formik.setFieldValue(name, value);
          setState(value);
          return;
        } else if (newStr.length === 4) {
          const value = phone_number.replace(/\) /g, "");
          formik.setFieldValue(name, value);
          setState(value);
          return;
        }
      }
    }
  }
  let countryCode = "";
  let areaCode = "";
  let middleDigits = "";
  let endingDigits = "";
  if (newStr.length === 11 || newStr[0] === "0" || newStr[0] === "1") {
    let cutStr = "";
    for (let i = 1; i < newStr.length; i++) cutStr += newStr[i];
    countryCode = `+${newStr[0]} `;
    remainingDigits--;
  }
  if (remainingDigits > 0) {
    areaCode = "(";
    for (let i = 0; i < remainingDigits; i++) {
      if (i === 3) break;
      if (remainingDigits > i && countryCode === "") {
        areaCode += newStr[i];
      } else if (remainingDigits > i && countryCode !== "") {
        areaCode += newStr[1 + i];
      }
    }
    remainingDigits -= areaCode.length - 1;
    if (areaCode.length == 4) areaCode += ") ";
  }
  if (remainingDigits > 0) {
    for (let i = 0; i < remainingDigits; i++) {
      if (i === 3) break;
      if (remainingDigits > i && countryCode === "") {
        middleDigits += newStr[3 + i];
      } else if (remainingDigits > i && countryCode !== "") {
        middleDigits += newStr[4 + i];
      }
    }
    remainingDigits -= middleDigits.length;
    if (middleDigits.length === 3) middleDigits += "-";
  }

  if (remainingDigits > 0) {
    for (let i = 0; i < remainingDigits; i++) {
      if (i === 4) break;
      if (remainingDigits > i && countryCode === "") {
        endingDigits += newStr[6 + i];
      } else if (remainingDigits > i && countryCode !== "") {
        endingDigits += newStr[7 + i];
      }
    }
  }

  let newValue = countryCode + areaCode + middleDigits + endingDigits;
  newValue = newValue ? newValue : newStr;
  formik.setFieldValue(name, newValue ? newValue : newStr);
  setState(newValue);
};

interface IpropsInput {
  label: React.ReactNode;
  name: string;
  type: string;
  fieldWrapperClass?: string;
  styledClass: string;
  labelClass?: string;
  inLineLabelError?: boolean;
}
// function Input(props: IpropsInput) {
export const PhoneField: FunctionComponent<IpropsInput> = (props) => {
  const {
    label,
    name,
    styledClass,
    fieldWrapperClass = "basic-input",
    labelClass,
    inLineLabelError,
    ...rest
  } = props;
  const formik: FormikContextType<any> = useFormikContext();
  const [oldStr, setOldStr] = useState("");
  useEffect(() => {
    parsePhoneNumber(name, formik, oldStr, setOldStr);
  }, [formik.initialValues[name] != ""]);

  return (
    <>
      <div className="flex justify-between">
        <label className={labelClass} htmlFor={name}>
          {label}
        </label>
        <label>
          {inLineLabelError && (
            <ErrorMessage name={name} component={TextError} />
          )}
        </label>
      </div>
      <div className={fieldWrapperClass}>
        <Field
          {...rest}
          onKeyUp={() => parsePhoneNumber(name, formik, oldStr, setOldStr)}
          id={name}
          name={name}
          className={styledClass}
        />
      </div>
    </>
  );
};
