import { useCallback } from "react";
import { shallowEqual, useSelector } from "react-redux";

// MHFO
export interface MHFOSubmissionValues {
  MHFODeliveryID?: number;
  "customer-name": string;
  "task-time": number | null;
  address1: string;
  address2: string;
  zip: string;
  state: string;
  city: string;
  "invoice-number": string;
  "task-hours": number | "";
  "task-description": string;
}
export interface MHFOParsedValues
  extends Omit<
    Omit<
      Omit<
        Omit<Omit<MHFOSubmissionValues, "customer-name">, "task-time">,
        "invoice-number"
      >,
      "task-hours"
    >,
    "task-description"
  > {
  customer: string;
  invoiceNo: string;
  taskStart: number;
  taskEnd: number;
  taskDetails: string;
  taskHours: number;
}
export interface MHFOEventProps extends MHFOParsedValues {
  MHFODeliveryID: number;
  createdBy: number;
  createdOn: string | number;
}
export type CreateMHFODelivery = (
  values: MHFOSubmissionValues,
  path?: string
) => void;
export type UpdateMHFODelivery = (
  values: Partial<MHFOSubmissionValues>,
  path?: string
) => void;
export type DeleteMHFODelivery = (
  values: Partial<MHFOEventProps>,
  path?: string
) => void;
interface Props {
  setRedirect: React.Dispatch<React.SetStateAction<string>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean[]>>;
}
export const useCalendarMHFO = ({ setRedirect, setIsLoading }: Props) => {
  const socket = useSelector(
    (state: Istate) => state.data.socket,
    shallowEqual
  );

  const createMHFODelivery: CreateMHFODelivery = useCallback(
    (values, path = "") => {
      socket?.emit(
        "CALENDAR",
        JSON.stringify({
          type: "MHFO",
          action: "CREATE",
          payload: values,
        })
      );
      setIsLoading((current) => {
        current[1] = true;
        return [...current];
      });
      if (path) setRedirect(path);
    },
    [socket, setRedirect, setIsLoading]
  );

  const updateMHFODelivery: UpdateMHFODelivery = useCallback(
    (values, path = "") => {
      socket?.emit(
        "CALENDAR",
        JSON.stringify({
          type: "MHFO",
          action: "UPDATE",
          payload: values,
        })
      );
      setIsLoading((current) => {
        current[1] = true;
        return [...current];
      });
      if (path) setRedirect(path);
    },
    [socket, setRedirect, setIsLoading]
  );

  const deleteMHFODelivery: DeleteMHFODelivery = useCallback(
    (values, path = "") => {
      socket?.emit(
        "CALENDAR",
        JSON.stringify({
          type: "MHFO",
          action: "DELETE",
          payload: values,
        })
      );
      setIsLoading((current) => {
        current[1] = true;
        return [...current];
      });
      if (path) setRedirect(path);
    },
    [socket, setRedirect, setIsLoading]
  );

  return {
    createMHFODelivery,
    updateMHFODelivery,
    deleteMHFODelivery,
  };
};
