import { push } from "connected-react-router";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormLogic } from "./form/FormLogic";

export const StagerInfoModal = () => {
  const dispatch = useDispatch();
  const stagingBuilder = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex]
  );
  const {
    socket,
    user: { id: userID, businessID },
  } = useSelector((state: Istate) => state.data);

  const handleChangeStagerName = () => {
    const newStagerName = window.prompt(
      "What would you like the Stager name to be displayed as?",
      stagingBuilder.stagerName
    );
    newStagerName &&
      socket?.emit(
        "STAGING_BUILDER_UPDATE",
        JSON.stringify({
          type: "META",
          action: "UPDATE",
          payload: {
            stagingBuilder: {
              stagingBuilderID: stagingBuilder.stagingBuilderID,
              stagerName: newStagerName,
            },
            userID,
            businessID,
          },
        })
      );
    dispatch(push("/stage"));
  };
  return (
    <div>
      <h1 className="text-2xl">
        Change StagingBuilder&trade; Ownership And/Or Stager Name
      </h1>
      <h2 className="text-xl">
        Click{" "}
        <span
          onClick={handleChangeStagerName}
          className="text-logo-blue cursor-pointer underline"
        >
          Here
        </span>{" "}
        to Change Stager Name (Legacy Way, does NOT Share StagingBuilder&trade;)
      </h2>
      <p className="grid auto-1fr gap-3 mb-8">
        <span>Current Stager Name:</span>
        <span>{stagingBuilder.stagerName}</span>
      </p>
      <hgroup>
        <h2 className="text-xl">Use Form Below to Change Ownership</h2>
        <h3 className="text-lg">Some Quick Notes:</h3>
      </hgroup>
      <ol className="max-w-prose mb-2 list-decimal ml-6">
        <li className="mb-1">
          You can only change ownership of StagingBuilders&trade; that were
          created by a StagingRents&reg; employee.
        </li>
        <li className="mb-1">
          Changing the ownership will allow the customer to see and edit the
          StagingBuilder&trade;.
        </li>
        <li>
          The stager name will be reset to null [displayed as the stager's first
          and last name] OR the default stager name (if user is in a business)
          when ownership changes.
        </li>
      </ol>
      <FormLogic />
    </div>
  );
};
