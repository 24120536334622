import React from "react";

interface Props {}

export const TransferInTHead = (props: Props) => {
  return (
    <thead>
      <tr>
        <th></th>
        <th>Item Name</th>
        <th>Item #</th>
        <th></th>
      </tr>
    </thead>
  );
};
