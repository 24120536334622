import { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";

import { useDispatch } from "react-redux";
import { clearInventory, fetchInventoryVerbose, startNewItem } from "../redux";

import "./Inventory/Inventory.scss";
import { Inventory, InventoryListContainer } from "./Inventory/";

import styled from "styled-components";
import { Main as Controls } from "./page-specific/inventory/delta/controls/Main";
import { Main as Report } from "./page-specific/inventory/delta/report/Main";
const InventoryEditorWrapper = styled.div`
  margin: 8px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  min-height: 100px;
  overflow-y: auto;
  max-height: 95vh;
  position: relative;
`;
const SimpleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;
interface IpropsInventory {}
export const InventoryRouter = ({}: IpropsInventory) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  useEffect(() => {
    if (!/\/inventory\/delta/i.test(pathname))
      dispatch(fetchInventoryVerbose({ force: false }));
    if (pathname == "/inventory/new") dispatch(startNewItem());
  }, [pathname]);

  useEffect(() => {
    return () => {
      dispatch(clearInventory());
    };
  }, []);

  // this is SO STUPID -- I forget why this is SO STUPID haha...
  // I think it is because I have to return null as the routed if it is missing a url param
  return (
    <Switch>
      <Route exact path="/inventory/delta/controls">
        <Controls />
      </Route>
      <Route exact path="/inventory/delta/report">
        <Report />
      </Route>
      <Route path="/inventory">
        <div className="body-container">
          <SimpleGrid>
            <InventoryListContainer />
            <InventoryEditorWrapper id="right-side-container">
              <Route exact path="/inventory">
                <Inventory />
              </Route>
              <Route exact path="/inventory/edit">
                {null}
              </Route>
              <Route exact path="/inventory/edit/:itemNo">
                <Inventory />
              </Route>
              <Route exact path="/inventory/new">
                {null}
              </Route>
              <Route exact path="/inventory/new/:itemNo">
                <Inventory />
              </Route>
            </InventoryEditorWrapper>
          </SimpleGrid>
        </div>
      </Route>
    </Switch>
  );
};
