import { useDispatch } from "react-redux";
import { AppThunk, addNewItemContainer } from "../../../../redux";

interface Props {
  disabled: boolean;
}
export const AddItemContainer = ({ disabled }: Props) => {
  const dispatch: AppThunk = useDispatch();
  const handleAddItemContainer = () => {
    dispatch(addNewItemContainer());
  };
  return (
    <button
      disabled={disabled}
      type="button"
      className={`btn p-1 ${
        disabled ? "btn-disabled btn-item-container" : "btn-item-container"
      }`}
      onClick={() => handleAddItemContainer()}
    >
      Add A Room
    </button>
  );
};
