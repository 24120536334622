import React from "react";
import { dateOptionsShortVerbose, GetDate } from "../../../../constants";

interface Props {
  stagingBuilder: StagingBuilder;
}

export const StagingInformation = ({ stagingBuilder }: Props) => {
  return (
    <div>
      <div className="flex space-between text-2xl">
        <h1>File Number:</h1>
        <h1>HS - {stagingBuilder.fileNumber}</h1>
      </div>
      <div style={{ height: "8px", backgroundColor: "var(--logo-purple)" }} />
      <hgroup className="mb-4">
        <h2 className="mb-2 text-2xl">Address:</h2>
        <h3>{stagingBuilder.address}</h3>
      </hgroup>
      <div style={{ height: "8px", backgroundColor: "var(--logo-purple)" }} />
      <hgroup className="mb-2">
        <h2 className="mb-4 text-2xl">Lessee Information:</h2>
        <div className="flex space-between mb-4">
          <h3>Name:</h3>
          <h3>{stagingBuilder.clientName}</h3>
        </div>
        <div className="flex space-between mb-4">
          <h3>Phone: </h3>
          <h3>{stagingBuilder.clientPhone}</h3>
        </div>
        <div className="flex space-between mb-4">
          <h3>Email: </h3>
          <h3>{stagingBuilder.clientEmail}</h3>
        </div>
      </hgroup>
      <div style={{ height: "8px", backgroundColor: "var(--logo-purple)" }} />
      <hgroup>
        <h2 className="mb-4 text-2xl">StagingBuilder Information:</h2>
        <div className="flex space-between mb-4">
          <h3>Delivered: </h3>
          <h3>
            {GetDate(
              stagingBuilder.deliveryActual,
              undefined,
              dateOptionsShortVerbose
            )}
          </h3>
        </div>
        <div className="flex space-between mb-4">
          <h3>Items: </h3>
          <h3>{stagingBuilder.items}</h3>
        </div>
        <div className="flex space-between mb-4">
          <h3>Rate: </h3>
          <h3 className="sup-dollar">{stagingBuilder.monthlyRate}</h3>
        </div>
        <div className="flex space-between mb-4">
          <h3>Retail: </h3>
          <h3 className="sup-dollar">{stagingBuilder.retailValue}</h3>
        </div>
      </hgroup>
    </div>
  );
};
