import React from "react";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { AppThunk, favoriteItem } from "../../../../redux";
import { handleStagingBuilderScan } from "../../../../redux/stagingBuilder/actions";
import { LocaleNumber } from "../../../constants";
import { CustomButton } from "../../../HighlyReusableComponents/CustomButton";

interface Props {
  itemID: number;
  itemNo: string;
  itemName: string;
  rentalRate: number;
  rentalPrice: number;
  dimensions: string;
  favorite: number;
  quanAvail: number;
  quanDraggable: number;
}

const ItemInfoFC = ({
  favorite,
  quanAvail,
  quanDraggable,
  rentalRate,
  rentalPrice,
  dimensions,
  itemID,
  itemName,
  itemNo,
}: Props) => {
  const [isFavorite, setIsFavorite] = React.useState(!!favorite);
  const dispatch: AppThunk = useDispatch();

  const handleFavoriteItemClick = (itemID: number) => {
    setIsFavorite((old) => !old);
    dispatch(favoriteItem(itemID, setIsFavorite));
  };
  return (
    <>
      <div>
        <span className="lg:hidden">Quan. Avail:</span> {quanDraggable}
      </div>
      <div>
        <span className="lg:hidden">Monthly Rate:</span>{" "}
        <span className="sup-dollar">{LocaleNumber(rentalRate)}</span>
      </div>
      <div>
        <span className="lg:hidden">Retail Cost:</span>{" "}
        <span className="sup-dollar">{LocaleNumber(rentalPrice)}</span>
      </div>
      <div>{dimensions}</div>
      <div style={{ textAlign: "center" }}>{itemName}</div>
      <div>
        <span className="lg:hidden">Item #</span> {itemNo.toLowerCase()}
      </div>
      <div>
        {isFavorite ? (
          <AiFillStar
            onClick={() => handleFavoriteItemClick(itemID)}
            size={30}
            color="#d4af37"
            cursor="pointer"
          />
        ) : (
          <AiOutlineStar
            onClick={() => handleFavoriteItemClick(itemID)}
            size={30}
            cursor="pointer"
          />
        )}
      </div>
      <div className="grid place-items-center lg:hidden">
        <CustomButton
          onClick={() => {
            const valuesToSubmit = {
              barcode: itemNo,
              action: "ADD_SCANNED_ITEM" as const,
            };
            dispatch(
              handleStagingBuilderScan({
                ...valuesToSubmit,
              })
            );
          }}
          appendClassName="mt-0"
        >
          Add Item
        </CustomButton>
      </div>
    </>
  );
};
export const ItemInfo = React.memo(ItemInfoFC);
