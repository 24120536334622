import { FormikProps, useField } from "formik";
import { FC, useEffect, useMemo } from "react";
import styled from "styled-components";
import { valleyCities } from "../../../../constants";
import { useApartmentCommunities } from "../../../../constants/hooks/fetching/useApartmentCommunities";
import FormikControl from "../../../../FormikComponents/FormikControl";
import { RadioButton } from "../../../../FormikComponents/RadioButton";
import { CustomButton } from "../../../../HighlyReusableComponents/CustomButton";
import { TextAreaCounterMemo } from "../../../../HighlyReusableComponents/FormComponents/TextArea_counter";
import { InitialValues } from "./DeliveryLogic";
const CastleRadioGroup = styled.div`
  input {
    margin: 0.4rem;
  }
`;
interface Props {
  formikProps: FormikProps<InitialValues>;
}

export const DeliveryFormFields: FC<Props> = ({
  formikProps: {
    submitForm,
    setFieldValue,
    values: { community },
  },
}) => {
  const [commentsField, ,] = useField("comments");
  const [detailsField, ,] = useField("package-details");
  const valleyCitiesOptions = useMemo(
    () => valleyCities.map((city) => ({ label: city, value: city })),
    [valleyCities]
  );
  const { apartmentCommunities } = useApartmentCommunities();
  const communities = useMemo(
    () =>
      apartmentCommunities.map(
        ({ appartmentCommunityID, appartmentCommunityName }) => ({
          label: appartmentCommunityName,
          value: appartmentCommunityID,
        })
      ),
    [apartmentCommunities]
  );
  useEffect(() => {
    console.log(community);
    // clear all the address fields
    if (community === undefined) {
      if (window.confirm("Would you like to clear the address fields?")) {
        setFieldValue("address1", "");
        setFieldValue("city", "");
        setFieldValue("zip", "");
      }
    } else {
      // if we add/change the community should we just change the address info, or
    }
  }, [community, setFieldValue]);
  const bedArray = useMemo(() => [1, 2, 3, 4, 5, 6, 0], []);
  const bedroomArray = useMemo(() => [1, 2, 3, 4, 5], []);
  return (
    <div>
      <div className="grid grid-cols-7 gap-x-8 gap-y-6 mt-4 mb-6">
        <div className="col-span-3 basic-input">
          <FormikControl
            labelClass="block"
            controlContainerclass="block"
            label="Customer Name"
            name="customer"
            control="input"
          />
        </div>
        <FormikControl
          creatable
          control="basic-select"
          label="File Number"
          name="file-number"
          options={[]}
          controlContainerclass="col-span-2"
        />
        <div className="col-span-2 basic-input">
          <FormikControl
            labelClass="block"
            controlContainerclass="block span-3"
            label="Scheduled Delivery Date"
            name="delivery"
            control="date-picker"
          />
        </div>
        <div
          style={{
            gridTemplateColumns: "auto auto",
            gridTemplateRows: "auto auto auto auto",
          }}
          className="grid col-span-2"
        >
          <h3 className="col-span-full text-xl mb-2">Package Accomodations</h3>
          <div className="">
            <CastleRadioGroup className="border-solid border-b">
              <RadioButton id="student" name="package" />
              <RadioButton id="corporate" name="package" />
              <RadioButton id="loft" name="package" />
            </CastleRadioGroup>
            <div className="mr-auto basic-input">
              <FormikControl
                controlContainerclass="flex justify-between flex-row-reverse span-3 mr-5"
                label='32"'
                labelClass="ml-2"
                name="tv-32"
                control="input"
                style={{
                  width: "5rem",
                  minHeight: "unset",
                  lineHeight: "16px",
                  height: "26px",
                  MozAppearance: "textfield",
                }}
                type="number"
              />
              <FormikControl
                controlContainerclass="flex justify-between flex-row-reverse span-3 mr-5"
                label='42"'
                labelClass="ml-2"
                name="tv-42"
                control="input"
                style={{
                  width: "5rem",
                  minHeight: "unset",
                  lineHeight: "16px",
                  height: "26px",
                  MozAppearance: "textfield",
                }}
                type="number"
              />
              <FormikControl
                controlContainerclass="flex justify-between flex-row-reverse span-3 mr-5"
                label='50"'
                labelClass="ml-2"
                name="tv-50"
                control="input"
                style={{
                  width: "5rem",
                  minHeight: "unset",
                  lineHeight: "16px",
                  height: "26px",
                  MozAppearance: "textfield",
                }}
                type="number"
              />
              <FormikControl
                controlContainerclass="flex justify-between flex-row-reverse span-3 mr-5"
                label='55"'
                labelClass="ml-2"
                name="tv-55"
                control="input"
                style={{
                  width: "5rem",
                  minHeight: "unset",
                  lineHeight: "16px",
                  height: "26px",
                  appearance: "none",
                  MozAppearance: "textfield",
                }}
                type="number"
              />
            </div>
          </div>
          <div>
            <CastleRadioGroup className="border-solid border-b">
              <RadioButton id="1-br" label="1 Br." name="bedrooms" />
              <RadioButton id="2-br" label="2 Br." name="bedrooms" />
              <RadioButton id="3-br" label="3 Br." name="bedrooms" />
              <RadioButton id="4-br" label="4 Br." name="bedrooms" />
              <RadioButton id="5-br" label="5 Br." name="bedrooms" />
            </CastleRadioGroup>
            <CastleRadioGroup>
              <RadioButton id="furn" label="Furn. Only" name="accomodations" />
              <RadioButton
                id="furn-hswrs"
                label="Furn. & Hswrs."
                name="accomodations"
              />
              <RadioButton
                id="hswrs"
                label="Hswrs. Only"
                name="accomodations"
              />
            </CastleRadioGroup>
          </div>
          <div
            className="col-span-full grid grid-flow-col gap-x-3"
            style={{
              gridTemplateRows: "repeat(8, minmax(0, auto))",
              gridTemplateColumns: "1fr repeat(5, minmax(0, auto))",
            }}
          >
            <span></span>
            <span>Twin</span>
            <span>Queen</span>
            <span>Qn. P/T</span>
            <span>King</span>
            <span>King P/T</span>
            <span>2 Twins</span>
            <span>No Beds</span>
            {bedroomArray.map((outer) => (
              <>
                <span>BR{outer}</span>
                {bedArray.map((inner) => (
                  <RadioButton
                    id={`bedroom-${outer} bed-${inner}`}
                    label=""
                    value={`${inner}`}
                    name={`bedroom-${outer}`}
                    className={!inner ? "accent-color-gray-500" : ""}
                  />
                ))}
              </>
            ))}
          </div>
        </div>
        <label htmlFor="package-details" className="flex flex-col col-span-2">
          <span>Package Details</span>
          <TextAreaCounterMemo
            id="package-details"
            className="block w-full h-full border border-solid rounded p-1 border-gray-400 focus:outline-none focus:ring"
            style={{}}
            wrapperStyles={{
              maxWidth: "none",
              width: "100%",
              flex: "1 1 auto",
            }}
            {...detailsField}
            maxLength={512}
          />
        </label>
        <div className="col-span-3">
          <FormikControl
            creatable
            control="basic-select"
            label="Apartment Community"
            name="community"
            options={communities}
            controlContainerclass="col-span-3"
          />
          <div className="grid grid-cols-3 gap-x-8 mt-4">
            <FormikControl
              styledClass="h-4ch"
              control="input"
              label="Apt. # / Suite"
              name="address2"
              placeholder="Suite #, Apt #, etc. "
            />
            <FormikControl
              styledClass="h-4ch"
              control="input"
              label="Floor"
              name="floor"
            />
          </div>
          <label className="block mt-4" htmlFor="comments">
            Comments & Extras
            <TextAreaCounterMemo
              wrapperStyles={{ width: "100%", maxWidth: "none" }}
              id="comments"
              className="block border border-solid rounded p-1 border-gray-400 focus:outline-none focus:ring"
              style={{ width: "100%", height: "12rem" }}
              {...commentsField}
              maxLength={512}
            />
          </label>
          <div className="grid grid-cols-3 gap-x-8 mt-4">
            <FormikControl
              styledClass="h-4ch"
              control="input"
              label="Monthly Rate"
              name="monthly-rate"
              controlContainerclass="basic-input"
              labelClass="block"
            />
            <FormikControl
              styledClass="h-4ch"
              control="input"
              label="Delivery Fee"
              name="delivery-fee"
              controlContainerclass="basic-input"
              labelClass="block"
            />
            <FormikControl
              styledClass="h-4ch"
              control="input"
              label="Deposit"
              name="deposit"
              controlContainerclass="basic-input"
              labelClass="block"
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-7 gap-x-2 gap-y-2">
        <div className="col-span-7 grid grid-cols-3 gap-x-8 gap-y-4">
          <FormikControl
            styledClass="h-4ch"
            control="input"
            label="Address 1"
            name="address1"
            controlContainerclass="basic-input col-span-2"
            labelClass="block"
          />
          <CustomButton disabled appendClassName="mt-6">
            Create Apartment Community
          </CustomButton>
          <FormikControl
            creatable
            control="basic-select"
            label="City"
            name="city"
            menuPlacement="top"
            options={valleyCitiesOptions}
          />
          <FormikControl
            controlContainerclass="control-container w-100p"
            fieldWrapperClass="field-wrapper select"
            styledClass="w-100p h-4ch"
            control="basic-select"
            label="State"
            name="state"
            labelClass="block"
            menuPlacement="top"
            options={[
              { value: "48", label: "Arizona" },
              { value: "31", label: "California" },
            ]}
          />
          <FormikControl control="input" label="Zip Code" name="zip" />
          <CustomButton
            disabled
            onClick={() => submitForm()}
            appendClassName="mt-3 col-span-3"
          >
            Submit Castle Delivery
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
