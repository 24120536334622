import { link } from "fs";
import React, { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Istate } from "../../../../../redux";
import "../../../../Prints/print.scss";
import { PrintErrorMessage } from "./PrintErrorMessage";

interface Props {
  setTagErrors: React.Dispatch<React.SetStateAction<boolean>>;
}
export const StagingRentsTag = ({ setTagErrors }: Props) => {
  const tag: itemVerbose | undefined = useSelector(
    (state: Istate) => state.data.user.activeItem,
    shallowEqual
  );
  let Errors: string[] = [];
  if (tag) {
    if (!tag.pictureA) {
      Errors.push("No Picture");
      setTagErrors(true);
    }
    if (!tag.itemNo) {
      Errors.push("No item Number");
      setTagErrors(true);
    }
    if (!tag.itemName) {
      Errors.push("No item Name");
      setTagErrors(true);
    }
    if (!tag.rentalPrice) {
      Errors.push("No Rental Price");
      setTagErrors(true);
    }
    if (!tag.rentalRate) {
      Errors.push("No Rental Rate");
      setTagErrors(true);
    }
  }

  return tag && !Boolean(Errors.length) ? (
    <div className="sr-tag">
      <div className="img-container">
        <img className="product" src={tag.pictureA} alt={tag.itemNo} />
        <p>
          {tag.srLocation}
          <br /> {tag.itemRating ? tag.itemRating : null}
        </p>
      </div>
      <div className="tag-info-container">
        <h1>{tag.itemName}</h1>
        <h1>{tag.dimensions ? tag.dimensions : null}</h1>
        <div className="writable-container">
          <div className="writable-container__writable-area">Notes</div>
          <div className="writable-container__writable-area">Quan.</div>
        </div>
        <div className="tag-info">
          <div>
            <div className="barcode text-center">{"000000-" + tag.itemNo}</div>
            <div className="below-barcode">
              <h3 className="dollar-sign-target">
                {tag.rentalPrice && tag.rentalPrice.toString().split(".")[0]}
              </h3>
              <h3>&middot;</h3>
              <h3> {tag.itemNo} </h3>
            </div>
          </div>
          <div>
            <img src="/api/media/logo.png" alt="StagingRents logo" />
            <h2 className="dollar-sign-target text-center">
              {tag.rentalRate && tag.rentalRate.toString().split(".")[0]}/mo.
            </h2>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <PrintErrorMessage errors={Errors} />
  );
};
