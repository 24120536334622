import { Formik, FormikHelpers, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  AppThunk,
  fetchingDataFailure,
  fetchingDataSuccess,
  Istate,
} from "../../../../../redux";
import { formInit, InitialValues } from "./initialValues";
import axios from "axios";
import { updateStagingBuilderMeta } from "../../../../../redux/stagingBuilder/actions/updateStagingBuilderMeta";

interface Props {
  render: ((
    FormikProps: FormikProps<InitialValues>,
    stagingItems: StagingItem[]
  ) => React.ReactNode) &
    ((
      FormikProps: FormikProps<InitialValues>,
      stagingItems: StagingItem[]
    ) => JSX.Element);
}

export const FormLogic = ({ render }: Props) => {
  const dispatch: AppThunk = useDispatch();
  const stagingBuilderID = useSelector(
    (state: Istate) => state.data.user.activeStagingBuilder,
    shallowEqual
  );
  const query = useSelector(
    (state: Istate) => state.router.location.query,
    shallowEqual
  );
  const [stagingItems, setStagingItems] = useState<StagingItem[]>([]);
  useEffect(() => {
    if (query.containerItemID === undefined) query.containerItemID = "0";

    stagingBuilderID &&
      axios({
        url: `/api/effects/get/staging-builder/staging-items?stagingBuilderID=${stagingBuilderID}&containerItemID=${Number(
          query.containerItemID
        )}`,
        method: "GET",
      })
        .then((res) => {
          let stagingItems: StagingItem[] = res.data.stagingItems;
          setStagingItems(stagingItems);
          dispatch(fetchingDataSuccess());
        })
        .catch((err) => {
          if (err.response) dispatch(fetchingDataFailure(err.response.status));
          else dispatch(fetchingDataFailure());
        });
  }, [query, stagingBuilderID, dispatch]);
  const onSubmit = (
    data: InitialValues & Partial<{ key: string; containerItemID: number }>,
    actions: FormikHelpers<InitialValues>
  ) => {
    data.stagingItems.forEach((element) => {
      element.itemReturnStatus = Number(element.itemReturnStatus);
    });
    if (Number(query.containerItemID)) {
      data.key = "containerItemID";
      data.containerItemID = Number(query.containerItemID);
    }
    axios({
      method: "PUT",
      url: "/api/form/staging-builder/check-in",
      data,
    }).then((res) => {
      setStagingItems(res.data.stagingItems);
      dispatch(updateStagingBuilderMeta(res.data.stagingBuilder));
      actions.resetForm();
    });
  };
  return (
    <Formik
      initialValues={formInit({ stagingBuilderID, stagingItems }).initialValues}
      onSubmit={onSubmit}
      validationSchema={
        formInit({ stagingBuilderID, stagingItems }).validationSchema
      }
      enableReinitialize
      render={(FormikProps) => render(FormikProps, stagingItems)}
    />
  );
};
