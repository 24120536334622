import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";

interface IpropsSelect {
  label: React.ReactNode;
  name: string;
  options: { id: number; text: string }[];
  searchOptions?: { id: number; text: string }[];
  disablefield?: number;
  notsearchable?: number;
  default?: { id?: string | number; text: String };
}

const InventorySelect: React.FunctionComponent<IpropsSelect> = (props) => {
  const { label, name, options, searchOptions, ...rest } = props;
  const searchingOptions = searchOptions ? searchOptions : options;
  const { disablefield } = props;
  const disableValue: boolean = disablefield ? true : false;
  return (
    <>
      <label
        style={{ alignSelf: "center", justifySelf: "left" }}
        htmlFor={name}
      >
        {label}
      </label>
      <Field
        as="select"
        id={name}
        name={name}
        disabled={disableValue}
        {...rest}
        className="inventory-input"
      >
        {props.default ? (
          <option value={props.default.id}>{props.default.text}</option>
        ) : null}
        {options.map((option: { id: number; text: string }) => {
          return (
            <option key={option.id} value={option.id}>
              {option.text}
            </option>
          );
        })}
      </Field>
      {props.notsearchable ? (
        <div></div>
      ) : (
        <Field
          as="select"
          id={`${name}-search`}
          name={`${name}-search`}
          {...rest}
          className="inventory-input"
        >
          <option value={0}>Search: </option>
          {searchingOptions.map((option: { id: number; text: string }) => {
            return (
              <option key={option.id} value={option.id}>
                {option.text}
              </option>
            );
          })}
        </Field>
      )}
      <ErrorMessage name={name} component={TextError} />
    </>
  );
};

export default InventorySelect;
