import type { FC } from "react";
import { FaShippingFast } from "react-icons/fa";
import { BlockContainer } from "./BlockContainer";
import { BlockBackDrop } from "./BlockBackDrop";
import { format } from "date-fns";
import { useSelector } from "react-redux";
interface Props {
  //
}

export const TruckDates: FC<Props> = () => {
  const { delivery, pickup } = useSelector(
    ({ data: { stagingBuilders, user } }: Istate) => {
      const s = stagingBuilders[user.activeStagingBuilderIndex];
      return {
        delivery: s.deliveryActual || s.deliveryDesired || null,
        pickup: s.pickupActual || s.pickupDesired || null,
      };
    }
  );
  return (
    <div className="col-span-full h-[70px] lg:col-span-2">
      <BlockContainer>
        <div className="3xl:gap-8 flex h-min w-full grid-cols-2 grid-rows-2 justify-between gap-2 md:grid lg:h-full lg:grid-cols-2 lg:gap-8 lg:px-2 2xl:gap-2">
          <div className="contents h-min w-full justify-between lg:flex">
            <FaShippingFast
              className="my-auto block text-2xl text-green-600"
              size={32}
            />
            <div>
              <span className="block font-thin leading-tight">Delivery</span>
              <span className="block leading-tight">
                {delivery ? format(delivery, "MM/dd/yyyy") : "TBD"}
              </span>
            </div>
          </div>
          <div className="contents h-min w-full justify-between lg:flex">
            <FaShippingFast
              className="my-auto block text-2xl text-amber-800"
              size={32}
            />
            <div>
              <span className="block font-thin leading-tight">Pickup</span>
              <span className="block min-w-[9ch] leading-tight">
                {pickup ? format(pickup, "MM/dd/yyyy") : "TBD"}
              </span>
            </div>
          </div>
        </div>
        <BlockBackDrop />
      </BlockContainer>
    </div>
  );
};
