import type { FC } from "react";
import { BlockContainer } from "./BlockContainer";
import { BlockBackDrop } from "./BlockBackDrop";
import { HiHome } from "react-icons/hi";
import { RiFileCopyLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { ClientBlock } from "./Client";

export const AddressBlock: FC<
  Pick<
    StagingBuilder,
    | "address"
    | "address1"
    | "address2"
    | "city"
    | "zip"
    | "stateInitials"
    | "stagingBuilderID"
    | "clientName"
  >
> = ({ address, address1, address2, city, stateInitials, zip, clientName }) => {
  return (
    <div className="relative col-span-full lg:contents lg:w-full">
      <Link
        className="block lg:col-span-2 lg:h-[70px]"
        to={{
          pathname: "/stage",
          search: "open-modal=staging-information",
        }}
      >
        <BlockContainer>
          <div className="my-auto mr-4 grid h-8 w-8 place-items-center rounded-full bg-[#f1566b]">
            <HiHome size={24} fill="white" />
          </div>
          <BlockBackDrop />
          <div className="">
            {address ? (
              <p className="">
                <button className="block text-lg leading-none">
                  {capitalizeFirstChars(address1)}{" "}
                  {capitalizeFirstChars(address2)}{" "}
                </button>
                <span className="block text-sm text-gray-600">
                  {capitalizeFirstChars(city)}, {stateInitials?.toUpperCase()}{" "}
                  {zip}
                  {address && (
                    <RiFileCopyLine
                      size={20}
                      onClickCapture={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        copyText(address);
                      }}
                      className="inline-block cursor-pointer leading-none"
                    />
                  )}
                </span>
              </p>
            ) : (
              <span className="text-blue-500 underline">Add an address</span>
            )}
          </div>
        </BlockContainer>
      </Link>
      <ClientBlock clientName={clientName} />
    </div>
  );
};

export const copyText = (text?: string) => {
  if (text) {
    if (navigator?.clipboard) return navigator?.clipboard?.writeText(text);
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    if (window.navigator.platform === "iPhone") {
      textField.setSelectionRange(0, 99999);
    } else {
      textField.select();
    }
    document.execCommand("copy");
    textField.remove();
  }
};

interface Options {
  maintainCase?: boolean;
}
export const capitalizeFirstChars = (
  str: string | null | undefined,
  options: Options = {
    maintainCase: false,
  }
) => {
  return str
    ?.split(/\s+/)
    .map((word) => {
      if (!word) return word;
      if (!options.maintainCase) word = word.toLowerCase();
      return word[0].toLocaleUpperCase() + word.slice(1);
    })
    .join(" ");
};
