import { EventProps } from "../index";
import {
  dateOptionsShortVerbose,
  GetDate,
  LocaleNumber,
  StagingBuilderStatuses,
} from "../../constants";
import { CalendarEventErrors, CalendarEventTypes } from ".";

interface Props {
  stagingBuilder: StagingBuilder;
  length: number;
  index: number;
}
/**
 * This function takes in a stagingBuilder (with no fileNumber) and return a calendar event
 *
 * @param param0
 * @returns
 * the type of this is always STAGING_UP
 *
 */
export const proposalEvent = ({
  stagingBuilder: sb,
  length,
  index,
}: Props): EventProps | undefined => {
  let className: string | undefined;
  let errorType = CalendarEventErrors.NO_ERROR;

  if (index > length) return undefined;
  if (sb.stagingStatus === StagingBuilderStatuses.PROPOSAL_FELL_THROUGH)
    return undefined;

  if (
    (!sb.pickupActual && sb.pickupDesired) ||
    (!sb.deliveryActual && sb.deliveryDesired)
  ) {
    className = "blink-red";
    if (!sb.deliveryActual && sb.deliveryDesired)
      errorType = CalendarEventErrors.UNDEFINED_DELIVERY_ACTUAL;
    else errorType = CalendarEventErrors.UNDEFINED_PICKUP_ACTUAL;
  }

  const toolTipInformation: Partial<EventProps> & {
    toolTip: (e: EventProps) => string;
  } = {
    toolTip: (e) => {
      let returnString = "";
      // The actual delivery date has not been approved
      if (
        (!sb.pickupActual &&
          sb.pickupDesired &&
          e.type === CalendarEventTypes.STAGING_DOWN) ||
        (!sb.deliveryActual &&
          sb.deliveryDesired &&
          e.type === CalendarEventTypes.STAGING_UP)
      ) {
        returnString += `Stager Requested This Date\nWe Need To Confirm\n\n`;
      }

      returnString += `Stager: ${sb.stagerName}\n` || "";
      returnString += sb.clientName ? `Client: ${sb.clientName}\n` : "";
      returnString += `${sb.address}\n` || "";
      returnString += `Items: ${sb.items || 0}\n` || "";

      returnString +=
        `Delivery: ${GetDate(
          sb.deliveryActual || sb.deliveryDesired || 0,
          undefined,
          dateOptionsShortVerbose
        )}\n` || "";
      returnString +=
        `Pick-Up: ${GetDate(
          sb.pickupActual || sb.pickupDesired || 0,
          undefined,
          dateOptionsShortVerbose
        )}\n` || "";
      returnString +=
        `\nCreated: ${GetDate(
          sb.createdOn,
          undefined,
          dateOptionsShortVerbose
        )}\n` || "";

      if (returnString === "") return e.title as string;
      return returnString;
    },
  };
  const title = (
    <p className="flex justify-between">
      <span>SP-{sb.stagingBuilderID}</span>
      <span>${LocaleNumber(sb.monthlyRate || 0)}/mo</span>
    </p>
  );
  return {
    ...toolTipInformation,
    id: `SP-${sb.stagingBuilderID}`,
    title,
    type: CalendarEventTypes.STAGING_UP,
    allDay: true,
    start: new Date(sb.deliveryActual || sb.deliveryDesired || 0),
    end: new Date(sb.deliveryActual || sb.deliveryDesired || 0),
    domAttributes: { className },
    styles: {
      backgroundColor: className === "" ? "transparent" : "var(--logo-purple)",
      color: className === "" ? "black" : "white",
      fontWeight: className === "" ? "bold" : "normal",
    },
    errorType,
  };
};
