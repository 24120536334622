import React from "react";
import { Field } from "formik";

interface Iprops {
  label: React.ReactNode;
  name: string;
}
export const InventoryCheckbox = (props: Iprops) => {
  return (
    <>
      <label htmlFor={props.name}>{props.label}</label>
      <Field
        style={{ justifySelf: "left" }}
        type="checkbox"
        name={props.name}
      />
      <div></div>
    </>
  );
};
