import { FaRegCircle, FaWrench } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { GoListUnordered } from "react-icons/go";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigation } from "../mobile-nav/context/NavigationContext";

export const Footer = () => {
  const activeSB = useSelector(
    (state: Istate) => state.data.user.activeStagingBuilder
  );
  const { leftRight, updateMobileViewSide, updateSidebarVisibility } =
    useNavigation();
  return (
    <footer className="block text-center col-span-full">
      <span className="hidden w-full sm:block">
        StagingRents Copyright 2020
      </span>
      <div className="sm:hidden grid grid-cols-3 place-items-center">
        <div onClick={() => updateSidebarVisibility(true)}>
          <GiHamburgerMenu />
        </div>
        <div className="grid place-items-center">
          <Link to="/">
            <FaRegCircle onClick={() => updateMobileViewSide(0)} />
          </Link>
        </div>
        <div className="">
          {activeSB ? (
            leftRight === 0 ? (
              <FaWrench onClick={() => updateMobileViewSide(1)} />
            ) : (
              <GoListUnordered onClick={() => updateMobileViewSide(0)} />
            )
          ) : null}
        </div>
      </div>
    </footer>
  );
};
export const Footer2 = () => {
  return (
    <footer className="block text-center col-span-full">
      <span className="w-full">StagingRents Copyright 2021</span>
    </footer>
  );
};
