import React, { useState } from "react";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import { AutoSave } from "../../FormikComponents/AutoSave";

import StagingBuilderHeader from "./StagingBuilderHeader";
import StagingBuilderContainers from "./StagingBuilderContainers";

import { useSelector, shallowEqual } from "react-redux";
import { Istate } from "../../../redux";
import { ErrorModal, UrlModal } from "./Modals";

interface Props {
  edit: boolean;
}
function StagingBuilder({ edit }: Props) {
  const stagingBuilderIndex = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders.findIndex(
        (stagingBuilder) =>
          stagingBuilder.stagingBuilderID ===
          state.data.user.activeStagingBuilder
      ),
    shallowEqual
  );
  const stagingBuilder = useSelector(
    (state: Istate) => state.data.stagingBuilders[stagingBuilderIndex]
  );
  const [errorModalOpen, setErrorModalOpen] = React.useState(false);

  const validationSchema = Yup.object({
    itemContainers: Yup.array().of(
      Yup.object().shape({
        itemContainer: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().min(3, "Too short").required("Required"),
          })
        ),
      })
    ),
  });
  const onSubmit = (values: typeof stagingBuilder) => {
    // this is the function that will move it from proposal to pending
    console.log(values);
    setErrorModalOpen(true);
  };
  const [showTrash, setShowTrash] = React.useState(false);

  // sticky headers
  const [headerHeight, setHeaderHeight] = useState<number>(58);
  // toast(headerHeight);

  return stagingBuilder ? (
    <div id="right-side-container" className="simple-border relative">
      <ErrorModal
        setOpen={setErrorModalOpen}
        open={errorModalOpen}
        errors={[]}
      />
      <UrlModal />
      <Formik
        validationSchema={validationSchema}
        initialValues={stagingBuilder}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {(FormikProps: FormikProps<typeof stagingBuilder>) => (
          <Form id={`staging-builder-${stagingBuilder.stagingBuilderID}`}>
            <StagingBuilderHeader
              edit={edit}
              showTrash={showTrash}
              setShowTrash={setShowTrash}
              headerHeight={headerHeight}
              setHeaderHeight={setHeaderHeight}
            />
            <StagingBuilderContainers
              showTrash={showTrash}
              setShowTrash={setShowTrash}
              FormikProps={FormikProps}
              edit={edit}
              headerHeight={headerHeight}
            />
            <AutoSave />
          </Form>
        )}
      </Formik>
    </div>
  ) : null;
}

export default StagingBuilder;
