import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { SortType } from ".";
import { LocaleNumber } from "../../../constants";
import { CustomButton } from "../../../HighlyReusableComponents/CustomButton";
import { useNavigation } from "../../../mobile-nav/context/NavigationContext";

interface Props {
  setSortType: React.Dispatch<React.SetStateAction<SortType>>;
  sortType: SortType;
}
export const ScannerHeader = ({ setSortType, sortType }: Props) => {
  const { updateMobileViewSide } = useNavigation();
  const { address, monthlyRate, retailValue } = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex],
    shallowEqual
  );
  const [showPrice, toggleShowPrice] = useState<boolean>(true);
  return (
    <div className="max-h-28 min-h-28 h-28">
      <div className="bg-logo-blue leading-8 pl-2 max-h-8">{address}</div>
      <div className="grid grid-cols-3 gap-1 mb-3 max-h-16">
        <CustomButton
          linkProps={{ to: "/stage" }}
          onClick={() => updateMobileViewSide(1)}
        >
          <span className="block sm:hidden">Staging Builder</span>
          <span className="hidden sm:inline-block">Inventory</span>
        </CustomButton>
        <CustomButton
          onClick={() =>
            setSortType((current) =>
              current === SortType.RECENT ? SortType.ROOM : SortType.RECENT
            )
          }
        >
          {sortType === SortType.RECENT ? "Sort by Room" : "Sort by Recent"}
        </CustomButton>
        <CustomButton onClick={() => toggleShowPrice((current) => !current)}>
          <span className="sup-dollar">
            {LocaleNumber(showPrice ? monthlyRate : retailValue)}
            {showPrice ? " Per Mo." : " Retail"}
          </span>
        </CustomButton>
      </div>
    </div>
  );
};
