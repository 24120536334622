import { FormikProps } from "formik";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { StagingItemStatusesDD } from "../../../DropDownLists/StagingItemStatusesDD";
import { InitialValues } from "./form/initialValues";
import type { StagingItemStatuses } from "../../../constants/enums/StagingItemStatuses";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Istate } from "../../../../redux";
import { push } from "connected-react-router";
import { ImCross } from "react-icons/im";

export const Header = ({
  dirty,
  submitForm,
  setFieldValue,
  values,
  resetForm,
}: FormikProps<InitialValues>) => {
  const dispatch = useDispatch();
  const [overWriteWith, setOverWriteWith] = useState<StagingItemStatuses>(0);
  const [revertSave, setRevertSave] = useState(values.stagingItems);
  const query = useSelector(
    (state: Istate) => state.router.location.query,
    shallowEqual
  );
  useEffect(() => {
    if (Number(query.sis)) setOverWriteWith(Number(query.sis));
  }, []);

  const updateAllTBD = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setOverWriteWith(~~e.target.value);

    Boolean(~~e.target.value) &&
      toast(
        `Make sure to also click apply change to set all the TBD to ${
          { ...StagingItemStatusesDD }[~~e.target.value].text
        }`,
        { position: "top-left" }
      );
  };
  const changeTBDs = () => {
    if (values?.stagingItems) {
      setRevertSave(values.stagingItems);

      toast(
        `Make sure to also click Submit in order for changed to take effect`,
        { position: "top-left" }
      );
      // take the stagingItems values and get the index of all the items where
      //  itemReturnStatus === 0
      const stagingItems: {
        stagingItemID: number;
        itemReturnStatus: StagingItemStatuses;
      }[] = values.stagingItems.map((item) => {
        return {
          ...item,
          itemReturnStatus: item.itemReturnStatus
            ? item.itemReturnStatus
            : overWriteWith,
        };
      });
      setFieldValue("stagingItems", stagingItems);
    }
  };
  return (
    <div className="p-5 relative">
      <div
        onClick={() => dispatch(push("/stage"))}
        className="absolute z-5"
        style={{ right: "15px", top: "15px" }}
      >
        <ImCross cursor="pointer" size="2em" />
      </div>
      <h1>Staging Items Check In</h1>
      <div style={{ width: "inherit" }}>
        <label htmlFor="updateAllTBD">
          You can update all TBD itemStatuses at the same time below.
        </label>
        <div className="flex">
          <select
            value={overWriteWith}
            onChange={updateAllTBD}
            name="updateAllTBD"
            id="updateAllTBD"
          >
            <option value={0}>Choose:</option>
            {StagingItemStatusesDD.filter((option) => option.id).map(
              (option) => (
                <option value={option.id}>{option.text}</option>
              )
            )}
          </select>
          <button
            disabled={!Boolean(overWriteWith)}
            className="btn btn-confirm btn-defaults px-6"
            type="button"
            onClick={changeTBDs}
          >
            Apply Change
          </button>
          <button
            disabled={!dirty}
            className="btn btn-confirm btn-defaults px-6"
            type="button"
            onClick={() => resetForm()}
          >
            Reset Form
          </button>
          <button
            className="btn btn-confirm btn-defaults px-6"
            disabled={!dirty}
            type="button"
            onClick={() => submitForm()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};
