import { ReactElement, memo } from "react";
import { Link } from "react-router-dom";
import stackedLogo from "../../assets/stacked-logo.png";
import noavatar from "../../assets/noavatar.png";
import { shallowEqual, useSelector } from "react-redux";
import { Istate } from "../../redux";
import { LoadingIndicator } from "./LoadingIndicator";
import leftSideBarPng from "../../assets/left-side-bar.png";
import { NavLinks } from "./NavLinks";

function LeftSideBar(): ReactElement {
  const { name, businessID } = useSelector(
    (state: Istate) => state.data.user,
    shallowEqual
  );

  return (
    <div
      className="relative pt-2 text-xl side-bar side-bar__left h-full bg-no-repeat bg-contain bg-bottom"
      style={{
        backgroundImage: `url(${leftSideBarPng}), linear-gradient(to right, #e2d9d4, #f3eeeb)`,
      }}
      id="LeftSideBar"
    >
      <div className="relative z-5">
        <Link
          to={{
            pathname: businessID === 1 ? undefined : "/",
            search: businessID === 1 ? "open-modal=employee-portal" : undefined,
          }}
          className="relative block active grid-center side-bar-container side-bar-container-logo"
        >
          <img
            className="side-bar__logo"
            src={stackedLogo}
            alt="StagingRents logo"
          />
        </Link>
        <div className="relative hidden lg:block side-bar side-bar-container side-bar-container-avatar">
          <img
            className="side-bar__avatar"
            src={noavatar}
            // src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse4.mm.bing.net%2Fth%3Fid%3DOIP.uL71S48VnaZ70vk5ojVH-AHaHa%26pid%3DApi&f=1"
            style={{ borderRadius: "50%" }}
            alt="User's Avatar"
          />
        </div>
        <div className="grid-center pt-4">
          <LoadingIndicator />
        </div>
        <div className="text-center font-bold pt-4 hidden welcome-banner">
          Welcome {name}!
        </div>
        <div className="overflow-y-scroll">
          <NavLinks />
        </div>
      </div>
    </div>
  );
}

export default memo(LeftSideBar);
