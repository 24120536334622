import { Instructions } from "./Instructions";
import ReactSelect from "react-select";
import { CustomButton } from "../../../../HighlyReusableComponents/CustomButton";
import { Formik, useField } from "formik";
import { ContinueScanning } from "./ContinueScanning";
import useSWR from "swr";
import { fetcher } from "../../../../../fetcher";
import { options } from "../options";
import { useMemo } from "react";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { useHandleFetchErrors } from "../../../../../util/handleFetchErrors";
export interface Data {
  section: string | null;
  success: boolean;
}
export const Main = () => {
  const { data } = useSWR<Data>("/api/effects/get/user-section", fetcher);
  const { section } = { ...data };
  const sectionLabel = useMemo(
    () =>
      section !== "0"
        ? options.find(({ value }) => value === section)?.label
        : null,
    [section]
  );
  const dispatch = useDispatch();
  const { errorHandler } = useHandleFetchErrors();
  return (
    <div className="body-container p-3 h-full">
      <h1 className="text-4xl">&Delta; Inventory</h1>
      <div className="md:grid md:grid-cols-2 gap-3 h-full min-w-0 min-h-0 overflow-y-auto md:overflow-y-hidden">
        <Instructions />
        <div className="pt-8 md:pt-0">
          <div className="grid grid-cols-2 gap-x-4 min-w-0 min-h-0">
            <div>
              <Formik
                initialValues={{ section: "0" }}
                onSubmit={async (values) => {
                  if (
                    window.confirm(
                      `Are you sure you want to clear the inventory for section ${
                        options.find(({ value }) => value === values.section)
                          ?.label
                      }?`
                    )
                  )
                    fetch("/api/form/users/user-section", {
                      method: "POST",
                      headers: { "Content-Type": "application/json" },
                      body: JSON.stringify(values),
                    })
                      .then(async (res) => await res.json())
                      .then((value) => {
                        dispatch(push("/scanner/delta-inventory"));
                      })
                      .catch((err) => console.error(err));
                }}
              >
                {({
                  submitForm,
                  dirty,
                  values: { section: formikSection },
                }) => (
                  <>
                    <h2 className="text-xl">
                      Start A New Delta Inventory Section
                    </h2>
                    <label htmlFor="">
                      <span>Section</span>
                      <FormikSelect name="section" options={options} />
                    </label>
                    <CustomButton
                      type="button"
                      onClick={submitForm}
                      appendClassName="max-w-xs"
                      disabled={!dirty}
                    >
                      {section === formikSection
                        ? `Restart Section ${sectionLabel}`
                        : "Start Scanning"}
                    </CustomButton>
                  </>
                )}
              </Formik>
            </div>
            <ContinueScanning {...data} sectionLabel={sectionLabel} />
          </div>
          <hr className="my-3" />
          <h2 className="text-xl">Create Delta Inventory Report</h2>
          <h3 className="text-red-900 font-bold">IMPORTANT!</h3>
          <p className="max-w-prose">
            Before creating the delta inventory report, make sure that you have
            scanned all the items that are a part of the section.
          </p>
          <div className="grid grid-cols-2 gap-x-4">
            <CustomButton
              type="button"
              appendClassName="max-w-xs"
              disabled={section === "0"}
              linkProps={{ to: "/inventory/delta/report" }}
            >
              Generate &Delta;Report
            </CustomButton>
            <CustomButton
              type="button"
              appendClassName="max-w-xs"
              disabled={section === "0" || true}
            >
              View Misplaced Inventory
            </CustomButton>
          </div>
          <hr className="my-3" />
          <h2 className="text-xl">Overwrite Inventory Quantities</h2>
          <h3 className="text-red-900 font-bold">IMPORTANT!</h3>
          <p className="max-w-prose">
            This action is irreversible. Make sure that you have follows all of
            the steps on the left hand side before overwriting the inventory.
          </p>
          {/* should really make a safe gaurd that prevents submission when ALL the quantities are 0 */}
          <div className="grid grid-cols-2 gap-x-4 min-w-0 min-h-0">
            <CustomButton
              disabled={section === "0"}
              type="button"
              appendClassName="max-w-xs"
              onClick={async () => {
                if (
                  window.confirm(
                    `Overwrite the inventory in section: ${sectionLabel}?`
                  )
                )
                  fetch(`/api/cursor/inventory/overwrite-delta-quantities`, {
                    method: "PUT",
                  })
                    .then((x) => errorHandler(x.status))
                    .catch((err) => console.error(err));
              }}
            >
              {sectionLabel
                ? `Overwrite Quantities In: ${sectionLabel}`
                : "Overwrite Quantities"}
            </CustomButton>
          </div>
        </div>
        <div className="flex mb-10" />
      </div>
    </div>
  );
};
interface Props2 {
  options: {
    label: string;
    value: string;
  }[];
  name: string;
}
const FormikSelect = ({ options, name }: Props2) => {
  const [field, , helpers] = useField(name);

  return (
    <ReactSelect
      onChange={(value) => {
        //@ts-ignore
        helpers.setValue(value?.value || "0");
      }}
      value={options?.find((value) => value.value === field.value)}
      className="max-w-xs"
      options={options}
    />
  );
};
