import { CgExtensionAdd } from "react-icons/cg";
import { useSelector, shallowEqual } from "react-redux";
import { Istate } from "../../../../../redux";
import { GetArrayFromStatus } from "../../../../constants";
import { NavLi } from "../../../../Menu/NavLi";

export const MenuLogic = () => {
  const stagingBuilder = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex],
    shallowEqual
  );
  const { businessID } = useSelector(
    (state: Istate) => state.data.user,
    shallowEqual
  );

  return (
    <>
      {businessID === 1 && (
        <NavLi
          key={0}
          Icon={CgExtensionAdd}
          label="Transfer Items Into StagingBuilder"
          href="/stage"
          query="left-side=transfer-in"
          target=""
          confirmationText="Are you sure you want to put the items in this StagingBuilder on hold?\n"
        />
      )}
      {[...GetArrayFromStatus(stagingBuilder)]}
    </>
  );
};
