import React from "react";
import { CustomButton } from "../../../HighlyReusableComponents/CustomButton";

interface Props {
  business: BUSINESS;
}

export const MyBusiness = ({ business }: Props) => {
  return (
    <>
      <h2>{business.businessName}</h2>
      <p className="max-w-prose">
        A lot of information about their business could go here
      </p>
      <CustomButton>Edit</CustomButton>
    </>
  );
};
