import React from "react";
import { Field, ErrorMessage, FieldProps, FastField } from "formik";
import TextError from "./TextError";

interface IpropsInput {
  label: React.ReactNode;
  name: string;
  required?: boolean;
  disablefield?: number;
  notsearchable?: number;
  fastInput?: boolean;
  fastSearch?: boolean;
}

// function Input(props: IpropsInput) {
const Input: React.FunctionComponent<IpropsInput> = ({
  fastInput = false,
  fastSearch = false,
  ...props
}) => {
  const { label, name, disablefield, ...rest } = props;

  const disableValue: boolean = disablefield ? true : false;
  return (
    <>
      <label
        style={{ alignSelf: "center", justifySelf: "left" }}
        htmlFor={name}
      >
        {label}
      </label>
      {fastInput ? (
        <FastField
          id={name}
          name={name}
          disabled={disableValue}
          {...rest}
          className="inventory-input"
        />
      ) : (
        <Field
          id={name}
          name={name}
          disabled={disableValue}
          {...rest}
          className="inventory-input"
        />
      )}
      {props.notsearchable ? (
        <div></div>
      ) : fastSearch ? (
        <FastField
          id={`${name}-search`}
          name={`${name}-search`}
          {...rest}
          className="inventory-input"
        />
      ) : (
        <Field
          id={`${name}-search`}
          name={`${name}-search`}
          {...rest}
          className="inventory-input"
        />
      )}
      <ErrorMessage name={name} component={TextError} />
    </>
  );
};

export default Input;
