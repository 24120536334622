import { GiHamburgerMenu } from "react-icons/gi";
import { Wrapper } from "./components/Wrapper";
import { FC } from "react";
import { useNavigation } from "./context/NavigationContext";

export const MobileNav: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { updateSidebarVisibility } = useNavigation();
  return (
    <div>
      <Wrapper>
        <div className="grid items-center">{children}</div>
        <div className="grid items-center absolute right-1">
          <GiHamburgerMenu
            cursor="pointer"
            style={{ height: "46px", width: "46px" }}
            onClick={() => updateSidebarVisibility(true)}
          />
        </div>
      </Wrapper>
    </div>
  );
};
