import { itemMinimal } from "./inventory/inventoryTypes";
import { routes } from "./route/routeTypes";
import { Socket } from "socket.io-client";
export interface SystemState {
  user: User;
  socket?: Socket;
  route: typeof routes;
  stagingBuilders: StagingBuilder[];
  inventory: itemVerbose[];
  filteredInventory: itemVerbose[];
  builderInventory: {
    [itemID: string]: itemMinimal;
  };
  builderInventoryOrder: { itemID: number }[];
  builderInventoryGroups: { [index: string]: number[] };
  lockedBuilderInventoryOrder: { itemID: number }[];
  inventorySearch: {
    [key: string]: {
      keyValue: string | string[] | number | boolean | number[];
    };
  };
  inventoryMeta?: {
    vendors?: {
      vendorID: number;
      vendorName: string;
    }[];
    collections?: {
      collectionID: number;
      collectionName: string;
      vendorID: number;
    }[];
    rooms?: {
      [index: number]: {
        roomName: string;
        roomNo: number;
        catNo: number;
      }[];
    };
  };
  version: string;
}
export const initialReduxState: SystemState = {
  user: {
    loading: false,
    loggedIn: undefined,
    loginFailed: false,
    unauthorizedAccess: false,
    nextNewStagingBuilder: 0,
    activeStagingBuilder: 0,
    activeStagingBuilderIndex: -1,
  },
  socket: undefined,
  route: routes,
  stagingBuilders: [],
  inventory: [],
  filteredInventory: [],
  builderInventory: {},
  builderInventoryOrder: [],
  builderInventoryGroups: {},
  lockedBuilderInventoryOrder: [],
  inventorySearch: {
    showAvail: { keyValue: true },
    showHold: { keyValue: true },
    showStaged: { keyValue: true },
    showOutOfStock: { keyValue: true },
    stagingSite: { keyValue: true },
    MHFOSite: { keyValue: true },
    noSite: { keyValue: true },
    itemNo: { keyValue: "" },
    model: { keyValue: "" },
    ain: { keyValue: "" },
    vendorID: { keyValue: "" },
    collectionID: { keyValue: "" },
    room: { keyValue: "" },
    piece: { keyValue: "" },
    itemName: { keyValue: "" },
    srLocation: { keyValue: [] },
    srColors: { keyValue: [] },
    srStyles: { keyValue: [] },
  },
  version: "210830A",
};

export default SystemState;
