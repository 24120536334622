import { push } from "connected-react-router";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

interface Props {
  barcodeRef: React.RefObject<HTMLInputElement>;
}

export const ScannerForm = ({ barcodeRef }: Props) => {
  const socket = useSelector((state: Istate) => state.data.socket);
  const pricingControl = () => {};
  const dispatch = useDispatch();
  useEffect(() => {
    socket?.off("INVENTORY", pricingControl);
    socket?.on("INVENTORY", pricingControl);
    return () => {
      socket?.off("INVENTORY", pricingControl);
    };
  }, [socket]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      barcodeRef.current?.value === undefined ||
      barcodeRef.current?.value === ""
    )
      return;
    // need to check if it exists
    // if it does push new info, maybe use modal?
    dispatch(
      push(`/scanner/pricing-control?itemNo=${barcodeRef.current.value}`)
    );
    // else display error
    barcodeRef.current.value = "";
  };
  return (
    <form onSubmit={onSubmit}>
      <label htmlFor="barcode">
        Please Scan a Barcode
        <br />
        <input ref={barcodeRef}></input>
      </label>
      <button style={{ display: "none" }} type="submit"></button>
    </form>
  );
};
