import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Istate } from "../../../../../redux";
import "../../../../Prints/print.scss";
import { PrintErrorMessage } from "./PrintErrorMessage";

interface Props {
  setTagErrors: React.Dispatch<React.SetStateAction<boolean>>;
}
export const MHFOTagStandard = ({ setTagErrors }: Props) => {
  const tag: itemVerbose | undefined = useSelector(
    (state: Istate) => state.data.user.activeItem,
    shallowEqual
  );
  let Errors: string[] = [];
  if (tag) {
    if (!tag.pictureA) {
      Errors.push("No Picture");
      setTagErrors(true);
    }
    if (!tag.itemNo) {
      Errors.push("No item Number");
      setTagErrors(true);
    }
    if (!tag.itemName) {
      Errors.push("No item Name");
      setTagErrors(true);
    }
    if (!tag.sellPrice) {
      Errors.push("No MHFO Price");
      setTagErrors(true);
    }
    if (!tag.comparePrice) {
      Errors.push("No ComparePrice");
      setTagErrors(true);
    }
  }
  return tag && !Boolean(Errors.length) ? (
    <div className="mhfo-tag">
      <img
        className="logo"
        src="https://modelhomefurnitureoutlet.com/static_pictures/outlet-logo.png"
        alt="mhfo logo"
      />
      <div className="product-img-container">
        <img className="product" src={tag.pictureA} alt={tag.itemNo} />
      </div>
      <div className={`text-center colored-container type-${tag.tagType}`}>
        <h1 className="white-bg mb-std item-name">{tag.itemName}</h1>
        <h2 className="white-bg mb-std sell-price">
          {tag.sellPrice.toString().split(".")[0]}
          <span className="sell-price__hundredths">
            {tag.sellPrice.toString().split(".")[1]}
          </span>
        </h2>
        {tag.tagDescription ? (
          <h3 className="white-bg mb-std tag-description">
            ${tag.tagDescription}
          </h3>
        ) : (
          <div></div>
        )}
        <div className="tri-split">
          {tag.rentalPrice || tag.rentalRate ? (
            <div className="white-bg rental-info">
              {tag.rentalRate
                ? tag.rentalRate.toString().split(".")[0] +
                  "/" +
                  tag.rentalPrice.toString().split(".")[0]
                : tag.rentalPrice.toString().split(".")[0]}
            </div>
          ) : (
            <div></div>
          )}
          <div className="white-bg compare-price-container mh-5">
            <span>compare at</span>
            <div className="white-bg compare-price">
              {tag.comparePrice.toString().split(".")[0]}
              <span className="compare-price__hundredths">
                {tag.comparePrice.toString().split(".")[1]}
              </span>
            </div>
          </div>
          <div className="white-bg item-number">{tag.itemNo}</div>
        </div>
      </div>
    </div>
  ) : (
    <PrintErrorMessage errors={Errors} />
  );
};
