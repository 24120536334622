import { dateOptions } from "..";

export const GetDate = (
  ts: number | undefined | null | string,
  text: string = "TBD",
  options: Intl.DateTimeFormatOptions = dateOptions
): string => {
  if (Number(ts) !== 0 && ts !== undefined && ts !== null)
    text = new Date(ts).toLocaleDateString("us-AZ", options);

  return text;
};
