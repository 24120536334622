import * as Yup from "yup";

export interface InitialValues {
  userID: string | number;
}

export const formInit = (
  stagingBuilder: StagingBuilder
): {
  initialValues: InitialValues;
  validationSchema: Yup.ObjectSchema<InitialValues | undefined, object>;
} => {
  const validationSchema = Yup.object({
    userID: Yup.number().required(),
  });

  return {
    initialValues: {
      userID: stagingBuilder.userID.toString(),
    },
    validationSchema,
  };
};
