import React from "react";
import FormikControl from "../../../FormikComponents/FormikControl";
import { FormikProps } from "formik";

interface Props {
  FormikProps: FormikProps<any>;
}

export const CustomerPermissions = (props: Props) => {
  return (
    <>
      <h2 className="font-size__125em">Customer Permissions</h2>
      <FormikControl
        control="right-input"
        styledClass="max-width-4em"
        label="Automatically Hold up to # of items"
        name="permitted"
      />
      <FormikControl
        control="right-checkbox"
        label="Can User Create StagingBuilders?"
        name="permitted-newStagingBuilder"
      />
      <FormikControl
        control="right-checkbox"
        label="Can User Login?"
        name="permitted-login"
      />
    </>
  );
};
