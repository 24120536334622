import { FancyCheck } from "../../../../HighlyReusableComponents/FormComponents/Checkbox";
import type { BooleanState } from "../../../../HighlyReusableComponents/hooks/useBooleanState";
interface IV {
  previouslyMore: boolean;
  previouslyLess: boolean;
  previouslyEqual: boolean;
}
interface Props {
  checkState: BooleanState<IV>;
}

export const CheckBoxes = ({ checkState }: Props) => {
  return (
    <>
      <FancyCheck
        name="auto"
        label="Previously More Tagged In Database"
        checked={checkState.values.previouslyMore}
        onClick={() => {
          if (!checkState.values.previouslyMore) {
            checkState.set("previouslyMore", true);
          } else {
            checkState.set("previouslyMore", false);
          }
        }}
      />
      <FancyCheck
        name="auto"
        label="Previously Less Tagged In Database"
        checked={checkState.values.previouslyLess}
        onClick={() => {
          if (!checkState.values.previouslyLess) {
            checkState.set("previouslyLess", true);
          } else {
            if (checkState.values.previouslyLess)
              checkState.set("previouslyLess", false);
          }
        }}
      />
      <FancyCheck
        name="auto"
        label="Same Amount As In Database"
        checked={checkState.values.previouslyEqual}
        onClick={() => {
          if (!checkState.values.previouslyEqual) {
            checkState.set("previouslyEqual", true);
          } else {
            checkState.set("previouslyEqual", false);
          }
        }}
      />
    </>
  );
};
