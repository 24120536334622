import { Formik, FormikHelpers, FormikProps } from "formik";
import { useMemo, FC, ReactNode } from "react";
import { useSelector } from "react-redux";
import { useCalendar } from "../../../../constants/contexts/calendar/CalendarContext";
import * as Yup from "yup";
import { MHFOSubmissionValues } from "../../../../constants/hooks/fetching/useCalendarMHFO";

interface Props {
  children: (props: FormikProps<MHFOSubmissionValues>) => ReactNode;
}
const validationSchema = Yup.object({
  "customer-name": Yup.string().required("Required"),
});
export const MHFODeliveryLogic: FC<Props> = ({ children }) => {
  const date = useSelector((state: Istate) => state.router.location.query.date);
  const {
    mhfo: { createMHFODelivery },
  } = useCalendar();
  const initialValues: MHFOSubmissionValues = useMemo(
    () => ({
      "customer-name": "",
      "task-time": Number(date) || null,
      address1: "",
      address2: "",
      zip: "",
      state: "48",
      city: "",
      "invoice-number": "",
      "task-hours": "",
      "task-description": "",
    }),
    [date]
  );
  const onSubmit = (
    values: typeof initialValues,
    a: FormikHelpers<typeof initialValues>
  ) => {
    if (createMHFODelivery) createMHFODelivery(values, "/calendar");
  };

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {(props: FormikProps<typeof initialValues>) => children(props)}
    </Formik>
  );
};
