import axios from "axios";
import { useFormikContext } from "formik";
import { useRef, useEffect, useState, useMemo } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Istate } from "../../redux";
import { yesno } from "../DropDownLists";
import FormikControl from "../FormikComponents/FormikControl";
import { CustomButton } from "../HighlyReusableComponents/CustomButton";
import { InitialValues } from "./initialValues";
import styled from "styled-components";
import { push } from "connected-react-router";
import { getUsers } from "../Accounts/Permissions";
const StartStagingEditor = styled.div`
  input {
    max-width: 100%;
  }
`;

export const StartStagingBuilderForm = () => {
  const dispatch = useDispatch();
  const { businessID } = useSelector(
    (state: Istate) => state.data.user,
    shallowEqual
  );
  const { isSubmitting, submitForm } = useFormikContext<InitialValues>();
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    []
  );
  const [users, setUsers] = useState<USER_MIN[]>([]);
  useEffect(() => {
    // employees: 0 to have only customers
    if (businessID === 1) getUsers(dispatch, setUsers, { employees: 0 });
  }, [dispatch, businessID]);
  const usersAsSelectObject = useMemo(
    () =>
      users
        .map(({ userID, first, last }) => ({
          label: `${first} ${last}`,
          value: userID.toString(),
        }))
        .sort(({ label: label1 }, { label: label2 }) =>
          (label1 as String).localeCompare(label2)
        ),
    [users]
  );

  const stagingNameRef = useRef<HTMLInputElement>();
  useEffect(() => {
    stagingNameRef?.current?.focus();
    if (businessID === 1)
      axios({
        url: `/api/effects/get/meta-information/free-file-numbers`,
        method: "get",
      })
        .then((res) => {
          try {
            res.data.success &&
              setOptions(
                (
                  res.data.fileNumbers as {
                    isFileFree: boolean;
                    fileNumber: number;
                  }[]
                )
                  .filter(
                    ({ isFileFree, fileNumber }) =>
                      isFileFree && fileNumber !== null
                  )
                  .reverse()
                  .map(({ fileNumber }, index) => ({
                    label:
                      fileNumber?.toString() +
                      (index === 0 ? " (Next Available)" : ""),
                    value: fileNumber?.toString(),
                  }))
              );
          } catch (err) {
            console.error(err);
          }
        })
        .catch((err) => console.error(err));
  }, [businessID]);
  return (
    <StartStagingEditor>
      <div className="grid grid-cols-2 sm:grid-cols-3 mb-1 gap-1">
        <h2
          className="cursor-pointer m-0 link inline-block self-center"
          style={{ fontSize: "1.5rem" }}
          onClick={() => {
            !isSubmitting && submitForm();
          }}
        >
          Skip for now
        </h2>
        <p className="hidden sm:block"></p>
        {businessID === 1 ? (
          <FormikControl
            label="File Number (Employee Only)"
            name="fileNumber"
            control="basic-select"
            styledClass="h-4ch"
            creatable
            options={options}
            disabled={businessID !== 1}
          />
        ) : null}
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-1">
        {businessID !== 1 ? (
          <FormikControl
            innerRef={stagingNameRef}
            label={
              "Name Your Staging (You can use this to search for it later)"
            }
            name="stagingName"
            control="input"
            styledClass="h-4ch"
            controlContainerclass="basic-input sm:col-span-2"
          />
        ) : (
          <FormikControl
            label="Stager's Name"
            name="stagingName"
            control="basic-select"
            styledClass="h-2ch"
            controlContainerclass="sm:col-span-2"
            creatable
            options={usersAsSelectObject}
            disabled={businessID !== 1}
          />
        )}

        <FormikControl
          label="Desired Delivery Date"
          name="deliveryDesired"
          control="date-picker"
          styledClass="h-4ch"
          controlContainerclass="basic-input w-full flex flex-col mt-auto"
          labelClass="text-left"
        />
      </div>
      <AddressFormikForm />
      <div className="grid grid-cols-2 gap-2 mt-3">
        <FormikControl
          label="Should StagingRents Place Items on Hold?"
          name="holdItems"
          control="left-checkbox"
        />
        <FormikControl
          label="Share with others in your Business?"
          name="shared"
          control="left-checkbox"
          options={yesno}
          controlContainerclass="flex space-between start-staging"
          fieldWrapperClass="field-wrapper select table"
          styledClass="h-4ch"
        />
      </div>
      <CustomButton
        className="disabled:cursor-not-allowed btn-pulse bg-logo-purple text-white w-full py-1.5 mt-2 rounded-lg border border-gray-400 focus:outline-none focus:ring"
        disabled={isSubmitting}
        type="button"
        onClick={() => {
          !isSubmitting && submitForm();
        }}
      >
        Start New Staging
      </CustomButton>
      <CustomButton
        appendClassName="sm:hidden"
        onClick={() => {
          dispatch(push("/"));
        }}
      >
        Cancel
      </CustomButton>
    </StartStagingEditor>
  );
};

export const AddressFormikForm = ({
  address1PlaceHolder = "Items cannot be placed on hold without an address",
}) => {
  return (
    <>
      <FormikControl
        controlContainerclass="mt-3 basic-input"
        styledClass="h-4ch"
        control="input"
        label="Address 1"
        name="address1"
        placeholder={address1PlaceHolder}
      />
      <FormikControl
        controlContainerclass="mt-3 basic-input"
        styledClass="h-4ch"
        control="input"
        label="Apt / Suite / Other"
        name="address2"
        placeholder="Suite #, Apt #, etc. "
      />
      <div className="grid sm:grid-cols-3 gap-1 mt-3 col-span-full">
        <FormikControl control="input" label="City" name="city" />
        <FormikControl
          controlContainerclass="control-container w-100p"
          fieldWrapperClass="field-wrapper select"
          styledClass="w-100p h-4ch"
          control="basic-select"
          label="State"
          name="state"
          labelClass="block"
          options={[{ value: "48", label: "Arizona" }]}
        />
        <FormikControl control="input" label="Zip Code" name="zip" />
      </div>
    </>
  );
};
