import React from "react";
import { FormikProps } from "formik";
import FormikControl from "../../../FormikComponents/FormikControl";

interface Props {
  FormikProps: FormikProps<any>;
}

export const EmployeePermissions = ({ FormikProps }: Props) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Permissions for Staff Members</th>
          <th style={{ width: "20%" }}>NonStaff Stagings</th>
          <th style={{ width: "20%" }}>Staff Stagings</th>
        </tr>
      </thead>
      <tbody>
        <FormikControl
          styledClass="ml-25p"
          control="right-double-checkbox-row"
          label="Can Modify Scheduling Dates?"
          name="permitted-dates"
        />
        <FormikControl
          styledClass="ml-25p"
          control="right-double-checkbox-row"
          label="Can Modify Rental Rates?"
          name="permitted-rate"
        />
        <FormikControl
          styledClass="ml-25p"
          control="right-double-checkbox-row"
          label="Can Modify Staging Retail?"
          name="permitted-retail"
        />
        <FormikControl
          styledClass="ml-25p"
          control="right-double-checkbox-row"
          label="Can Modify Staging Builders?"
          name="permitted-stagingBuilders"
        />
        <FormikControl
          styledClass="ml-25p"
          control="right-double-checkbox-row"
          label="Can Delete Staging Builders?"
          name="permitted-stagingBuilders"
        />
        <br />
        <FormikControl
          styledClass="ml-25p"
          control="right-double-checkbox-row"
          label={`Can Modify Stager's "Type"?`}
          name="permitted-type"
          replaceSecondField={<td></td>}
        />
        <FormikControl
          styledClass="ml-25p"
          control="right-double-checkbox-row"
          label={`Can Modify Stager's "Permissions"?`}
          name="permitted-permissions"
          replaceSecondField={<td></td>}
        />

        <br />
        <FormikControl
          styledClass="ml-25p"
          control="right-double-checkbox-row"
          label="Can Modify MHFO Prices?"
          name="permitted-mhfo"
          replaceSecondField={<td></td>}
        />
        <FormikControl
          styledClass="ml-25p"
          control="right-double-checkbox-row"
          label="Can Modify Staging Inventory Levels?"
          name="permitted-staging-inventory"
          replaceSecondField={<td></td>}
        />
        <FormikControl
          styledClass="ml-25p"
          control="right-double-checkbox-row"
          label="Can Modify Scheduling Dates?"
          name="permitted-mhfo-inventory"
          replaceSecondField={<td></td>}
        />
        <FormikControl
          styledClass="ml-25p"
          control="right-double-checkbox-row"
          label={`Can Modify "My Account" for others?`}
          name="permitted-login"
          replaceSecondField={<td></td>}
        />
      </tbody>
    </table>
  );
};
