import * as Yup from "yup";
/**
 * Need to wrap in useMemo before use
 * @param date
 * @returns initialValues for StartStagingBuilder form
 */
export const initialValues = (
  businessID: number | undefined,
  date?: number
) => ({
  fileNumber: "",
  stagingName: "",
  deliveryDesired: date ? date : null,
  address1: "",
  address2: "",
  city: "",
  state: "48",
  zip: "",
  holdItems: businessID === 1 ? true : false,
  shared: true,
});
export type InitialValues = ReturnType<typeof initialValues>;

export const StartStagingSchema = () => {
  return Yup.object({
    fileNumber: Yup.lazy((value) =>
      value === ""
        ? Yup.string()
        : Yup.number()
            .moreThan(8200)
            .typeError("The file number must only contain numbers")
    ),
    stagingName: Yup.string().when("fileNumber", {
      is: (value) => (Number(value) ? value : false),
      then: Yup.string().required("This is required"),
    }),
    deliveryDesired: Yup.number()
      .nullable()
      .when("fileNumber", {
        is: (value) => (Number(value) ? value : false),
        then: Yup.number()
          .moreThan(0, "The current date is invalid")
          .required("This is required"),
      }),
  });
};
