import React from "react";
import { ScannerForm } from "./ScannerForm";

interface Props {
  barcodeRef: React.RefObject<HTMLInputElement>;
}

export const Scanner = ({ barcodeRef }: Props) => {
  return (
    <div>
      <ScannerForm barcodeRef={barcodeRef}></ScannerForm>
    </div>
  );
};
