import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Istate } from "../../../redux";
import { UserInfo } from "./UserInfo";
import { FormLogic } from "./form/FormLogic";
import { Login } from "./Modals";

export const MyAccount = () => {
  const edit = useSelector(
    (state: Istate) => state.router.location.query.edit,
    shallowEqual
  );
  const { user } = useSelector((state: Istate) => state.data, shallowEqual);
  const loggedInAt = user.loggedInAt || 0;
  // 5 mins after last login
  const timeLimit = loggedInAt + 15000;
  const needAuth = Date.now() > timeLimit;
  return (
    <div className="my-account-wrapper">
      <h2 className="text-xl">Update Your Account Info</h2>
      {edit && needAuth && <Login />}
      {edit && !needAuth && <FormLogic user={user} />}
      {(!edit || needAuth) && <UserInfo user={user} />}
    </div>
  );
};
