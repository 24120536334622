import { useEffect, useState, FC, useMemo } from "react";
import styled from "styled-components";
import InventoryListHeader from "./InventoryListHeader";
import InventoryListBody from "./InventoryListBody";
import { InventoryHeader } from "./InventoryHeader/";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector, shallowEqual } from "react-redux";
import { Istate } from "../../redux";
const InventoryListWrapper = styled.div`
  border-radius: 2px;
  min-height: 100px;
  overflow-y: hidden;
  height: inherit;
`;

interface IpropsInventoryListContainer {}
export const InventoryListContainer: FC<IpropsInventoryListContainer> = (
  props
) => {
  const [openPrint, setOpenPrint] = useState(false);
  const filteredInventory = useSelector(
    ({ data: { filteredInventory } }: Istate) => filteredInventory,
    shallowEqual
  );
  const filteredInventoryMemo = useMemo(
    () => Array.from(new Set(filteredInventory.map(({ itemID }) => itemID))),
    [filteredInventory]
  );

  const { length: filteredInventoryLength } = filteredInventoryMemo;
  const [moreItems, setMoreItems] = useState(filteredInventoryLength > 0);

  const [itemsToRender, setItemsToRender] = useState<
    typeof filteredInventoryMemo
  >([]);
  // if there ARE items, more items is true
  const loadMoreItems = () => {
    const numberOfItemsToEndOn =
      itemsToRender.length + 15 < filteredInventoryLength
        ? itemsToRender.length + 15
        : filteredInventoryLength;
    setMoreItems(!(numberOfItemsToEndOn === filteredInventoryLength));
    setItemsToRender(filteredInventoryMemo.slice(0, numberOfItemsToEndOn));
  };

  useEffect(() => {
    // if it is empty add 15 or as many as posible
    if (itemsToRender.length === 0 && filteredInventoryLength > 0) {
      const sliceNumber =
        filteredInventoryLength > 15 ? 15 : filteredInventoryLength;
      setItemsToRender(filteredInventoryMemo.slice(0, sliceNumber));
      setMoreItems(filteredInventoryLength > sliceNumber);
    }
  }, [filteredInventoryMemo, filteredInventoryLength, itemsToRender]);

  useEffect(() => {
    const sliceNumber =
      filteredInventoryLength > 15 ? 15 : filteredInventoryLength;
    setItemsToRender(filteredInventoryMemo.slice(0, sliceNumber));
    setMoreItems(filteredInventoryLength > sliceNumber);
  }, [filteredInventoryMemo, filteredInventoryLength]);

  const [offsetHeight, setOffsetHeight] = useState<number | undefined>(
    undefined
  );

  return (
    <div
      className="p-1 overflow-y-hidden"
      style={{ maxHeight: "97vh", height: "97vh" }}
    >
      <InventoryListWrapper className="relative">
        <InventoryHeader
          setOffsetHeight={setOffsetHeight}
          openPrint={openPrint}
          setOpenPrint={setOpenPrint}
        />
        <InfiniteScroll
          dataLength={itemsToRender.length}
          next={loadMoreItems}
          hasMore={moreItems}
          scrollableTarget="item-scroll-target"
          loader={<></>}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>No More Items Try Searching for Something Else</b>
            </p>
          }
        >
          <table className="inventory-table mt-1">
            <thead>
              <InventoryListHeader />
            </thead>
            <tbody
              style={{
                height: `calc(97vh - ${offsetHeight || 0}px - 2rem)`,
              }}
              id="item-scroll-target"
            >
              <InventoryListBody itemsToRender={itemsToRender} />
            </tbody>
          </table>
        </InfiniteScroll>
      </InventoryListWrapper>
    </div>
  );
};
