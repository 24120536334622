import { useMemo } from "react";
import { BlockContainer } from "./BlockContainer";
import { BlockBackDrop } from "./BlockBackDrop";
import { RxMagicWand } from "react-icons/rx";
import { Link } from "react-router-dom";
import { capitalizeFirstChars } from "./Address";
import type { FC } from "react";

export const StagerBlock: FC<Pick<StagingBuilder, "stagerName">> = ({
  stagerName,
}) => {
  const { first, last } = useMemo(() => {
    const [first, ...rest] = stagerName?.split(" ") || [];
    return {
      first: capitalizeFirstChars(first || "missing"),
      last: capitalizeFirstChars(rest?.join(" ") || "stager info"),
    };
  }, [stagerName]);
  return (
    <Link
      className="col-start-1 row-start-2 block h-full lg:col-start-auto lg:row-span-2 lg:row-start-auto lg:h-[158px]"
      to={{
        pathname: "/stage",
        search: "open-modal=stager-info",
      }}
    >
      <BlockContainer>
        <div className="flex-col pb-2">
          <h2>Designed By</h2>
          <div className="flex">
            <div className="my-auto mr-4 grid h-8 w-8 place-items-center rounded-full">
              <RxMagicWand size={32} color="var(--logo-purple)" />
            </div>
            <BlockBackDrop />
            <div className="flex flex-col">
              <span className="text-lg font-medium leading-normal">
                <span>{first || null}</span>
              </span>
              <span className="text-gray-700">{last || null}</span>
            </div>
          </div>
        </div>
      </BlockContainer>
    </Link>
  );
};
