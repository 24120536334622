import React from "react";

function InventoryListHeader() {
  // TODO: add sort by click
  if (process.env.NODE_ENV === "development")
    console.warn(
      "Need to add sort by click to /Components/Inventory/InventoryListHeader.tsx"
    );
  return (
    <tr className="border-b border-gray-300" style={{ lineHeight: "14px" }}>
      <th>Picture</th>
      <th>Item #</th>
      <th>Quantity</th>
      <th>Grouped</th>
      <th>Retail</th>
      <th>Rate</th>
      <th>item Name</th>
    </tr>
  );
}

export default React.memo(InventoryListHeader);
