import React, { useCallback } from "react";
import { Link, LinkProps } from "react-router-dom";

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  linkProps?: React.RefAttributes<HTMLAnchorElement> & LinkProps<unknown>;
  children?: React.ReactNode;
  className?: string;
  disabledStyle?: React.CSSProperties;
  appendClassName?: string;
}
/**
 *
 * @param className "btn-pulse bg-logo-blue w-full py-1.5 mt-2 rounded-lg border border-gray-400 focus:outline-none focus:ring "
 * @returns a button
 * @todo
 * 1) Add tool tip
 * 2) Add tool tip saying "too many items to print" to inventory/inventoryHeader/index.tsx
 */
export const CustomButton = ({
  children,
  className = "btn-pulse bg-logo-blue w-full py-1.5 my-2 mb-0 rounded-lg border border-gray-400 focus:outline-none focus:ring disabled:opacity-50 ",
  appendClassName = "",
  disabled,
  style,
  disabledStyle = {},
  linkProps,
  ...props
}: Props) => {
  const combinedStyles = useCallback(() => {
    return disabled ? { ...style, ...disabledStyle } : { ...style };
  }, [disabled]);

  return linkProps ? (
    <Link className="flex w-full" {...linkProps}>
      <button
        disabled={disabled}
        style={combinedStyles()}
        className={className + appendClassName}
        {...props}
      >
        {children}
      </button>
    </Link>
  ) : (
    <button
      disabled={disabled}
      style={combinedStyles()}
      className={className + appendClassName}
      {...props}
    >
      {children}
    </button>
  );
};
