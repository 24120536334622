import React from "react";

interface Props {
  showTrash?: boolean;
  setShowTrash?: React.Dispatch<React.SetStateAction<boolean>>;
}
export const ShowTrashedItems = ({ showTrash, setShowTrash }: Props) => {
  const toggleTrash = () => {
    setShowTrash && setShowTrash(!showTrash);
  };
  return (
    <button
      type="button"
      className="btn btn-item-container p-1"
      onClick={() => toggleTrash()}
    >
      {showTrash ? "Hide" : "Show"} Removed Items
    </button>
  );
};
