import { ToastyGreen } from "./ToastyGreen";
import { ToastyRed } from "./ToastyRed";

export function ToastySuccess(
  status: number,
  content: string,
  success: boolean = true
) {
  if (success) {
    switch (status) {
      case 200:
        ToastyGreen(content);
        break;
      case 201:
        ToastyGreen(content);
        break;
      default:
        ToastyGreen("Success");
    }
  } else {
    ToastyRed(content);
  }
}
