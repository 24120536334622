import { push } from "connected-react-router";
import React from "react";
import { useDispatch } from "react-redux";
import { AppThunk } from "../../../redux";

interface Props {
  user: User;
}

export const UserInfo = ({ user }: Props) => {
  const dispatch: AppThunk = useDispatch();

  const editUser = () => {
    dispatch(push("/accounts/my-account?edit=true"));
  };
  return (
    <div className="grid col-2-auto mt-4">
      <h3 className="m-0 text-bold">Name:</h3>
      <span>{`${user.first} ${user.last}`}</span>
      <h3 className="m-0 text-bold">Email:</h3>
      <span>{user.email}</span>
      <h3 className="m-0 text-bold">Phone:</h3>
      <span>{user.phone}</span>
      <h3 className="m-0 text-bold">Business: </h3>
      <span>Coming Soon...</span>
      <button
        className="col-span-full bg-logo-blue w-full py-1.5 mt-2 rounded-lg border border-gray-400 focus:outline-none focus:ring"
        onClick={editUser}
        type="button"
      >
        Edit Your Info
      </button>
    </div>
  );
};
