import axios from "axios";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { fetchingData, newStagingBuilder } from "../..";
import {
  fetchingDataFailure,
  fetchingDataSuccess,
} from "../../users/userActions";
import { InitialValues } from "../../../components/StartStagingBuilder/initialValues";
import { ToastyRed } from "../../../components/constants";
import { fetch_full_StagingBuilder } from "../stagingBuilderActions";
import { clearActiveStagingBuilder } from "./clearActiveStagingBuilder";

export const startStagingBuilder = (
  data: InitialValues
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: Function
  ): Promise<void> => {
    const {
      data: {
        user: { businessID },
      },
    } = getState() as Istate;
    dispatch(fetchingData()); // active loading sign
    dispatch(clearActiveStagingBuilder());
    axios({
      method: "POST",
      url: "/api/form/staging-builder/create",
      data,
    })
      .then((res) => {
        dispatch(newStagingBuilder(res.data.stagingBuilder));
        return res;
      })
      .then((res) => {
        const path: true | string =
          res.data.stagingBuilder.fileNumber && businessID
            ? "/stage?open-modal=generic-hold-tags"
            : true;
        dispatch(
          fetch_full_StagingBuilder(
            { stagingBuilderID: res.data.stagingBuilder.stagingBuilderID },
            true,
            path
          )
        );
        dispatch(fetchingDataSuccess(res.status, res.data.message));
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          ToastyRed(err.response.data.message);
          return dispatch(fetchingDataFailure());
        }
        if (err.response)
          return dispatch(fetchingDataFailure(err.response.status));
        return dispatch(fetchingDataFailure());
      });
  };
};
