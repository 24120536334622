import React from "react";
import styled from "styled-components";

const TitleGrid = styled.div`
  position: sticky;
  top: 40px;
  // grid-template-columns: 8ch 18ch repeat(4, 1fr) 4ch;
  grid-template-columns: 52px 48px 66px 90px 1fr 70px 56px;
  place-items: center;
  font-size: 12px;
  padding: 0 8px 0 88px;
  background-color: var(--back-drop-light);
`;
const Title = styled.h3`
  margin: 0;
  padding: 8px;
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
`;
const DraggableInventoryHeader = React.memo(
  function DraggableInventoryHeader() {
    return (
      <TitleGrid className="hidden lg:grid xl:gap-6 2xl:ml-6">
        <Title>Avail</Title>
        <Title>Rate</Title>
        <Title>Retail</Title>
        <Title>Dimensions</Title>
        <Title>Name</Title>
        <Title>item #</Title>
        <Title>Fav</Title>
      </TitleGrid>
    );
  }
);
const DraggableInventoryHeader2 = React.memo(
  function DraggableInventoryHeader2() {
    return (
      <thead>
        <tr>
          <th></th>
          <th>Avail.</th>
          <th>Rate</th>
          <th>Retail</th>
          <th>Name</th>
          <th>item #</th>
          <th style={{ textAlign: "right" }}>Fav.</th>
        </tr>
      </thead>
    );
  }
);

export { DraggableInventoryHeader, DraggableInventoryHeader2 };
