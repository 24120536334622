import React, { FC } from "react";
import Modal from "react-modal";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { FormContainer } from "./FormContainer";
import { Route } from "react-router-dom";
const modalStyle: React.CSSProperties = {
  inset: "23vh 28vw",
  height: "min-content",
  maxHeight: "94vh",
  overflowY: "auto",
};

export const StartStagingModal = () => {
  const dispatch = useDispatch();

  const reqClose = () => {
    dispatch(push("/"));
  };
  return (
    <Route path="/start-staging">
      <Modal style={{ content: modalStyle }} isOpen onRequestClose={reqClose}>
        <StartStagingWrapper>
          <h1 className="text-center sm:text-left text-2xl sm:text-3xl">
            Start A New StagingBuilder
          </h1>
        </StartStagingWrapper>
      </Modal>
    </Route>
  );
};

/**
 * StartStagingWrapper can accept children that are placed BEFORE the FormContainer element
 *
 * This is was dont to have a dynamic header
 * @returns the start staging builder jsx without the surrounding modal
 */
export const StartStagingWrapper: FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  return (
    <div className="relative w-full h-min ">
      {children}
      <FormContainer />
    </div>
  );
};
