import { useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useFormikContext } from "formik";
import { useDispatch } from "react-redux";
import { AppThunk, updateItemContainerNames } from "../../redux";
import { StagingBuilderContainer } from "../../redux/stagingBuilder/stagingBuilderTypes";

export function AutoSave() {
  const formik = useFormikContext<StagingBuilder>();
  const dispatch: AppThunk = useDispatch();

  const debouncedSubmitCaller = useDebouncedCallback((ctx: typeof formik) => {
    if (ctx.isValid) {
      const containerInfo = ctx.values.itemContainers.map(
        (container: StagingBuilderContainer) => {
          return {
            stagingBuilderID: container.stagingBuilderID,
            itemContainerID: container.itemContainerID,
            containerName: container.containerName,
          };
        }
      );
      containerInfo.length && dispatch(updateItemContainerNames(containerInfo));
    }
  }, 500);

  useEffect(() => {
    if (formik.isValid && formik.dirty && !formik.isSubmitting) {
      debouncedSubmitCaller.callback(formik);
    }
  }, [debouncedSubmitCaller, formik]);

  return null;
}
