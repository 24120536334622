import { Formik, FormikHelpers, FormikProps } from "formik";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { PromptIfDirty } from "../../../../FormikComponents/PromptIfDirty";

export interface InitialValues {
  customer: string;
  fileNumber: number | null;
  delivery: number | null;
  community: string;
  comments: string;
  "package-details": string;
  "tv-32": number | "";
  "tv-42": number | "";
  "tv-50": number | "";
  "tv-55": number | "";
  "bedroom-1": "0" | "1" | "2" | "3" | "4" | "5" | "6";
  "bedroom-2": "0" | "1" | "2" | "3" | "4" | "5" | "6";
  "bedroom-3": "0" | "1" | "2" | "3" | "4" | "5" | "6";
  "bedroom-4": "0" | "1" | "2" | "3" | "4" | "5" | "6";
  "bedroom-5": "0" | "1" | "2" | "3" | "4" | "5" | "6";
  state: "31" | "48";
}

interface Props {
  children: (props: FormikProps<InitialValues>) => React.ReactNode;
}

export const DeliveryLogic: React.FC<Props> = ({ children }) => {
  const date = useSelector((state: Istate) => state.router.location.query.date);
  const initialValues = useMemo(
    (): InitialValues => ({
      fileNumber: null,
      delivery: Number(date) || null,
      comments: "",
      "package-details": "",
      state: "48",
      community: "",
      customer: "",
      "tv-32": "",
      "tv-42": "",
      "tv-50": "",
      "tv-55": "",
      "bedroom-1": "0",
      "bedroom-2": "0",
      "bedroom-3": "0",
      "bedroom-4": "0",
      "bedroom-5": "0",
    }),
    [date]
  );
  const onSubmit = (
    values: typeof initialValues,
    a: FormikHelpers<typeof initialValues>
  ) => {
    console.log({ values, a });
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {(props: FormikProps<typeof initialValues>) => (
        <>
          <PromptIfDirty />
          {children(props)}
        </>
      )}
    </Formik>
  );
};
