import { ReactNode } from "react";
import { CalendarEventErrors, CalendarEventTypes } from ".";
import { EventProps } from "..";
import { addBusinessDays, addDays } from "date-fns";
import {
  calculateDistance,
  dateOptionsShortVerbose,
  GetDate,
  LocaleNumber,
  oneDecimal,
  StagingBuilderStatuses,
} from "../../constants";
interface Props {
  stagingBuilder: StagingBuilder;
  length: number;
  index: number;
}
export const stagingEvent = ({
  stagingBuilder: sb,
  length,
  index,
}: Props): EventProps | undefined => {
  let className: string = "";
  let toolTipErrorText = "";
  let errorType = CalendarEventErrors.NO_ERROR;

  const isLimbo = [
    StagingBuilderStatuses.LIMBO_HOLD,
    StagingBuilderStatuses.LIMBO_RELEASE,
    StagingBuilderStatuses.LIMBO_REQUEST,
    StagingBuilderStatuses.LIMBO_REQUEST_HOLD,
  ].includes(sb.stagingStatus);
  const isDFT =
    sb.stagingStatus === StagingBuilderStatuses.DEAL_FELL_THROUGH ||
    sb.stagingStatus === StagingBuilderStatuses.DEAL_FELL_THROUGH_REQUEST;
  const isPaid =
    !(sb.stagingStatus < StagingBuilderStatuses.PAID) &&
    sb.stagingStatus !== StagingBuilderStatuses.PROPOSAL_FELL_THROUGH;

  if (
    !isPaid &&
    Number(sb.deliveryActual) &&
    Number(sb.deliveryActual) < addBusinessDays(Date.now(), 2).getTime()
  ) {
    className = "blink-red";
    toolTipErrorText += "\nThis staging has not been marked as paid";
  }

  if (
    addDays(Number(sb.deliveryActual), 1).getTime() < Date.now() &&
    sb.stagingStatus < StagingBuilderStatuses.STAGED
  ) {
    className = "blink-red";
    toolTipErrorText +=
      "\nThis staging's delivery date has passed and has not been marked as Staged.";
  }

  if (
    index > length &&
    addDays(Number(sb.pickupDesired || sb.pickupActual), 1).getTime() <
      Date.now() &&
    sb.stagingStatus !== StagingBuilderStatuses.CLOSED
  ) {
    className = "blink-red";
    toolTipErrorText +=
      "\nThis staging's pickup date has passed and the inventory has not been marked as returned.";
  }

  const returnObject: Partial<EventProps> & {
    toolTip: (e: EventProps) => string;
  } = {
    toolTip: (e) => {
      let returnString = "";
      returnString += isLimbo ? `Staging is in Limbo\n` : "";
      returnString +=
        (!sb.pickupActual &&
          sb.pickupDesired &&
          e.type === CalendarEventTypes.STAGING_DOWN) ||
        (!sb.deliveryActual &&
          sb.deliveryDesired &&
          e.type === CalendarEventTypes.STAGING_UP)
          ? `Stager Requested This Date\nWe Need To Respond\n\n`
          : "";

      returnString += `Stager: ${sb.stagerName}\n` || "";
      returnString += sb.clientName ? `Client: ${sb.clientName}\n` : "";
      returnString += `${sb.address}\n` || "";
      returnString += `Items: ${sb.items || 0}\n` || "";

      returnString +=
        `Delivery: ${GetDate(
          sb.deliveryActual || sb.deliveryDesired || 0,
          undefined,
          dateOptionsShortVerbose
        )}\n` || "";
      returnString +=
        `Pick-Up: ${GetDate(
          sb.pickupActual || sb.pickupDesired || 0,
          undefined,
          dateOptionsShortVerbose
        )}\n` || "";

      if (toolTipErrorText) returnString += `${toolTipErrorText}\n`;

      returnString +=
        `\nCreated: ${GetDate(
          sb.createdOn,
          undefined,
          dateOptionsShortVerbose
        )}\n` || "";

      if (returnString === "") return e.title as string;
      return returnString;
    },
  };

  if (index < length) {
    let title: string | ReactNode = `HS-${sb.fileNumber} SU`;

    title = isLimbo ? `HS-${sb.fileNumber} SL` : title;
    title = isDFT ? `HS-${sb.fileNumber} DFT` : title;
    let priceColor = "white";
    if (!(isLimbo || isDFT) && !isPaid) priceColor = "maroon";
    if (isLimbo) priceColor = "black";
    title = (
      <p className="flex justify-between">
        <span>{title}</span>
        <span>
          {oneDecimal(
            Math.round(
              Number(sb.monthlyRate) / 115 +
                ((calculateDistance({ lat: sb.lat, lng: sb.lng }) || 0) *
                  1.4 *
                  2 *
                  2 *
                  60) /
                  35 /
                  60
            ) / 2
          )}
        </span>
        <span
          style={{
            color: priceColor,
          }}
        >
          ${LocaleNumber(sb.monthlyRate || 0)}/mo
        </span>
      </p>
    );

    let backgroundColor = sb.deliveryActual ? "var(--logo-purple)" : "orange";

    let color = "white";
    backgroundColor = isLimbo ? "yellow" : backgroundColor;
    backgroundColor = isDFT ? "black" : backgroundColor;

    color = isLimbo ? "black" : color;

    return sb.deliveryActual || sb.deliveryDesired
      ? {
          ...returnObject,
          id: `SU-${sb.stagingBuilderID}`,
          title,
          type: CalendarEventTypes.STAGING_UP,
          allDay: true,
          start: new Date(sb.deliveryActual || sb.deliveryDesired || 0),
          end: new Date(sb.deliveryActual || sb.deliveryDesired || 0),
          styles: {
            backgroundColor,
            color,
          },
          domAttributes: { className },
          errorType,
        }
      : undefined;
  }
  // implicit else Start of staging downs
  let title = (
    <p className="flex justify-between">
      {`HS-${sb.fileNumber} SD`}
      <span>
        {oneDecimal(
          Math.round(
            Number(sb.monthlyRate) / 115 +
              ((calculateDistance({ lat: sb.lat, lng: sb.lng }) || 0) *
                1.4 *
                2 *
                2 *
                60) /
                35 /
                60
          ) / 2
        )}
      </span>
      <span>${LocaleNumber(sb.monthlyRate || 0)}/mo</span>
    </p>
  );

  return sb.pickupActual || sb.pickupDesired
    ? {
        ...returnObject,
        id: `SD-${sb.stagingBuilderID}`,
        type: CalendarEventTypes.STAGING_DOWN,
        title,
        allDay: true,
        start: new Date(sb.pickupActual || sb.pickupDesired || 0),
        end: new Date(sb.pickupActual || sb.pickupDesired || 0),
        styles: {
          backgroundColor: "var(--logo-blue)",
        },
        domAttributes: { className },
        errorType,
      }
    : undefined;
};
