import { InputHTMLAttributes, useEffect, useState } from "react";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean;
  name: string;
  label: string;
  classes?: {
    wrapper?: string;
    container?: string;
  };
  disabled?: boolean;
}

export const FancyCheck = ({
  name,
  label,
  checked = false,
  classes,
  ...inputProps
}: Props) => {
  const [isChecked, setIsChecked] = useState(checked);
  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);
  return (
    <div className={classes?.wrapper}>
      <div className={classes?.container || "flex items-center mr-4 mb-2"}>
        <input
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setIsChecked((current) => !current);
            }
          }}
          className="opacity-0 absolute h-8 w-8 cursor-pointer"
          type="checkbox"
          onClick={() => setIsChecked((current) => !current)}
          {...inputProps}
        />
        <div className="bg-white border-2 rounded-md border-blue-400 w-8 h-8 flex flex-shrink-0 justify-center items-center mr-2 ">
          <svg
            className={`fill-current w-3 h-3 text-logo-blue pointer-events-none
                ${isChecked ? " block" : " hidden"}
              `}
            version="1.1"
            viewBox="0 0 17 12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="none" fillRule="evenodd">
              <g
                transform="translate(-9 -11)"
                fill="#1F73F1"
                fillRule="nonzero"
              >
                <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
              </g>
            </g>
          </svg>
        </div>
        <label htmlFor={name} className="select-none">
          {label}
        </label>
      </div>
    </div>
  );
};
