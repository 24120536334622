import React from "react";
import { FieldProps } from "formik";

interface IpropsError {
  className?: string;
  component?: string | React.ReactElement<FieldProps>;
  children?: React.ReactNode;
  render?: (errorMessage: string) => React.ReactNode;
}
const TextError: React.FunctionComponent<IpropsError> = (props) => {
  return (
    <div style={{ gridColumn: "1/-1" }} className="form-error-text">
      {props.children}
    </div>
  );
};

export default TextError;
