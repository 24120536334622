import { push } from "connected-react-router";
import { Formik, FormikHelpers, FormikProps } from "formik";
import React, { useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useCalendar } from "../../../../constants/contexts/calendar/CalendarContext";
import { TaskSubmissionValues } from "../../../../constants/hooks/fetching/useCalendarTasks";

interface Props {
  children: (props: FormikProps<TaskSubmissionValues>) => React.ReactNode;
}

export const EditImportantTaskLogic: React.FC<Props> = ({ children }) => {
  const dispatch = useDispatch();
  const task = useSelector(
    (state: Istate) => state.router.location.query.task,
    shallowEqual
  );

  const {
    tasks: { events, updateTask },
  } = useCalendar();

  const initialValues: TaskSubmissionValues = useMemo(() => {
    const event = events.find(({ taskID }) => taskID === Number(task));

    if (event === undefined) {
      dispatch(push("/calendar"));
      return {
        "task-time": null,
        hours: "",
        "task-description": "",
        title: "",
      };
    }

    return {
      "task-time": event?.taskStart,
      hours: event?.taskHours.toString(),
      "task-description": event?.taskDetails,
      title: event?.taskTitle,
    };
  }, [events, task, dispatch]);
  const onSubmit = (
    values: typeof initialValues,
    a: FormikHelpers<typeof initialValues>
  ) => {
    if (values.title === "")
      values.title =
        values["task-description"].match(/(\w| ){0,25}(\n|$)/gi)?.[0] || "";

    if (updateTask) updateTask({ ...values, taskID: task }, "/calendar");
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {(props: FormikProps<typeof initialValues>) => children(props)}
    </Formik>
  );
};
