import type { FC } from "react";

interface Props {
  children: React.ReactNode;
}

export const BlockContainer: FC<Props> = ({ children }) => {
  return (
    <div className="shadow-logo-blue relative flex h-full rounded-3xl bg-white p-4 shadow-md drop-shadow-lg">
      {children}
    </div>
  );
};
