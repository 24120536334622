import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import * as Yup from "yup";
import { AppThunk, Istate } from "../../../../../redux";
import { updateStagingInfo } from "../../../../../redux/stagingBuilder/stagingBuilderActions";
import { PromptIfDirty } from "../../../../FormikComponents/PromptIfDirty";
import { StagingInformationEditor } from "./StagingInformationEditor";

interface Props {
  deliveryFee: number | null;
}

export const StagingInformation = ({ deliveryFee }: Props) => {
  const dispatch: AppThunk = useDispatch();
  const stagingInformation = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[state.data.user.activeStagingBuilderIndex],
    shallowEqual
  );

  const initialValues = {
    stagingStatus: stagingInformation.stagingStatus,
    deliveryDesired: stagingInformation.deliveryDesired || null,
    pickupDesired: stagingInformation.pickupDesired || null,
    // deliveryActual: stagingInformation.deliveryActual || null,
    // pickupActual: stagingInformation.pickupActual || null,
    fee1: stagingInformation.fee1 || deliveryFee,
    fee2: stagingInformation.fee2,
    fee3: stagingInformation.fee3,
    fee4: stagingInformation.fee4,
    fee5: stagingInformation.fee5,
    fee6: Boolean(stagingInformation.fee6),
    taxExempt: Boolean(stagingInformation.taxExempt),
    address1: stagingInformation.address1 || "",
    address2: stagingInformation.address2 || "",
    city: stagingInformation.city || "",
    state: stagingInformation.state || "48",
    zip: stagingInformation.zip || "",
    lockBoxCode: stagingInformation.lockBoxCode || "",
    occupied: stagingInformation.occupied ? true : false,
    clientName: stagingInformation.clientName || "",
    clientEmail: stagingInformation.clientEmail || "",
    clientPhone: stagingInformation.clientPhone || "",
    selfSign: Boolean(stagingInformation.selfSign),
  };

  const validationSchema = Yup.object({
    selfSign: Yup.boolean(),
    address1: Yup.string(),
    address2: Yup.string(),
    city: Yup.string(),
    state: Yup.number(),
    zip: Yup.string(),
    clientName: Yup.string(),
    clientPhone: Yup.string()
      .min(10, "Too Short")
      .matches(
        /^(\+1)?( |-)?\(?\d{3}\)?( |-)?\d{3}( |-)?\d{4}$/,
        "Incorrect Phone Number Format"
      ),
    clientEmail: Yup.string().email("Invalid email format"),
  });
  const onSubmit = (
    values: typeof initialValues,
    actions: FormikHelpers<typeof initialValues>
  ) => {
    dispatch(updateStagingInfo({ formData: values, actions }));
  };
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validateOnBlur={true}
      >
        {(FormikProps: FormikProps<any>) => (
          <Form>
            <PromptIfDirty />
            <StagingInformationEditor FormikProps={FormikProps} />
          </Form>
        )}
      </Formik>
    </div>
  );
};
