import * as Yup from "yup";

export interface InitialValues {
  pickupDesired?: number | null;
  pickupActual?: number | null;
}

interface returnValues {
  initialValues: InitialValues;
  validationSchema: Yup.ObjectSchema<InitialValues | undefined | null, object>;
}

export const formInit = (stagingBuilder: StagingBuilder): returnValues => {
  const validationSchema = Yup.object({
    pickupActual: Yup.number().nullable(),
    pickupDesired: Yup.number().nullable(),
  });

  return {
    initialValues: {
      pickupActual: stagingBuilder.pickupActual || null,
      pickupDesired: stagingBuilder.pickupDesired || null,
    },
    validationSchema,
  };
};
