import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import { socketDateChange } from "../../../redux/stagingBuilder/actions/handleSocketDateChange";

interface Props {
  selectedDate: Date | undefined;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  disabled?: boolean;
  backDate?: boolean;
  shouldUpdateDataBase?: {
    stagingBuilder: boolean;
    key: "deliveryActual" | "deliveryDesired";
  };
}

export const DateComponent = ({
  disabled = false,
  backDate = true,
  selectedDate,
  setSelectedDate,
  shouldUpdateDataBase,
}: Props) => {
  const dispatch = useDispatch();
  const onChangeHandler = (date: any) => {
    setSelectedDate(date);
    if (shouldUpdateDataBase?.stagingBuilder) {
      dispatch(
        socketDateChange({
          payload: {
            stagingBuilder: { [shouldUpdateDataBase.key]: Date.parse(date) },
          },
        })
      );
    }
  };
  return (
    <DatePicker
      disabled={disabled}
      selected={selectedDate}
      onChange={onChangeHandler}
      filterDate={(date) => {
        const day = date.getDay();
        return day !== 0;
      }}
      minDate={backDate ? null : new Date()}
      dateFormat="eeee MMMM d, yyyy"
      isClearable={!disabled}
      scrollableMonthYearDropdown
      showDisabledMonthNavigation
      popperModifiers={{
        preventOverflow: {
          enabled: true,
        },
      }}
    />
  );
};
