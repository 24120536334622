import axios from "axios";
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import React, { useEffect, useRef, useMemo, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Istate } from "../../../redux";
import { CustomButton } from "../../HighlyReusableComponents/CustomButton";
import { PrintInventoryModal } from "./PrintInventoryModal";
import { SearchInventory } from "./SearchInventory";

export type sortTypes = "itemID" | "rentalPrice" | "mValue" | "rentalRate";
interface Props {
  openPrint: boolean;
  setOpenPrint: React.Dispatch<React.SetStateAction<boolean>>;
  setOffsetHeight: React.Dispatch<React.SetStateAction<number | undefined>>;
}
export const InventoryHeader = ({
  openPrint,
  setOpenPrint,
  setOffsetHeight,
}: Props) => {
  const filteredInventory = useSelector(
    (state: Istate) => state.data.filteredInventory,
    shallowEqual
  );
  const { pathname } = useSelector(
    (state: Istate) => state.router.location,
    shallowEqual
  );
  const { room, piece } = useSelector((state: Istate) => {
    return {
      room: state.data.inventorySearch?.room,
      piece: state.data.inventorySearch?.piece,
    };
  }, shallowEqual);
  const itemIDArray = useMemo(
    () => filteredInventory.map((item) => item.itemID),
    [filteredInventory]
  );

  const formikRef = React.useRef<FormikProps<any>>();
  const submitFormikForm = () => {
    formikRef?.current?.submitForm();
  };

  const measuredRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (measuredRef !== null) {
      setOffsetHeight(measuredRef?.current?.getBoundingClientRect().height);
    }
  }, [measuredRef]);

  const initialValues = useMemo(
    () => ({
      itemIDs: JSON.stringify(itemIDArray),
      queueName: JSON.stringify({ room, piece }),
      sort: "itemID" as sortTypes,
    }),
    [itemIDArray, room, piece]
  );
  const onSubmit = async (values: typeof initialValues) => {
    const data = await axios({
      method: "post",
      url: "/api/print/inventory/html",
      data: values,
    }).then((res) => res.data);
    const myWindow = window.open("", "_blank");
    if (myWindow) {
      myWindow.document.write(data);
      myWindow.focus();
    }
  };
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      enableReinitialize
    >
      {({ submitForm }) => (
        <div ref={measuredRef} className="grid col-5 gap-1">
          <div style={{ gridColumn: "1/5" }}>
            <SearchInventory
              measuredRef={measuredRef}
              setOffsetHeight={setOffsetHeight}
              formikRef={formikRef}
            />
          </div>
          <Field name="itemIDs" type="hidden" />
          <Field name="queueName" type="hidden" />
          <div>
            <CustomButton
              className="bg-logo-blue w-full py-1.5 mt-2 rounded-lg border border-gray-400 focus:outline-none focus:ring"
              type="button"
              linkProps={{
                to: {
                  pathname,
                  search: "open-modal=print-inventory-config",
                },
              }}
            >
              Print Current <br />({filteredInventory.length} items)
            </CustomButton>
            <PrintInventoryModal
              printLength={filteredInventory.length}
              openPrint={openPrint}
              submitForm={submitForm}
            />
            <button
              className="bg-logo-blue w-full py-1.5 mt-2 rounded-lg border border-gray-400 focus:outline-none focus:ring"
              type="button"
              onClick={submitFormikForm}
            >
              Search
            </button>
          </div>
        </div>
      )}
    </Formik>
  );
};
