import React, { createRef } from "react";

import { AppThunk, handleInventoryEmployeeScan } from "../../../redux";
import { useDispatch } from "react-redux";

const scannerOptions = [
  { id: 0, text: "Add Inventory" },
  { id: 1, text: "Remove Inventory" },
];

interface Iprops {
  barcodeRef: React.RefObject<HTMLInputElement>;
  setColorFeedback: React.Dispatch<React.SetStateAction<string>>;
  location: INVENTORY_LOCATIONS;
}
export const ScannerForm = ({
  barcodeRef,
  setColorFeedback,
  location,
}: Iprops) => {
  const dispatch: AppThunk = useDispatch();
  const typeRef = createRef<HTMLSelectElement>();
  const [initialValues, newInitialValues] = React.useState({
    barcode: "",
    scanType: 0,
  });
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    // need to make sure all of the MySQL not null fields are filled out
    // make an easy to submit object
    e.preventDefault();
    let scanType = "";
    if (typeRef.current?.value === undefined) return;
    if (
      barcodeRef.current?.value === undefined ||
      barcodeRef.current?.value === ""
    )
      return;

    switch (~~typeRef.current?.value) {
      case 0:
        scanType = "add";
        break;
      case 1:
        scanType = "remove";
        break;
      default:
        scanType = "";
        break;
    }
    const valuesToSubmit = {
      barcode: barcodeRef.current.value,
      type: scanType,
      location: location,
    };
    // scan type becomes part of the url
    dispatch(handleInventoryEmployeeScan(valuesToSubmit));
    setColorFeedback("MediumSeaGreen");
    barcodeRef.current.value = "";
  };

  const changeScanType = () => {
    return newInitialValues((current) => {
      return {
        barcode: "",
        scanType: 1 - current.scanType,
      };
    });
  };
  return (
    <form onSubmit={onSubmit}>
      <div
        onClick={() => {
          changeScanType();
        }}
      >
        <select
          ref={typeRef}
          value={initialValues.scanType}
          name="scanType"
          id="scanType"
          className="w-full"
        >
          {scannerOptions.map((option) => (
            <option value={option.id}>{option.text}</option>
          ))}
        </select>
      </div>
      <br />
      <br />
      <br />
      <label htmlFor="barcode">
        Please Scan a barcode
        <br />
        <input ref={barcodeRef}></input>
      </label>
      <button style={{ display: "none" }} type="submit"></button>
    </form>
  );
};
