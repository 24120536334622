import React, { ReactElement } from "react";
import Input from "./Input";
import { RightInput } from "./RightInput";
import InventoryInput from "./InventoryInput";

import "./FormStyles.scss";
import InventorySelect from "./InventorySelect";
import { MultiSelectInventory } from "./MultiSelect";
import { InventoryCheckbox } from "./InventoryCheckbox";
import { BasicSelect } from "./BasicSelect";
import { SelectField } from "./Select";
import { PhoneField } from "./PhoneField";
import { LeftCheckbox, RightDoubleCheckboxRow } from "./Checkbox";
import { RightCheckbox } from "./Checkbox";
import { DatePickerField } from "./DatePicker";
import { RightDoubleCheckbox } from "./Checkbox";

interface IpropsFormikControl {
  innerRef?: React.RefObject<any>;
  children?: (message: string) => React.ReactNode;
  control:
    | "input"
    | "right-input"
    | "inventory-input"
    | "select"
    | "basic-select"
    | "inventory-select"
    | "inventory-checkbox"
    | "multi-select"
    | "left-checkbox"
    | "right-checkbox"
    | "right-double-checkbox-row"
    | "phone-number"
    | "date-picker";
  label: React.ReactNode;
  name: string;
  type?: string;
  default?: { id?: string | number; text: string }; // adds a Choose: option to select menu
  options?:
    | { id: number; text: string }[]
    | { value: number | string; label: string }[];
  searchOptions?: { id: number; text: string }[] | any[];
  styledClass?: string;
  style?: React.CSSProperties;
  labelClass?: string;
  fieldWrapperClass?: string;
  controlContainerclass?: React.HTMLAttributes<HTMLElement>["className"];
  disablefield?: number;
  replaceSecondField?: JSX.Element | null;
  notsearchable?: number;
  disabled?: boolean;
  creatable?: boolean;
  placeholder?: string;
  menuPlacement?: "top" | "auto" | "bottom";
  onchange?: () => void;
  inLineLabelError?: boolean;
  fastInput?: boolean;
  fastSearch?: boolean;
}

// function FormikControl(props: IpropsFormikControl) {
const FormikControl: React.FunctionComponent<IpropsFormikControl> = (props) => {
  const { control, type, styledClass, ...rest } = props;
  switch (control) {
    case "input": {
      let theType: string = type ? type : "text";
      let styledClassNU: string = styledClass ? styledClass : "default-input";
      return (
        <Input type={theType} styledClass={styledClassNU} {...rest}></Input>
      );
    }
    case "right-input": {
      console.log("right input");
      let theType: string = type ? type : "text";
      let styledClassNU: string = styledClass ? styledClass : "default-input";
      return (
        <RightInput type={theType} styledClass={styledClassNU} {...rest} />
      );
    }
    case "inventory-input": {
      let theType: string = type ? type : "text";
      let styledClassNU: string = styledClass ? styledClass : "inventory-input";
      return <InventoryInput disablefield={props.disablefield} {...rest} />;
    }
    case "basic-select": {
      const options: any = props.options && props.options;
      let styledClassNU: string = styledClass ? styledClass : "inventory-input";
      const newRest = { ...rest, styledClass: styledClassNU };
      return options ? (
        <BasicSelect options={options} {...newRest}></BasicSelect>
      ) : null;
    }
    case "select": {
      const options: any = props.options && props.options;
      const defaultField = props.default;

      let styledClassNU: string = styledClass ? styledClass : "inventory-input";
      const newRest = { ...rest, styledClass: styledClassNU };
      return options ? (
        <SelectField
          options={options}
          defaultField={defaultField}
          {...newRest}
        />
      ) : null;
    }
    case "inventory-select": {
      const options: any = props.options && props.options;
      return options ? <InventorySelect options={options} {...rest} /> : null;
    }
    case "multi-select": {
      const options = props.options && props.options;
      return options ? (
        <MultiSelectInventory options={options} {...rest} />
      ) : null;
    }
    case "inventory-checkbox": {
      return <InventoryCheckbox {...rest}></InventoryCheckbox>;
    }
    case "left-checkbox": {
      return <LeftCheckbox {...rest} />;
    }
    case "right-checkbox": {
      return <RightCheckbox {...rest} />;
    }
    case "right-double-checkbox-row": {
      return <RightDoubleCheckboxRow {...props} />;
    }
    case "phone-number": {
      let theType: string = type ? type : "text";
      let styledClassNU: string = styledClass ? styledClass : "default-input";
      return (
        <PhoneField type={theType} styledClass={styledClassNU} {...rest} />
      );
    }
    case "date-picker": {
      let styledClassNU: string = styledClass
        ? styledClass
        : "default-date-picker";
      return <DatePickerField styledClass={styledClassNU} {...rest} />;
    }
    default:
      return null;
  }
};

export default FormikControl;
