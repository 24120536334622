import {
  memo,
  useRef,
  useEffect,
  CSSProperties,
  DetailedHTMLProps,
  TextareaHTMLAttributes,
} from "react";
interface Props
  extends DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  value?: string;
  autoFocus?: boolean;
  wrapperStyles?: CSSProperties;
}

/**
 * Dont try to use after pseudo element for char counter... its REALLY slow
 * @param param0
 * @returns
 *
 */
export const TextAreaCounter = ({
  autoFocus = false,
  wrapperStyles,
  ...props
}: Props) => {
  const { value, maxLength } = props;
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    autoFocus && textAreaRef.current?.focus();
  }, [textAreaRef]);
  return (
    <div style={wrapperStyles} className="relative max-w-min max-h-min">
      <textarea
        {...props}
        style={{ resize: "none", ...props.style }}
        ref={textAreaRef}
      />
      <div className="absolute bottom right z-1 pr-1 pb-1">
        {value?.length || 0}/{maxLength}
      </div>
    </div>
  );
};

export const TextAreaCounterMemo = memo(TextAreaCounter);
