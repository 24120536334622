export enum ContainerItemStatuses {
  INACTIVE_ITEM = 0,
  PROPOSED_ITEM = 1,
  HOLD = 2, // this will cause an insert into holdItems
  CONFIRMED = 3,
  STAGED = 4, // this will cause an insert into stagingItems
  PICKED_UP = 5, // this will cause an update in stagingItems
  REMOVED_FROM_PROPOSAL = 6, // trash
  TRANSFER = 7, // This item will be coming from a different staging and we can ignore missing inventory
  REMOVED_FROM_CONFIRMED = 20,
  TURNED_AWAY_AT_STAGING = 21,
  EMPLOYEE_REMOVAL = 26,
  AUTOMATED_REMOVAL = 27,
  RELEASE = 71,
  ITEM_FELL_THROUGH = 72,
  MISSING = 80,
  LOST = 81,
  STOLEN = 82,
  SOLD = 90,
}
