interface Props {
  stagingBuilderID: number;
  stagingStatus: number;
  editedOn: number;
}
export const updateStagingStatus = ({
  stagingBuilderID,
  stagingStatus,
  editedOn,
}: Props): StagingBuilderReducer => {
  return {
    type: "UPDATE_STAGINGBUILDER_META",
    payload: { stagingBuilderID, editedOn, stagingStatus },
  };
};
