import { useEffect, useState } from "react";
import { FormikProps } from "formik";
import FormikControl from "../../../../FormikComponents/FormikControl";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Istate } from "../../../../../redux";
import noavatar from "../../../../../assets/noavatar.png";
import { GetDate, LocaleNumber, ParseStatus } from "../../../../constants/";
import { CustomButton } from "../../../../HighlyReusableComponents/CustomButton";
import { push } from "connected-react-router";
import { FeeHandler } from "./FeeHandler";

interface Props {
  FormikProps: FormikProps<any>;
}

export const StagingInformationEditor = ({ FormikProps }: Props) => {
  const dispatch = useDispatch();
  const user = useSelector((state: Istate) => state.data.user, shallowEqual);
  const stagingBuilder = useSelector(
    (state: Istate) =>
      state.data.stagingBuilders[user.activeStagingBuilderIndex],
    shallowEqual
  );
  // this is so that if they accidentally click the selfSign button it wont throw away their info
  const [localClientInfo, setLocalClientInfo] = useState({
    clientName: FormikProps.values.clientName,
    clientPhone: FormikProps.values.clientPhone,
    clientEmail: FormikProps.values.clientEmail,
  });
  const [isSelfSign, setIsSelfSign] = useState(FormikProps.values.selfSign);
  const handleSelfSign = () => {
    if (FormikProps.touched.selfSign) {
      if (FormikProps.values.selfSign) {
        setIsSelfSign(true);
        // set the local variable to the old info
        setLocalClientInfo({
          clientName: FormikProps.values.clientName,
          clientPhone: FormikProps.values.clientPhone,
          clientEmail: FormikProps.values.clientEmail,
        });
        // set fields to the stagers user values
        FormikProps.setFieldValue("clientName", stagingBuilder.stagerName);
        // FormikProps.setFieldValue("clientPhone", user.phone);
        FormikProps.setFieldValue("clientEmail", user.email);
      } else if (isSelfSign && !FormikProps.values.selfSign) {
        setIsSelfSign(false);
        FormikProps.setFieldValue("clientName", localClientInfo.clientName);
        FormikProps.setFieldValue("clientPhone", localClientInfo.clientPhone);
        FormikProps.setFieldValue("clientEmail", localClientInfo.clientEmail);
      }
    }
  };
  useEffect(() => {
    if (FormikProps.dirty) {
      handleSelfSign();
    }
  }, [FormikProps.values.selfSign, FormikProps.touched.selfSign]);
  useEffect(() => {
    FormikProps.resetForm();
  }, [stagingBuilder]);
  const handleSubmitClick = async (action?: "CLOSE-MODAL") => {
    handleSelfSign();
    await FormikProps.validateForm();
    FormikProps.submitForm();
    if (action === "CLOSE-MODAL") dispatch(push("/stage"));
  };

  return (
    <>
      <div>
        <div
          style={{
            width: "100%",
            margin: "0 auto",
            gridTemplateRows: "auto repeat(11, auto)",
          }}
          className="grid"
        >
          <div>
            <h1
              style={{ display: "flex", fontWeight: "bold", fontSize: "20pt" }}
            >
              <img className="w-12" src={noavatar} alt="" />
              <span style={{ marginLeft: "10px", alignSelf: "center" }}>
                {stagingBuilder.stagerName}'s Staging
              </span>
            </h1>

            <div className="flex space-between">
              <label>Staging Status</label>
              <div style={{ lineHeight: "2em" }}>
                {ParseStatus(
                  FormikProps.initialValues.stagingStatus as number,
                  user.businessID,
                  stagingBuilder.fileNumber
                )}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2">
            <div>
              <FormikControl
                disabled={
                  !(
                    user.id === stagingBuilder.userID ||
                    stagingBuilder.businessID === user.businessID
                  ) && FormikProps.values.deliveryDesired !== null
                }
                control="date-picker"
                label="Desired Delivery Date"
                name="deliveryDesired"
                labelClass="block"
                controlContainerclass="basic-input"
              />
              <FormikControl
                disabled={
                  user.businessID === 1 && stagingBuilder.employeeCreated === 1
                    ? false
                    : FormikProps.initialValues.pickupDesired < Date.now() ||
                      FormikProps.initialValues.pickupActual < Date.now()
                }
                control="date-picker"
                label="Desired Pickup Date"
                name="pickupDesired"
                labelClass="block"
                controlContainerclass="basic-input"
              />
            </div>
            <div className="ml-auto">
              <div>
                <label className="block text-bold">Actual Delivery Date</label>
                <div className="leading-10 mt-px">
                  {GetDate(stagingBuilder.deliveryActual)}
                </div>
              </div>
              <div>
                <label className="block text-bold">Actual Pickup Date</label>
                <div className="leading-10 mt-px">
                  {GetDate(stagingBuilder.pickupActual)}
                </div>
              </div>
            </div>
          </div>
          <FeeHandler formikProps={FormikProps}>
            <div className="">
              <label>Rental Rate</label>
              <div className="sup-dollar" style={{ lineHeight: "2em" }}>
                {LocaleNumber(stagingBuilder.monthlyRate, true)}
              </div>
            </div>
            <div className="">
              <label>Total Retail Value</label>
              <div className="sup-dollar" style={{ lineHeight: "2em" }}>
                {LocaleNumber(stagingBuilder.retailValue, true)}
              </div>
            </div>
          </FeeHandler>
        </div>
        <div>
          <h2>Location Information:</h2>
          <FormikControl
            styledClass="h-4ch"
            control="input"
            label="Address 1"
            name="address1"
            placeholder="Items cannot be placed on hold without an address"
          />
          <FormikControl
            styledClass="h-4ch"
            control="input"
            label="Address 2"
            name="address2"
          />
          <div className="grid grid-cols-3 gap-1">
            <FormikControl control="input" label="City" name="city" />
            <FormikControl
              controlContainerclass="control-container w-100p"
              fieldWrapperClass="field-wrapper select"
              styledClass="w-100p h-4ch"
              control="basic-select"
              label="State"
              name="state"
              labelClass="block"
              options={[{ value: "48", label: "Arizona" }]}
            />
            <FormikControl control="input" label="Zip Code" name="zip" />
          </div>
          <FormikControl
            control="input"
            label="Lock Box Or Gate Code"
            name="lockBoxCode"
            controlContainerclass="basic-input flex justify-between"
          />
          <div className="flex justify-between">
            <FormikControl
              control="right-checkbox"
              label={<span className="mr-1">One Month</span>}
              name="fee6"
            />
            <FormikControl
              control="right-checkbox"
              label={<span className="mr-1">Occupied</span>}
              name="occupied"
            />
            <FormikControl
              disabled={user.businessID !== 1}
              control="right-checkbox"
              label={<span className="mr-1">Tax Exempt</span>}
              name="taxExempt"
            />
          </div>
        </div>
        <div>
          <h2>Lessee Information:</h2>
          <FormikControl
            control="input"
            label="Customer Name"
            name="clientName"
            controlContainerclass="basic-input flex justify-between"
            disabled={FormikProps.values.selfSign}
          />
          <div className="flex space-between">
            <FormikControl
              control="phone-number"
              label="Customer Phone"
              name="clientPhone"
              disabled={FormikProps.values.selfSign}
            />
          </div>
          <FormikControl
            control="input"
            label="Customer Email"
            name="clientEmail"
            controlContainerclass="basic-input flex justify-between"
            disabled={FormikProps.values.selfSign}
          />
          <FormikControl
            control="left-checkbox"
            type="checkbox"
            label={
              <p className="p-label__checkbox">
                I (the Stager) intend to sign the lease myself and be
                responsible for the rent and care of the furniture. (Not
                typically checked)
              </p>
            }
            labelClass="grid auto-1fr"
            name="selfSign"
            disabled={stagingBuilder.userID !== user.id}
          />
        </div>
        <div className="grid grid-cols-2 gap-1">
          <CustomButton onClick={() => handleSubmitClick()} type="button">
            Save
          </CustomButton>
          <CustomButton
            onClick={() => handleSubmitClick("CLOSE-MODAL")}
            type="button"
          >
            Save & Exit
          </CustomButton>
        </div>
      </div>
    </>
  );
};
